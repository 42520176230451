import { useGetSearchProductsQuery } from '@api/api-slice';
import { getPaginationResult } from '@features/pagination/get-pagination-result';
import { IPaginable } from '@features/pagination/paginable.interface';
import { IProductSummary } from '@features/product/product.interface';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';

type HookResult = RequestStatusFlags & IPaginable<IProductSummary>;

export const useSearchProducts = (query: string): HookResult => {
  return getPaginationResult(useGetSearchProductsQuery({ query }, { skip: query === '' }));
};
