import React from 'react';
import { useMicrosoftAuthorization } from './use-microsoft-authorization';
import { Alert, Box, Button, CircularProgress, Link } from '@mui/material';

export const MicrosoftAuthorization: React.FC = () => {
  const { isLoggedIn, authorizationUri, organisationName, handleMicrosoftLogout, isLoading } =
    useMicrosoftAuthorization();

  if (isLoading) {
    return <CircularProgress sx={{ margin: 'auto' }} />;
  }

  if (!authorizationUri) {
    return <Alert severity="warning">Failed to fetch Microsoft Uri</Alert>;
  }

  return (
    <Box sx={{ textAlign: 'center', m: 1 }}>
      {isLoggedIn ? (
        <>
          <Button variant="outlined" size="small" fullWidth onClick={handleMicrosoftLogout}>
            Logout from Microsoft
          </Button>
          {organisationName}
        </>
      ) : (
        <Link href={authorizationUri} sx={{ display: 'block' }}>
          <Button variant="outlined" size="small" fullWidth>
            Login to Microsoft
          </Button>
        </Link>
      )}
    </Box>
  );
};
