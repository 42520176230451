import { Describe, StructError, array, assert, boolean, nullable, number, object, string } from 'superstruct';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { ITutor } from '@features/tutors/tutor.interface';

const TutorStruct: Describe<ITutor> = object({
  id: number(),
  firstName: string(),
  lastName: string(),
  email: string(),
  isActive: boolean(),
  activeSupportAssigned: number(),
  availability: nullable(string()),
  employmentType: nullable(string()),
  hourlyRate: number(),
  feedbackLast: nullable(number()),
  feedbackAverageWeek: nullable(number()),
  feedbackAverageMonth: nullable(number()),
  feedbackAverageTotal: nullable(number()),
  qualification: array(number()),
  hasZoho: boolean(),
  userId: number(),
});

export function validateTutors(response: { tutors: ITutor[] }, url: string): void {
  for (const tutor of response.tutors) {
    try {
      assert(tutor, TutorStruct);
    } catch (error) {
      for (const failure of (error as StructError).failures()) {
        handleFailure(failure, url);
      }
    }
  }
}
