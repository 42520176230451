import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { IProductWithCondition } from '../product.interface';

export type FormModel = Pick<
  IProductWithCondition,
  | 'productName'
  | 'productCodeAs'
  | 'productCodeSst'
  | 'isActive'
  | 'amountExVat'
  | 'vatTreatment'
  | 'trafficLight'
  | 'productDescription'
>;

export type ApiModel = Pick<
  IProductWithCondition,
  | 'productName'
  | 'productCodeAs'
  | 'productCodeSst'
  | 'isActive'
  | 'amountExVat'
  | 'vatTreatment'
  | 'trafficLight'
  | 'productDescription'
>;

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  productName: { type: 'transportable', initial: '' },
  productCodeAs: { type: 'transportable', initial: '' },
  productCodeSst: { type: 'transportable', initial: '' },
  isActive: { type: 'transportable', initial: false },
  amountExVat: { type: 'transportable', initial: 0 },
  vatTreatment: { type: 'transportable', initial: '' },
  trafficLight: { type: 'backend-label', optionKey: 'productTrafficLightOptions', initial: null },
  productDescription: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  productName: Serializers.string,
  productCodeAs: Serializers.string,
  productCodeSst: Serializers.string,
  isActive: Serializers.boolean,
  amountExVat: Serializers.float,
  vatTreatment: Serializers.string,
  trafficLight: Serializers.backendValue,
  productDescription: Serializers.string,
});
