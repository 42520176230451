import { useGetAssessorQuery } from '@api/api-slice';
import { RouteParamsType } from '@routes/route-params.type';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useParams } from 'react-router-dom';
import { IAssessorSummary } from './assessor.interface';
import { useAppSelector } from '@store/use-app-selector';
import { UserRoles } from '@features/user/user-role.type';

type HookResult = RequestStatusFlags & {
  assessor: IAssessorSummary | null;
};

export function useAssessor(): HookResult {
  const { assessorIdString } = useParams<RouteParamsType>();
  const { user } = useAppSelector((state) => state.user);

  const isUserAssessor = user?.role === UserRoles.assessor && 'assessorId' in user;
  const assessorId = isUserAssessor ? user.assessorId : parseInt(assessorIdString as string, 10);

  const { data, ...flags } = useGetAssessorQuery(assessorId as number);
  return {
    assessor: data?.assessor ?? null,
    ...flags,
  };
}
