import React, { PropsWithChildren } from 'react';
import { Stack, Switch, Tooltip, TooltipProps } from '@mui/material';
import { useToggle } from '@hooks/use-toggle';

type Disablable = { disabled: boolean };

type Props = PropsWithChildren<Disablable> & Disablable & TooltipProps & { enablable?: boolean };

/**
 * `WhyDisabled` is a React component that wraps a given child component with `disabled` prop to show tooltip, why it is disabled.
 *
 * The component accepts `TooltipProps` for mui `Tooltip` component.
 *
 * If the `disabled` prop is set to true, the child component is initially disabled, and a tooltip is displayed to provide additional information.
 * If the `enablable` prop is also true, a switch is displayed next to the child component. This switch allows users to toggle `disabled` the child component.
 */
export const WhyDisabled: React.FC<Props> = ({ children, disabled, enablable, ...tooltipProps }) => {
  const [isDisabled, toggleIsDisabled] = useToggle(disabled);

  if (disabled) {
    if (enablable) {
      return (
        <Stack direction="row" alignItems="center">
          <Switch checked={!isDisabled} onChange={toggleIsDisabled} size="small" />
          <Tooltip {...tooltipProps}>
            <div>{children}</div>
          </Tooltip>
        </Stack>
      );
    }
    return (
      <Tooltip {...tooltipProps}>
        <div>{children}</div>
      </Tooltip>
    );
  }

  return <>{children}</>;
};
