import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { Button, Grid } from '@mui/material';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { FormModel, sanitize, serialize } from './form.config';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { filterEmptyObjectFields } from '@utils/filter-empty-object-fields';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { stringifyObjectFields } from '@utils/stringify-object-fields';

export const FilterHepsForm: FC = () => {
  const { ...backendLabels } = useAppBackendLabels([]);
  const [params, setParams] = useSearchParams();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: sanitize(deserializeURLSearchParams(params), backendLabels),
  });

  function onSubmit(data: FormModel): void {
    setParams(new URLSearchParams(stringifyObjectFields(filterEmptyObjectFields(serialize(data)))));
  }

  function onReset(): void {
    reset();
    setParams({});
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1} columns={{ xs: 4, md: 8, lg: 12 }}>
        <Grid item xs={4} md={4} lg={6}>
          <ControlledTextField control={control} name="name" label="Hep Name" />
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <Button type="reset" variant="outlined" onClick={onReset} fullWidth>
            Reset
          </Button>
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <Button type="submit" variant="contained" fullWidth>
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
