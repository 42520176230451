import {
  Describe,
  StructError,
  assert,
  assign,
  boolean,
  enums,
  nullable,
  number,
  object,
  pick,
  string,
} from 'superstruct';
import { IEquipmentInvoiceSummary } from '@features/bulk-invoicing/equipment/equipment-invoice-summary.interface';
import { TechnicalOrderStruct } from '@features/order/technical/spec/technical-order-struct.spec';
import { QuoteStatuses } from '@features/quote/quote-status.type';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { MoneyStruct } from '@utils/superstruct/money.struct';
import { EquipmentInvoiceSummariesResponse } from '../api';

const EquipmentInvoiceSummaryStruct: Describe<IEquipmentInvoiceSummary> = assign(
  pick(TechnicalOrderStruct, [
    'organisationId',
    'orderTypeId',
    'quoteId',
    'isUpgradedEquipment',
    'dsa2ApprovedAmount',
    'upgradePrice',
    'isContributePayable',
  ]),
  object({
    id: number(),
    quoteStatus: enums(Object.values(QuoteStatuses)),
    exVat: MoneyStruct,
    inclVat: MoneyStruct,
    customerId: number(),
    orderId: number(),
    totalAmount: MoneyStruct,
    isMatchesDsa2: boolean(),
    funderInvoice: nullable(string()),
    dipReference: nullable(string()),
  }),
);

export function validateFilterEquipmentInvoiceSummary(response: EquipmentInvoiceSummariesResponse, url: string): void {
  for (const entry of response.entries) {
    try {
      assert(entry, EquipmentInvoiceSummaryStruct);
    } catch (error) {
      for (const failure of (error as StructError).failures()) {
        handleFailure(failure, url);
      }
    }
  }
}
