import React from 'react';
import { IOrderTechnical } from '@features/order/technical/order-technical.interface';
import { IOrderRepairAndReplace } from '@features/order/repair-and-replace/order-repair-and-replace.interface';
import { useSaveOrderElectronicInvoice } from './use-save-order-electronic-invoice';
import { useRefreshOrderElectronicInvoice } from './use-refresh-order-electronic-invoice';
import { useSendOrderElectronicInvoice } from './use-send-order-electronic-invoice';
import { ElectronicInvoice } from '@features/electronic-invoice/ElectronicInvoice';

type Props = {
  order: IOrderTechnical | IOrderRepairAndReplace;
};

export const OrderElectronicInvoice: React.FC<Props> = ({ order }) => {
  const { id, orderClass } = order;
  const { saveOrderElectronicInvoice, isLoading: isLoadingSave } = useSaveOrderElectronicInvoice(id);
  const { refreshOrderElectronicInvoice, isLoading: isLoadingRefresh } = useRefreshOrderElectronicInvoice(orderClass);
  const { sendOrderElectronicInvoice, isLoading: isLoadingSend } = useSendOrderElectronicInvoice(order.orderClass);

  async function handleSave(electronicInvoice: string | null): Promise<void> {
    if (electronicInvoice) {
      saveOrderElectronicInvoice(electronicInvoice);
    }
  }

  async function handleRefresh(): Promise<void> {
    refreshOrderElectronicInvoice(id);
  }

  async function handleSend(): Promise<void> {
    sendOrderElectronicInvoice(id);
  }

  const isLoading = isLoadingSave || isLoadingRefresh || isLoadingSend;

  if (!order.invoiceNumber) {
    return null;
  }

  return (
    <ElectronicInvoice
      invoice={order.electronicInvoice}
      invoiceLogs={order.electronicInvoiceLogs}
      isLoading={isLoading}
      handleSave={handleSave}
      handleRefresh={handleRefresh}
      handleSend={handleSend}
    />
  );
};
