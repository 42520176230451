import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useGetAppointmentSummariesQuery } from '@api/api-slice';
import { getPaginationResult } from '@features/pagination/get-pagination-result';
import { IPaginable } from '@features/pagination/paginable.interface';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { IAppointmentSummary } from '@features/appointment-summaries/appointment-summary.interface';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { sanitize, serialize } from './form-config';

type HookResult = RequestStatusFlags & IPaginable<IAppointmentSummary>;

export function useFilteredAppointments(urlSearchParams: URLSearchParams, count: number, skip: number): HookResult {
  const backendLabels = useAppBackendLabels([
    'organisationOptions',
    'assessorOptions',
    'assessmentCentreOptions',
    'statusOptions',
  ]);
  const params = serialize(sanitize(deserializeURLSearchParams(urlSearchParams), backendLabels));
  return getPaginationResult(useGetAppointmentSummariesQuery({ ...params, count, skip }));
}
