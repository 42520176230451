import React, { useMemo } from 'react';
import { ICustomer } from '@features/customer/customer.interface';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { CopyToClipboard } from '@components/CopyToClipboard';

type Props = {
  customer: ICustomer;
};

export const CopyCustomerInfo: React.FC<Props> = ({ customer }) => {
  const backendLabels = useAppBackendLabels(['fundingBodyOptions', 'hepOptions']);

  const customerInfo = useMemo(
    () => `CRN: ${customer.crn}
Name: ${customer.firstName} ${customer.lastName}
Email: ${customer.email}
Telephone Number: ${customer.contactNumber}
Alternative Number: ${customer.altNumber}
Delivery Address: ${customer.address}
Postcode: ${customer.postcode}
Funding Body: ${backendLabels.fundingBodyOptions.find((option) => customer.fundingBodyId === option.value)?.label}
HEP: ${backendLabels.hepOptions.find((option) => customer.hepId === option.value)?.label}
Notes: ${customer.notes}
`,
    [customer, backendLabels],
  );

  return (
    <CopyToClipboard textToCopy={customerInfo} tooltipText="Changes you made before saving form will not be copied" />
  );
};
