/** Represent date as ISO String, YYYY-MM-DD format, without time */
export type ISODateString = string;
/** Represent date as ISO String, YYYY-MM-DDTHH:mm:ss */
export type ISODateTimeString = string;

export const ISODateRegexp = /^\d{4}-\d{2}-\d{2}/;
/** Check if date string */
export function isISODateString(date: unknown): date is ISODateString {
  return typeof date === 'string' && ISODateRegexp.test(date);
}

export const ISODateWithoutTimeRegexp = /^\d{4}-\d{2}-\d{2}$/;
/** Check if date without time and timezone, so we do not get timezone into account */
export function isISODateWithoutTimeString(date: unknown): date is ISODateString {
  return typeof date === 'string' && ISODateWithoutTimeRegexp.test(date);
}

export const ISODateTimeRegexp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
/** Check if date with time */
export function isISODateTimeString(date: unknown): date is ISODateTimeString {
  return typeof date === 'string' && ISODateTimeRegexp.test(date);
}
