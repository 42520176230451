import { useEffect } from 'react';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { IReport } from './report.interface';
import { useGetReportsQuery } from '@api/api-slice';
import { isErrorResponse } from '@api/error-response.interface';
import { useAppDispatch } from '@store/use-app-dispatch';
import { useAppSelector } from '@store/use-app-selector';
import { ReportsActions, selectReportsByOrderId } from './reports.slice';

type HookResult = RequestStatusFlags & {
  reports: IReport[];
};

export function useReports(orderId: number): HookResult {
  const { data, ...flags } = useGetReportsQuery({ orderId });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isErrorResponse(data) && data) {
      dispatch(ReportsActions.upsertReports(data));
    }
  }, [data, dispatch]);

  const reports = useAppSelector((state) => selectReportsByOrderId(state, orderId));

  return { reports, ...flags };
}
