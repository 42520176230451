import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label: string;
  disabled?: boolean;
  defaultValue?: FieldValues[Path<T>];
};

export const ControlledCheckbox: <T extends FieldValues>(props: Props<T>) => JSX.Element = ({
  control,
  name,
  label,
  disabled = false,
  defaultValue = false,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }): JSX.Element => (
        <FormControlLabel
          control={<Checkbox checked={value} onChange={onChange} />}
          label={label}
          disabled={disabled}
        />
      )}
    />
  );
};
