import { useVerifyMutation } from '@api/api-slice';
import { useState } from 'react';
import { AxiosResponse } from 'axios';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { IResetResponseError, IVerifyEmailRequest } from './api';

type HookResult = {
  handleVerifyEmail: (account: IVerifyEmailRequest) => Promise<void>;
  errorMessage: string;
} & RequestStatusFlags;

export function useVerifyEmail(): HookResult {
  const [verifyEmail, flags] = useVerifyMutation();
  const [errorMessage, setErrorMessage] = useState('');
  const handleVerifyEmail = async (account: IVerifyEmailRequest): Promise<void> => {
    try {
      await verifyEmail(account).unwrap();
    } catch (error) {
      setErrorMessage((error as AxiosResponse<IResetResponseError>).data.error);
    }
  };

  return { handleVerifyEmail, errorMessage, ...flags };
}
