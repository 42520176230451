import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Alert, Stack, TextField, Typography } from '@mui/material';
import { useAppTitle } from '@hooks/use-app-title';
import { useConfirmPassword } from './use-confirm-password';
import { AuthState, IPassword } from './Reset';
import { AppLoadingButton } from '@components/AppLoadingButton';

type Props = {
  password: IPassword;
  handleNext: (step: number) => void;
};

export const ConfirmCode: React.FC<Props> = ({ password, handleNext }) => {
  const { handleConfirmPassword, isLoading, isSuccess, errorMessage, isError } = useConfirmPassword();
  const [code, setCode] = useState('');
  useAppTitle('Confirm Code');
  const onSubmit = async (event: SyntheticEvent): Promise<void> => {
    event.preventDefault();

    await handleConfirmPassword({ account: { ...password, reset_password_token: code } });
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      handleNext(AuthState.Complete);
    }
  }, [handleNext, isSuccess, isError]);

  const isButtonDisabled = code.trim() === '';

  return (
    <form onSubmit={onSubmit}>
      <Stack direction="column" spacing={2} sx={{ marginTop: 3 }}>
        <Typography variant="subtitle2" color="textSecondary">
          Confirm code from Email
        </Typography>
        <TextField
          variant="outlined"
          inputProps={{ minLength: 4 }}
          id="outlined-adornment-code"
          type="number"
          label="Code"
          name="reset_password_token"
          required
          value={code}
          onChange={(event): void => setCode(event.target.value)}
        />
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <AppLoadingButton
          variant="contained"
          size="large"
          type="submit"
          isLoading={isLoading}
          disabled={isButtonDisabled}
          fullWidth
        >
          Submit
        </AppLoadingButton>
      </Stack>
    </form>
  );
};
