import React, { useEffect } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { useGetAnnouncement } from './use-get-announcement';

export const useAnnouncement = (): void => {
  const { announcement } = useGetAnnouncement();

  useEffect(() => {
    const shouldShowAnnouncement = announcement?.platforms?.includes('CRM');

    if (shouldShowAnnouncement) {
      enqueueSnackbar(
        <>
          <Stack direction="row" sx={{ width: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h6" sx={{ marginRight: 2 }}>
                {announcement.title}
              </Typography>
              <Typography sx={{ flexGrow: 1 }}>{announcement.text}</Typography>
            </Box>
            <IconButton color="inherit" onClick={(): void => closeSnackbar()} sx={{ alignSelf: 'center' }}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </>,
        {
          variant: 'success',
          persist: true,
        },
      );
    }
  }, [announcement]);
};
