import { Describe, assign, enums, literal, nullable, number, object, omit, string } from 'superstruct';
import { RepairAndReplaceTypes } from '../repair-and-replace-type.type';
import { TechnicalOrderStruct } from '@features/order/technical/spec/technical-order-struct.spec';
import { IOrderRepairAndReplace } from '../order-repair-and-replace.interface';
import { OrderClasses } from '@features/order/order-class.type';
import { FileStruct } from '@utils/superstruct/file-struct';

export const RepairAndReplaceOrderStruct: Describe<IOrderRepairAndReplace> = assign(
  omit(TechnicalOrderStruct, ['quotationFilePath', 'quotationFile']),
  object({
    orderClass: literal(OrderClasses.RepairAndReplace),
    repairAndReplaceType: nullable(enums(Object.values(RepairAndReplaceTypes))),
    rnrQuotationFilePath: nullable(string()),
    rnrQuotationFile: nullable(FileStruct),
    repairAndReplaceReportId: nullable(number()),
  }),
);
