import moment from 'moment-timezone';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { IAnnualSupportCharge } from '../annual-support-charge-list/annual-support-charge.interface';

export type FormModel = Pick<
  IAnnualSupportCharge,
  | 'numberOfSupportYear'
  | 'annualSupportInclVat'
  | 'annualSupportExVat'
  | 'billingDate'
  | 'cancellationReason'
  | 'isCancelled'
  | 'invoice'
>;

export type ApiModel = Pick<
  IAnnualSupportCharge,
  | 'numberOfSupportYear'
  | 'annualSupportInclVat'
  | 'annualSupportExVat'
  | 'billingDate'
  | 'cancellationReason'
  | 'isCancelled'
  | 'invoice'
>;

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  numberOfSupportYear: { type: 'transportable', initial: 0 },
  annualSupportExVat: { type: 'transportable', initial: 0 },
  annualSupportInclVat: { type: 'transportable', initial: 0 },
  billingDate: { type: 'date', initial: moment() },
  cancellationReason: { type: 'backend-label', optionKey: 'ascCancellationReasonOptions', initial: null },
  isCancelled: { type: 'transportable', initial: false },
  invoice: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  numberOfSupportYear: Serializers.integer,
  annualSupportExVat: Serializers.float,
  annualSupportInclVat: Serializers.float,
  billingDate: Serializers.date,
  isCancelled: Serializers.boolean,
  cancellationReason: Serializers.backendValue,
  invoice: Serializers.string,
});
