import { useGetAssessmentCentreQuery } from '@api/api-slice';
import { RouteParamsType } from '@routes/route-params.type';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useParams } from 'react-router-dom';
import { IAssessmentCentre } from './assessment-centre.interface';

type HookResult = {
  assessmentCentre: IAssessmentCentre | null;
} & RequestStatusFlags;

export function useAssessmentCentre(): HookResult {
  const { assessmentCentreIdString } = useParams<RouteParamsType>();
  const assessmentCentreId = parseInt(assessmentCentreIdString as string, 10);
  const { data, ...flags } = useGetAssessmentCentreQuery(assessmentCentreId);

  return {
    assessmentCentre: data?.assessmentCentre ?? null,
    ...flags,
  };
}
