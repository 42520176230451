import { useSaveSessionMutation } from '@api/api-slice';
import { ISession } from '../session.interface';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { isErrorResponse } from '@api/error-response.interface';
import { useAppDispatch } from '@store/use-app-dispatch';
import { SessionsActions } from '../sessions.slice';

type HookResult = {
  saveSessionElectronicInvoice: (value: string) => Promise<void>;
} & RequestStatusFlags;

export function useSaveSessionElectronicInvoice(session: ISession, allocationId: number): HookResult {
  const [saveSessionMutation, flags] = useSaveSessionMutation();
  const dispatch = useAppDispatch();

  const saveSessionElectronicInvoice = async (electronicInvoice: string | null): Promise<void> => {
    const data = {
      allocationId,
      payload: {
        ...session,
        electronicInvoice,
      },
    };

    const result = await saveSessionMutation(data).unwrap();
    if (isErrorResponse(result)) {
      return;
    }
    dispatch(SessionsActions.upsertSession(result));
  };

  return {
    saveSessionElectronicInvoice,
    ...flags,
  };
}
