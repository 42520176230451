import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { store } from '@store/store';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { hasNestedFile } from '@utils/has-nested-file';
import { createFormData } from '@utils/form-data';
import { AnonymousEndpoints } from './anonymous-endpoints';
import { UserActions } from '@features/user/user.slice';
import { enqueueSnackbar } from 'notistack';
import { IErrorResponse } from './error-response.interface';

export const httpClient = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  validateStatus: (status) => status >= 200 && status < 500,
});

export function applyTokenFromState(): void {
  const state = store.getState();
  const { token } = state.user;
  if (token) {
    patchAuthorizationHeader(token);
  }
}

export function patchAuthorizationHeader(token: string): void {
  httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export function removeAuthorizationHeader(): void {
  httpClient.defaults.headers.common.Authorization = undefined;
}

export type AxiosBaseQuery = BaseQueryFn<
  {
    url: string;
    method: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
  },
  unknown,
  unknown
>;

function isAuthorizedRequest(url: string): boolean {
  return !Object.values(AnonymousEndpoints).includes(url);
}

function isNoToken(): boolean {
  return !httpClient.defaults.headers.common.Authorization;
}

export function axiosBaseQuery({ baseUrl }: { baseUrl: string }): AxiosBaseQuery {
  return async ({ url, method, data, params }) => {
    const isDenied = isAuthorizedRequest(url) && isNoToken();
    if (isDenied) {
      // Testing no token response breaking
      // eslint-disable-next-line no-console
      console.log({ url });
      return {
        error: {
          status: 401,
          data: 'Access denied',
        },
      };
    }
    try {
      // if posting a form with file, using special axios method for it
      // and creating and populating form data
      const methodNames = ['POST', 'PUT', 'PATCH'];
      const isMethodWithBody = method && methodNames.includes(method.toUpperCase());
      if (isMethodWithBody && hasNestedFile(data)) {
        const methodName = `${method.toLowerCase()}Form` as 'postForm' | 'putForm' | 'patchForm';
        return httpClient[methodName](baseUrl + url, createFormData(data));
      }

      // else sending regular request with optional JSON body
      const result = await httpClient.request({ url: baseUrl + url, method, data, params });
      if (result.status >= 400) {
        const { message } = result.data as IErrorResponse;
        if (result.status === 401) {
          store.dispatch(UserActions.logOut());
        }
        enqueueSnackbar(message);
      }

      return result;
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      const message = err.message ?? 'Internal Server Error';
      enqueueSnackbar(`${message} accessing ${url}`);
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
}
