import { useNavigate, useParams } from 'react-router-dom';
import { RouteParamsType } from '@routes/route-params.type';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { ICustomer } from '@features/customer/customer.interface';
import { useGetCustomerQuery } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { useEffect } from 'react';
import { CustomersActions, selectCustomerState } from './customer.slice';
import { Routes } from '@routes/routes';
import { enqueueSnackbar } from 'notistack';
import { useAppSelector } from '@store/use-app-selector';

type HookResult = RequestStatusFlags & {
  customer: ICustomer | null;
};

export function useCustomer(): HookResult {
  const { customerIdString, orderIdString } = useParams<RouteParamsType>();
  // the customer id is always exist since the hook used on customer route
  const customerId = parseInt(customerIdString as string, 10);

  const { data, ...flags } = useGetCustomerQuery(customerId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      dispatch(CustomersActions.setCustomer(data));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (data) {
      const firstOrderId = data.ordersTabs[0]?.id;
      if (!firstOrderId) {
        // TODO remove after fix orderTabs for assessors
        enqueueSnackbar(
          `AccessDenied. This Appointment has either been completed or cancelled. Please note, once Assessment process is completed you will no longer be able to access student data. Please contact Assessment Admin team if you need to access student data. They will unlock the order for you.`,
          { variant: 'warning' },
        );
        navigate(`${Routes.orderSummaries}/${customerIdString}`);
      } else {
        if (!orderIdString) {
          enqueueSnackbar(`No order id found, selecting first customer order`, { variant: 'warning' });
          navigate(`${Routes.orderSummaries}/${customerIdString}/${firstOrderId}`);
        } else {
          // TODO_REF fix this
          // const orderId = parseInt(orderIdString, 10);
          // const ids = customer.ordersTabs.map(({ id }) => id);
          // if (!ids.includes(orderId)) {
          //   enqueueSnackbar(
          //     `Given order id: ${orderId} doesn't belong to this customer, selecting first customer order`,
          //     { variant: 'warning' },
          //   );
          //   navigate(`${Routes.orderSummaries}/${customerIdString}/${firstOrderId}`);
          // }
        }
      }
    }
  }, [customerIdString, data, dispatch, navigate, orderIdString]);

  const customer = useAppSelector((state) => selectCustomerState(state));

  return { customer, ...flags };
}
