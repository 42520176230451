import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { GridSortModel } from '@mui/x-data-grid';
import { AppDataGrid } from '@components/AppDataGrid';
import { WhyDisabled } from '@components/WhyDisabled';
import { AppModal } from '@components/AppModal';
import { SaveSessionForm } from './save-session-form/SaveSessionForm';
import { useSessions } from './use-sessions';
import { ISODateString } from '@utils/dates/iso-string.type';
import { ChooseSessionTimeSlot } from '@features/sessions/save-session-form/ChooseSessionTimeSlot';
import { SwitchBooking } from '@features/automatic-booking/SwitchBooking';
import { IAllocation } from '@features/allocations/allocation.interface';
import { useToggle } from '@hooks/use-toggle';
import { useFilteredSessionColumns } from './use-filtered-session-columns';

type Props = {
  allocation: IAllocation;
  isDisabled: boolean;
  endDate: ISODateString | null;
};

const defaultSortModel: GridSortModel = [
  {
    field: 'startMoment',
    sort: 'desc',
  },
];

export const Sessions: React.FC<Props> = ({ allocation, isDisabled, endDate }) => {
  const { sessions, isLoading } = useSessions(allocation.id);
  const [isModalOpen, toggleIsModalOpen] = useToggle();
  const [activeSessionId, setActiveSessionId] = useState<number | null>(null);
  const [isExtended, toggleIsExtended] = useToggle();

  const handleCloseModal = useCallback((): void => {
    setActiveSessionId(null);
    toggleIsModalOpen();
  }, [toggleIsModalOpen]);

  const handleEditSession = useCallback(
    (id: number | null) => {
      setActiveSessionId(id);
      toggleIsModalOpen();
    },
    [toggleIsModalOpen],
  );

  const columns = useFilteredSessionColumns(handleEditSession, isExtended, allocation.id);

  const activeSession = useMemo(
    () => sessions.find((session) => session.id === activeSessionId),
    [activeSessionId, sessions],
  );

  const modalTitle = activeSessionId === null ? 'New Session' : 'Edit Session';

  return (
    <Box sx={{ marginTop: 2 }}>
      <Stack direction="row" alignItems="center" sx={{ marginBottom: 1 }}>
        <Typography variant="h5">Sessions for Allocation {allocation.id}</Typography>
        <Button sx={{ ml: 1 }} variant="outlined" size="small" onClick={toggleIsExtended}>
          {isExtended ? 'Show Less' : 'Show More'}
        </Button>
        <Box sx={{ ml: 'auto' }}>
          <WhyDisabled
            title="Allocation end date should be in the future and should be usable hours"
            disabled={isDisabled}
            enablable
          >
            <Button
              variant="outlined"
              size="small"
              disabled={isDisabled}
              onClick={(): unknown => handleEditSession(null)}
              startIcon={<AddIcon />}
            >
              Create Session
            </Button>
          </WhyDisabled>
        </Box>
      </Stack>
      <AppDataGrid
        sx={{ height: '320px' }}
        rows={sessions}
        columns={columns}
        sortModel={defaultSortModel}
        loading={isLoading}
      />
      <AppModal open={isModalOpen} onClose={handleCloseModal} title={modalTitle}>
        <SwitchBooking
          isInitiallyManual={activeSessionId !== null}
          AutomaticComponent={
            <ChooseSessionTimeSlot
              sessionId={activeSession?.id ?? null}
              allocationId={allocation.id}
              qualification={allocation.nmhSupportType}
              onClose={handleCloseModal}
              tutorId={allocation.tutorId}
            />
          }
          ManualComponent={
            <SaveSessionForm
              session={activeSession}
              allocationId={allocation.id}
              endDate={endDate}
              onClose={handleCloseModal}
            />
          }
        />
      </AppModal>
    </Box>
  );
};
