import React, { PropsWithChildren, createContext, useState } from 'react';
import { Drawer } from '@mui/material';

type OpenPDFHandler = (pdfURL: string) => void;

export const PreviewPDFContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openPDF: (pdfURL: string) => {
    return;
  },
});

export const PreviewPDFProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [pdfURL, setPdfURL] = useState<string | null>(null);

  const openPDF: OpenPDFHandler = (pdfURL: string) => setPdfURL(pdfURL);
  const closePDF = (): void => setPdfURL(null);

  return (
    <PreviewPDFContext.Provider value={{ openPDF }}>
      {children}
      <Drawer anchor="right" open={!!pdfURL} onClose={closePDF} PaperProps={{ sx: { width: '62vw' } }}>
        {pdfURL && <iframe src={pdfURL} width="100%" height="100%" frameBorder="0" />}
      </Drawer>
    </PreviewPDFContext.Provider>
  );
};
