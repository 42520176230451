import React from 'react';
import { AppPaper } from '@components/AppPaper';
import { useAppTitle } from '@hooks/use-app-title';
import { AssessorWeekTimeSlots } from '@features/assessors/week-time-slots/AssessorWeekTimeSlots';
import { AssessorDetails } from './AssessorDetails';
import { AssessorFeedback } from './AssessorFeedback';
import { AssessorProgressRecords } from './AssessorProgressRecords';

export const AssessorPage: React.FC = () => {
  useAppTitle('Assessors');
  return (
    <AppPaper>
      <AssessorDetails />
      <AssessorWeekTimeSlots />
      <AssessorFeedback />
      <AssessorProgressRecords />
    </AppPaper>
  );
};
