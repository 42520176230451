import React from 'react';
import { Button } from '@mui/material';
import { AppDrawer } from '@components/AppDrawer';
import { useToggle } from '@hooks/use-toggle';
import { TheftClaimFormDrawer } from './TheftClaimFormDrawer';

type Props = {
  id: number | null;
  formId: number | null;
};

export const TheftClaimForm: React.FC<Props> = ({ id, formId }) => {
  const [isDrawerOpen, toggleIsDrawerOpen] = useToggle();
  const buttonLabel = !formId ? 'Create Theft Claim Form' : 'Edit Theft Claim Form';

  return (
    <>
      <Button variant="outlined" fullWidth onClick={toggleIsDrawerOpen}>
        {buttonLabel}
      </Button>

      <AppDrawer open={isDrawerOpen} onClose={toggleIsDrawerOpen}>
        <TheftClaimFormDrawer id={id} />
      </AppDrawer>
    </>
  );
};
