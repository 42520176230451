import { assert, define } from 'superstruct';
import { ErgonomicAssessmentOrderStruct } from '@features/order/ergonomic-assessment/spec/ergonomic-assessment-order-struct.spec';
import { NewOrderStruct } from '@features/order/new/spec/new-order-struct.spec';
import { NeedsAssessmentOrderStruct } from '@features/order/needs-assessment/spec/needs-assessment-order-struct.spec';
import { NmhOrderStruct } from '@features/order/nmh/spec/nmh-order-struct.spec';
import { TechnicalOrderStruct } from '@features/order/technical/spec/technical-order-struct.spec';
import { IOrder } from '@features/order/order.interface';
import { OrderClasses } from '@features/order/order-class.type';
import { RepairAndReplaceOrderStruct } from '../repair-and-replace/spec/repair-and-replace-order-struct.spec';

export const OrderStruct = define<IOrder>('Order', (order) => {
  switch ((order as IOrder).orderClass) {
    case null:
      assert(order, NewOrderStruct);
      break;
    case OrderClasses.ErgonomicAssessment:
      assert(order, ErgonomicAssessmentOrderStruct);
      break;
    case OrderClasses.NeedsAssessment:
      assert(order, NeedsAssessmentOrderStruct);
      break;
    case OrderClasses.Nmh:
      assert(order, NmhOrderStruct);
      break;
    case OrderClasses.Technical:
      assert(order, TechnicalOrderStruct);
      break;
    case OrderClasses.RepairAndReplace:
      assert(order, RepairAndReplaceOrderStruct);
      break;
  }
  return true;
});
