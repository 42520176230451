import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { AppModal } from '@components/AppModal';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { useRole } from '@features/user/use-role';
import { UserRoles } from '@features/user/user-role.type';
import { useToggle } from '@hooks/use-toggle';
import { useResumeAccount } from './use-resume-account';

type Props = {
  customerId: number;
};

export const ResumeAccount: React.FC<Props> = ({ customerId }) => {
  const [isModalOpen, toggleIsModalOpen] = useToggle();
  const { submitHandler, isLoading } = useResumeAccount();
  const role = useRole();

  async function handleResume(): Promise<void> {
    const isSuspended = await submitHandler(customerId);
    if (isSuspended) {
      toggleIsModalOpen();
    }
  }

  const canResumeAccount = role === UserRoles.financeManager;

  if (!canResumeAccount) {
    return null;
  }

  return (
    <>
      {/* Here button is contained, because is the only action user can do with the customer. The save option is not available and greyed out */}
      <Button onClick={toggleIsModalOpen} variant="contained" size="large" color="warning">
        Resume Account
      </Button>

      <AppModal open={isModalOpen} onClose={toggleIsModalOpen} title="Please Fill Suspension Reason" color="warning">
        <Box>
          <Typography variant="h6" gutterBottom>
            You are about to resume customer account. All cancelled orders will remain cancelled. You will need to
            unlock individual orders to make any account changes. If you resume the account to perform any finance or
            account maintenance action, please remember to suspend the account again upon completion.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Please confirm to continue.
          </Typography>

          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
            <AppLoadingButton
              isLoading={isLoading}
              onClick={handleResume}
              variant="contained"
              color="warning"
              fullWidth
              sx={{ mt: 2 }}
            >
              Confirm Resume
            </AppLoadingButton>
            <Button variant="outlined" onClick={toggleIsModalOpen}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </AppModal>
    </>
  );
};
