import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheftClaimForm } from './use-theft-claim-form';
import { SaveTheftClaimForm } from './SaveTheftClaimForm';
import { TheftClaimFormProgressRecords } from './TheftClaimFormProgressRecords';

type Props = {
  id: number | null;
};
// TODO Remove assertion
export const TheftClaimFormDrawer: React.FC<Props> = ({ id }) => {
  const { theftClaimForm, setTheftClaimForm } = useTheftClaimForm(id);
  return (
    <Box>
      <Typography variant="h4" component="h1">
        SCL Repair and Replace Theft Claim Form
      </Typography>
      <SaveTheftClaimForm theftClaimForm={theftClaimForm} setTheftClaimForm={setTheftClaimForm} orderId={id!} />
      {theftClaimForm && <TheftClaimFormProgressRecords id={theftClaimForm.id} />}
    </Box>
  );
};
