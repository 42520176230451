import { useParams } from 'react-router-dom';
import { useGetTutorFeedbackSummariesQuery } from '@api/api-slice';
import { getPaginationResult } from '@features/pagination/get-pagination-result';
import { IPaginable } from '@features/pagination/paginable.interface';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { RouteParamsType } from '@routes/route-params.type';
import { ITutorFeedbackSummary } from './tutor-feedback-summary.interface';

type HookResult = RequestStatusFlags & IPaginable<ITutorFeedbackSummary>;

export function useTutorFeedbackSummaries(params: URLSearchParams, count: number, skip: number): HookResult {
  const { tutorIdString } = useParams<RouteParamsType>();
  // the tutor id is always exist since the hook used on tutor route
  const tutorId = parseInt(tutorIdString as string, 10);
  return getPaginationResult(useGetTutorFeedbackSummariesQuery({ tutorId, count, skip }));
}
