import { AnyAction, Dispatch, Middleware } from '@reduxjs/toolkit';
import { UserActions } from '@features/user/user.slice';
import { persistor } from '@store/store';
import { removeAuthorizationHeader } from '@api/http-query-client';

export const purgeOnLogoutMiddleware: Middleware = () => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
  if (action.type === UserActions.logOut.type) {
    persistor.purge();
    removeAuthorizationHeader();
  }
  return next(action);
};
