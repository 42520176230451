import { AssessmentCentreSummaryResponse } from '@features/assessment-centres/api';
import { AssessmentCentreSummary } from '@features/assessment-centres/assessment-centre.interface';
import { Describe, StructError, array, assert, nullable, number, object, string } from 'superstruct';
import { handleFailure } from '@utils/superstruct/handle-failure';

const AssessmentCentreStruct: Describe<AssessmentCentreSummary> = object({
  id: number(),
  name: string(),
  city: nullable(string()),
  postcode: nullable(string()),
  assessorIds: array(number()),
});

export function validateAssessmentCentres(response: AssessmentCentreSummaryResponse, url: string): void {
  for (const entry of response.entries) {
    try {
      assert(entry, AssessmentCentreStruct);
    } catch (error) {
      for (const failure of (error as StructError).failures()) {
        handleFailure(failure, url);
      }
    }
  }
}
