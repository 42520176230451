import React from 'react';
import { Moment } from 'moment-timezone';
import { Box, CircularProgress, Theme, styled } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { theme } from '@context/MUIProvider';
import { StyledComponent } from 'styled-components';
import { CALENDAR_WIDTH } from './calendar-width';

const FullWidthWrapper = (function generateStyledWrapper(): StyledComponent<'div', Theme> {
  return styled('div')`
    .MuiDateCalendar-root {
      width: ${CALENDAR_WIDTH}px;
    }
    .MuiCalendarPicker-root {
      width: ${CALENDAR_WIDTH}px;
    }
    .MuiPickersCalendarHeader-root {
      padding: 0;
      margin-top: 8px;
    }
    .MuiDayCalendar-weekDayLabel,
    .MuiPickersDay-dayWithMargin {
      justify-content: flex-start;
      width: ${CALENDAR_WIDTH / 7}px;
      border-radius: 0;
      margin: 0;
    }
    .MuiPickersDay-root.Mui-selected,
    .MuiPickersDay-root.Mui-selected:focus {
      background: ${theme.palette.action.selected};
      color: inherit;
    }
    .MuiPickersDay-root.Mui-selected:hover {
      background: ${theme.palette.action.hover};
      color: inherit;
    }
  `;
})();

type Props = {
  isLoading: boolean;
  renderDay: (props: PickersDayProps<Moment>) => JSX.Element;
  value: Moment;
  onChange: (date: Moment) => unknown;
  onMonthChange?: React.Dispatch<React.SetStateAction<Moment>>;
  disablePast?: boolean;
};

export const AppDatePicker: React.FC<Props> = ({
  isLoading,
  renderDay,
  value,
  onChange,
  onMonthChange,
  disablePast,
}) => {
  return (
    <Box sx={{ width: `${CALENDAR_WIDTH}px` }}>
      <FullWidthWrapper>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={value}
          onChange={(nextDate): unknown => nextDate && onChange(nextDate)}
          onMonthChange={(monthFirstDay): unknown => onMonthChange && onMonthChange(monthFirstDay)}
          disableHighlightToday
          disabled={isLoading}
          disablePast={disablePast}
          loading={isLoading}
          slots={{ day: renderDay }}
          renderLoading={(): JSX.Element => <CircularProgress />}
        />
      </FullWidthWrapper>
    </Box>
  );
};
