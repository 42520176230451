import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { FormModel, sanitize, serialize } from './form-config';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { stringifyObjectFields } from '@utils/stringify-object-fields';
import { filterEmptyObjectFields } from '@utils/filter-empty-object-fields';
import { Button, Stack } from '@mui/material';
import { ControlledDatePicker } from '@components/inputs/ControlledDatePicker';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ControlledTextField } from '@components/inputs/ControlledTextField';

export const FilterEquipmentInvoice: FC = () => {
  const { ...backendLabels } = useAppBackendLabels(['organisationOptions']);
  const [params, setParams] = useSearchParams();
  const { control, handleSubmit, reset } = useForm<FormModel>({
    defaultValues: sanitize(deserializeURLSearchParams(params), backendLabels),
  });

  function onSubmit(data: FormModel): void {
    setParams(new URLSearchParams(stringifyObjectFields(filterEmptyObjectFields(serialize(data)))));
  }

  function onReset(): void {
    reset();
    setParams({});
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" spacing={1}>
        <ControlledDatePicker control={control} name="pickUpFrom" label="Pick Up Date From" />
        <ControlledDatePicker control={control} name="pickUpTo" label="Pick Up Date To" />
        <ControlledAutocomplete
          control={control}
          name="organisationId"
          label="Warehouse"
          options={backendLabels.organisationOptions}
          multiple
        />
        <ControlledTextField control={control} name="dipReference" label="Dip Reference" />
        <Button type="reset" variant="outlined" onClick={onReset} fullWidth>
          Reset
        </Button>
        <Button type="submit" variant="contained" fullWidth>
          Search
        </Button>
      </Stack>
    </form>
  );
};
