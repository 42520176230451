import { useGetAssessorUtilisationQuery } from '@api/api-slice';
import { useSearchParams } from 'react-router-dom';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { IAssessorAvailability } from '../assessor-availability.interface';
import { ISlotSummary } from '../slot-summary.interface';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { sanitize, serialize } from './form-config';

type HookResult = RequestStatusFlags & {
  assessorAvailability: IAssessorAvailability[];
  slotSummary: ISlotSummary | null;
};

export function useFilteredAssessorUtilisation(): HookResult {
  const [urlSearchParams] = useSearchParams();
  const backendLabels = useAppBackendLabels(['assessorOptions', 'employmentOptions', 'organisationOptions']);
  const params = serialize(sanitize(deserializeURLSearchParams(urlSearchParams), backendLabels));
  const { data, ...flags } = useGetAssessorUtilisationQuery({ ...params });
  return {
    assessorAvailability: data?.assessorAvailability ?? [],
    slotSummary: data?.slotSummary ?? null,
    ...flags,
  };
}
