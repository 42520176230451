import { FieldValues, Path, UseFormSetError } from 'react-hook-form';
import { IErrorResponse } from './error-response.interface';

export type ErrorHandler = (response: IErrorResponse) => unknown;

/**
 * Factory function to create an error handler for a given form model.
 * This handler will set form errors based on an IErrorResponse object.
 *
 * @template FormModel - A type that extends FieldValues, representing the shape of the form data.
 * @param {UseFormSetError<FormModel>} setError - The setError function from React Hook Form's useForm.
 * @returns {ErrorHandler} - Returns an error handler function.
 */
export function errorHandlerFactory<FormModel extends FieldValues>(setError: UseFormSetError<FormModel>): ErrorHandler {
  return (errorResponse: IErrorResponse): void => {
    for (const field of errorResponse.fields) {
      setError(field.name as Path<FormModel>, {
        type: 'validate',
        message: field.message,
      });
    }
  };
}
