import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useEmailTemplates } from './use-email-templates';
import { useCreateOrderContactRecord } from '@features/order/shared/order-action-records/use-create-order-contact-record';
import { IOrder } from '@features/order/order.interface';
import { ICustomer } from '@features/customer/customer.interface';
import { RecordModelNames } from '@features/action-records/record-model-name.type';

type Props = {
  customerId: ICustomer['id'];
  orderId: IOrder['id'];
  orderTypeId: IOrder['orderTypeId'];
};

export const EmailTemplates: React.FC<Props> = ({ customerId, orderId, orderTypeId }) => {
  const emailTemplates = useEmailTemplates(customerId);
  const { handleSaveContactRecord } = useCreateOrderContactRecord();
  const emailTemplateForOrderType = emailTemplates.filter((template) => template.orderTypeId === orderTypeId);

  function handleLinkClick(emailTemplateName: string): void {
    handleSaveContactRecord({
      modelName: RecordModelNames.Order,
      id: orderId,
      request: {
        way: 'out', // For now hardcoded
        record_type: 'email', // For now hardcoded
        details: `Send ${emailTemplateName} template`,
      },
    });
  }

  return (
    <Box sx={{ marginTop: 2 }}>
      <Stack direction="row" alignItems="center" sx={{ marginBottom: 1 }} spacing={1}>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Email Templates
        </Typography>
        {emailTemplateForOrderType.map((template) => (
          <Button
            key={template.id}
            component={NavLink}
            variant="outlined"
            size="small"
            to={decodeURIComponent(template.mailToUrl)}
            target="_blank"
            onClick={(): void => handleLinkClick(template.templateName)}
            endIcon={<OpenInNewIcon />}
          >
            {template.templateName}
          </Button>
        ))}
      </Stack>
    </Box>
  );
};
