import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Card, Link, Stack, Typography } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import { useAppTitle } from '@hooks/use-app-title';
import { Routes } from '@routes/routes';
import { VerifyEmail } from './VerifyEmail';
import { ConfirmPassword } from './ConfirmPassword';
import { ConfirmCode } from './ConfirmCode';

export interface IPassword {
  password: string;
  password_confirmation: string;
}

const defaultValues: IPassword = {
  password: '',
  password_confirmation: '',
};

export const AuthState = {
  VerifyEmail: 0,
  ConfirmPassword: 1,
  ConfirmCode: 2,
  Complete: 3,
};

export const Reset: React.FC = () => {
  const [activeStep, setActiveStep] = useState(AuthState.VerifyEmail);
  const [password, setPassword] = useState<IPassword>(defaultValues);
  useAppTitle('Reset Password');

  const handleNext = (step: number): void => {
    setActiveStep(step);
  };

  const renderCurrentView = (): React.ReactNode => {
    switch (activeStep) {
      case AuthState.VerifyEmail:
        return <VerifyEmail handleNext={handleNext} />;
      case AuthState.ConfirmPassword:
        return <ConfirmPassword handleNext={handleNext} setPassword={setPassword} password={password} />;
      case AuthState.ConfirmCode:
        return <ConfirmCode handleNext={handleNext} password={password} />;
      case AuthState.Complete:
        return (
          <Stack direction="column" alignItems="center" spacing={2} sx={{ marginTop: 3 }}>
            <Typography>Password updated!</Typography>
            <Link component={NavLink} to={Routes.login}>
              Go to Login
            </Link>
          </Stack>
        );
    }
  };

  return (
    <Box sx={{ background: '#B2BFCB', height: '100vh', padding: '128px 0' }}>
      <Card sx={{ padding: 6, paddingBottom: 3, width: 290, margin: 'auto' }}>
        <Typography variant="h4">Password Reset</Typography>
        {renderCurrentView()}
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', my: 2 }}>
          <MobileStepper
            variant="dots"
            steps={4}
            position="static"
            activeStep={activeStep}
            sx={{ maxWidth: 400 }}
            nextButton={null}
            backButton={null}
          />
        </Box>
        {activeStep === AuthState.VerifyEmail && (
          <Link component={NavLink} to={Routes.login}>
            Back to Login
          </Link>
        )}
      </Card>
    </Box>
  );
};
