import { Button, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { IReport } from '../report.interface';
import { IFundingBodyOption, IHepLabelOption } from '@features/backend-label/backend-label.type';

type Props = {
  report: IReport | void;
  fundingBodyOption?: IFundingBodyOption<number> | void;
  hepOption?: IHepLabelOption<number> | void;
  isHepEmail: boolean;
  handleSubmit: () => void;
  closeModal: () => void;
};

export const ReportAction: FC<Props> = ({
  report,
  fundingBodyOption,
  hepOption,
  isHepEmail,
  handleSubmit,
  closeModal,
}) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        You are about to send Needs Assessment Report to the following recipients:
      </Typography>
      <Typography variant="body1" gutterBottom>
        {report?.reportReferenceFileName} Encrypted to {fundingBodyOption?.reportSubmissionEmail}
      </Typography>

      {isHepEmail && (
        <Typography variant="body1" gutterBottom>
          {report?.reportReferenceFileName} to {hepOption?.email} password set to Date of Birth in DDMMYYYY format.
        </Typography>
      )}

      <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
        <Button variant="contained" onClick={(): void => handleSubmit()} color="success">
          Confirm
        </Button>
        <Button variant="contained" onClick={closeModal}>
          Cancel
        </Button>
      </Stack>
    </>
  );
};
