import React from 'react';
import { Alert, SxProps } from '@mui/material';
import { useIsOrderDisabled } from './use-is-order-disabled';

type Props = {
  orderId: number;
  position: 'top' | 'bottom';
};

export const OrderCancelledWarning: React.FC<Props> = ({ orderId, position }) => {
  const { isDisabled, reason } = useIsOrderDisabled(orderId);
  const isTop = position === 'top';
  const sx: SxProps = { mt: isTop ? -1 : 2, mb: isTop ? 2 : 1 };

  if (isDisabled) {
    return (
      <Alert sx={sx} severity="warning">
        {reason}
      </Alert>
    );
  }

  return null;
};
