import React, { FC } from 'react';
import { IFundingBodyOption, IHepLabelOption } from '@features/backend-label/backend-label.type';
import { IOrderNeedsAssessment } from '../../../order-needs-assessment.interface';
import { AppDetails, DetailsConfig } from '@components/AppDetails';
import { ICustomer } from '@features/customer/customer.interface';

type Info = {
  fundingBodyName?: string;
  fundingBodyEmail?: string | null;
  isCtsWithHep?: boolean;
  hepName?: string;
  hepEmail?: string | null;
  isReportShare?: boolean;
  studentEmail?: string | null;
};

type Props = {
  fundingBodyOption?: IFundingBodyOption<number> | void;
  hepOption?: IHepLabelOption<number> | void;
  order: IOrderNeedsAssessment;
  customer: ICustomer | null;
};

export const FundingAndHepInfo: FC<Props> = ({ fundingBodyOption, hepOption, order, customer }) => {
  const fundingBodyAndHepInfo: Info = {};

  if (fundingBodyOption) {
    fundingBodyAndHepInfo.fundingBodyName = fundingBodyOption.label;
    fundingBodyAndHepInfo.fundingBodyEmail = fundingBodyOption.reportSubmissionEmail;
    fundingBodyAndHepInfo.isCtsWithHep = order.shareNarWithHep;
  }

  if (hepOption) {
    fundingBodyAndHepInfo.hepName = hepOption.label;
    fundingBodyAndHepInfo.hepEmail = hepOption.email;
    fundingBodyAndHepInfo.isReportShare = hepOption.reportShare;
  }

  if (customer) {
    fundingBodyAndHepInfo.studentEmail = customer.email;
  }

  const config: DetailsConfig<Info> = {
    fundingBodyName: { label: 'Funding Body' },
    fundingBodyEmail: { label: 'Funding Email' },
    isCtsWithHep: { label: 'CTS with HEP' },
    hepName: { label: 'HEP' },
    isReportShare: { label: 'HEP Report Share' },
    hepEmail: { label: 'HEP Email' },
    studentEmail: { label: 'Student Email' },
  };

  return <AppDetails model={fundingBodyAndHepInfo} config={config} />;
};
