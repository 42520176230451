import { useAppSelector } from '@store/use-app-selector';
import { IMicrosoftAuthorizationState, MicrosoftActions, selectMicrosoftState } from './microsoft.slice';
import { useGetMicrosoftLinkQuery, useRequestMicrosoftLogoutMutation } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { useEffect } from 'react';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';

type HookResult = {
  handleMicrosoftLogout: () => void;
} & RequestStatusFlags &
  IMicrosoftAuthorizationState;

export function useMicrosoftAuthorization(): HookResult {
  const user = useAppSelector((state) => state.user.user);
  const { authorizationUri, organisationName, isLoggedIn } = useAppSelector((state) => selectMicrosoftState(state));

  const shouldSkip = authorizationUri !== null || isLoggedIn || user === null;
  const userId = user?.id || 0;
  const { data, ...flags } = useGetMicrosoftLinkQuery(userId.toString(), { skip: shouldSkip });
  const [requestMicrosoftLogoutMutation] = useRequestMicrosoftLogoutMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) {
      dispatch(MicrosoftActions.setAuthData(data));
    }
  }, [data, dispatch]);

  const handleMicrosoftLogout = async (): Promise<void> => {
    try {
      await requestMicrosoftLogoutMutation(userId.toString()).unwrap();
      dispatch(MicrosoftActions.logOut());
    } catch (error) {
      console.error('Failed to log out from Microsoft', error);
    }
  };

  return { authorizationUri, organisationName, isLoggedIn, handleMicrosoftLogout, ...flags };
}
