import React, { FC } from 'react';
import { ElectronicInvoice } from '@features/electronic-invoice/ElectronicInvoice';
import { IAppointment } from '../appointment.interface';
import { useSaveAppointmentElectronicInvoice } from './use-save-appointment-electronic-invoice';
import { useRefreshAppointmentElectronicInvoice } from './use-refresh-appointment-electronic-invoice';
import { useSendAppointmentElectronicInvoice } from './use-send-appointment-electronic-invoice';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { useGenerateAppointmentElectronicInvoice } from './use-generate-appointment-electronic-invoice';

type Props = {
  appointment: IAppointment;
};

export const AppointmentElectronicInvoice: FC<Props> = ({ appointment }) => {
  const { saveAppointmentElectronicInvoice, isLoading: isLoadingSave } =
    useSaveAppointmentElectronicInvoice(appointment);
  const { refreshAppointmentElectronicInvoice, isLoading: isLoadingRefresh } = useRefreshAppointmentElectronicInvoice();
  const { sendAppointmentElectronicInvoice, isLoading: isLoadingSend } = useSendAppointmentElectronicInvoice();
  const { generateAppointmentElectronicInvoice, isLoading: isGenerating } = useGenerateAppointmentElectronicInvoice();

  async function handleSave(electronicInvoice: string | null): Promise<void> {
    if (electronicInvoice) {
      saveAppointmentElectronicInvoice(electronicInvoice);
    }
  }

  async function handleRefresh(): Promise<void> {
    refreshAppointmentElectronicInvoice(appointment.id);
  }

  async function handleSend(): Promise<void> {
    sendAppointmentElectronicInvoice(appointment.id);
  }

  async function handleGenerate(): Promise<void> {
    generateAppointmentElectronicInvoice(appointment.id);
  }

  const isLoading = isLoadingSave || isLoadingRefresh || isLoadingSend;

  if (!appointment.funderInvoice) {
    return (
      <AppLoadingButton isLoading={isGenerating} onClick={handleGenerate} variant="outlined" size="small">
        Generate
      </AppLoadingButton>
    );
  }

  return (
    <ElectronicInvoice
      invoice={appointment.electronicInvoice}
      invoiceLogs={appointment.electronicInvoiceLogs}
      isButtonDisabled={!appointment.funderInvoice}
      isLoading={isLoading}
      handleSave={handleSave}
      handleRefresh={handleRefresh}
      handleSend={handleSend}
      buttonSize="small"
    />
  );
};
