import React, { FC } from 'react';
import { FundingAndHepInfo } from './FundingAndHepInfo';
import { useToggle } from '@hooks/use-toggle';
import { Box, Stack, Typography } from '@mui/material';
import { AppModal } from '@components/AppModal';
import { useSendReport } from '../use-send-report';
import { ReportAction } from '../ReportAction';
import { IOrderNeedsAssessment } from '@features/order/needs-assessment/order-needs-assessment.interface';
import { IReport } from '../../report.interface';
import { IFundingBodyOption, IHepLabelOption } from '@features/backend-label/backend-label.type';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { ReportRecipient } from '../report-recipient';
import { ICustomer } from '@features/customer/customer.interface';

type Props = {
  report: IReport | void;
  order: IOrderNeedsAssessment;
  hepOption: IHepLabelOption<number> | void;
  fundingBodyOption: IFundingBodyOption<number> | void;
  customer: ICustomer | null;
};

export const SendFinalReport: FC<Props> = ({ report, order, fundingBodyOption, hepOption, customer }) => {
  const [isModalOpen, toggleIsModalOpen] = useToggle();

  const { sendReport, isLoading } = useSendReport();

  const handleSubmit = (): void => {
    toggleIsModalOpen();
    sendReport(ReportRecipient.final);
  };

  const isFundingBodyEmail = Boolean(fundingBodyOption?.reportSubmissionEmail);
  const isHepEmail = Boolean(hepOption?.email && hepOption?.reportShare && order?.shareNarWithHep);

  return (
    <Box>
      <Typography variant="h4">Send Needs Assessment Report</Typography>
      <Stack direction="column" spacing={2} sx={{ mt: 1 }}>
        <FundingAndHepInfo
          fundingBodyOption={fundingBodyOption}
          hepOption={hepOption}
          order={order}
          customer={customer}
        />

        <AppLoadingButton fullWidth onClick={toggleIsModalOpen} disabled={!isFundingBodyEmail} isLoading={isLoading}>
          Send Final
        </AppLoadingButton>
      </Stack>

      <AppModal open={isModalOpen} onClose={toggleIsModalOpen} title="Send Needs Assessment Report">
        <ReportAction
          report={report}
          fundingBodyOption={fundingBodyOption}
          hepOption={hepOption}
          isHepEmail={isHepEmail}
          handleSubmit={handleSubmit}
          closeModal={toggleIsModalOpen}
        />
      </AppModal>
    </Box>
  );
};
