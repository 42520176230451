/* eslint-disable no-underscore-dangle */
import { difference, uniq } from 'lodash';
import { PayloadAction, createDraftSafeSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { AppBackendLabelKey, AppBackendLabelsBase } from '@features/backend-label/backend-label.type';
import { UserActions } from '@features/user/user.slice';
import { isOperatorOption } from './user-label.interface';

interface IBackendLabelsState extends AppBackendLabelsBase {
  _pendingOptionKeys: Array<AppBackendLabelKey>;
}

const InitialOptionState: AppBackendLabelsBase = {
  hepOptions: [],
  fundingBodyOptions: [],
  preferredCommunicationWaysOptions: [],
  statusOptions: [],
  labelOptions: [],
  userOptions: [],
  typeOptions: [],
  intensityOptions: [],
  levelOfStudyOptions: [],
  warrantyOptions: [],
  supportOptions: [],
  qualificationOptions: [],
  organisationOptions: [],
  conditionOptions: [],
  assessmentCentreOptions: [],
  assessmentTypeOptions: [],
  orderTypeNameOptions: [],
  productTrafficLightOptions: [],
  disabilityTypeOptions: [],
  assessorOptions: [],
  tutorOptions: [],
  quoteSupportPeriodOptions: [],
  productCategory: [],
  appointmentsCancellationReasonOptions: [],
  crmFreeSlotOptions: [],
  employmentOptions: [],
  sessionsCancellationReasonOptions: [],
  ascCancellationReasonOptions: [],
  appointmentLabelOptions: [],
};

const initialState: IBackendLabelsState = {
  ...InitialOptionState,
  _pendingOptionKeys: createPendingOptionKeys(InitialOptionState),
};

const backendLabelsSlice = createSlice({
  name: 'BackendLabels',
  initialState,
  reducers: {
    upsertBackendLabels: (state, { payload }: PayloadAction<Partial<AppBackendLabelsBase>>) => ({
      ...state,
      _pendingOptionKeys: difference(state._pendingOptionKeys, createPendingOptionKeys(payload)),
      ...payload,
    }),
    resetOptionByKey: (state, { payload }: PayloadAction<AppBackendLabelKey>) => ({
      ...state,
      _pendingOptionKeys: uniq([...state._pendingOptionKeys, payload]),
      [payload]: [],
    }),
    resetBackendLabels: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(UserActions.logOut, () => initialState);
  },
});

export const BackendLabelsActions = backendLabelsSlice.actions;
export const backendLabelsReducer = backendLabelsSlice.reducer;
export type BackendLabelsActionsType = typeof BackendLabelsActions;

export const selectBackendLabelsState = (state: RootState): IBackendLabelsState => state.labels;
export const selectBackendLabelsPendingOptionKeys = createDraftSafeSelector(
  selectBackendLabelsState,
  (state) => state._pendingOptionKeys,
);
export const selectAllUserOptions = createDraftSafeSelector(selectBackendLabelsState, (state) => state.userOptions);
export const selectOperatorOptions = createDraftSafeSelector(selectAllUserOptions, (options) =>
  options.filter(isOperatorOption),
);

function createPendingOptionKeys(options: Partial<AppBackendLabelsBase>): Array<AppBackendLabelKey> {
  return Object.keys(options) as Array<AppBackendLabelKey>;
}

export const EmptyBackendLabelOptions = { ...InitialOptionState, operatorOptions: [] };
