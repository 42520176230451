import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { GridSortModel } from '@mui/x-data-grid';
import { AppDataGrid } from '@components/AppDataGrid';
import { useAppointments } from './use-appointments';
import { ISODateString } from '@utils/dates/iso-string.type';
import { useDisableRole } from '@features/auth/use-disable-role';
import { RestrictRole } from '@features/auth/RestrictRole';
import { UserRoles } from '@features/user/user-role.type';
import { useFilteredAppointmentColumns } from './use-filtered-appointment-columns';
import { OperatorAction } from './OperatorAction';
import { AssessorAction } from './AssessorAction';
import { useToggle } from '@hooks/use-toggle';

type Props = {
  orderId: number;
  isDisabled: boolean;
  endDate: ISODateString | null;
};

const defaultSortModel: GridSortModel = [
  {
    field: 'creationDate',
    sort: 'desc',
  },
];

export const Appointments: React.FC<Props> = ({ orderId, isDisabled, endDate }) => {
  const { appointments, isLoading } = useAppointments(orderId);
  const [isModalOpen, toggleIsModalOpen] = useToggle();
  const [isExtended, toggleIsExtended] = useToggle();
  const [editId, setEditId] = useState<number | null>(null);

  const isDisabledForAssessorAndQA = useDisableRole([UserRoles.assessor, UserRoles.qualityAssurance]);

  const closeModal = useCallback((): void => {
    toggleIsModalOpen();
    setEditId(null);
  }, [toggleIsModalOpen]);

  const handleAppointmentAction = useCallback(
    (id: number) => {
      setEditId(id);
      toggleIsModalOpen();
    },
    [toggleIsModalOpen],
  );

  const columns = useFilteredAppointmentColumns(handleAppointmentAction, isDisabledForAssessorAndQA, isExtended);

  const activeAppointment = useMemo(
    () => appointments.find((appointment) => appointment.id === editId),
    [editId, appointments],
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ marginTop: 2 }}>
      <Stack direction="row" alignItems="center" sx={{ marginBottom: 1 }}>
        <Typography variant="h5">Appointments</Typography>
        <RestrictRole roles={[UserRoles.assessor, UserRoles.qualityAssurance]}>
          <Button sx={{ ml: 1 }} variant="outlined" size="small" onClick={toggleIsExtended}>
            {isExtended ? 'Show Less' : 'Show More'}
          </Button>
          <Button
            sx={{ ml: 'auto' }}
            variant="outlined"
            size="small"
            disabled={isDisabled}
            onClick={toggleIsModalOpen}
            startIcon={<AddIcon />}
          >
            Create Appointment
          </Button>
        </RestrictRole>
      </Stack>
      <AppDataGrid
        sx={{ height: '320px' }}
        rows={appointments}
        columns={columns}
        sortModel={defaultSortModel}
        loading={isLoading}
      />
      {isDisabledForAssessorAndQA ? (
        <AssessorAction orderId={orderId} appointmentId={editId} isOpen={isModalOpen} closeModal={closeModal} />
      ) : (
        <OperatorAction
          orderId={orderId}
          endDate={endDate}
          appointmentId={editId}
          activeAppointment={activeAppointment}
          isOpen={isModalOpen}
          closeModal={closeModal}
        />
      )}
    </Box>
  );
};
