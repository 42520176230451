import React, { FC, Fragment, useState } from 'react';
import { IProductWithCondition } from '../product.interface';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { Chip, Grid, Stack, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

type Props = {
  product: IProductWithCondition;
};

export const Justification: FC<Props> = ({ product }) => {
  const { disabilityTypeOptions } = useAppBackendLabels(['disabilityTypeOptions']);
  const [activeDisabilityType, setActiveDisabilityType] = useState<null | number>(null);

  const filteredProductConditions = product?.allProductDisabilityTypes.filter((item) =>
    activeDisabilityType ? item.disabilityTypeId === activeDisabilityType : true,
  );

  const productDisabilityTypeIds = product.allProductDisabilityTypes.map((condition) => condition.disabilityTypeId);
  const productDisabilityTypeOptions = disabilityTypeOptions.filter((option) =>
    productDisabilityTypeIds.includes(option.value),
  );

  return (
    <>
      <Stack direction="column" sx={{ mt: 4 }}>
        <Typography variant="h4" component="h1">
          Justification text
        </Typography>

        <Stack spacing={2} direction="row" sx={{ mt: 2 }}>
          {[{ value: null, label: 'All' }, ...productDisabilityTypeOptions].map((condition) => (
            <Chip
              key={condition.value}
              label={condition.label}
              color={activeDisabilityType === condition.value ? 'primary' : 'default'}
              icon={activeDisabilityType === condition.value ? <CheckIcon sx={{ fontSize: '16px' }} /> : undefined}
              onClick={(): void => setActiveDisabilityType(condition.value)}
            />
          ))}
        </Stack>
      </Stack>

      {filteredProductConditions?.map((condition) => (
        <Stack key={condition.disabilityTypeId}>
          {condition.justifications.map((justification, index: number) => (
            <Grid key={index} container columns={{ xs: 4, md: 8, lg: 12 }} spacing={1} sx={{ mt: 1 }}>
              <Grid item xs={1} md={2} lg={2}>
                <strong>Disability Type:</strong>
              </Grid>
              <Grid item xs={4} md={6} lg={10}>
                {condition.disabilityType}
              </Grid>
              <Grid item xs={1} md={2} lg={2}>
                <strong>Title:</strong>
              </Grid>
              <Grid item xs={4} md={6} lg={10}>
                {justification.justificationTitle}
              </Grid>
              <Grid item xs={1} md={2} lg={2}>
                <strong>Justification:</strong>
              </Grid>
              <Grid item xs={4} md={6} lg={10}>
                {justification.justificationText}
              </Grid>
            </Grid>
          ))}
        </Stack>
      ))}
    </>
  );
};
