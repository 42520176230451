import { createEnumOptions } from '@utils/enum/create-enum.factory';

export const Remaining = {
  Spent: 'spent',
  Unspent: 'unspent',
} as const;

type Keys = keyof typeof Remaining;

export type RemainingType = (typeof Remaining)[Keys];

export const RemainingOptions = createEnumOptions(Remaining);
