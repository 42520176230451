import React from 'react';
import { useRole } from '@features/user/use-role';
import { UserRoles } from '@features/user/user-role.type';
import { ResumeAccount } from './ResumeAccount';
import { SuspendAccount } from './SuspendAccount';

type Props = {
  customerId: number;
  isInitiallySuspended: boolean;
};

export const SuspendOrResumeAccount: React.FC<Props> = ({ customerId, isInitiallySuspended }) => {
  const role = useRole();
  if (!role || (role !== UserRoles.operator && role !== UserRoles.financeManager)) {
    return null;
  }
  return isInitiallySuspended ? <ResumeAccount customerId={customerId} /> : <SuspendAccount customerId={customerId} />;
};
