import { useGetQuickFilterTabsQuery } from '@api/api-slice';
import { IQuickFilterTab } from './quick-filter-tab.interface';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { QuickFilterTabsType } from './quick-filter-tabs.type';

type HookResult = RequestStatusFlags & { tabs: Array<IQuickFilterTab> };

export const AllTab: IQuickFilterTab = {
  id: -1,
  name: 'All',
  fields: {},
  hasBulkSending: false,
};

export function useQuickFilterTabs(type: QuickFilterTabsType): HookResult {
  const { data, ...flags } = useGetQuickFilterTabsQuery(type);
  const tabs = [AllTab, ...(data?.tabs ?? [])];
  return { tabs, ...flags };
}
