import { appMutationFactory } from '@api/api-slice';
import { IUser } from '@features/user/user.interface';
import { AnonymousEndpoints } from '@api/anonymous-endpoints';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';
import { AssessorRoleType, UserRoleType, UserRoles } from '@features/user/user-role.type';

export const Endpoints = {
  login: AnonymousEndpoints.login,
};

export interface IAccount {
  email: string;
  password: string;
}

interface IAssessorDTO extends IUser {
  role: AssessorRoleType;
  person: {
    id: number;
  };
}

interface IBaseUserDTO extends IUser {
  role: Exclude<UserRoleType, AssessorRoleType>;
  person: null;
}

interface ILoginResponseDTO {
  message: string;
  token: string;
  user: IAssessorDTO | IBaseUserDTO;
}

export const buildLogin = appMutationFactory<IAccount, ILoginResponse | IErrorResponse>({
  query: (account) => ({
    url: Endpoints.login,
    method: 'POST',
    data: { account },
  }),
  transformResponse,
});

interface ILoginResponse {
  message: string;
  token: string;
  user: IUser;
}

function transformResponse(response: ILoginResponseDTO | IErrorResponse): ILoginResponse | IErrorResponse {
  if (isErrorResponse(response)) {
    return response;
  }
  return {
    ...response,
    user: {
      ...response.user,
      assessorId: response.user.role === UserRoles.assessor ? response.user.person.id : null,
    },
  };
}
