import { Failure } from 'superstruct';
import { concatPath } from './concat-path';

/**
 * Create human readable message of superstruct failure
 *
 * @param {Failure} failure superstruct failure type
 * @param {string} prefix page name or endpoint
 * @returns {string}
 */
export function createFailureMessage(failure: Failure, prefix: string, key: string): string {
  const path = concatPath(failure.path);

  // here picking the first element
  const [value] = failure.branch;

  let id = '';
  if (value) {
    if (key in value) {
      id = `[${key}=${value[key]}]`;
    } else {
      console.warn('Not key specified for accessing failure field');
      id = '[KEY NOT PASSED]';
    }
  }

  return `${prefix} ${id}${path}: ${failure.message}`;
}
