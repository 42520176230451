import { useCallback, useMemo, useState } from 'react';
import { MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { ActionCreatorWithPayload, Update } from '@reduxjs/toolkit';
import { AxiosBaseQuery } from '@api/http-query-client';
import { useAppDispatch } from '@store/use-app-dispatch';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { BulkActionRowResult } from './bulk-action-row-result.type';
import { isSuccessRow } from './is-success-row';
import { ModelWithId } from './model-with-id.type';
import { showResultSnackbar } from './show-result-snackbar';
import { RowWithResult, createRowWithResult } from './create-row-with-result';
import { BulkUpdateResponse } from './bulk-update-response.type';

type HookResult<Model extends ModelWithId, Payload> = {
  handleSubmit: (payload: Payload) => Promise<void>;
  rowsWithResult: RowWithResult<Model>[];
} & RequestStatusFlags;

type UseBulkActionMutation<Model extends ModelWithId, Payload> = UseMutation<
  MutationDefinition<Payload, AxiosBaseQuery, never, void | BulkUpdateResponse<Model>, 'api'>
>;

export function useBulkUpdate<Model extends ModelWithId, Payload>(
  mutationHook: UseBulkActionMutation<Model, Payload>,
  UpdateAction: ActionCreatorWithPayload<readonly Update<Model>[]>,
  rows: Model[],
): HookResult<Model, Payload> {
  const [mutation, flags] = mutationHook();
  const [results, setResults] = useState<BulkActionRowResult<Model>[]>([]);
  const rowsWithResult = useMemo(() => createRowWithResult(rows, results), [results, rows]);
  const dispatch = useAppDispatch();

  const handleSubmit = useCallback(
    async (payload: Payload): Promise<void> => {
      const result = await mutation(payload).unwrap();
      if (result) {
        const acceptedChanges = result.results.filter(isSuccessRow);
        dispatch(UpdateAction(acceptedChanges));
        setResults(result.results);
        showResultSnackbar(result.results);
      }
    },
    [dispatch, mutation, UpdateAction],
  );

  return {
    ...flags,
    handleSubmit,
    rowsWithResult,
  };
}
