import { useCallback } from 'react';
import { useCreateSessionMutation, useSaveSessionMutation } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { ISession } from '@features/sessions/session.interface';
import { SessionsActions } from '@features/sessions/sessions.slice';
import { RequestStatusFlags, mergeRequestStatusFlags } from '@utils/merge-request-status-flags';
import { isErrorResponse } from '@api/error-response.interface';
import { ApiModel } from '@features/sessions/save-session-form/form-config';

// This hook can be generic in future, so I mark model dependent parts

// Api Model type
type FormData = Pick<ApiModel, 'startMoment' | 'duration' | 'offeredDate'>;

type HookResult = {
  // Promise return type
  handleFormSubmit: (data: FormData) => Promise<ISession | void>;
} & RequestStatusFlags;

export function useSubmitSessionAutomatedBooking(
  sessionId: number | null,
  allocationId: number,
  onClose: () => void,
): HookResult {
  // This hooks are model dependent
  const [createMutation, createFlags] = useCreateSessionMutation();
  const [saveMutation, saveFlags] = useSaveSessionMutation();
  const dispatch = useAppDispatch();

  const handleFormSubmit = useCallback(
    // Promise return type
    async (data: FormData): Promise<ISession | void> => {
      const mutationHandler = sessionId ? saveMutation : createMutation;
      // payload creation
      const payload = {
        allocationId,
        payload: {
          ...data,
          id: sessionId,
        },
      };

      const result = await mutationHandler(payload).unwrap();

      if (isErrorResponse(result)) {
        return;
      }

      // action
      dispatch(SessionsActions.upsertSession(result));
      onClose();
    },
    [sessionId, saveMutation, createMutation, allocationId, dispatch, onClose],
  );

  return {
    handleFormSubmit,
    ...mergeRequestStatusFlags(createFlags, saveFlags),
  };
}

/*
So we need to pass to potential generic hook
- two type templates: FormData and Model (IAppointment or ISession)
- create and save mutation hooks
- payload creation function
- action
*/
