import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { AssessmentMethodOptions } from '@features/order/needs-assessment/assessment-method.type';
import { Routes } from '@routes/routes';
import { createColumnFactory } from '@utils/create-column.factory';
import { formatDateTime } from '@utils/dates/format-date-time';
import { BooleanChip } from '@utils/render-boolean';
import { renderEnum } from '@utils/enum/render-enum';
import { renderMoney } from '@utils/money/render-money';
import { IAppointmentInvoiceSummary } from './appointment-invoice-summary.interface';
import { Result } from '../bulk-action-row-result.type';
import { ResultCell } from '../ResultCell';

type SummaryWithResult = IAppointmentInvoiceSummary & { result: Result | null };

const createAppointmentInvoiceSummaryColumn = createColumnFactory<IAppointmentInvoiceSummary>();

export const appointmentInvoiceColumns: GridColDef[] = [
  createAppointmentInvoiceSummaryColumn('id', {
    width: 80,
    headerName: 'IDs',
    description: 'CustomerId/OrderId',
    renderCell: ({ row: summary }) => (
      <Link
        key={summary.id}
        component={NavLink}
        to={`${Routes.orderSummaries}/${summary.customerId}/${summary.orderId}`}
      >
        {summary.customerId}/{summary.orderId}
      </Link>
    ),
  }),
  createAppointmentInvoiceSummaryColumn('customerFullName', {
    headerName: 'Customer',
    width: 150,
  }),
  createAppointmentInvoiceSummaryColumn('customerCrn', {
    headerName: 'CRN',
    width: 98,
  }),
  createAppointmentInvoiceSummaryColumn('orderStatusId', {
    headerName: 'Status',
    width: 100,
    renderCell: ({ row }) => <BackendLabel value={row.orderStatusId} optionKey="statusOptions" />,
  }),
  createAppointmentInvoiceSummaryColumn('appointmentDate', {
    headerName: 'Appointment Date',
    width: 125,
    renderCell: ({ row }) => formatDateTime(row.appointmentDate),
  }),
  createAppointmentInvoiceSummaryColumn('assessmentMethod', {
    headerName: 'Method',
    width: 110,
    renderCell: ({ row }) => renderEnum(row.assessmentMethod, AssessmentMethodOptions),
  }),
  createAppointmentInvoiceSummaryColumn('isCancelled', {
    headerName: 'Cancelled',
    width: 70,
    renderCell: ({ row }) => <BooleanChip value={row.isCancelled} isInvertedColor />,
  }),
  createAppointmentInvoiceSummaryColumn('cancellationReason', {
    headerName: 'Cancellation Reason',
    flex: 1,
  }),
  createAppointmentInvoiceSummaryColumn('exVat', {
    headerName: 'Gross Cost',
    width: 90,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'number',
    renderCell: ({ row }) => renderMoney(row.exVat),
  }),
  createAppointmentInvoiceSummaryColumn('funderInvoice', { headerName: 'Funder Invoice', width: 110 }),
  createAppointmentInvoiceSummaryColumn('supplierInvoice', { headerName: 'Supplier Invoice', width: 110 }),
  createAppointmentInvoiceSummaryColumn('isPaid', {
    headerName: 'Paid',
    width: 60,
    renderCell: ({ row }) => <BooleanChip value={row.isPaid} />,
  }),
  createAppointmentInvoiceSummaryColumn('dipReference', {
    headerName: 'DIP',
    width: 70,
  }),
  createAppointmentInvoiceSummaryColumn('organisationId', {
    headerName: 'Organisation',
    flex: 1,
    renderCell: ({ row }) => <BackendLabel value={row.organisationId} optionKey="organisationOptions" />,
    optionKey: 'organisationOptions',
  }),
];

const createColumnWithResult = createColumnFactory<SummaryWithResult>();

export const appointmentInvoiceColumnsWithResult = appointmentInvoiceColumns.concat(
  createColumnWithResult('result', {
    headerName: 'Result',
    width: 100,
    renderCell: ({ row }) => <ResultCell result={row.result} />,
  }),
);
