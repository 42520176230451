import { useEffect } from 'react';
import { SessionsActions, selectSessionsByAllocationId } from '@features/sessions/sessions.slice';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { ISession } from '@features/sessions/session.interface';
import { useGetAllocationSessionsQuery } from '@api/api-slice';
import { useAppSelector } from '@store/use-app-selector';
import { useAppDispatch } from '@store/use-app-dispatch';

type HookResult = RequestStatusFlags & {
  sessions: ISession[];
};

export function useSessions(allocationId: number): HookResult {
  const { data, ...flags } = useGetAllocationSessionsQuery(allocationId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) {
      dispatch(SessionsActions.upsertSessions(data));
    }
  }, [data, dispatch]);

  const sessions = useAppSelector((state) => selectSessionsByAllocationId(state, allocationId));

  return { sessions, ...flags };
}
