import { IPaginable } from '@features/pagination/paginable.interface';
import { HepSummary } from '../hep.interface';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { sanitize, serialize } from './form.config';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { getPaginationResult } from '@features/pagination/get-pagination-result';
import { useGetHepsQuery } from '@api/api-slice';

type HookResult = RequestStatusFlags & IPaginable<HepSummary>;

export function useFilteredHeps(urlSearchParams: URLSearchParams, count: number, skip: number): HookResult {
  const backendLabels = useAppBackendLabels([]);
  const params = serialize(sanitize(deserializeURLSearchParams(urlSearchParams), backendLabels));
  return getPaginationResult(useGetHepsQuery({ ...params, count, skip }));
}
