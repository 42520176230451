import {
  BaseOrderApiModel,
  BaseOrderFormModel,
  baseOrderSanitizeMap,
  baseOrderSerializeMap,
} from '@features/order/base/form-config';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { IOrderNew } from './order-new.interface';

export type FormModel = BaseOrderFormModel & Pick<IOrderNew, 'orderTypeId'>;

export type ApiModel = BaseOrderApiModel & Pick<IOrderNew, 'orderTypeId'>;

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  ...baseOrderSanitizeMap,
  orderTypeId: { type: 'backend-label', optionKey: 'typeOptions', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  ...baseOrderSerializeMap,
  orderTypeId: Serializers.backendValue,
});
