import { Describe, assign, literal, object } from 'superstruct';
import { IOrderErgonomicAssessment } from '../order-ergonomic-assessment.interface';
import { BaseOrderStruct } from '@features/order/base/spec/base-order-struct.spec';
import { OrderClasses } from '@features/order/order-class.type';

export const ErgonomicAssessmentOrderStruct: Describe<IOrderErgonomicAssessment> = assign(
  BaseOrderStruct,
  object({
    orderClass: literal(OrderClasses.ErgonomicAssessment),
  }),
);
