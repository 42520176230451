import { StructError, assert } from 'superstruct';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { ICustomer } from '../customer.interface';
import { CustomerStruct } from './customer.struct.spec';

export function validateCustomer(customer: ICustomer, url: string): void {
  try {
    assert(customer, CustomerStruct);
  } catch (error) {
    for (const failure of (error as StructError).failures()) {
      handleFailure(failure, url);
    }
  }
}
