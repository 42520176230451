import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { UseQueryHookResult } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { AxiosBaseQuery } from '@api/http-query-client';
import { IPaginable, PaginationDefaults } from './paginable.interface';

/**
 * Fallback `data.entries` to empty array and `data.pagination` to defaults, if no success.
 * Also collects and passes flags.
 */
export function getPaginationResult<T>({
  data,
  ...flags
}: UseQueryHookResult<QueryDefinition<unknown, AxiosBaseQuery, never, IPaginable<T>, 'api'>>): IPaginable<T> &
  RequestStatusFlags {
  return {
    entries: data?.entries ?? [],
    pagination: data?.pagination ?? PaginationDefaults,
    ...flags,
  };
}
