import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IOrder } from './order.interface';
import { RouteParamsType } from '@routes/route-params.type';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useGetOrderQuery } from '@api/api-slice';
import { OrdersActions, selectById } from './orders.slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { useAppSelector } from '@store/use-app-selector';

type HookResult = RequestStatusFlags & {
  order: IOrder | undefined;
};

export function useOrder(): HookResult {
  const { orderIdString, customerIdString } = useParams<RouteParamsType>();
  const orderId = parseInt(orderIdString as unknown as string, 10);
  const customerId = parseInt(customerIdString as unknown as string, 10);
  const order = useAppSelector((state) => selectById(state, orderId));
  const shouldSkip = !orderIdString || !customerIdString || Boolean(order);
  const { data, ...flags } = useGetOrderQuery({ orderId, customerId }, { skip: shouldSkip });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) {
      dispatch(OrdersActions.upsertOrder(data));
    }
  }, [data, dispatch]);

  return { order, ...flags };
}
