import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import { Autocomplete, Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useSearchProducts } from './use-search-products';
import { IProductSummary } from '@features/product/product.interface';

type Props = {
  onAddProduct: (product: IProductSummary) => unknown;
};

export const AddProduct: React.FC<Props> = ({ onAddProduct }) => {
  const [query, setQuery] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState<IProductSummary | null>(null);
  const { entries, isLoading } = useSearchProducts(query);

  const handleDebouncedQueryChange = debounce((value) => {
    setQuery(value);
  }, 300);

  const handleAddProduct = (): void => {
    if (product) {
      onAddProduct({ ...product, quantity });
    }
    setQuery('');
    setQuantity(1);
    setProduct(null);
  };

  const isButtonDisabled = product === null || quantity < 1;

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h5">Add Products</Typography>
      <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }} sx={{ mt: -1 }}>
        <Grid item xs={4} md={3} lg={5}>
          <Autocomplete
            getOptionLabel={(option: IProductSummary): string => option.productName}
            getOptionKey={(option: IProductSummary): number => option.id}
            options={entries}
            value={product}
            onChange={(event, newValue): void => {
              setProduct(newValue);
            }}
            loading={isLoading}
            renderInput={(params): React.ReactNode => (
              <TextField
                {...params}
                onChange={(e): void => handleDebouncedQueryChange(e.target.value)}
                label="Search products"
              />
            )}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} md={3} lg={5}>
          <TextField
            value={quantity}
            onChange={(e): void => setQuantity(Number(e.target.value))}
            label="Quantity"
            type="number"
            fullWidth
          />
        </Grid>
        <Grid item xs={4} md={2} lg={2}>
          <Button onClick={handleAddProduct} variant="outlined" disabled={isButtonDisabled} fullWidth>
            Add&nbsp;Product
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
