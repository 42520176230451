import { Describe, StructError, array, assert, literal, nullable, number, object, string } from 'superstruct';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { FilterAssessorsResponse } from '../api';
import { IAssessor } from '../assessor.interface';
import { UserRoles } from '@features/user/user-role.type';

const AssessorStruct: Describe<IAssessor> = object({
  id: number(),
  firstName: string(),
  lastName: string(),
  email: string(),
  notes: nullable(string()),
  role: literal(UserRoles.assessor),
  organisationId: nullable(number()),
  disabilityTypeIds: array(number()),
  postcode: nullable(string()),
  assessmentCentreTagIds: array(number()),
  modesOfAssessment: array(string()),
});

export function validateAssessors(response: FilterAssessorsResponse, url: string): void {
  for (const entry of response.entries) {
    try {
      assert(entry, AssessorStruct);
    } catch (error) {
      for (const failure of (error as StructError).failures()) {
        handleFailure(failure, url);
      }
    }
  }
}
