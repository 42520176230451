import { IUser } from '@features/user/user.interface';
import { PayloadAction, createDraftSafeSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

interface IUserState {
  user: IUser | null;
  token: string | null;
  isSidebarOpen: boolean;
}

const initialState: IUserState = {
  user: null,
  token: null,
  isSidebarOpen: true,
};

type LoginPayload = Pick<IUserState, 'token' | 'user'>;

const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    logIn: (state, { payload }: PayloadAction<LoginPayload>) => {
      state.user = payload.user;
      state.token = payload.token;
    },
    logOut: () => initialState,
    toggleSidebar: (state) => ({ ...state, isSidebarOpen: !state.isSidebarOpen }),
  },
});

export const UserActions = userSlice.actions;
export const userReducer = userSlice.reducer;
export type UserActionsType = typeof UserActions;

export const selectUserState = (state: RootState): IUserState => state.user;
export const selectUserRoleState = createDraftSafeSelector(selectUserState, (state) => state.user?.role ?? null);
export const selectIsSidebarOpen = createDraftSafeSelector(selectUserState, (state) => state.isSidebarOpen);
