import { Moment } from 'moment-timezone';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { Serializers } from '@utils/forms/serializers';
import { AssessmentMethodOptions } from '../../assessment-method.type';
import { IAppointment } from '../appointment.interface';

export type FormModel = Pick<
  IAppointment,
  | 'assessmentMethod'
  | 'assessmentCentreId'
  | 'assessorId'
  | 'isCancelled'
  | 'cancellationReason'
  | 'funderInvoice'
  | 'supplierInvoice'
  | 'electronicInvoice'
> & {
  offeredDate: Moment | null;
  appointmentDate: Moment | null;
  cancellationDate: Moment | null;
};

export type ApiModel = Pick<
  IAppointment,
  | 'assessmentMethod'
  | 'assessmentCentreId'
  | 'assessorId'
  | 'offeredDate'
  | 'isCancelled'
  | 'cancellationDate'
  | 'cancellationReason'
  | 'appointmentDate'
  | 'funderInvoice'
  | 'supplierInvoice'
  | 'electronicInvoice'
>;

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  assessmentMethod: { type: 'enum', options: AssessmentMethodOptions, initial: null },
  assessmentCentreId: { type: 'backend-label', optionKey: 'assessmentCentreOptions', initial: null },
  assessorId: { type: 'backend-label', optionKey: 'assessorOptions', initial: null },
  offeredDate: { type: 'date-time', initial: null },
  isCancelled: { type: 'transportable', initial: false },
  cancellationDate: { type: 'date-time', initial: null },
  cancellationReason: { type: 'backend-label', optionKey: 'appointmentsCancellationReasonOptions', initial: null },
  appointmentDate: { type: 'date-time', initial: null },
  funderInvoice: { type: 'transportable', initial: '' },
  supplierInvoice: { type: 'transportable', initial: '' },
  electronicInvoice: { type: 'transportable', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  assessmentMethod: Serializers.enum,
  assessmentCentreId: Serializers.backendValue,
  assessorId: Serializers.backendValue,
  offeredDate: Serializers.dateTime,
  isCancelled: Serializers.boolean,
  cancellationDate: Serializers.dateTime,
  cancellationReason: Serializers.backendValue,
  appointmentDate: Serializers.dateTime,
  funderInvoice: Serializers.string,
  supplierInvoice: Serializers.string,
  electronicInvoice: Serializers.string,
});
