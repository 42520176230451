import React, { FC } from 'react';
import { IProductWithCondition } from '../product.interface';
import { useSaveProductForm } from './use-save-product-form';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { ControlledToggleButton } from '@components/inputs/ControlledToggleButton';
import { Button, Stack } from '@mui/material';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';

type Props = {
  product: IProductWithCondition;
  onClose: () => void;
};

export const SaveProductForm: FC<Props> = ({ product, onClose }) => {
  const { control, isLabelsLoading, isLoading, productTrafficLightOptions, submitHandler } = useSaveProductForm(
    product,
    onClose,
  );

  return (
    <form onSubmit={submitHandler}>
      <Stack direction="column" spacing={2}>
        <ControlledTextField control={control} name="productName" label="Product name" />
        <ControlledTextField control={control} name="productCodeAs" label="Product code AS" />
        <ControlledTextField control={control} name="productCodeSst" label="Product code SST" />
        <ControlledToggleButton
          control={control}
          name="isActive"
          fullWidth
          getLabel={(value: boolean): string => (value ? 'Active' : 'Not Active')}
        />
        <ControlledTextField
          control={control}
          name="amountExVat"
          label="Net Amount"
          inputProps={{ inputMode: 'numeric' }}
          required
        />
        <ControlledTextField control={control} name="vatTreatment" label="VAT Treatment" />
        <ControlledAutocomplete
          control={control}
          name="trafficLight"
          options={productTrafficLightOptions}
          label="Traffic Light"
          isLoading={isLabelsLoading}
        />
        <ControlledTextField
          control={control}
          name="productDescription"
          label="Product Description"
          multiline
          minRows={2}
        />
        <Button variant="contained" type="submit" disabled={isLoading}>
          Save
        </Button>
      </Stack>
    </form>
  );
};
