import React from 'react';
import { startCase } from 'lodash';
import { Button } from '@mui/material';
import { AppDetails, DetailsConfig } from '@components/AppDetails';
import { AppDrawer } from '@components/AppDrawer';
import { useToggle } from '@hooks/use-toggle';
import { formatDate } from '@utils/dates/format-date';
import { formatDateTime } from '@utils/dates/format-date-time';
import { renderIsSubmittedByCustomer } from './render-is-submitted-by-customer';
import { ITheftClaimForm } from './theft-claim-form.interface';

type Props = {
  theftClaimForm: ITheftClaimForm;
};

export const PrintTheftClaimForm: React.FC<Props> = ({ theftClaimForm }) => {
  const [isDrawerOpen, toggleIsDrawerOpen] = useToggle();

  const config: DetailsConfig<Omit<ITheftClaimForm, 'id'>> = {
    formId: { label: 'Form Id' },
    fullName: { label: 'Full Name' },
    typesOfStolenEquipment: { label: 'Equipment Type', render: (value) => value.map(startCase).join(', ') },
    descriptionOfStolenEquipment: { label: 'Description Of Stolen Equipment' },
    datetimeTheftOccured: { label: 'Theft Occurred At', render: (value) => formatDateTime(value) },
    datetimeTheftDiscovered: { label: 'Theft Discovered At', render: (value) => formatDateTime(value) },
    datetimeEquipmentLastSeen: { label: 'Equipment Last Seen At', render: (value) => formatDateTime(value) },
    datePoliceReported: { label: 'Police Reported At', render: (value) => formatDate(value) },
    crimeReferenceNumber: { label: 'Crime Reference Number' },
    detailsOfTheft: { label: 'Details Of Theft' },
    stepsWereTakenToRecover: { label: 'Steps Were Taken To Recover' },
    submittedByCustomer: { label: 'Is Submitted By Customer', render: renderIsSubmittedByCustomer },
    dateSubmitted: { label: 'Submitted At', render: (value) => formatDate(value) },
  };

  return (
    <>
      <Button onClick={toggleIsDrawerOpen} variant="outlined" fullWidth sx={{ mt: 2 }}>
        Print Theft Claim Form
      </Button>
      <AppDrawer open={isDrawerOpen} onClose={toggleIsDrawerOpen}>
        <AppDetails model={theftClaimForm} config={config} />
      </AppDrawer>
    </>
  );
};
