import { useEffect } from 'react';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { IAllocation } from '@features/allocations/allocation.interface';
import { useGetOrderAllocationsQuery } from '@api/api-slice';
import { AllocationsActions, selectAllAllocations } from './allocations.slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { useAppSelector } from '@store/use-app-selector';

type HookResult = RequestStatusFlags & {
  allocations: IAllocation[];
};

export function useAllocations(orderId: number): HookResult {
  const { data, ...flags } = useGetOrderAllocationsQuery(orderId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) {
      dispatch(AllocationsActions.setAllocations(data));
    }
  }, [data, dispatch]);

  const allocations = useAppSelector((state) => selectAllAllocations(state));

  return { allocations, ...flags };
}
