import { StructError, assert } from 'superstruct';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { FilterAllocationsResponse } from '../api';
import { AllocationSummaryStruct } from './allocation-struct.spec';

export function validateAllocationSummaries(response: FilterAllocationsResponse, url: string): void {
  for (const entry of response.entries) {
    try {
      assert(entry, AllocationSummaryStruct);
    } catch (error) {
      for (const failure of (error as StructError).failures()) {
        handleFailure(failure, url);
      }
    }
  }
}
