import React from 'react';
import { Typography } from '@mui/material';
import { BuildDate, BuildHash } from '@environment/version.config';
import { formatDate } from '@utils/dates/format-date';

export const Version: React.FC = () => {
  const version = `Client version: ${BuildHash} from ${formatDate(BuildDate)}`;
  return (
    <Typography component="p" variant="caption" sx={{ textAlign: 'center', color: 'text.secondary', mt: 'auto' }}>
      {version}
    </Typography>
  );
};
