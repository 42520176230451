import { Describe, assign, literal, nullable, number, object, pattern, string } from 'superstruct';
import { IOrderNmh } from '../order-nmh.interface';
import { BaseOrderStruct } from '@features/order/base/spec/base-order-struct.spec';
import { OrderClasses } from '@features/order/order-class.type';
import { ISODateRegexp } from '@utils/dates/iso-string.type';

export const NmhOrderStruct: Describe<IOrderNmh> = assign(
  BaseOrderStruct,
  object({
    orderClass: literal(OrderClasses.Nmh),
    levelOfStudy: nullable(string()),
    intensity: nullable(string()),
    courseEndDate: nullable(pattern(string(), ISODateRegexp)),
    organisationId: nullable(number()),
    equipmentDeliveryDate: nullable(pattern(string(), ISODateRegexp)),
  }),
);
