import { useSaveSensitivePersonalInformationMutation } from '@api/api-slice';
import { UseFormReturn, useForm } from 'react-hook-form';
import { FormModel, sanitize, serialize } from './form-config';
import { createPreventBubblingFormHandler } from '@utils/create-prevent-bubbling-form-handler.factory';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { collectChangedValues } from '@utils/collect-changed-values';
import { ISensitivePersonalInformation } from './sensitive-personal-information.interface';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';

type HookResult = {
  submitHandler: React.FormEventHandler;
} & RequestStatusFlags &
  UseFormReturn<FormModel>;

export function useSaveSensitivePersonalInformation(
  id: number,
  spi: ISensitivePersonalInformation | null,
  setSpi: React.Dispatch<React.SetStateAction<ISensitivePersonalInformation | null>>,
): HookResult {
  const [mutation, flags] = useSaveSensitivePersonalInformationMutation();
  const { ...backendLabels } = useAppBackendLabels(['disabilityTypeOptions', 'conditionOptions']);

  const values = sanitize(spi, backendLabels);
  const useFormResult = useForm<FormModel>({ values });
  const { handleSubmit } = useFormResult;

  const handleSaveSensitivePersonalInformation = async (data: FormModel): Promise<void> => {
    const payload = collectChangedValues(serialize(data), values);
    const result = await mutation({ id, payload }).unwrap();
    setSpi(result);
  };

  const submitHandler = createPreventBubblingFormHandler(handleSubmit(handleSaveSensitivePersonalInformation));

  return {
    submitHandler,
    ...flags,
    ...useFormResult,
  };
}
