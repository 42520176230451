import { Moment } from 'moment-timezone';
import { QuoteStatusOptions } from '@features/quote/quote-status.type';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { ISODateString } from '@utils/dates/iso-string.type';
import { Serializers } from '@utils/forms/serializers';

export type FormModel = {
  pickUpFrom: Moment | null;
  pickUpTo: Moment | null;
  organisationId: Array<number | null>;
  orderTypeId: Array<number | null>;
  quoteStatus: Array<number | null>;
  isMatchesDsa2: boolean;
  quoteId: string | null;
  isContributePayable: boolean;
  isUpgradedEquipment: boolean;
  funderInvoice: string | null;
  dipReference: string | null;
};

export type ApiModel = {
  pickUpFrom: ISODateString;
  pickUpTo: ISODateString;
  organisationId: Array<number | null>;
  orderTypeId: Array<number | null>;
  quoteStatus: Array<number | null>;
  isMatchesDsa2: boolean | null;
  quoteId: string | null;
  isContributePayable: boolean | null;
  isUpgradedEquipment: boolean | null;
  funderInvoice: string | null;
  dipReference: string | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  pickUpFrom: { type: 'date', initial: null },
  pickUpTo: { type: 'date', initial: null },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', multiple: true, initial: [] },
  orderTypeId: { type: 'backend-label', optionKey: 'orderTypeNameOptions', multiple: true, initial: [] },
  quoteStatus: { type: 'enum', options: QuoteStatusOptions, multiple: true, initial: [] },
  isMatchesDsa2: { type: 'transportable', initial: null },
  quoteId: { type: 'transportable', initial: '' },
  isContributePayable: { type: 'transportable', initial: null },
  isUpgradedEquipment: { type: 'transportable', initial: null },
  funderInvoice: { type: 'transportable', initial: '' },
  dipReference: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  pickUpFrom: Serializers.date,
  pickUpTo: Serializers.date,
  organisationId: Serializers.array,
  orderTypeId: Serializers.array,
  quoteStatus: Serializers.array,
  isMatchesDsa2: Serializers.booleanNullable,
  quoteId: Serializers.stringNullable,
  isContributePayable: Serializers.booleanNullable,
  isUpgradedEquipment: Serializers.booleanNullable,
  funderInvoice: Serializers.stringNullable,
  dipReference: Serializers.stringNullable,
});
