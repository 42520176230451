import { Describe, StructError, array, assert, boolean, nullable, number, object, string } from 'superstruct';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { IOrderSummary } from '@features/order-summaries/order-summary.interface';
import { OrderSummaryResponse } from '../../filter-assessment-order-summaries-form/api';

const OrderSummaryStruct: Describe<IOrderSummary> = object({
  id: number(),
  orderTypeId: nullable(number()),
  orderStatusId: nullable(number()),
  labels: array(number()),
  daysInLastStatus: number(),
  customerId: number(),
  customerCrn: nullable(string()),
  customerEmail: string(),
  customerPhone: nullable(string()),
  customerPostcode: nullable(string()),
  customerFullName: string(),
  organisationId: nullable(number()),
  isCancelled: boolean(),
  extraMile: boolean(),
});

export function validateFilterOrderSummary(response: OrderSummaryResponse, url: string): void {
  for (const entry of response.entries) {
    try {
      assert(entry, OrderSummaryStruct);
    } catch (error) {
      for (const failure of (error as StructError).failures()) {
        handleFailure(failure, url);
      }
    }
  }
}
