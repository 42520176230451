import { useGenerateAnnualSupportChargeByQuoteMutation } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { AnnualSupportChargeActions } from './annual-support-charge-list/annual-support-charge.slice';
import { OrdersActions } from '../orders.slice';

type HookResult = {
  generateAnnualSupportChargeByQuote: (orderId: number) => Promise<void>;
} & RequestStatusFlags;

export function useGenerateAnnualSupportChargeByQuote(): HookResult {
  const [sendMutation, flags] = useGenerateAnnualSupportChargeByQuoteMutation();
  const dispatch = useAppDispatch();

  const generateAnnualSupportChargeByQuote = async (orderId: number): Promise<void> => {
    const result = await sendMutation(orderId).unwrap();
    if (result) {
      dispatch(AnnualSupportChargeActions.upsertAnnualSupportCharges(result.annualSupportCharges));
      dispatch(OrdersActions.disableAscOrderGeneration(orderId));
    }
  };

  return { generateAnnualSupportChargeByQuote, ...flags };
}
