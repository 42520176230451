import { useEffect } from 'react';
import { useGetLabelsQuery } from '@api/api-slice';
import { useAppSelector } from '@store/use-app-selector';
import { useAppDispatch } from '@store/use-app-dispatch';
import {
  BackendLabelsActions,
  selectBackendLabelsState,
  selectOperatorOptions,
} from '@features/backend-label/backend-labels.slice';
import { AppBackendLabelKey, AppBackendLabels } from '@features/backend-label/backend-label.type';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';

export function useAppBackendLabels(optionKeys: Array<AppBackendLabelKey>): AppBackendLabels & RequestStatusFlags {
  const dispatch = useAppDispatch();
  const { _pendingOptionKeys, ...backendLabels } = useAppSelector((state) => selectBackendLabelsState(state));
  const keys = optionKeys.filter((key) => _pendingOptionKeys.includes(key));
  const operatorOptions = useAppSelector((state) => selectOperatorOptions(state));

  const shouldSkip = keys.length === 0;

  const { data, ...flags } = useGetLabelsQuery(keys, { skip: shouldSkip });
  useEffect(() => {
    if (data) {
      dispatch(BackendLabelsActions.upsertBackendLabels(data));
    }
  }, [data, dispatch]);

  return { ...backendLabels, operatorOptions, ...flags };
}
