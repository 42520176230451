import { createDraftSafeSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { IAppointment } from './appointment.interface';

const appointmentsAdapter = createEntityAdapter<IAppointment>({
  selectId: (entry) => entry.id,
});

const appointmentsSlice = createSlice({
  name: 'Appointments',
  initialState: appointmentsAdapter.getInitialState(),
  reducers: {
    upsertAppointments: appointmentsAdapter.upsertMany,
    upsertAppointment: appointmentsAdapter.upsertOne,
    updateAppointment: appointmentsAdapter.updateOne,
  },
});

export const AppointmentsActions = appointmentsSlice.actions;
export const appointmentsReducer = appointmentsSlice.reducer;
export type AppointmentsActionsType = typeof AppointmentsActions;

const { selectAll } = appointmentsAdapter.getSelectors((state: RootState) => state.appointments);

export const selectAppointmentById = createDraftSafeSelector(
  selectAll,
  (_: RootState, id: number) => id,
  (items, id) => items.find((item) => item.id === id) ?? null,
);

export const selectAppointmentsByOrderId = createDraftSafeSelector(
  selectAll,
  (_: RootState, id: number) => id,
  (items, id) => items.filter((item) => item.orderId === id),
);
