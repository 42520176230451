import { ISessionInvoiceSummary } from '@features/bulk-invoicing/session/session-invoice-summary.interface';
import { createDraftSafeSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

const sessionInvoiceSummaryAdapter = createEntityAdapter<ISessionInvoiceSummary>({
  selectId: (entry) => entry.id,
});

const sessionInvoiceSummarySlice = createSlice({
  name: 'Session Invoice Summary',
  initialState: sessionInvoiceSummaryAdapter.getInitialState(),
  reducers: {
    setSessions: sessionInvoiceSummaryAdapter.setAll,
    updateSession: sessionInvoiceSummaryAdapter.updateOne,
    updateSessions: sessionInvoiceSummaryAdapter.updateMany,
  },
});

export const SessionInvoiceSummaryActions = sessionInvoiceSummarySlice.actions;
export const sessionInvoiceSummaryReducer = sessionInvoiceSummarySlice.reducer;
export type SessionInvoiceSummaryActionsType = typeof SessionInvoiceSummaryActions;

const { selectAll } = sessionInvoiceSummaryAdapter.getSelectors((state: RootState) => state.sessionInvoiceSummaries);

export const selectAllSessionSummaries = selectAll;

export const selectSessionSummariesByIds = createDraftSafeSelector(
  selectAll,
  (_: RootState, ids: number[]) => ids,
  (all, ids) => all.filter((item) => ids.includes(item.id)),
);
