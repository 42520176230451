import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { Box } from '@mui/material';
import { baseUrl } from '@environment/config';
import { PreviewPDFContext } from '@context/PreviewPDF';
import { useAppSelector } from '@store/use-app-selector';

export type OpenFileProps = PropsWithChildren<{
  path: string;
  isPreview?: boolean;
}>;

/**
 * Component emulating link to a file with token in query parameter from the store.
 * Hide token parameter from showing in the browser.
 * */
export const OpenFile: React.FC<OpenFileProps> = ({ path, isPreview = false, children }) => {
  const token = useAppSelector((state) => state.user.token);
  const { openPDF } = useContext(PreviewPDFContext);

  const link = useMemo(() => {
    if (token) {
      const url = new URL(`${baseUrl}${path}`);
      url.searchParams.set('token', encodeURIComponent(token));
      if (isPreview) {
        url.searchParams.set('preview', '');
      }
      return url.toString();
    }
    return null;
  }, [token, path, isPreview]);

  const handleClick = (): void => {
    if (link) {
      if (isPreview) {
        openPDF(link);
      } else {
        window.location.href = link;
      }
    } else {
      // TODO replace with logout
      console.warn('There is no token, replace with logout');
    }
  };

  return (
    <Box onClick={handleClick} sx={{ cursor: 'pointer', flexGrow: 1, width: '100%' }}>
      {children}
    </Box>
  );
};
