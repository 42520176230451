import { SanitizeMap } from '@utils/forms/sanitize.factory';
import { SerializeMap } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { IOrderBase } from './order-base.interface';

export type BaseOrderFormModel = Pick<IOrderBase, 'orderStatusId' | 'userId' | 'notes'> & {
  acceptanceLetterFile: File | null;
};

export type BaseOrderApiModel = Pick<IOrderBase, 'orderStatusId' | 'userId' | 'notes'> & {
  acceptanceLetterFile: File | null;
};

export const baseOrderSanitizeMap: SanitizeMap<BaseOrderFormModel> = {
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', initial: null },
  userId: { type: 'backend-label', optionKey: 'operatorOptions', initial: null },
  notes: { type: 'transportable', initial: '' },
  acceptanceLetterFile: { type: 'transportable', initial: null },
};

export const baseOrderSerializeMap: SerializeMap<BaseOrderApiModel> = {
  orderStatusId: Serializers.backendValue,
  userId: Serializers.backendValue,
  notes: Serializers.string,
  acceptanceLetterFile: Serializers.file,
};
