import { findClosestWeekends } from '@utils/dates/find-closest-weekends';
import { Moment } from 'moment-timezone';

const SecondsInDay = 60 * 60 * 24;

export function getIsInvoiceDisabled(dateMoment: Moment | null, cancellationDateMoment: Moment | null): boolean {
  if (!dateMoment || !cancellationDateMoment) {
    return false;
  }

  if (dateMoment.isBefore(cancellationDateMoment)) {
    return false;
  }

  const diffSeconds = dateMoment.diff(cancellationDateMoment, 'seconds');
  // even if there are weekend in between dates, if diff more that 3 days, there are 1 working day for sure
  if (diffSeconds > SecondsInDay * 3) {
    return true;
  } else if (diffSeconds <= SecondsInDay) {
    return false;
  }

  const { saturdayStart, mondayStart } = findClosestWeekends(cancellationDateMoment, dateMoment);
  // no weekend in date range but more that 1 working day
  if (!saturdayStart && !mondayStart && diffSeconds > SecondsInDay) {
    return true;
  }

  const weekendStart = saturdayStart ?? cancellationDateMoment;
  const weekendEnd = mondayStart ?? dateMoment;

  const closestWeekendSeconds = weekendEnd.diff(weekendStart, 'seconds');

  return diffSeconds - closestWeekendSeconds > SecondsInDay;
}
