import moment from 'moment-timezone';
import { ISlot } from './slot.interface';
import { LondonTimezone } from '@utils/dates/london-timezone';

const timeIntervals = [
  { start: '09:00', end: '10:30' },
  { start: '10:30', end: '12:00' },
  { start: '12:00', end: '13:30' },
  { start: '14:00', end: '15:30' },
  { start: '15:30', end: '17:00' },
  { start: '17:00', end: '18:30' },
  { start: '18:30', end: '20:00' },
];

export function splitSlotsByTimeIntervals(slots: ISlot[]): ISlot[] {
  const splittedSlots: ISlot[] = [];

  for (const { start, end } of slots) {
    const slotStart = moment(start).tz(LondonTimezone);
    const slotEnd = moment(end).tz(LondonTimezone);

    timeIntervals.forEach(({ start, end }) => {
      const intervalStart = slotStart
        .clone()
        .hour(parseInt(start.split(':')[0]))
        .minute(parseInt(start.split(':')[1]));
      const intervalEnd = slotStart
        .clone()
        .hour(parseInt(end.split(':')[0]))
        .minute(parseInt(end.split(':')[1]));

      // Check if the slot overlaps with the interval
      if (
        intervalStart.isBetween(slotStart, slotEnd, undefined, '[]') &&
        intervalEnd.isBetween(slotStart, slotEnd, undefined, '[]')
      ) {
        splittedSlots.push({
          start: intervalStart.toISOString(true),
          end: intervalEnd.toISOString(true),
        });
      }
    });
  }

  return splittedSlots;
}
