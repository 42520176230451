import { Moment } from 'moment-timezone';
import { ISODateString } from '@utils/dates/iso-string.type';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';

export type FormModel = {
  startDate: Moment | null;
  endDate: Moment | null;
  tutorId: number[];
  isPaid: boolean | null;
  organisationId: number[];
  funderInvoice: string;
  tutorInvoice: string | null;
  sessionId: string | null;
  signedByTutor: boolean;
  signedByStudent: boolean;
};

export type ApiModel = {
  startDate: ISODateString | null;
  endDate: ISODateString | null;
  tutorId: number[];
  isPaid: boolean | null;
  organisationId: number[];
  funderInvoice: string | null;
  tutorInvoice: string | null;
  sessionId: string | null;
  signedByTutor: boolean | null;
  signedByStudent: boolean | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  startDate: { type: 'date', initial: null },
  endDate: { type: 'date', initial: null },
  tutorId: { type: 'backend-label', optionKey: 'tutorOptions', multiple: true, initial: [] },
  isPaid: { type: 'transportable', initial: null },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', multiple: true, initial: [] },
  funderInvoice: { type: 'transportable', initial: '' },
  tutorInvoice: { type: 'transportable', initial: '' },
  sessionId: { type: 'transportable', initial: '' },
  signedByTutor: { type: 'transportable', initial: null },
  signedByStudent: { type: 'transportable', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  startDate: Serializers.date,
  endDate: Serializers.date,
  tutorId: Serializers.backendValue,
  isPaid: Serializers.booleanNullable,
  organisationId: Serializers.backendValue,
  funderInvoice: Serializers.stringNullable,
  tutorInvoice: Serializers.stringNullable,
  sessionId: Serializers.stringNullable,
  signedByTutor: Serializers.booleanNullable,
  signedByStudent: Serializers.booleanNullable,
});
