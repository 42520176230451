import { useCreateSessionMutation, useSaveSessionMutation } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { ISession } from '@features/sessions/session.interface';
import { SessionsActions } from '@features/sessions/sessions.slice';
import { RequestStatusFlags, mergeRequestStatusFlags } from '@utils/merge-request-status-flags';
import { isErrorResponse } from '@api/error-response.interface';
import { errorHandlerFactory } from '@api/error-handler.factory';
import { FormModel, sanitize, serialize } from './form-config';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import { AppBackendLabels } from '@features/backend-label/backend-label.type';
import { createPreventBubblingFormHandler } from '@utils/create-prevent-bubbling-form-handler.factory';
import { IsDisabledAndWhy, getIsAndWhyDisabled } from '@utils/forms/get-is-and-why-disabled';
import { getIsInvoiceDisabled } from '@utils/get-is-invoices-disabled';

type HookResult = {
  submitHandler: React.FormEventHandler;
  isLabelsLoading: boolean;
  buttonText: string;
  isCancelled: boolean;
  isInvoiceDisabled: boolean;
} & UseFormReturn<FormModel> &
  AppBackendLabels &
  RequestStatusFlags &
  IsDisabledAndWhy;

export function useSaveSessionForm(session: ISession | void, allocationId: number, onClose: () => void): HookResult {
  const { isLoading: isLabelsLoading, ...backendLabels } = useAppBackendLabels(['sessionsCancellationReasonOptions']);
  const defaultValues = sanitize(session, backendLabels);
  const useFormResult = useForm({ defaultValues });
  const { handleSubmit, formState, setError, resetField, watch } = useFormResult;
  const [createMutation, createFlags] = useCreateSessionMutation();
  const [saveMutation, saveFlags] = useSaveSessionMutation();
  const dispatch = useAppDispatch();

  const id = session?.id ?? null;

  const errorHandler = errorHandlerFactory<FormModel>(setError);

  const handleFormSubmit = useCallback(
    async (data: FormModel): Promise<ISession | void> => {
      const serializedData = serialize(data);

      const mutationHandler = id ? saveMutation : createMutation;
      const payload = {
        allocationId,
        payload: {
          ...serializedData,
          id,
        },
      };
      const result = await mutationHandler(payload).unwrap();

      if (isErrorResponse(result)) {
        errorHandler(result);
        return;
      }

      dispatch(SessionsActions.upsertSession(result));
      onClose();
    },
    [id, saveMutation, createMutation, allocationId, dispatch, onClose, errorHandler],
  );

  const submitHandler = createPreventBubblingFormHandler(handleSubmit(handleFormSubmit));

  const isCancelled = watch('isCancelled');

  useEffect(() => {
    if (isCancelled === false) {
      resetField('cancellationDate');
      resetField('cancellationReason');
    }
  }, [isCancelled, resetField]);

  const baseRequiredFieldLabels = ['Start Date Time', 'Duration', 'Offered Date Time'];
  const cancelledRequiredFieldLabels = ['Cancellation Date Time', 'Cancellation Reason'];
  const requiredFieldLabels = [...baseRequiredFieldLabels, ...(isCancelled ? cancelledRequiredFieldLabels : [])];

  const cancellationDate = watch('cancellationDate');
  const appointmentDate = watch('startMoment');
  const isInvoiceDisabled = useMemo(
    () => getIsInvoiceDisabled(appointmentDate, cancellationDate),
    [appointmentDate, cancellationDate],
  );

  return {
    ...backendLabels,
    ...useFormResult,
    ...mergeRequestStatusFlags(createFlags, saveFlags),
    ...getIsAndWhyDisabled(formState, requiredFieldLabels, isLabelsLoading),
    submitHandler,
    isLabelsLoading,
    buttonText: id ? 'Save Session' : 'Create Session',
    isCancelled,
    isInvoiceDisabled,
  };
}
