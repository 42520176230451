import { baseUrl } from '@environment/config';

export async function downloadReportTemplate(orderId: number, token: string): Promise<void> {
  try {
    const url = `${baseUrl}/needs_assessment_orders/${orderId}/generate_report_template`;

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const disposition = response.headers.get('content-disposition');
    const filenameRegex = /filename\*?=["']?([^"';]+)["']?/;
    const match = disposition?.match(filenameRegex);
    const filename = match ? match[1] : 'Report_Template.docx';

    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `${filename}.docx`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Download failed:', error);
  }
}
