import {
  BaseOrderApiModel,
  BaseOrderFormModel,
  baseOrderSanitizeMap,
  baseOrderSerializeMap,
} from '@features/order/base/form-config';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { IOrderAnnualSupportCharge } from './annual-support-charge-order.interface';

export type FormModel = BaseOrderFormModel & Pick<IOrderAnnualSupportCharge, 'quoteInternalId'>;

export type ApiModel = BaseOrderApiModel & Pick<IOrderAnnualSupportCharge, 'quoteInternalId'>;

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  ...baseOrderSanitizeMap,
  quoteInternalId: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  ...baseOrderSerializeMap,
  quoteInternalId: Serializers.string,
});
