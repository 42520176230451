import { createEnumOptions } from '@utils/enum/create-enum.factory';

export const RequestReasons = {
  PreAssessmentFormReview: 'Pre-Assessment Form Review',
  UploadDocuments: 'Upload Documents',
  NeedsAssessmentPrep: 'Needs Assessment Prep',
  MedicalEvidenceReview: 'Medical Evidence Review',
  DSA1Review: 'DSA1 Review',
} as const;

type Keys = keyof typeof RequestReasons;

export type RequestReasonsType = (typeof RequestReasons)[Keys];

export const RequestReasonsOptions = createEnumOptions(RequestReasons);
