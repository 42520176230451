import { useCallback } from 'react';
import { useSubmitNoShowMutation } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { AppointmentsActions } from '@features/order/needs-assessment/appointment/appointments.slice';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { isErrorResponse } from '@api/error-response.interface';

type HookResult = {
  submitHandler: (appointmentId: number | null, orderId: number, onClose: () => void) => Promise<void>;
} & RequestStatusFlags;

export function useSubmitNoShow(): HookResult {
  const [mutation, flags] = useSubmitNoShowMutation();
  const dispatch = useAppDispatch();

  const submitHandler = useCallback(
    async (appointmentId: number | null, orderId: number, onClose: () => void): Promise<void> => {
      const result = await mutation({ appointmentId, orderId }).unwrap();

      if (isErrorResponse(result)) {
        return;
      }

      dispatch(AppointmentsActions.upsertAppointment(result));
      onClose();
    },
    [dispatch, mutation],
  );

  return {
    submitHandler,
    ...flags,
  };
}
