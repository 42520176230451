import { useGetDeliveryCalendarQuery } from '@api/api-slice';
import { ISODateString } from '@utils/dates/iso-string.type';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { IDeliveryCalendar } from './api';

type HookResult = RequestStatusFlags & {
  calendar: IDeliveryCalendar;
};

export function useTechOrdersByDate(startDate: ISODateString, endDate: ISODateString): HookResult {
  const { data: calendar, ...flags } = useGetDeliveryCalendarQuery([startDate, endDate]);
  return {
    calendar: calendar ?? {},
    ...flags,
  };
}
