import { AnyAction, Middleware } from '@reduxjs/toolkit';

type ActionDetails = {
  type: string;
  timestamp: number;
};

const ActionDetailsByPayload = new Map<object, ActionDetails>();
const DebouncePeriod = 64;
const CleanupTimeout = DebouncePeriod * 2;

function saveActionDetailsAndScheduleCleanUp({ type, payload }: AnyAction, timestamp: number): void {
  ActionDetailsByPayload.set(payload, { type, timestamp });
  setTimeout(() => {
    ActionDetailsByPayload.get(payload) && ActionDetailsByPayload.delete(payload);
  }, CleanupTimeout);
}

/**
 * Middleware to debounce actions with object payloads, allowing a leading edge execution.
 * It means that the first action of type and payload will be executed.
 * And all same actions with same payload within debounce period will be ignored.
 */
export const debounceObjectPayloadMiddleware: Middleware = () => (next) => (action) => {
  if (typeof action.payload !== 'object' || action.payload === null) {
    return next(action);
  }

  const now = Date.now();
  const actionDetails = ActionDetailsByPayload.get(action.payload);
  if (actionDetails && actionDetails.type === action.type && now - actionDetails.timestamp < DebouncePeriod) {
    saveActionDetailsAndScheduleCleanUp(action, now);
    // ignore action, the same action already processed in debounce period
    return;
  }

  saveActionDetailsAndScheduleCleanUp(action, now);
  return next(action);
};
