import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useGetModelProgressRecordsQuery } from '@api/api-slice';
import { IActionRecord } from '@features/action-records/action-record.interface';
import { IPaginable } from '@features/pagination/paginable.interface';
import { getPaginationResult } from '@features/pagination/get-pagination-result';
import { RecordModelNameType } from './record-model-name.type';

export type HookResult = RequestStatusFlags & IPaginable<IActionRecord>;

export function useProgressRecords(
  modelName: RecordModelNameType,
  id: number,
  count: number,
  skip: number,
): HookResult {
  return getPaginationResult(useGetModelProgressRecordsQuery({ count, skip, modelName, id }));
}
