export interface IFieldError {
  name: string;
  message: string;
}

export interface IErrorResponse {
  message: string;
  fields: IFieldError[];
}

export function isErrorResponse(response: unknown): response is IErrorResponse {
  return (
    isObjectWithKeys<IErrorResponse>(response, ['fields', 'message']) &&
    typeof response.message === 'string' &&
    Array.isArray(response.fields) &&
    response.fields.every(
      (field: unknown) =>
        isObjectWithKeys<IFieldError>(field, ['message', 'name']) &&
        typeof field.name === 'string' &&
        typeof field.message === 'string',
    )
  );
}

function isObjectWithKeys<T extends object>(target: unknown, keys: string[]): target is T {
  if (typeof target !== 'object' || target === null) {
    return false;
  }

  return keys.every((key) => key in target);
}
