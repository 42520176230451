import { useSaveQuoteMutation } from '@api/api-slice';
import { IQuoteDetail } from '@features/quote/quote.interface';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { isErrorResponse } from '@api/error-response.interface';
import { IProductSummary } from '@features/product/product.interface';
import { QuoteStatusType, QuoteStatuses } from '../quote-status.type';
import { useRole } from '@features/user/use-role';
import { UserRoles } from '@features/user/user-role.type';

type HookResult = {
  editHandler: () => Promise<void>;
  isEditVisible: boolean;
  isDraft: boolean;
} & RequestStatusFlags;

export const useAssessorQuoteAction = (
  quote: IQuoteDetail,
  setQuote: (quote: IQuoteDetail) => unknown,
  setProducts: (value: IProductSummary[]) => void,
): HookResult => {
  const [mutation, flags] = useSaveQuoteMutation();
  const role = useRole();
  const isDraft = quote.status === QuoteStatuses.Draft;
  const status = isDraft ? QuoteStatuses.Completed : QuoteStatuses.Draft;

  const editHandler = async (): Promise<void> => {
    if (!quote) {
      return;
    }
    const result = await mutation({
      quoteInternalId: quote.quoteInternalId,
      quote: {
        status,
      },
    }).unwrap();
    if (isErrorResponse(result)) {
      return;
    }
    setQuote(result);
    setProducts(result.quoteProducts);
  };

  const editableStatuses: QuoteStatusType[] = [QuoteStatuses.Draft, QuoteStatuses.Completed];
  const isEditVisible = quote !== null && editableStatuses.includes(quote.status) && role === UserRoles.assessor;

  return {
    ...flags,
    editHandler,
    isEditVisible,
    isDraft,
  };
};
