import React, { useMemo } from 'react';
import { isNil } from 'lodash';
import { CopyToClipboard } from '@components/CopyToClipboard';
import { EmptyCell } from '@utils/empty-cell';
import { AppBackendLabels, IBackendLabelOption } from '@features/backend-label/backend-label.type';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';

type Column = {
  field: string;
  headerName?: string;
  optionKey?: keyof AppBackendLabels;
};

type Props<T> = {
  rows: T[];
  columns: Column[];
};

function getDisplayText(value: unknown, labelOptions?: IBackendLabelOption<string | number | null>[]): string {
  if (Array.isArray(value)) {
    return value.map((item) => labelOptions?.find((option) => option.value === item)?.label ?? EmptyCell).join(', ');
  }

  if (labelOptions) {
    // Find label for the value, if not found use EmptyCell
    return labelOptions.find((option) => option.value === value)?.label ?? EmptyCell;
  }
  // Return string value, use EmptyCell for undefined and null values
  return isNil(value) ? EmptyCell : String(value);
}

// Function to construct a tab-separated table string
function createTabSeparatedTable<T>(rows: T[], columns: Column[], backendLabels: AppBackendLabels): string {
  // Construct table header
  const header = columns.map((column) => column.headerName || column.field).join('\t');

  // Construct table body
  const body = rows
    .map((row) =>
      columns
        .map(({ field, optionKey }) =>
          getDisplayText(row[field as keyof T], optionKey ? backendLabels[optionKey] : undefined),
        )
        .join('\t'),
    )
    .join('\n');

  return `${header}\n${body}`;
}

export const CopyTableToClipboard: <T>(props: Props<T>) => JSX.Element = ({ rows, columns }) => {
  const backendLabels = useAppBackendLabels([]);
  const tableString = useMemo(
    () => createTabSeparatedTable(rows, columns, backendLabels),
    [rows, columns, backendLabels],
  );
  return <CopyToClipboard textToCopy={tableString} />;
};
