import { useSendNeedsAssessmentReportMutation } from '@api/api-slice';
import { isErrorResponse } from '@api/error-response.interface';
import { RouteParamsType } from '@routes/route-params.type';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { ReportRecipient } from './report-recipient';
import { SendReportRequest } from '../api';

type HookResult = {
  sendReport: (reportType: string) => Promise<void>;
} & RequestStatusFlags;

export function useSendReport(): HookResult {
  const [mutation, flags] = useSendNeedsAssessmentReportMutation();

  const { orderIdString } = useParams<RouteParamsType>();
  const orderId = parseInt(orderIdString as string);

  const sendReport = async (reportType: string): Promise<void> => {
    const payload: SendReportRequest = { orderId, payload: { toFundingBody: true, toHep: true, finalToStudent: true } };

    if (reportType === ReportRecipient.student) {
      payload.payload = { draftToStudent: true };
    }

    const result = await mutation(payload).unwrap();

    if (isErrorResponse(result)) {
      return;
    }

    enqueueSnackbar('Your report has been sent successfully!', { variant: 'success' });
  };

  return { sendReport, ...flags };
}
