import { IBackendLabelOption } from '@features/backend-label/backend-label.type';
import { IAllocation } from '@features/allocations/allocation.interface';
import {
  AdminRoleType,
  AssessorRoleType,
  FinanceManagerType,
  OperatorRoleType,
  TutorRoleType,
  UserRoleType,
  UserRoles,
} from '@features/user/user-role.type';

export interface IUserOptionBase extends IBackendLabelOption<number> {
  role: UserRoleType;
}

export interface ITutorOption extends IUserOptionBase {
  role: TutorRoleType;
  nmhSupportTypes: IAllocation['nmhSupportType'][];
  qualification: number[];
}

export interface IOperatorOption extends IUserOptionBase {
  role: OperatorRoleType | FinanceManagerType;
  nmhSupportTypes: never[];
}

export interface IAdminOption extends IUserOptionBase {
  role: AdminRoleType;
  nmhSupportTypes: never[];
}

export interface IAssessorOption extends IUserOptionBase {
  role: AssessorRoleType;
  assessmentCentreIds: number[];
}

export type IUserOption = ITutorOption | IOperatorOption | IAdminOption;

export const isOperatorOption = (option: IUserOption): option is IOperatorOption => option.role === UserRoles.operator;
export const isTutorOption = (option: IUserOption): option is ITutorOption => option.role === UserRoles.tutor;
export const isAdminOption = (option: IUserOption): option is IAdminOption => option.role === UserRoles.admin;
export const isManagerOption = (option: IUserOption): option is IAdminOption | IOperatorOption =>
  option.role === UserRoles.admin || option.role === UserRoles.operator || option.role === UserRoles.financeManager;
