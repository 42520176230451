import React from 'react';
import { IOrderTechnical } from '@features/order/technical/order-technical.interface';
import { IOrderRepairAndReplace } from '@features/order/repair-and-replace/order-repair-and-replace.interface';
import { Box, Chip, Tab } from '@mui/material';
import { QuoteLines } from './QuoteLines';
import { SalesOrderLines } from './SalesOrderLines';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { IQuoteLineProduct, LinesTabs, LinesTabsType } from './use-quote-line-products';

type Props = {
  order: IOrderTechnical | IOrderRepairAndReplace;
  products: IQuoteLineProduct[];
  currentTab: LinesTabsType;
  setCurrentTab: (value: LinesTabsType) => unknown;
};

export const LineComponentId = 'Line-Component';

export const Lines: React.FC<Props> = ({ order, products, currentTab, setCurrentTab }) => {
  const handleChange = (event: React.SyntheticEvent, newValue: LinesTabsType): void => {
    setCurrentTab(newValue);
  };

  const isStatusVisible = order.unleashedStatus && currentTab === LinesTabs.salesOrder;

  return (
    <Box sx={{ mt: 2 }} id={LineComponentId}>
      <TabContext value={currentTab}>
        <TabList onChange={handleChange} sx={{ mb: 1 }}>
          <Tab value={LinesTabs.quote} label={LinesTabs.quote} />
          <Tab value={LinesTabs.salesOrder} label={LinesTabs.salesOrder} />
          {isStatusVisible && (
            <Chip
              label={order.unleashedStatus}
              variant="outlined"
              color="primary"
              sx={{ alignSelf: 'center', ml: 2 }}
            />
          )}
        </TabList>
        <TabPanel value={LinesTabs.quote} sx={{ p: 0 }}>
          <QuoteLines lines={products} />
        </TabPanel>
        <TabPanel value={LinesTabs.salesOrder} sx={{ p: 0 }}>
          <SalesOrderLines lines={order.salesOrderLines} orderId={order.id} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
