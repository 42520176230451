import { useGetAnnouncementQuery } from '@api/api-slice';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { Announcement } from './api';

type HookResult = {
  announcement: Partial<Announcement>;
} & RequestStatusFlags;

export function useGetAnnouncement(): HookResult {
  const { data, ...flags } = useGetAnnouncementQuery();

  return {
    announcement: data?.announcement ?? {},
    ...flags,
  };
}
