import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { filterEmptyObjectFields } from '@utils/filter-empty-object-fields';
import { stringifyObjectFields } from '@utils/stringify-object-fields';
import { FormModel, sanitize, serialize } from './form-config';
import { OrderClasses } from '@features/order/order-class.type';
import { DateFilterOptions } from './date.type';

export const FilterAssessmentOrderSummariesForm: React.FC = () => {
  const { isLoading, ...backendLabels } = useAppBackendLabels(['statusOptions']);
  const [params, setParams] = useSearchParams();

  const { control, handleSubmit, reset } = useForm<FormModel>({
    values: sanitize(deserializeURLSearchParams(params), backendLabels),
  });
  const assessmentStatuses = backendLabels.statusOptions.filter(
    (option) => option.orderClass === OrderClasses.NeedsAssessment,
  );

  function onSubmit(data: FormModel): void {
    setParams(new URLSearchParams(stringifyObjectFields(filterEmptyObjectFields(serialize(data)))));
  }

  function onReset(): void {
    reset();
    setParams({});
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1} columns={{ xs: 4, md: 8, lg: 12 }}>
        <Grid item xs={4} md={4} lg={3}>
          <ControlledAutocomplete
            control={control}
            name="orderStatusId"
            label="Order Status"
            options={assessmentStatuses}
            isLoading={isLoading}
            multiple
            isNullAvailable
          />
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <ControlledAutocomplete
            control={control}
            name="date"
            label="Date"
            options={DateFilterOptions}
            isLoading={isLoading}
            isNullAvailable
          />
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <ControlledTextField control={control} name="customerFirstName" label="First Name" />
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <ControlledTextField control={control} name="customerLastName" label="Last Name" />
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <ControlledTextField control={control} name="customerCrn" label="CRN" />
        </Grid>
        <Grid item xs={4} md={4} lg={3}></Grid>
        <Grid item xs={4} md={4} lg={3}>
          <Button type="reset" variant="outlined" onClick={onReset} fullWidth>
            Reset
          </Button>
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <Button type="submit" variant="contained" fullWidth>
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
