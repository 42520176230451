import React, { useState } from 'react';
import { Box, Button, Tab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { AppModal } from '@components/AppModal';
import { SaveContactRecordForm } from '@features/action-records/SaveContactRecordForm';
import { useToggle } from '@hooks/use-toggle';
import { RestrictRole } from '@features/auth/RestrictRole';
import { UserRoles } from '@features/user/user-role.type';
import { OrderContactRecords } from './OrderContactRecords';
import { OrderProgressRecords } from './OrderProgressRecords';
import { TabContext, TabList, TabPanel } from '@mui/lab';

export const ActionRecordTabs = {
  Progress: 'Progress Records',
  Contact: 'Contact Records',
} as const;
type Keys = keyof typeof ActionRecordTabs;
export type ActionRecordTabType = (typeof ActionRecordTabs)[Keys];

type Props = {
  orderId: number;
};

export const OrderActionRecords: React.FC<Props> = ({ orderId }) => {
  const [isOpen, toggleIsOpen] = useToggle();
  const [currentTab, setCurrentTab] = useState<ActionRecordTabType>(ActionRecordTabs.Progress);

  const handleChange = (event: React.SyntheticEvent, newValue: ActionRecordTabType): void => {
    setCurrentTab(newValue);
  };

  const handleClose = (): void => {
    toggleIsOpen();
    setCurrentTab(ActionRecordTabs.Contact);
  };

  return (
    <Box sx={{ marginTop: 2 }}>
      <TabContext value={currentTab}>
        <TabList onChange={handleChange} sx={{ mb: 1 }}>
          <Tab value={ActionRecordTabs.Progress} label={ActionRecordTabs.Progress} />
          <Tab value={ActionRecordTabs.Contact} label={ActionRecordTabs.Contact} />
          <RestrictRole roles={[UserRoles.assessor, UserRoles.qualityAssurance]}>
            <Button
              variant="outlined"
              size="small"
              onClick={toggleIsOpen}
              startIcon={<AddIcon />}
              sx={{ marginLeft: 'auto' }}
            >
              Create Contact Record
            </Button>
          </RestrictRole>
        </TabList>
        <TabPanel value={ActionRecordTabs.Progress} sx={{ p: 0 }}>
          <OrderProgressRecords orderId={orderId} />
        </TabPanel>
        <TabPanel value={ActionRecordTabs.Contact} sx={{ p: 0 }}>
          <OrderContactRecords orderId={orderId} />
        </TabPanel>
      </TabContext>
      <AppModal open={isOpen} onClose={toggleIsOpen} title="New Contact Record">
        <SaveContactRecordForm orderId={orderId} onClose={handleClose} />
      </AppModal>
    </Box>
  );
};
