import { useGetOrderTypeSummariesQuery } from '@api/api-slice';
import { ISODateString } from '@utils/dates/iso-string.type';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { IOrderTypeSummary } from './order-type-summary.interface';

type HookResult = RequestStatusFlags & {
  summaries: IOrderTypeSummary[];
};

export function useOrderTypeSummaries(startDate: ISODateString, endDate: ISODateString): HookResult {
  const { data: summaries, ...flags } = useGetOrderTypeSummariesQuery([startDate, endDate]);
  return {
    summaries: summaries ?? [],
    ...flags,
  };
}
