import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useGetAssessorsQuery } from '@api/api-slice';
import { getPaginationResult } from '@features/pagination/get-pagination-result';
import { IPaginable } from '@features/pagination/paginable.interface';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { IAssessor } from './assessor.interface';

type HookResult = RequestStatusFlags & IPaginable<IAssessor>;

export function useAssessors(urlSearchParams: URLSearchParams, count: number, skip: number): HookResult {
  const params = deserializeURLSearchParams(urlSearchParams);
  return getPaginationResult(useGetAssessorsQuery({ ...params, count, skip }));
}
