import moment from 'moment-timezone';
import { DateTimeFormat, TimeFormat } from './formats';
import { LondonTimezone } from './london-timezone';

/**
 * @param start ISODateTime string
 * @param end ISODateTime string
 */
export function formatDateTimeRange(start: string, end: string): string {
  const startMoment = moment(start).tz(LondonTimezone);
  const endMoment = moment(end).tz(LondonTimezone);

  return `${startMoment.format(DateTimeFormat)}–${endMoment.format(TimeFormat)}`;
}
