import { ISession } from '@features/sessions/session.interface';
import { createDraftSafeSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { selectUserState } from '@features/user/user.slice';

const sessionsAdapter = createEntityAdapter<ISession>({
  selectId: (entry) => entry.id,
});

const sessionsSlice = createSlice({
  name: 'Sessions',
  initialState: sessionsAdapter.getInitialState(),
  reducers: {
    upsertSessions: sessionsAdapter.upsertMany,
    upsertSession: sessionsAdapter.upsertOne,
    updateSession: sessionsAdapter.updateOne,
  },
});

export const SessionsActions = sessionsSlice.actions;
export const sessionsReducer = sessionsSlice.reducer;
export type SessionsActionsType = typeof SessionsActions;

const { selectAll } = sessionsAdapter.getSelectors((state: RootState) => state.sessions);

export const selectSessionsByAllocationId = createDraftSafeSelector(
  selectAll,
  (_: RootState, allocationId: number | null) => allocationId,
  (sessions, allocationId) => sessions.filter((session) => session.nmhAllocationId === allocationId),
);

export const selectSessionsByTutorId = createDraftSafeSelector(
  selectAll,
  (_: RootState, tutorId: number) => tutorId,
  (sessions, tutorId) => sessions.filter((session) => session.tutorId === tutorId),
);

export const selectCurrentUserSessions = createDraftSafeSelector(selectAll, selectUserState, (sessions, userState) => {
  const userId = userState.user?.id;
  return userId ? sessions.filter((session) => session.tutorId === userId) : [];
});
