import { StructError, assert } from 'superstruct';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { FilterSessionsResponse } from '../api';
import { SessionSummaryStruct } from './session-struct.spec';

export function validateSessionSummaries(response: FilterSessionsResponse, url: string): void {
  for (const entry of response.entries) {
    try {
      assert(entry, SessionSummaryStruct);
    } catch (error) {
      for (const failure of (error as StructError).failures()) {
        handleFailure(failure, url);
      }
    }
  }
}
