import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { WeekSlotSummary } from './slot-summary.interface';
import { DaySlotSummary } from './DaySlotSummary';

type Props = {
  summary: WeekSlotSummary;
  title: string;
};

export const UtilisationSummary: React.FC<Props> = ({ summary, title }) => {
  return (
    <TableRow>
      <TableCell colSpan={3}>{title}</TableCell>
      <DaySlotSummary details={summary.monday} />
      <DaySlotSummary details={summary.tuesday} />
      <DaySlotSummary details={summary.wednesday} />
      <DaySlotSummary details={summary.thursday} />
      <DaySlotSummary details={summary.friday} />
      <DaySlotSummary details={summary.saturday} />
      <DaySlotSummary details={summary.sunday} />
    </TableRow>
  );
};
