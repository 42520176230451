import React from 'react';
import { Button, Stack } from '@mui/material';
import { ModalChildProps } from '@components/AppModal';
import { ControlledFileField } from '@components/inputs/ControlledFileField';
import { ControlledToggleButton } from '@components/inputs/ControlledToggleButton';
import { IReport } from '../report.interface';
import { useSaveReportForm } from './use-save-report-form';

type Props = {
  report: IReport | void;
  orderId: number;
} & ModalChildProps;

export const SaveReportForm: React.FC<Props> = ({ report, orderId, onClose }) => {
  const { submitHandler, isSaveEnabled, buttonText, control } = useSaveReportForm(report, orderId, onClose);

  return (
    <form onSubmit={submitHandler}>
      <Stack direction="column" spacing={2}>
        <ControlledToggleButton
          control={control}
          name="isCurrent"
          getLabel={(value: boolean): string => (value ? 'Current' : 'Not Current')}
          fullWidth
        />
        <ControlledToggleButton
          control={control}
          name="isFinal"
          getLabel={(value: boolean): string => (value ? 'Final' : 'Not Final')}
          fullWidth
        />
        <ControlledFileField
          fullWidth
          control={control}
          name="reportReferenceFile"
          label="Reference File"
          fileDetails={report?.reportReferenceFilePath}
          buttonLabel="Preview Reference"
          inputProps={{
            accept: '.pdf, .doc, .docx',
          }}
          isPreview
        />
        <Button variant="contained" size="large" type="submit" disabled={!isSaveEnabled}>
          {buttonText}
        </Button>
      </Stack>
    </form>
  );
};
