import { startCase } from 'lodash';
import { FieldValues, FormState } from 'react-hook-form';

export type IsDisabledAndWhy = {
  isDisabled: boolean;
  whyDisabledReason: string;
};

export function getIsAndWhyDisabled<FormModel extends FieldValues>(
  formState: FormState<FormModel>,
  requiredFieldNames: string[],
  isLabelsLoading: boolean,
): IsDisabledAndWhy {
  const hasRequiredFields = requiredFieldNames.length > 0;
  const isValid = hasRequiredFields ? formState.isValid : true;
  const isFilled = Object.keys(formState.dirtyFields).length > 0;

  const isDisabled = !isValid || !isFilled || formState.isLoading || formState.isSubmitting || isLabelsLoading;

  const isInvalidMessage = hasRequiredFields
    ? `${requiredFieldNames.map((name) => startCase(name.toString())).join(', ')} fields are required`
    : 'Form is invalid';

  // Reasons why the form might be disabled, ordered by priority
  const reasons = [
    { condition: isLabelsLoading, message: 'Labels are loading' },
    { condition: formState.isLoading, message: 'Form is loading' },
    { condition: formState.isSubmitting, message: 'Form is submitting' },
    { condition: !isValid, message: isInvalidMessage },
    { condition: !isFilled, message: 'No changes detected' },
  ];

  const whyDisabledReason = reasons.find((reason) => reason.condition)?.message ?? '';

  return {
    isDisabled,
    whyDisabledReason,
  };
}
