import React from 'react';
import { TableCell } from '@mui/material';
import { SlotDetails } from './slot-summary.interface';
import { EmptyCell } from '@utils/empty-cell';

type Props = {
  details: SlotDetails[];
};

export const DaySlotSummary: React.FC<Props> = ({ details }) => {
  return (
    <>
      {details.map(({ value, id }, index) => (
        <TableCell
          key={id}
          align="center"
          sx={{ borderLeft: '1px solid' }}
          className={index === 0 ? 'MuiTableCell-compact' : 'MuiTableCell-compact MuiTableCell-weak'}
        >
          {value ? value : EmptyCell}
        </TableCell>
      ))}
    </>
  );
};
