import { createEnumOptions } from '@utils/enum/create-enum.factory';

export const SuspensionReasons = {
  FundingBodyStop: 'Funding Body Stop',
  FinishedStudying: 'Finished Studying',
} as const;

type Keys = keyof typeof SuspensionReasons;

export type SuspensionReasonsType = (typeof SuspensionReasons)[Keys];

export const SuspensionReasonsOptions = createEnumOptions(SuspensionReasons);
