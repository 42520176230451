import { useCallback } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useCreateReportMutation, useSaveReportMutation } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { IReport } from '../report.interface';
import { ReportsActions } from '@features/order/needs-assessment/report/reports.slice';
import { RequestStatusFlags, mergeRequestStatusFlags } from '@utils/merge-request-status-flags';
import { isErrorResponse } from '@api/error-response.interface';
import { errorHandlerFactory } from '@api/error-handler.factory';
import { FormModel, sanitize, serialize } from './form-config';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { AppBackendLabels } from '@features/backend-label/backend-label.type';
import { filterEmptyObjectFields } from '@utils/filter-empty-object-fields';
import { createPreventBubblingFormHandler } from '@utils/create-prevent-bubbling-form-handler.factory';

type HookResult = {
  submitHandler: React.FormEventHandler;
  isSaveEnabled: boolean;
  isLabelsLoading: boolean;
  buttonText: string;
} & UseFormReturn<FormModel> &
  AppBackendLabels &
  RequestStatusFlags;

export function useSaveReportForm(report: IReport | void, orderId: number, onClose: () => void): HookResult {
  const { isLoading: isLabelsLoading, ...backendLabels } = useAppBackendLabels(['hepOptions', 'fundingBodyOptions']);
  const defaultValues = sanitize(report, backendLabels);
  const useFormResult = useForm({ defaultValues });
  const { handleSubmit, formState, setError } = useFormResult;
  const [createReportMutation, createFlags] = useCreateReportMutation();
  const [saveReportMutation, saveFlags] = useSaveReportMutation();
  const dispatch = useAppDispatch();

  const hasDirtyFields = Object.keys(formState.dirtyFields).length > 0;
  const id = report?.id ?? null;

  const errorHandler = errorHandlerFactory<FormModel>(setError);

  const handleFormSubmit = useCallback(
    async (data: FormModel): Promise<IReport | void> => {
      const serializedData = serialize(data);

      //Backend can't check empty fields
      const filteredData = filterEmptyObjectFields(serializedData);

      const mutationHandler = id ? saveReportMutation : createReportMutation;
      const payload = {
        orderId,
        reportId: id,
        payload: filteredData,
      };
      const result = await mutationHandler(payload).unwrap();

      if (isErrorResponse(result)) {
        errorHandler(result);
        return;
      }

      dispatch(ReportsActions.upsertReport(result));
      onClose();
    },
    [id, saveReportMutation, createReportMutation, orderId, dispatch, onClose, errorHandler],
  );

  const submitHandler = createPreventBubblingFormHandler(handleSubmit(handleFormSubmit));

  return {
    ...useFormResult,
    ...backendLabels,
    ...mergeRequestStatusFlags(createFlags, saveFlags),
    submitHandler,
    isSaveEnabled: hasDirtyFields && !formState.isLoading && !formState.isSubmitting && !isLabelsLoading,
    isLabelsLoading,
    buttonText: id ? 'Save Report' : 'Create Report',
  };
}
