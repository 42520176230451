import React from 'react';
import { useRole } from '@features/user/use-role';
import { UserRoles } from '@features/user/user-role.type';
import { CancelOrder } from './CancelOrder';
import { RestoreOrder } from './RestoreOrder';
import { useIsOrderDisabled } from './use-is-order-disabled';
import { OrderDisabledReasons } from './order-disabled-reason.type';

type Props = {
  orderId: number;
};

export const CancelOrRestoreOrder: React.FC<Props> = ({ orderId }) => {
  const { isDisabled, reason } = useIsOrderDisabled(orderId);
  const role = useRole();

  if (isDisabled) {
    if (reason === OrderDisabledReasons.OrderIsCancelled) {
      return <RestoreOrder orderId={orderId} />;
    }
    return null;
  }

  if (!role || (role !== UserRoles.operator && role !== UserRoles.financeManager)) {
    return null;
  }

  return <CancelOrder orderId={orderId} />;
};
