import { useRefreshAppointmentElectronicInvoiceMutation } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { AppointmentsActions } from '../appointments.slice';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { isErrorResponse } from '@api/error-response.interface';

type HookResult = {
  refreshAppointmentElectronicInvoice: (id: number) => Promise<void>;
} & RequestStatusFlags;

export function useRefreshAppointmentElectronicInvoice(): HookResult {
  const [refreshMutation, flags] = useRefreshAppointmentElectronicInvoiceMutation();
  const dispatch = useAppDispatch();

  const refreshAppointmentElectronicInvoice = async (id: number): Promise<void> => {
    const electronicInvoice = await refreshMutation(id).unwrap();
    if (!isErrorResponse(electronicInvoice)) {
      dispatch(AppointmentsActions.updateAppointment({ id, changes: { electronicInvoice } }));
    }
  };

  return { refreshAppointmentElectronicInvoice, ...flags };
}
