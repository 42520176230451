import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { IAnnualSupportCharge } from '../annual-support-charge-list/annual-support-charge.interface';
import { FormModel, sanitize, serialize } from './form-config';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useCreateAnnualSupportChargeManuallyMutation, useSaveAnnualSupportChargeMutation } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { RequestStatusFlags, mergeRequestStatusFlags } from '@utils/merge-request-status-flags';
import { AppBackendLabels } from '@features/backend-label/backend-label.type';
import { useCallback } from 'react';
import { createPreventBubblingFormHandler } from '@utils/create-prevent-bubbling-form-handler.factory';
import { isErrorResponse } from '@api/error-response.interface';
import { errorHandlerFactory } from '@api/error-handler.factory';
import { AnnualSupportChargeActions } from '../annual-support-charge-list/annual-support-charge.slice';
type HookResult = {
  submitHandler: React.FormEventHandler;
  isSaveEnabled: boolean;
  isLabelsLoading: boolean;
  buttonText: string;
} & UseFormReturn<FormModel> &
  AppBackendLabels &
  RequestStatusFlags;

export function useSaveAnnualSupportCharge(
  annualSupportCharge: IAnnualSupportCharge | void,
  orderId: number,
  onClose: () => void,
): HookResult {
  const { isLoading: isLabelsLoading, ...backendLabels } = useAppBackendLabels([]);

  const defaultValues = sanitize(annualSupportCharge, backendLabels);
  const useFormResult = useForm({ defaultValues });
  const { handleSubmit, formState, setError } = useFormResult;
  const hasDirtyFields = Object.keys(formState.dirtyFields).length > 0;
  const errorHandler = errorHandlerFactory<FormModel>(setError);

  const [createMutation, createFlags] = useCreateAnnualSupportChargeManuallyMutation();
  const [saveMutation, saveFlags] = useSaveAnnualSupportChargeMutation();

  const dispatch = useAppDispatch();

  const annualSupportChargeId = annualSupportCharge?.id ?? null;

  const handleFormSubmit = useCallback(
    async (data: FormModel): Promise<IAnnualSupportCharge | void> => {
      const serializedData = serialize(data);
      const mutationHandler = annualSupportCharge ? saveMutation : createMutation;

      const payload = {
        orderId,
        payload: serializedData,
        annualSupportChargeId,
      };

      const result = await mutationHandler(payload).unwrap();

      if (isErrorResponse(result)) {
        errorHandler(result);
        return;
      }

      dispatch(AnnualSupportChargeActions.upsertAnnualSupportCharge(result));
      onClose();
    },
    [
      annualSupportChargeId,
      orderId,
      annualSupportCharge,
      createMutation,
      saveMutation,
      onClose,
      dispatch,
      errorHandler,
    ],
  );

  const submitHandler = createPreventBubblingFormHandler(handleSubmit(handleFormSubmit));

  return {
    ...backendLabels,
    ...useFormResult,
    ...mergeRequestStatusFlags(createFlags, saveFlags),
    submitHandler,
    isSaveEnabled: hasDirtyFields && !formState.isLoading && !formState.isSubmitting && !isLabelsLoading,
    isLabelsLoading,
    buttonText: annualSupportCharge?.id ? 'Save ASC' : 'Create ASC',
  };
}
