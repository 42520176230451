import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useSaveTheftClaimForm } from './use-save-theft-claim-form';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { StolenEquipmentTypeOptions } from './stolen-equipment-type.type';
import { ControlledDateTimePicker } from '@components/inputs/ControlledDateTimePicker';
import { ControlledDatePicker } from '@components/inputs/ControlledDatePicker';
import { ControlledToggleButton } from '@components/inputs/ControlledToggleButton';
import { ITheftClaimForm } from './theft-claim-form.interface';
import { renderIsSubmittedByCustomer } from './render-is-submitted-by-customer';
import { PrintTheftClaimForm } from './PrintTheftClaimForm';

type Props = {
  orderId: number;
  theftClaimForm: ITheftClaimForm | null;
  setTheftClaimForm: (quote: ITheftClaimForm) => unknown;
};

export const SaveTheftClaimForm: React.FC<Props> = ({ theftClaimForm, setTheftClaimForm, orderId }) => {
  const {
    submitHandler,
    control,
    isSaveDisabled,
    isLoading: isSaving,
  } = useSaveTheftClaimForm(theftClaimForm, setTheftClaimForm, orderId);

  return (
    <form onSubmit={submitHandler}>
      <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }} sx={{ mt: 1 }}>
        <Grid item xs={4} md={4} lg={6}>
          <ControlledTextField control={control} name="formId" label="Form Id" disabled />
        </Grid>
        <Grid item xs={4} md={4} lg={6}>
          <ControlledTextField control={control} name="fullName" label="Full Name" />
        </Grid>
        <Grid item xs={4} md={4} lg={12}>
          <ControlledAutocomplete
            control={control}
            name="typesOfStolenEquipment"
            options={StolenEquipmentTypeOptions}
            label="Equipment Type"
            multiple
          />
        </Grid>
        <Grid item xs={4} md={4} lg={12}>
          <ControlledTextField
            control={control}
            name="descriptionOfStolenEquipment"
            label="Description Of Stolen Equipment"
            multiline
            minRows={3}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={6}>
          <ControlledDateTimePicker control={control} name="datetimeTheftOccured" label="Theft Occurred At" />
        </Grid>
        <Grid item xs={4} md={4} lg={6}>
          <ControlledDateTimePicker control={control} name="datetimeTheftDiscovered" label="Theft Discovered At" />
        </Grid>
        <Grid item xs={4} md={4} lg={6}>
          <ControlledDateTimePicker control={control} name="datetimeEquipmentLastSeen" label="Equipment Last Seen At" />
        </Grid>
        <Grid item xs={4} md={4} lg={6}>
          <ControlledDatePicker control={control} name="datePoliceReported" label="Police Reported At" />
        </Grid>
        <Grid item xs={4} md={4} lg={12}>
          <ControlledTextField control={control} name="crimeReferenceNumber" label="Crime Reference Number" />
        </Grid>
        <Grid item xs={4} md={4} lg={12}>
          <ControlledTextField control={control} name="detailsOfTheft" label="Details Of Theft" multiline minRows={3} />
        </Grid>
        <Grid item xs={4} md={4} lg={12}>
          <ControlledTextField
            control={control}
            name="stepsWereTakenToRecover"
            label="Steps Were Taken To Recover"
            multiline
            minRows={3}
          />
        </Grid>

        <Grid item xs={4} md={4} lg={12}>
          <Typography variant="body1" component="p">
            By completing this form, you confirm that all information is correct to the best of your knowledge and
            belief, full, true, accurate and correct.
          </Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={6}>
          <ControlledToggleButton
            control={control}
            name="submittedByCustomer"
            getLabel={renderIsSubmittedByCustomer}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} md={4} lg={6}>
          <ControlledDatePicker control={control} name="dateSubmitted" label="Submitted At" />
        </Grid>
      </Grid>
      {theftClaimForm && <PrintTheftClaimForm theftClaimForm={theftClaimForm} />}
      <AppLoadingButton fullWidth type="submit" isLoading={isSaving} disabled={isSaveDisabled} sx={{ mt: 2 }}>
        Save Theft Claim Form
      </AppLoadingButton>
    </form>
  );
};
