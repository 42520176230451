import { appMutationFactory, appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { ITheftClaimForm } from './theft-claim-form.interface';
import { ToSnakeCase, modelToSnakeCase } from '@utils/model-to-snake-case';

interface ITheftClaimFormResponse {
  repairAndReplaceReport: ITheftClaimForm;
}

export const buildGetTheftClaimForm = appQueryFactory<number, ITheftClaimForm>({
  query: (bodyId) => {
    return {
      url: EndpointUrlBuilder.getTheftClaimForm(bodyId),
      method: 'GET',
    };
  },
  transformResponse,
});

type SaveRequest = {
  bodyId: number;
  form: Partial<ITheftClaimForm>;
};

export const buildSaveTheftClaimForm = appMutationFactory<SaveRequest, ITheftClaimForm>({
  query: ({ bodyId, form }) => {
    return {
      url: EndpointUrlBuilder.saveTheftClaimForm(bodyId),
      method: 'PATCH',
      data: createPayload(form),
    };
  },
  transformResponse,
});

function transformResponse(response: ITheftClaimFormResponse): ITheftClaimForm {
  return response.repairAndReplaceReport;
}

function createPayload(quote: Partial<ITheftClaimForm>): ToSnakeCase<Partial<ITheftClaimForm>> {
  return modelToSnakeCase(quote);
}
