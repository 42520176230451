import { Moment } from 'moment-timezone';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { ITheftClaimForm } from './theft-claim-form.interface';
import { StolenEquipmentTypeOptions } from './stolen-equipment-type.type';

export type FormModel = Pick<
  ITheftClaimForm,
  | 'formId'
  | 'fullName'
  | 'typesOfStolenEquipment'
  | 'descriptionOfStolenEquipment'
  | 'crimeReferenceNumber'
  | 'detailsOfTheft'
  | 'stepsWereTakenToRecover'
  | 'submittedByCustomer'
> & {
  datetimeTheftOccured: Moment | null;
  datetimeTheftDiscovered: Moment | null;
  datetimeEquipmentLastSeen: Moment | null;
  datePoliceReported: Moment | null;
  dateSubmitted: Moment | null;
};

export type ApiModel = Pick<
  ITheftClaimForm,
  | 'formId'
  | 'fullName'
  | 'typesOfStolenEquipment'
  | 'descriptionOfStolenEquipment'
  | 'crimeReferenceNumber'
  | 'detailsOfTheft'
  | 'stepsWereTakenToRecover'
  | 'submittedByCustomer'
  | 'datetimeTheftOccured'
  | 'datetimeTheftDiscovered'
  | 'datetimeEquipmentLastSeen'
  | 'datePoliceReported'
  | 'dateSubmitted'
>;

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  formId: { type: 'transportable', initial: '' },
  fullName: { type: 'transportable', initial: '' },
  typesOfStolenEquipment: { type: 'enum', options: StolenEquipmentTypeOptions, multiple: true, initial: [] },
  descriptionOfStolenEquipment: { type: 'transportable', initial: '' },
  crimeReferenceNumber: { type: 'transportable', initial: '' },
  detailsOfTheft: { type: 'transportable', initial: '' },
  stepsWereTakenToRecover: { type: 'transportable', initial: '' },
  submittedByCustomer: { type: 'transportable', initial: false },
  datetimeTheftOccured: { type: 'date-time', initial: null },
  datetimeTheftDiscovered: { type: 'date-time', initial: null },
  datetimeEquipmentLastSeen: { type: 'date-time', initial: null },
  datePoliceReported: { type: 'date', initial: null },
  dateSubmitted: { type: 'date', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  formId: Serializers.string,
  fullName: Serializers.string,
  typesOfStolenEquipment: Serializers.enum,
  descriptionOfStolenEquipment: Serializers.stringNullable,
  crimeReferenceNumber: Serializers.stringNullable,
  detailsOfTheft: Serializers.stringNullable,
  stepsWereTakenToRecover: Serializers.stringNullable,
  submittedByCustomer: Serializers.boolean,
  datetimeTheftOccured: Serializers.dateTime,
  datetimeTheftDiscovered: Serializers.dateTime,
  datetimeEquipmentLastSeen: Serializers.dateTime,
  datePoliceReported: Serializers.date,
  dateSubmitted: Serializers.date,
});
