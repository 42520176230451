import React, { FC } from 'react';
import { useReSendReport } from './use-re-send-report';
import { Box, Stack, Typography } from '@mui/material';
import { IFundingBodyOption, IHepLabelOption } from '@features/backend-label/backend-label.type';
import { ICustomer } from '@features/customer/customer.interface';
import { ControlledCheckbox } from '@components/inputs/ControlledCheckbox';
import { AppLoadingButton } from '@components/AppLoadingButton';

type Props = {
  hepOption: IHepLabelOption<number> | void;
  fundingBodyOption: IFundingBodyOption<number> | void;
  customer: ICustomer | null;
};

export const ReSendReport: FC<Props> = ({ hepOption, fundingBodyOption, customer }) => {
  const { submitHandler, control, formState, isLoading } = useReSendReport();

  return (
    <Box>
      <Typography variant="h4">Re-Send Final Needs Assessment Report</Typography>
      <form onSubmit={(e): void => submitHandler(e)}>
        <Stack direction="column" sx={{ mt: 1 }}>
          <ControlledCheckbox
            control={control}
            name="finalToStudent"
            label={`Student ${customer?.email ?? ''}`}
            disabled={!customer?.email}
          />
          <ControlledCheckbox
            control={control}
            name="toHep"
            label={`Hep ${hepOption?.email ?? ''}`}
            disabled={!hepOption?.email}
          />
          <ControlledCheckbox
            control={control}
            name="toFundingBody"
            disabled={!fundingBodyOption?.reportSubmissionEmail}
            label={`Funding Body ${fundingBodyOption?.reportSubmissionEmail ?? ''}`}
          />
        </Stack>

        <AppLoadingButton type="submit" fullWidth disabled={!formState.isDirty} isLoading={isLoading} sx={{ mt: 1 }}>
          Re-Send Selected
        </AppLoadingButton>
      </form>
    </Box>
  );
};
