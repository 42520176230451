import { StructError, assert } from 'superstruct';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { IAllocation } from '../allocation.interface';
import { AllocationStruct } from '../filter-allocations-form/spec/allocation-struct.spec';

export function validateAllocations(allocations: IAllocation[], url: string): void {
  for (const entry of allocations) {
    try {
      assert(entry, AllocationStruct);
    } catch (error) {
      for (const failure of (error as StructError).failures()) {
        handleFailure(failure, url);
      }
    }
  }
}
