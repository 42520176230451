import React from 'react';
import { Box, Typography } from '@mui/material';
import { QuoteProgressRecords } from './QuoteProgressRecords';
import { QuoteDetails } from './QuoteDetails';
import { SaveQuote } from './save-quote/SaveQuote';
import { useQuote } from './use-quote';
import { QuoteRecommendationTexts } from './QuoteRecommendations';
import { QuoteAnnualSupportCharge } from './QuoteAnnualSupportCharge';

type Props = {
  quoteId: string | null;
  orderId: number;
  isReadonly?: true;
};

export const QuoteDrawer: React.FC<Props> = ({ quoteId, orderId, isReadonly }) => {
  const { quote, setQuote, isLoading } = useQuote(quoteId);
  return (
    <Box>
      {quote ? (
        <QuoteDetails quote={quote} />
      ) : (
        <Typography variant="h4" component="h1">
          Create Quote
        </Typography>
      )}
      <SaveQuote orderId={orderId} quote={quote} setQuote={setQuote} isLoading={isLoading} isReadonly={isReadonly} />
      {quote && quote.annualSupportCharge && <QuoteAnnualSupportCharge quote={quote} />}
      {quote && <QuoteRecommendationTexts quote={quote} />}
      {quote && <QuoteProgressRecords id={quote.id} />}
    </Box>
  );
};
