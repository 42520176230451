import { useRequestSensitivePersonalInformationMutation } from '@api/api-slice';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { ISensitivePersonalInformation } from '@features/order/needs-assessment/sensitive-personal-information/sensitive-personal-information.interface';
import { useState } from 'react';

type HookResult = {
  requestHandler: (id: number, reason: string) => Promise<void>;
  spi: ISensitivePersonalInformation | null;
  setSpi: React.Dispatch<React.SetStateAction<ISensitivePersonalInformation | null>>;
} & RequestStatusFlags;

export function useRequestSensitivePersonalInformation(): HookResult {
  const [mutation, flags] = useRequestSensitivePersonalInformationMutation();
  const [spi, setSpi] = useState<ISensitivePersonalInformation | null>(null);

  const requestHandler = async (id: number, reason: string): Promise<void> => {
    const result = await mutation({ id, reason }).unwrap();
    setSpi(result);
  };

  return {
    requestHandler,
    spi,
    setSpi,
    ...flags,
  };
}
