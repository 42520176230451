import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useGetProductsQuery } from '@api/api-slice';
import { IProduct } from '../product.interface';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { getPaginationResult } from '@features/pagination/get-pagination-result';
import { IPaginable } from '@features/pagination/paginable.interface';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { sanitize, serialize } from './form-config';

type HookResult = RequestStatusFlags & IPaginable<IProduct>;

export function useFilteredProducts(urlSearchParams: URLSearchParams, count: number, skip: number): HookResult {
  const backendLabels = useAppBackendLabels(['productCategory']);
  const params = serialize(sanitize(deserializeURLSearchParams(urlSearchParams), backendLabels));
  return getPaginationResult(useGetProductsQuery({ ...params, count, skip }));
}
