import React, { SyntheticEvent } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { AppModal } from '@components/AppModal';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { useRole } from '@features/user/use-role';
import { UserRoles } from '@features/user/user-role.type';
import { useToggle } from '@hooks/use-toggle';
import { useRestoreOrder } from './use-restore-order';

type Props = {
  orderId: number;
};

export const RestoreOrder: React.FC<Props> = ({ orderId }) => {
  const [isModalOpen, toggleIsModalOpen] = useToggle();
  const { submitHandler, isLoading } = useRestoreOrder();
  const role = useRole();

  async function handleRestore(): Promise<void> {
    const isSuspended = await submitHandler(orderId);
    if (isSuspended) {
      toggleIsModalOpen();
    }
  }

  const handleCancel = async (event: SyntheticEvent): Promise<void> => {
    event.stopPropagation();
    toggleIsModalOpen();
  };

  const canResumeAccount = role === UserRoles.financeManager || role === UserRoles.operator;

  if (!canResumeAccount) {
    return null;
  }

  return (
    <>
      {/* Here button is contained, because is the only action user can do with the order. The save option is not available and greyed out */}
      <Button onClick={toggleIsModalOpen} variant="contained" size="large" color="warning">
        Restore Order
      </Button>

      <AppModal open={isModalOpen} onClose={toggleIsModalOpen} title="Please Fill Suspension Reason" color="warning">
        <>
          <Typography variant="h6" gutterBottom>
            You are about to reinstate a previously cancelled order. Please ensure you are not reinstating a duplicate
            order.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Please confirm to continue.
          </Typography>

          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
            <AppLoadingButton variant="contained" onClick={handleRestore} color="warning" isLoading={isLoading}>
              Restore Order
            </AppLoadingButton>
            <Button variant="outlined" onClick={handleCancel}>
              Go Back
            </Button>
          </Stack>
        </>
      </AppModal>
    </>
  );
};
