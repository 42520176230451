import { useCallback, useEffect, useMemo } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useCreateAppointmentMutation, useSaveAppointmentMutation } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { IAppointment } from '../appointment.interface';
import { AppointmentsActions } from '@features/order/needs-assessment/appointment/appointments.slice';
import { RequestStatusFlags, mergeRequestStatusFlags } from '@utils/merge-request-status-flags';
import { isErrorResponse } from '@api/error-response.interface';
import { errorHandlerFactory } from '@api/error-handler.factory';
import { FormModel, sanitize, serialize } from './form-config';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { AppBackendLabels } from '@features/backend-label/backend-label.type';
import { createPreventBubblingFormHandler } from '@utils/create-prevent-bubbling-form-handler.factory';
import { IsDisabledAndWhy, getIsAndWhyDisabled } from '@utils/forms/get-is-and-why-disabled';
import { getIsInvoiceDisabled } from '@utils/get-is-invoices-disabled';

type HookResult = {
  submitHandler: React.FormEventHandler;
  isLabelsLoading: boolean;
  buttonText: string;
  isCancelled: boolean;
  isInvoiceDisabled: boolean;
} & UseFormReturn<FormModel> &
  AppBackendLabels &
  RequestStatusFlags &
  IsDisabledAndWhy;

export function useSaveAppointmentForm(
  appointment: IAppointment | void,
  orderId: number,
  onClose: () => void,
): HookResult {
  const { isLoading: isLabelsLoading, ...backendLabels } = useAppBackendLabels([
    'assessmentCentreOptions',
    'assessorOptions',
    'appointmentsCancellationReasonOptions',
  ]);
  const defaultValues = sanitize(appointment, backendLabels);
  const useFormResult = useForm({ defaultValues });
  const { handleSubmit, formState, setError, watch, resetField } = useFormResult;
  const [createAppointmentMutation, createFlags] = useCreateAppointmentMutation();
  const [saveAppointmentMutation, saveFlags] = useSaveAppointmentMutation();
  const dispatch = useAppDispatch();

  const id = appointment?.id ?? null;

  const errorHandler = errorHandlerFactory<FormModel>(setError);

  const handleFormSubmit = useCallback(
    async (data: FormModel): Promise<IAppointment | void> => {
      const serializedData = serialize(data);

      const mutationHandler = id ? saveAppointmentMutation : createAppointmentMutation;
      const payload = {
        orderId,
        appointmentId: id,
        payload: serializedData,
      };
      const result = await mutationHandler(payload).unwrap();

      if (isErrorResponse(result)) {
        errorHandler(result);
        return;
      }

      dispatch(AppointmentsActions.upsertAppointment(result));
      onClose();
    },
    [id, saveAppointmentMutation, createAppointmentMutation, orderId, dispatch, onClose, errorHandler],
  );

  const submitHandler = createPreventBubblingFormHandler(handleSubmit(handleFormSubmit));

  const isCancelled = watch('isCancelled');

  useEffect(() => {
    if (isCancelled === false) {
      resetField('cancellationDate');
      resetField('cancellationReason');
    }
  }, [isCancelled, resetField]);

  const baseRequiredFieldLabels = ['Assessment Mode', 'Assessment Centre', 'Assessor', 'Offered Date Time'];
  const cancelledRequiredFieldLabels = ['Cancellation Date Time', 'Cancellation Reason'];
  const requiredFieldLabels = [...baseRequiredFieldLabels, ...(isCancelled ? cancelledRequiredFieldLabels : [])];

  const cancellationDate = watch('cancellationDate');
  const appointmentDate = watch('appointmentDate');
  const isInvoiceDisabled = useMemo(
    () => getIsInvoiceDisabled(appointmentDate, cancellationDate),
    [appointmentDate, cancellationDate],
  );

  return {
    ...useFormResult,
    ...backendLabels,
    ...mergeRequestStatusFlags(createFlags, saveFlags),
    ...getIsAndWhyDisabled(formState, requiredFieldLabels, isLabelsLoading),
    submitHandler,
    isLabelsLoading,
    buttonText: id ? 'Save Appointment' : 'Create Appointment',
    isCancelled,
    isInvoiceDisabled,
  };
}
