import React from 'react';
import { Chip, ChipProps } from '@mui/material';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { getColorByDelay } from './get-color-by-delay';

type Props = {
  delay: number | null;
  orderStatusId: number | null;
};

export const Delay: React.FC<Props> = ({ delay, orderStatusId }) => {
  const { statusOptions } = useAppBackendLabels(['statusOptions']);
  const option = statusOptions.find(({ value }) => value === orderStatusId);
  let color: ChipProps['color'] = 'default';

  if (option && delay !== null && option.criticalDelay) {
    color = getColorByDelay(delay, option.criticalDelay);
  }
  return <Chip label={delay} size="small" variant="outlined" color={color} className="MuiChip-status" />;
};
