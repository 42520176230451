import React from 'react';
import { Chip, CircularProgress, IconButton, Stack } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { ITimesheetInfo } from '@features/sessions/timesheet-info/timesheet-info.interface';
import { useUploadTimesheet } from './use-upload-timesheet';
import { UserRoles } from '@features/user/user-role.type';
import { OpenFile } from '@components/inputs/OpenFile';
import { FileInput } from '@components/inputs/FileInput';
import HistoryIcon from '@mui/icons-material/History';
import { useRegenerateTimesheet } from './use-regenerate-timesheet';

type Props = {
  sessionId: number;
  timesheet: ITimesheetInfo;
};

export const Timesheet: React.FC<Props> = ({ timesheet, sessionId }) => {
  const { handleUploadTimesheet, isLoading, isSuccess, isError } = useUploadTimesheet();
  const { handleRegenerateTimesheet, isLoading: isReGenerateIsLoading } = useRegenerateTimesheet();
  const { isUploadAllowed, signedBy, downloadPath } = timesheet;

  const isSignedByCustomer = signedBy?.includes(UserRoles.customer) || downloadPath;
  const isSignedByTutor = signedBy?.includes(UserRoles.tutor) || downloadPath;

  const handleFileChange = (fileList: FileList): void => {
    handleUploadTimesheet(sessionId, fileList[0]);
  };

  const renderUpload = (): JSX.Element | null => {
    if (isLoading) {
      return <CircularProgress />;
    }
    if (isSuccess) {
      return (
        <IconButton color="success">
          <CloudDoneIcon />
        </IconButton>
      );
    }
    if (isError) {
      return (
        <IconButton color="error">
          <ReportProblemIcon />
        </IconButton>
      );
    }
    if (isUploadAllowed) {
      return (
        <IconButton color="default" aria-label="upload picture" component="label">
          <FileInput accept=".pdf" onChange={handleFileChange} />
          <CloudUploadIcon />
        </IconButton>
      );
    }
    return null;
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Chip label="Student" variant="outlined" size="small" color={isSignedByCustomer ? 'success' : 'default'} />
      <Chip label="Tutor" variant="outlined" size="small" color={isSignedByTutor ? 'success' : 'default'} />

      {downloadPath && (
        <OpenFile path={downloadPath} isPreview>
          <PictureAsPdfIcon />
        </OpenFile>
      )}

      {renderUpload()}

      <IconButton
        onClick={(): unknown => handleRegenerateTimesheet(sessionId, timesheet)}
        disabled={isReGenerateIsLoading}
      >
        <HistoryIcon />
      </IconButton>
    </Stack>
  );
};
