import { useCallback, useState } from 'react';

/**
 * Custom React hook that creates a boolean state with a toggle function.
 * This hook simplifies the implementation of a toggleable state, such as
 * showing/hiding an element, toggling a class, or any binary state behavior.
 * @param {boolean} initial - Optional. The initial state value. Defaults to false.
 */
export function useToggle(initial = false): [boolean, () => unknown, (next: boolean) => unknown] {
  const [isActive, setIsActive] = useState(initial);
  const toggle = useCallback((): unknown => setIsActive(!isActive), [isActive]);
  const set = useCallback((next: boolean): unknown => setIsActive(next), []);
  return [isActive, toggle, set];
}
