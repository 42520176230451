import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { Box } from '@mui/material';

interface IProps extends DataGridProps {
  fullHeight?: boolean;
}

export const AppDataGrid: React.FC<IProps> = ({ sx, fullHeight = false, ...props }) => {
  const { ref, height } = useResizeDetector({ skipOnMount: !fullHeight });
  const gridHeight = fullHeight ? height : null;

  // remove column separators if is compact
  const slots = props.slots ?? {};
  const isCompact = props.density !== 'comfortable' && props.density !== 'standard';

  if (isCompact) {
    slots.columnResizeIcon = (): JSX.Element | null => null;
  }

  return (
    <Box sx={{ flexGrow: 1, ...sx }} ref={fullHeight ? ref : null}>
      <DataGrid
        density="compact"
        sx={{ height: gridHeight }}
        disableRowSelectionOnClick
        pageSizeOptions={[10, 15, 25, 50, 100]}
        hideFooter
        slots={slots}
        {...props}
      />
    </Box>
  );
};
