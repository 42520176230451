import { appMutationFactory } from '@api/api-slice';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';
import { ICustomer } from '../customer.interface';
import { EndpointUrlBuilder } from '@api/endpoints';

type Request = {
  customerId: number;
  reason: string;
};

type Response = { student: ICustomer } | IErrorResponse;

export const buildSuspendAccount = appMutationFactory<Request, ICustomer | IErrorResponse>({
  query: ({ customerId, reason }) => ({
    url: EndpointUrlBuilder.deactivateAccount(customerId),
    method: 'POST',
    data: {
      account_stop_reason: reason,
    },
  }),
  transformResponse,
});

export const buildResumeAccount = appMutationFactory<number, ICustomer | IErrorResponse>({
  query: (customerId) => ({
    url: EndpointUrlBuilder.activateAccount(customerId),
    method: 'POST',
  }),
  transformResponse,
});

function transformResponse(response: Response): ICustomer | IErrorResponse {
  return isErrorResponse(response) ? response : response.student;
}
