import { createEnumOptions } from '@utils/enum/create-enum.factory';

export const AssessmentMethods = {
  RemoteVideo: 'remote-video',
  RemoteTel: 'remote-tel',
  FaceToFace: 'face-to-face',
} as const;

type Keys = keyof typeof AssessmentMethods;

export type AssessmentMethodType = (typeof AssessmentMethods)[Keys];

export const AssessmentMethodOptions = createEnumOptions(AssessmentMethods);
