import { Describe, assign, boolean, nullable, number, object, pattern, string } from 'superstruct';
import { IAllocation, IAllocationSummary } from '@features/allocations/allocation.interface';
import { ISODateRegexp } from '@utils/dates/iso-string.type';

export const AllocationStruct: Describe<IAllocation> = object({
  id: number(),
  nmhSupportType: string(), // TODO remove
  // qualification: number(),
  allocated: number(),
  remaining: nullable(number()),
  endDate: pattern(string(), ISODateRegexp),
  hourlyRate: number(),
  isValidated: boolean(),
  tutorId: nullable(number()),
});

export const AllocationSummaryStruct: Describe<IAllocationSummary> = assign(
  AllocationStruct,
  object({
    orderId: number(),
    orderStatusId: nullable(number()),
    customerId: number(),
    customerFullName: string(),
    customerCrn: nullable(string()),
    used: number(),
    creationDate: pattern(string(), ISODateRegexp),
  }),
);
