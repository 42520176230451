import { Moment } from 'moment-timezone';
import { AssessmentMethodOptions, AssessmentMethodType } from '@features/order/needs-assessment/assessment-method.type';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { ISODateString } from '@utils/dates/iso-string.type';
import { Serializers } from '@utils/forms/serializers';

export type FormModel = {
  startDate: Moment | null;
  endDate: Moment | null;
  organisationId: Array<number | null>;
  assessorId: Array<number | null>;
  funderInvoice: string | null;
  supplierInvoice: string | null;
  isPaid: boolean | null;
  customerFirstName: string;
  customerLastName: string;
  customerCrn: string;
  customerEmail: string;
  assessmentCentreId: Array<number | null>;
  orderStatusId: Array<number | null>;
  appointmentId: number | null;
  assessmentMethod: AssessmentMethodType | null;
  assessmentType: Array<string | null>;
  isCancelled: boolean | null;
};

export type ApiModel = {
  startDate: ISODateString;
  endDate: ISODateString;
  organisationId: Array<number | null>;
  assessorId: Array<number | null>;
  funderInvoice: string | null;
  supplierInvoice: string | null;
  isPaid: boolean | null;
  customerFirstName: string | null;
  customerLastName: string | null;
  customerCrn: string | null;
  customerEmail: string | null;
  assessmentCentreId: Array<number | null>;
  orderStatusId: Array<number | null>;
  appointmentId: string | null;
  assessmentMethod: AssessmentMethodType | null;
  assessmentType: Array<string | null>;
  isCancelled: boolean | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  startDate: { type: 'date', initial: null },
  endDate: { type: 'date', initial: null },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', multiple: true, initial: [] },
  assessorId: { type: 'backend-label', optionKey: 'assessorOptions', multiple: true, initial: [] },
  funderInvoice: { type: 'transportable', initial: '' },
  supplierInvoice: { type: 'transportable', initial: '' },
  isPaid: { type: 'transportable', initial: null },
  customerFirstName: { type: 'transportable', initial: '' },
  customerLastName: { type: 'transportable', initial: '' },
  customerCrn: { type: 'transportable', initial: '' },
  customerEmail: { type: 'transportable', initial: '' },
  assessmentCentreId: { type: 'backend-label', optionKey: 'assessmentCentreOptions', multiple: true, initial: [] },
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', multiple: true, initial: [] },
  appointmentId: { type: 'transportable', initial: null },
  assessmentMethod: { type: 'enum', options: AssessmentMethodOptions, initial: null },
  assessmentType: { type: 'backend-label', optionKey: 'assessmentTypeOptions', multiple: true, initial: [] },
  isCancelled: { type: 'transportable', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  startDate: Serializers.date,
  endDate: Serializers.date,
  organisationId: Serializers.array,
  assessorId: Serializers.array,
  funderInvoice: Serializers.stringNullable,
  supplierInvoice: Serializers.stringNullable,
  isPaid: Serializers.booleanNullable,
  customerFirstName: Serializers.stringNullable,
  customerLastName: Serializers.stringNullable,
  customerCrn: Serializers.stringNullable,
  customerEmail: Serializers.stringNullable,
  assessmentCentreId: Serializers.array,
  orderStatusId: Serializers.array,
  appointmentId: Serializers.string,
  assessmentMethod: Serializers.enum,
  assessmentType: Serializers.array,
  isCancelled: Serializers.booleanNullable,
});
