import { Moment } from 'moment-timezone';
import { IAllocationSummary } from '@features/allocations/allocation.interface';
import { IOrder } from '@features/order/order.interface';
import { ISODateString } from '@utils/dates/iso-string.type';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { RemainingOptions, RemainingType } from './remaining.type';
import { UsedOptions, UsedType } from './used.type';

// TODO change nmhSupportType -> qualification
export type FormModel = {
  nmhSupportType: IAllocationSummary['nmhSupportType'][];
  creationStartDate: Moment | null;
  creationEndDate: Moment | null;
  endingStartDate: Moment | null;
  endingEndDate: Moment | null;
  orderStatusId: IOrder['orderStatusId'][];
  used: UsedType | null;
  remaining: RemainingType | null;
  customerFirstName: string;
  customerLastName: string;
  tutorId: IAllocationSummary['tutorId'][];
};

export type ApiModel = {
  nmhSupportType: IAllocationSummary['nmhSupportType'][];
  tutorId: IAllocationSummary['tutorId'][];
  creationStartDate: ISODateString | null;
  creationEndDate: ISODateString | null;
  endingStartDate: ISODateString | null;
  endingEndDate: ISODateString | null;
  orderStatusId: IOrder['orderStatusId'][];
  used: UsedType | null;
  remaining: RemainingType | null;
  customerFirstName: string | null;
  customerLastName: string | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  nmhSupportType: { type: 'backend-label', optionKey: 'supportOptions', multiple: true, initial: [] },
  tutorId: { type: 'backend-label', optionKey: 'tutorOptions', multiple: true, initial: [] },
  creationStartDate: { type: 'date', initial: null },
  creationEndDate: { type: 'date', initial: null },
  endingStartDate: { type: 'date', initial: null },
  endingEndDate: { type: 'date', initial: null },
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', multiple: true, initial: [] },
  used: { type: 'enum', options: UsedOptions, initial: null },
  remaining: { type: 'enum', options: RemainingOptions, initial: null },
  customerFirstName: { type: 'transportable', initial: '' },
  customerLastName: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  nmhSupportType: Serializers.array,
  creationStartDate: Serializers.date,
  creationEndDate: Serializers.date,
  endingStartDate: Serializers.date,
  endingEndDate: Serializers.date,
  orderStatusId: Serializers.array,
  used: Serializers.enum,
  remaining: Serializers.enum,
  customerFirstName: Serializers.stringNullable,
  customerLastName: Serializers.stringNullable,
  tutorId: Serializers.array,
});
