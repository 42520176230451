import React from 'react';
import { Typography } from '@mui/material';
import { AppDataGrid } from '@components/AppDataGrid';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { useBulkSendSessionInvoiceMutation } from '@api/api-slice';
import { useBulkUpdate } from '../use-bulk-update';
import { SessionInvoiceSummaryActions } from './session-invoice-summary.slice';
import { sessionInvoiceColumnsWithResult } from './session-invoice-columns';
import { useSelectedSessionInvoiceSummaries } from './filter-session-invoice-summaries/use-selected-session-invoice-summaries';

type Props = {
  selectedIds: number[];
};

export const SendSessionEInvoices: React.FC<Props> = ({ selectedIds }) => {
  const rows = useSelectedSessionInvoiceSummaries(selectedIds);
  const { handleSubmit, rowsWithResult, isLoading } = useBulkUpdate(
    useBulkSendSessionInvoiceMutation,
    SessionInvoiceSummaryActions.updateSessions,
    rows,
  );

  const handleClick = (): void => {
    handleSubmit({ ids: selectedIds });
  };

  return (
    <>
      <Typography variant="h4" component="h1">
        Send E-Invoices
      </Typography>
      <AppLoadingButton isLoading={isLoading} onClick={handleClick}>
        Send E-Invoices
      </AppLoadingButton>
      <AppDataGrid
        rows={rowsWithResult}
        columns={sessionInvoiceColumnsWithResult}
        sx={{ mt: 1 }}
        fullHeight
        hideFooter
      />
    </>
  );
};
