import { StructError, assert } from 'superstruct';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { IActionRecord } from '../action-record.interface';
import { ActionRecordStruct } from './action-record-struct.spec';

export function validateActionRecords(records: IActionRecord[], url: string): void {
  for (const entry of records) {
    try {
      assert(entry, ActionRecordStruct);
    } catch (error) {
      for (const failure of (error as StructError).failures()) {
        handleFailure(failure, url);
      }
    }
  }
}
