// This file is autogenerated during deploy.sh
// Please do not change it manually

// This file is used in both webpack and the application.

// We need a CommonJS module for use with webpack
// So here, we use a JS file with TS typings
module.exports = {
  baseUrl: 'https://api.atdsa.co.uk',
  title: 'StudyTech CRM',
  color: '#0288d1',
  isWarning: false,
};
