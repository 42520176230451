export const OrderClasses = {
  NeedsAssessment: 'needs_assessment',
  ErgonomicAssessment: 'ergonomic_assesment',
  Technical: 'technical_order',
  Nmh: 'nmh',
  AnnualSupportCharge: 'asc_order',
  RepairAndReplace: 'repair_and_replace',
  UnsupportedCustomer: 'unsupported_customer',
} as const;

type Keys = keyof typeof OrderClasses;

export type OrderClassType = (typeof OrderClasses)[Keys];
