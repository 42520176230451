import { useCallback } from 'react';
import { useCreateAppointmentMutation, useSaveAppointmentMutation } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { IAppointment } from '@features/order/needs-assessment/appointment/appointment.interface';
import { AppointmentsActions } from '@features/order/needs-assessment/appointment/appointments.slice';
import { RequestStatusFlags, mergeRequestStatusFlags } from '@utils/merge-request-status-flags';
import { isErrorResponse } from '@api/error-response.interface';
import { ApiModel } from '@features/order/needs-assessment/appointment/save-appointment-form/form-config';

// This hook can be generic in future, so I mark model dependent parts

// Api Model type
type FormData = Pick<ApiModel, 'appointmentDate' | 'assessmentMethod' | 'offeredDate'>;

type HookResult = {
  // Promise return type
  handleFormSubmit: (data: FormData) => Promise<IAppointment | void>;
} & RequestStatusFlags;

export function useSubmitAppointmentAutomatedBooking(
  appointmentId: number | null,
  orderId: number,
  onClose: () => void,
): HookResult {
  // This hooks are model dependent
  const [createMutation, createFlags] = useCreateAppointmentMutation();
  const [saveMutation, saveFlags] = useSaveAppointmentMutation();
  const dispatch = useAppDispatch();

  const handleFormSubmit = useCallback(
    // Promise return type
    async (data: FormData): Promise<IAppointment | void> => {
      const mutationHandler = appointmentId ? saveMutation : createMutation;
      // payload creation
      const payload = {
        orderId,
        appointmentId,
        payload: data,
      };
      const result = await mutationHandler(payload).unwrap();

      if (isErrorResponse(result)) {
        return;
      }

      // action
      dispatch(AppointmentsActions.upsertAppointment(result));
      onClose();
    },
    [appointmentId, saveMutation, createMutation, orderId, dispatch, onClose],
  );

  return {
    handleFormSubmit,
    ...mergeRequestStatusFlags(createFlags, saveFlags),
  };
}

/*
So we need to pass to potential generic hook
- two type templates: FormData and Model (IAppointment or ISession)
- create and save mutation hooks
- payload creation function
- action
*/
