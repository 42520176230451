import React, { useState } from 'react';
import { Alert, Typography } from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { useTutorFeedbackSummaries } from './use-tutor-feedback-summaries';
import { AppDataGrid } from '@components/AppDataGrid';
import { ITutorFeedbackSummary } from './tutor-feedback-summary.interface';
import { createColumnFactory } from '@utils/create-column.factory';
import { formatDate } from '@utils/dates/format-date';
import { AppModal } from '@components/AppModal';
import { useServerPagination } from '@features/pagination/use-server-pagination';
import { PageSizeNames } from '@features/pagination/page-size-names';

const createFeedbackSummaryColumn = createColumnFactory<ITutorFeedbackSummary>();

const columns: GridColDef[] = [
  createFeedbackSummaryColumn('id', { headerName: 'Id', width: 60 }),
  createFeedbackSummaryColumn('feedbackDate', {
    headerName: 'Date',
    width: 90,
    renderCell: ({ row }) => formatDate(row.feedbackDate),
  }),
  createFeedbackSummaryColumn('feedbackScore', { headerName: 'Score', width: 60 }),
  createFeedbackSummaryColumn('sessionId', { headerName: 'Session Id', width: 90 }),
  createFeedbackSummaryColumn('questions', {
    headerName: 'Ratings',
    width: 90,
    renderCell: ({ row }) => row.questions.map(({ rate }) => rate).join(', '),
  }),
  createFeedbackSummaryColumn('openComments', { headerName: 'Comments', flex: 1 }),
];

export const TutorFeedback: React.FC = () => {
  const { entries, totalEntries, paginationModel, onPaginationModelChange, isLoading } = useServerPagination(
    useTutorFeedbackSummaries,
    PageSizeNames.TutorFeedback,
  );
  const [currentFeedback, setCurrentFeedback] = useState<ITutorFeedbackSummary | null>(null);

  const openFeedback = ({ row }: GridRowParams<ITutorFeedbackSummary>): void => setCurrentFeedback(row);
  const closeFeedback = (): void => setCurrentFeedback(null);
  const feedbackTitle = currentFeedback
    ? `Score: ${currentFeedback.feedbackScore}, Date: ${formatDate(currentFeedback.feedbackDate, 'DD/MM/YYYY HH:mm')}`
    : '';

  if (!entries) {
    return <Alert severity="warning">Feedback not found.</Alert>;
  }

  return (
    <>
      <Typography variant="h4" component="h1">
        Feedback Summaries
      </Typography>
      <AppDataGrid
        rows={entries}
        columns={columns}
        loading={isLoading}
        sx={{ mt: 1 }}
        hideFooter={false}
        paginationMode="server"
        rowCount={totalEntries}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        onRowClick={openFeedback}
        fullHeight
      />
      <AppModal open={Boolean(currentFeedback)} onClose={closeFeedback} title={feedbackTitle}>
        <Typography>{currentFeedback?.openComments}</Typography>
      </AppModal>
    </>
  );
};
