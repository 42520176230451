import React, { FunctionComponent, useState } from 'react';
import {
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import moment, { Moment } from 'moment-timezone';
import { DatePicker } from '@mui/x-date-pickers';
import { useOrderTypeSummaries } from './use-order-type-summaries';
import { toISODay } from '@utils/dates/to-iso-day';

const InitialStartDate = moment().subtract(1, 'weeks');
const InitialEndDate = moment();

export const OrderTypeTable: FunctionComponent = () => {
  const [startDate, setStartDate] = useState<Moment>(InitialStartDate);
  const [endDate, setEndDate] = useState<Moment>(InitialEndDate);

  const { summaries, isLoading } = useOrderTypeSummaries(
    toISODay(startDate || InitialStartDate),
    toISODay(endDate || InitialEndDate),
  );

  return (
    <TableContainer component={Paper} sx={{ p: 3, boxSizing: 'border-box', height: '100%' }}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="h4" component="h1" sx={{ flexGrow: 1 }}>
          Orders
        </Typography>
        <DatePicker
          label="Start Date"
          value={startDate}
          format="DD/MM/YYYY"
          onChange={(value): void => {
            if (value) {
              setStartDate(value);
            }
          }}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          format="DD/MM/YYYY"
          onChange={(value): void => {
            if (value) {
              setEndDate(value);
            }
          }}
        />
      </Stack>
      <Table sx={{ width: '100%', mt: 1 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Order Type</TableCell>
            <TableCell align="right">In</TableCell>
            <TableCell align="right">Out</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell align="center" colSpan={3}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            summaries.map((summary) => (
              <TableRow key={`type-${summary.type}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <BackendLabel
                    value={summary.type}
                    optionKey="orderTypeNameOptions"
                    isPlainText
                    fallbackText="No Status"
                  />
                </TableCell>
                <TableCell align="right">{summary.in}</TableCell>
                <TableCell align="right">{summary.out}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
