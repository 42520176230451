import { ICustomer, OrderTab } from '@features/customer/customer.interface';
import { PayloadAction, createDraftSafeSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

type ICustomersState = ICustomer | null;

const initialState = null as ICustomersState;

const customerSlice = createSlice({
  name: 'Customers',
  initialState,
  reducers: {
    setCustomer: (state, { payload }: PayloadAction<ICustomer>) => payload,
    updateCustomerOrderTabs: (state, { payload }: PayloadAction<OrderTab>) => {
      if (state) {
        return {
          ...state,
          ordersTabs: state?.ordersTabs.concat(payload),
        };
      }
      return null;
    },
    setExternalImmyBotId: (state, { payload: { externalImmyBotId } }: PayloadAction<{ externalImmyBotId: number }>) => {
      if (state) {
        return {
          ...state,
          externalImmyBotId,
        };
      }
      return null;
    },
    resetCustomer: () => initialState,
  },
});

export const CustomersActions = customerSlice.actions;
export const customerReducer = customerSlice.reducer;
export type CustomersActionsType = typeof CustomersActions;

export const selectCustomerState = (state: RootState): ICustomersState => state.customer;
export const selectIsCustomerSuspended = createDraftSafeSelector(
  selectCustomerState,
  (customer) => customer?.isAccountSuspended ?? false,
);
