import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Navigation } from '@routes/Navigation';
import { User } from '@features/user/User';
import { theme } from '@context/MUIProvider';
import { EnvironmentWarning } from '@environment/EnvironmentWarning';
import { isWarning } from '@environment/config';
import { useAppDispatch } from '@store/use-app-dispatch';
import { UserActions, selectIsSidebarOpen } from '@features/user/user.slice';
import { useAppSelector } from '@store/use-app-selector';
import { AppLinearProgressBar } from '@components/AppLinearProgressBar';
import { MicrosoftAuthorization } from '@features/microsoft-authorization/MicrosoftAuthorization';
import { ZohoAuthorization } from '@features/zoho-authorization/ZohoAuthorization';
import { Version } from './Version';
import { useAnnouncement } from '@features/announcement/useAnnouncement';

export const Layout: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => selectIsSidebarOpen(state));
  useAnnouncement();

  function toggleSidebar(): void {
    dispatch(UserActions.toggleSidebar());
  }

  const toggleButtonWidth = 16;
  const sidebarWidth = 240;
  const layoutWidth = isOpen ? `calc(100vw - ${sidebarWidth}px - ${toggleButtonWidth}px)` : '100vw';
  const height = isWarning ? `calc(100vh - 48px)` : '100vh';

  return (
    <>
      <AppLinearProgressBar />
      <EnvironmentWarning />
      <Stack direction="row">
        <Stack direction="row" sx={{ backgroundColor: 'white', height: '100vh', position: 'sticky', top: 0 }}>
          {isOpen && (
            <Box
              sx={{
                width: sidebarWidth,
                display: 'flex',
                flexDirection: 'column',
                borderRight: `1px solid ${theme.palette.divider}`,
              }}
            >
              <User />
              <Navigation />
              <ZohoAuthorization />
              <MicrosoftAuthorization />
              <Version />
            </Box>
          )}

          <Button onClick={toggleSidebar} sx={{ width: toggleButtonWidth, minWidth: toggleButtonWidth, p: 0 }}>
            {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </Button>
        </Stack>
        <Box
          sx={{
            padding: 4,
            minHeight: height,
            width: layoutWidth,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'var(--color-main-background)',
          }}
        >
          <Outlet />
        </Box>
      </Stack>
    </>
  );
};
