import { useCreateOrderContactRecordMutation } from '@api/api-slice';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useAppDispatch } from '@store/use-app-dispatch';
import { OrderContactRecordsActions } from './order-contact-record.slice';
import { isErrorResponse } from '@api/error-response.interface';
import { IProgressRecordRequest } from './api';

export function useCreateOrderContactRecord(): {
  handleSaveContactRecord: (request: IProgressRecordRequest) => Promise<boolean>;
} & RequestStatusFlags {
  const [mutation, flags] = useCreateOrderContactRecordMutation();
  const dispatch = useAppDispatch();

  const handleSaveContactRecord = async (request: IProgressRecordRequest): Promise<boolean> => {
    const result = await mutation(request).unwrap();
    if (!isErrorResponse(result)) {
      dispatch(OrderContactRecordsActions.addRecord(result));
      return true;
    }
    return false;
  };

  return { handleSaveContactRecord, ...flags };
}
