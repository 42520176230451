import { useGetTheftClaimFormQuery } from '@api/api-slice';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { ITheftClaimForm } from './theft-claim-form.interface';
import { useEffect, useState } from 'react';

type HookResult = RequestStatusFlags & {
  theftClaimForm: ITheftClaimForm | null;
  setTheftClaimForm: React.Dispatch<React.SetStateAction<ITheftClaimForm | null>>;
};

export function useTheftClaimForm(id: number | null): HookResult {
  const [theftClaimForm, setTheftClaimForm] = useState<ITheftClaimForm | null>(null);
  const [shouldSkip, setShouldSkip] = useState(!id);
  const { data, ...flags } = useGetTheftClaimFormQuery(id as number, { skip: shouldSkip });

  useEffect(() => {
    if (data) {
      setTheftClaimForm(data);
      setShouldSkip(true);
    }
  }, [data]);

  useEffect(() => {
    if (theftClaimForm !== null) {
      setShouldSkip(true);
    }
  }, [theftClaimForm]);

  return {
    theftClaimForm,
    setTheftClaimForm,
    ...flags,
  };
}
