import { UseFormReturn, useForm } from 'react-hook-form';
import { useSaveTheftClaimFormMutation } from '@api/api-slice';
import { errorHandlerFactory } from '@api/error-handler.factory';
import { isErrorResponse } from '@api/error-response.interface';
import { EmptyBackendLabelOptions } from '@features/backend-label/backend-labels.slice';
import { collectChangedValues } from '@utils/collect-changed-values';
import { createPreventBubblingFormHandler } from '@utils/create-prevent-bubbling-form-handler.factory';
import { filterEmptyObjectFields } from '@utils/filter-empty-object-fields';
import { RequestStatusFlags, mergeRequestStatusFlags } from '@utils/merge-request-status-flags';
import { ITheftClaimForm } from './theft-claim-form.interface';
import { FormModel, sanitize, serialize } from './form-config';

type HookResult = {
  submitHandler: React.FormEventHandler;
  isSaveDisabled: boolean;
} & RequestStatusFlags &
  UseFormReturn<FormModel>;

export const useSaveTheftClaimForm = (
  theftClaimForm: ITheftClaimForm | null,
  setTheftClaimForm: (quote: ITheftClaimForm) => unknown,
  bodyId: number,
): HookResult => {
  const [mutation, flags] = useSaveTheftClaimFormMutation();
  const useFormResult = useForm<FormModel>({ values: sanitize(theftClaimForm, EmptyBackendLabelOptions) });
  const { handleSubmit, setError, formState } = useFormResult;

  const errorHandler = errorHandlerFactory<FormModel>(setError);

  const handleFormSubmit = async (data: FormModel): Promise<void> => {
    const serializedData = serialize(data);
    const payload = {
      bodyId,
      form: {
        ...filterEmptyObjectFields(collectChangedValues(serializedData, theftClaimForm)),
      },
    };

    const result = await mutation(payload).unwrap();
    if (isErrorResponse(result)) {
      errorHandler(result);
      return;
    }
    setTheftClaimForm(result);
  };

  const submitHandler = createPreventBubblingFormHandler(handleSubmit(handleFormSubmit));

  const isFilled = Object.keys(formState.dirtyFields).length > 0;
  const isSaveDisabled = !isFilled || formState.isLoading || formState.isSubmitting;

  return {
    ...useFormResult,
    ...mergeRequestStatusFlags(flags),
    isSaveDisabled,
    submitHandler,
  };
};
