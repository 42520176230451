import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useAppDispatch } from '@store/use-app-dispatch';
import { Routes } from '@routes/routes';
import { useCreateCustomerMutation, useSaveCustomerMutation } from '@api/api-slice';
import { isErrorResponse } from '@api/error-response.interface';
import { errorHandlerFactory } from '@api/error-handler.factory';
import { ICustomer } from '@features/customer/customer.interface';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { AppBackendLabels } from '@features/backend-label/backend-label.type';
import { RequestStatusFlags, mergeRequestStatusFlags } from '@utils/merge-request-status-flags';
import { collectChangedValues } from '@utils/collect-changed-values';
import { FormModel, sanitize, serialize } from './form-config';
import { CustomersActions } from '../customer.slice';

type HookResult = {
  submitHandler: React.FormEventHandler;
  isSaveEnabled: boolean;
  isLabelsLoading: boolean;
  buttonText: string;
} & UseFormReturn<FormModel> &
  AppBackendLabels &
  RequestStatusFlags;

export function useSaveCustomerForm(customer: ICustomer | void): HookResult {
  const { isLoading: isLabelsLoading, ...backendLabels } = useAppBackendLabels([
    'fundingBodyOptions',
    'hepOptions',
    'preferredCommunicationWaysOptions',
    'labelOptions',
  ]);
  const values = sanitize(customer, backendLabels);
  const useFormResult = useForm({ values });
  const { handleSubmit, formState, reset, setError } = useFormResult;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [createMutation, createFlags] = useCreateCustomerMutation();
  const [saveMutation, saveFlags] = useSaveCustomerMutation();

  const hasDirtyFields = Object.keys(formState.dirtyFields).length > 0;
  const id = customer?.id ?? null;

  const errorHandler = errorHandlerFactory<FormModel>(setError);

  const handleFormSubmit = useCallback(
    async (data: FormModel): Promise<ICustomer | void> => {
      const serializedData = serialize(data);
      const payload = collectChangedValues(serializedData, values);

      const mutationHandler = id ? saveMutation : createMutation;
      const result = await mutationHandler({ id, payload }).unwrap();

      if (isErrorResponse(result)) {
        errorHandler(result);
        return;
      }

      if (id) {
        dispatch(CustomersActions.setCustomer(result));
        reset(sanitize(result, backendLabels));
      } else {
        navigate(`${Routes.orderSummaries}/${result.id}`, { replace: true });
      }

      return result;
    },
    [backendLabels, createMutation, values, dispatch, errorHandler, id, navigate, reset, saveMutation],
  );

  useEffect(() => () => {
    CustomersActions.resetCustomer();
  });

  const submitHandler = handleSubmit(handleFormSubmit);

  return {
    ...backendLabels,
    ...useFormResult,
    ...mergeRequestStatusFlags(createFlags, saveFlags),
    submitHandler,
    isSaveEnabled: hasDirtyFields && !formState.isLoading && !formState.isSubmitting && !isLabelsLoading,
    isLabelsLoading,
    buttonText: id ? 'Save Customer' : 'Create Customer',
  };
}
