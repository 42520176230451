import { useSendAppointmentElectronicInvoiceMutation } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { AppointmentsActions } from '../appointments.slice';
import { isErrorResponse } from '@api/error-response.interface';

type HookResult = {
  sendAppointmentElectronicInvoice: (id: number) => Promise<void>;
} & RequestStatusFlags;

export function useSendAppointmentElectronicInvoice(): HookResult {
  const [sendMutation, flags] = useSendAppointmentElectronicInvoiceMutation();
  const dispatch = useAppDispatch();

  const sendAppointmentElectronicInvoice = async (id: number): Promise<void> => {
    const changes = await sendMutation(id).unwrap();
    if (!isErrorResponse(changes)) {
      dispatch(
        AppointmentsActions.updateAppointment({
          id,
          changes,
        }),
      );
    }
  };

  return { sendAppointmentElectronicInvoice, ...flags };
}
