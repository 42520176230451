import { Describe, StructError, array, assert, nullable, number, object, string } from 'superstruct';
import { IAssessmentCentre } from '../assessment-centre.interface';
import { IAssessmentCentreResponse } from '../api';
import { handleFailure } from '@utils/superstruct/handle-failure';

const AssessmentCentreDetailStruct: Describe<IAssessmentCentre> = object({
  id: number(),
  name: string(),
  city: nullable(string()),
  postcode: nullable(string()),
  assessorIds: array(number()),
  addressLine1: nullable(string()),
  addressLine2: nullable(string()),
  addressLine3: nullable(string()),
  description: nullable(string()),
  googleMapsLink: nullable(string()),
});

export function validateAssessmentCentreDetail(response: IAssessmentCentreResponse, url: string): void {
  try {
    assert(response.assessmentCentre, AssessmentCentreDetailStruct);
  } catch (error) {
    for (const failure of (error as StructError).failures()) {
      handleFailure(failure, url);
    }
  }
}
