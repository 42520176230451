import { Describe, assign, literal, object } from 'superstruct';
import { IOrderNew } from '../order-new.interface';
import { BaseOrderStruct } from '@features/order/base/spec/base-order-struct.spec';

export const NewOrderStruct: Describe<IOrderNew> = assign(
  BaseOrderStruct,
  object({
    orderClass: literal(null),
  }),
);
