import { useCreateQuoteMutation, useSaveQuoteMutation } from '@api/api-slice';
import { IQuoteDetail } from '@features/quote/quote.interface';
import { RequestStatusFlags, mergeRequestStatusFlags } from '@utils/merge-request-status-flags';
import { FormState, UseFormReturn, useForm } from 'react-hook-form';
import { FormModel, sanitize } from './form-config';
import { errorHandlerFactory } from '@api/error-handler.factory';
import { isErrorResponse } from '@api/error-response.interface';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { IProductSummary } from '@features/product/product.interface';
import { isEqual } from 'lodash';
import { QuoteStatuses } from '../quote-status.type';
import { filterEmptyObjectFields } from '@utils/filter-empty-object-fields';
import { collectChangedValues } from '@utils/collect-changed-values';
import { createPreventBubblingFormHandler } from '@utils/create-prevent-bubbling-form-handler.factory';
import { useAppDispatch } from '@store/use-app-dispatch';
import { OrdersActions } from '@features/order/orders.slice';

type HookResult = {
  submitHandler: React.FormEventHandler;
  isSaveDisabled: boolean;
} & RequestStatusFlags &
  UseFormReturn<FormModel>;

export const useSaveQuote = (
  quote: IQuoteDetail | null,
  setQuote: (quote: IQuoteDetail) => unknown,
  products: IProductSummary[],
  setProducts: (value: IProductSummary[]) => void,
  orderId: number,
): HookResult => {
  const [createMutation, createFlags] = useCreateQuoteMutation();
  const [saveMutation, saveFlags] = useSaveQuoteMutation();
  const backendLabels = useAppBackendLabels(['assessmentCentreOptions', 'quoteSupportPeriodOptions']);
  const useFormResult = useForm<FormModel>({ values: sanitize(quote, backendLabels) });
  const { handleSubmit, setError, formState } = useFormResult;
  const dispatch = useAppDispatch();

  const errorHandler = errorHandlerFactory<FormModel>(setError);

  const quoteInternalId = quote?.quoteInternalId ?? null;

  const handleFormSubmit = async (data: FormModel): Promise<void> => {
    const payload = {
      quoteInternalId,
      quote: {
        ...filterEmptyObjectFields(
          collectChangedValues(
            {
              ...data,
              // backend asked not to send orderId for existing quotes
              orderId: quoteInternalId ? undefined : orderId,
            },
            quote ?? {},
          ),
        ),
        products: products.map(getIdAndQuantity),
      },
    };
    const mutationHandler = quote ? saveMutation : createMutation;
    const result = await mutationHandler(payload).unwrap();
    if (isErrorResponse(result)) {
      errorHandler(result);
      return;
    }
    setQuote(result);
    setProducts(result.quoteProducts);
    dispatch(OrdersActions.updateOrder({ id: orderId, changes: { quoteId: result.quoteInternalId } }));
  };

  const submitHandler = createPreventBubblingFormHandler(handleSubmit(handleFormSubmit));
  const isSaveDisabled = getIsSaveDisabled(quote, products, formState);

  return {
    ...useFormResult,
    ...mergeRequestStatusFlags(createFlags, saveFlags),
    isSaveDisabled,
    submitHandler,
  };
};

function getIdAndQuantity({ id, quantity }: IProductSummary): { id: number; quantity: number } {
  return { id, quantity };
}

function getIsSaveDisabled(
  quote: IQuoteDetail | null,
  products: IProductSummary[],
  formState: FormState<FormModel>,
): boolean {
  if (!quote) {
    // if a new quote, checking for form is valid and filled and products are added
    return !formState.isValid || products.length === 0;
  }
  if (quote.status === QuoteStatuses.Draft) {
    const areProductsSame = isEqual(products.map(getIdAndQuantity), quote.quoteProducts.map(getIdAndQuantity));
    // if draft quote, checking for form is valid and either it changed, or products are added
    return !formState.isValid || (!formState.isDirty && areProductsSame);
  }
  // otherwise user can't change products, only status
  return !formState.isValid || !formState.isDirty;
}
