import { useEffect } from 'react';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { AppointmentsActions, selectAppointmentsByOrderId } from './appointments.slice';
import { IAppointment } from './appointment.interface';
import { useGetAppointmentsQuery } from '@api/api-slice';
import { isErrorResponse } from '@api/error-response.interface';
import { useAppDispatch } from '@store/use-app-dispatch';
import { useAppSelector } from '@store/use-app-selector';

type HookResult = RequestStatusFlags & {
  appointments: IAppointment[];
};

export function useAppointments(orderId: number): HookResult {
  const { data, ...flags } = useGetAppointmentsQuery({ orderId });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isErrorResponse(data) && data) {
      dispatch(AppointmentsActions.upsertAppointments(data));
    }
  }, [data, dispatch]);

  const appointments = useAppSelector((state) => selectAppointmentsByOrderId(state, orderId));

  return { appointments, ...flags };
}
