import { useCallback } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useSuspendAccountMutation } from '@api/api-slice';
import { isErrorResponse } from '@api/error-response.interface';
import { RequestStatusFlags, mergeRequestStatusFlags } from '@utils/merge-request-status-flags';
import { useAppDispatch } from '@store/use-app-dispatch';
import { CustomersActions } from '../customer.slice';

type HookResult = {
  submitHandler(customerId: number, reason: string): Promise<boolean>;
} & RequestStatusFlags;

export function useSuspendAccount(): HookResult {
  const [mutation, flags] = useSuspendAccountMutation();
  const dispatch = useAppDispatch();

  const submitHandler = useCallback(
    async (customerId: number, reason: string): Promise<boolean> => {
      const result = await mutation({ customerId, reason }).unwrap();

      if (isErrorResponse(result)) {
        return false; // will show snackbar in api layer
      } else {
        enqueueSnackbar('Account suspended', { variant: 'success' });
        dispatch(CustomersActions.setCustomer(result));
        return true;
      }
    },
    [dispatch, mutation],
  );

  return {
    ...mergeRequestStatusFlags(flags),
    submitHandler,
  };
}
