import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { ControlledDatePicker } from '@components/inputs/ControlledDatePicker';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { filterEmptyObjectFields } from '@utils/filter-empty-object-fields';
import { FormModel, sanitize, serialize } from './form-config';
import { stringifyObjectFields } from '@utils/stringify-object-fields';
import { BooleanOptions } from '@utils/boolean-options';
import { OrderClasses } from '@features/order/order-class.type';
import { QuoteStatusOptions } from '@features/quote/quote-status.type';

export const FilterEquipmentInvoiceSummaries: React.FC = () => {
  const { isLoading, ...backendLabels } = useAppBackendLabels(['organisationOptions', 'orderTypeNameOptions']);
  const [params, setParams] = useSearchParams();
  const { control, handleSubmit, reset } = useForm<FormModel>({
    values: sanitize(deserializeURLSearchParams(params), backendLabels),
  });

  // Resets form values on "all" quick filter tab click
  useEffect(() => {
    if (params.size === 0) {
      reset();
    }
  }, [params.size, reset]);

  function onSubmit(data: FormModel): void {
    setParams(new URLSearchParams(stringifyObjectFields(filterEmptyObjectFields(serialize(data)))));
  }

  function onReset(): void {
    reset();
    setParams({});
  }

  const filteredOrderTypes = backendLabels.orderTypeNameOptions.filter(
    (option) => option.orderClass === OrderClasses.Technical,
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1.5} columns={{ xs: 4, md: 8, lg: 12 }} justifyContent="flex-end">
        <Grid item xs={4} md={4} lg={2}>
          <ControlledDatePicker control={control} name="pickUpFrom" label="Pick Up Date From" />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledDatePicker control={control} name="pickUpTo" label="Pick Up Date To" />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledAutocomplete
            control={control}
            name="organisationId"
            label="Organisation"
            options={backendLabels.organisationOptions}
            isLoading={isLoading}
            multiple
          />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledAutocomplete
            control={control}
            name="orderTypeId"
            label="Order Type"
            options={filteredOrderTypes}
            isLoading={isLoading}
            multiple
            isNullAvailable
          />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledAutocomplete
            control={control}
            name="quoteStatus"
            label="Quote Status"
            options={QuoteStatusOptions}
            isNullAvailable
            multiple
          />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledTextField control={control} name="quoteId" label="Quote Number" />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledAutocomplete
            control={control}
            name="isMatchesDsa2"
            label="Quote Matches DSA2"
            options={BooleanOptions}
          />{' '}
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledAutocomplete
            control={control}
            name="isContributePayable"
            label="DSA Contribution"
            options={BooleanOptions}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={1}>
          <ControlledAutocomplete
            control={control}
            name="isUpgradedEquipment"
            label="Upgraded"
            options={BooleanOptions}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledTextField control={control} name="funderInvoice" label="Funder Invoice" />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledTextField control={control} name="dipReference" label="DIP Reference" />
        </Grid>
        <Grid item xs={4} md={4} lg={1}>
          <Button type="reset" variant="outlined" size="medium" onClick={onReset} fullWidth>
            Reset
          </Button>
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <Button type="submit" variant="contained" fullWidth>
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
