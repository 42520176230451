/** Merges second array with first, using `keyName` for comparison. If you ever figure out how to do this with lodash, do it so, and delete me :D */
export function mergeBy<T extends object, K extends keyof T>(
  array1: T[],
  array2: Partial<T>[],
  keyName: K,
): (T & Partial<T>)[] {
  const array2Map = new Map(array2.map((item) => [item[keyName], item]));

  return array1.map((item) => {
    const key = item[keyName];
    const match = array2Map.get(key);
    return { ...item, ...(match ?? {}) };
  });
}
