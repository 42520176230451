import React from 'react';
import { ActionRecords } from '@features/action-records/ActionRecords';
import { HookResult, useProgressRecords } from '@features/action-records/use-progress-records';
import { RecordModelNames } from '@features/action-records/record-model-name.type';

type Props = {
  orderId: number;
};

export const OrderProgressRecords: React.FC<Props> = ({ orderId }) => {
  const useEntries = (params: URLSearchParams, skip: number, count: number): HookResult =>
    useProgressRecords(RecordModelNames.Order, orderId, count, skip);

  return <ActionRecords useEntries={useEntries} />;
};
