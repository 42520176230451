import React, { useMemo } from 'react';
import { Skeleton } from '@mui/material';
import { getRandomInt } from '@utils/math/get-random-int';
import { splitIntoSegments } from '@utils/math/split-into-segments';

const minSymbols = 5;
const maxSymbols = 27;
const minWordLength = 5;
const maxWordLength = 12;

export function useGeneratedSkeletons(isLocked: boolean, hasData: boolean): JSX.Element[] {
  return useMemo(() => {
    if (isLocked && hasData) {
      const skeletonSymbolCount = getRandomInt(minSymbols, maxSymbols);
      const count = Math.ceil(skeletonSymbolCount / maxWordLength);
      const skeletonSegments = splitIntoSegments(skeletonSymbolCount, count, minWordLength, maxWordLength);
      const widthPercentMultiplier = 100 / maxSymbols;

      return skeletonSegments.map((width, index) => (
        <Skeleton
          key={index}
          variant="text"
          animation={false}
          width={`${width * widthPercentMultiplier}%`}
          sx={{ height: '1rem' }}
        />
      ));
    }

    return [];
  }, [isLocked, hasData]);
}
