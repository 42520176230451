import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { ControlledDatePicker } from '@components/inputs/ControlledDatePicker';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { filterEmptyObjectFields } from '@utils/filter-empty-object-fields';
import { FormModel, sanitize, serialize } from './form-config';
import { stringifyObjectFields } from '@utils/stringify-object-fields';
import { OrderClasses } from '@features/order/order-class.type';
import { UsedOptions } from './used.type';
import { RemainingOptions } from './remaining.type';

export const FilterAllocationsForm: React.FC = () => {
  const { isLoading, ...backendLabels } = useAppBackendLabels(['statusOptions', 'supportOptions', 'tutorOptions']);
  const [params, setParams] = useSearchParams();
  const { control, handleSubmit, reset } = useForm<FormModel>({
    values: sanitize(deserializeURLSearchParams(params), backendLabels),
  });

  const statusOptions = backendLabels.statusOptions.filter((option) => option.orderClass === OrderClasses.Nmh);

  function onSubmit(data: FormModel): void {
    setParams(new URLSearchParams(stringifyObjectFields(filterEmptyObjectFields(serialize(data)))));
  }

  function onReset(): void {
    reset();
    setParams({});
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1} columns={{ xs: 4, md: 8, lg: 12 }}>
        <Grid item xs={4} md={4} lg={3}>
          <ControlledDatePicker control={control} name="creationStartDate" label="Creation Date From" />
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <ControlledDatePicker control={control} name="creationEndDate" label="Creation Date To" />
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <ControlledDatePicker control={control} name="endingStartDate" label="End Date From" />
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <ControlledDatePicker control={control} name="endingEndDate" label="End Date To" />
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <ControlledAutocomplete
            control={control}
            name="nmhSupportType"
            label="Support Type"
            options={backendLabels.supportOptions}
            isLoading={isLoading}
            multiple
          />
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <ControlledAutocomplete
            control={control}
            name="orderStatusId"
            label="NMH Order Status"
            options={statusOptions}
            isLoading={isLoading}
            multiple
            isNullAvailable
            isStatus
          />
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <ControlledAutocomplete control={control} name="used" label="Used" options={UsedOptions} />
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <ControlledAutocomplete control={control} name="remaining" label="Remaining" options={RemainingOptions} />
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <ControlledTextField control={control} name="customerFirstName" label="Customer First Name" />
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <ControlledTextField control={control} name="customerLastName" label="Customer Last Name" />
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <ControlledAutocomplete
            control={control}
            name="tutorId"
            label="Tutor"
            options={backendLabels.tutorOptions}
            isLoading={isLoading}
            isNullAvailable
            multiple
          />
        </Grid>
        <Grid item xs={4} md={4} lg={1}>
          <Button type="reset" variant="outlined" onClick={onReset} fullWidth>
            Reset
          </Button>
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <Button type="submit" variant="contained" fullWidth>
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
