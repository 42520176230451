import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { SuspensionReasonsOptions } from './suspension-reason.type';
import { AppDropdownWithComment } from '@components/inputs/AppDropdownWithComment';
import { AppModal } from '@components/AppModal';
import { useToggle } from '@hooks/use-toggle';
import { useSuspendAccount } from './use-suspend-account';
import { AppLoadingButton } from '@components/AppLoadingButton';

type Props = {
  customerId: number;
};

export const SuspendAccount: React.FC<Props> = ({ customerId }) => {
  const [isModalOpen, toggleIsModalOpen] = useToggle();
  const [reason, setReason] = useState('');
  const { submitHandler, isLoading } = useSuspendAccount();

  const isButtonDisabled = reason === '';

  async function handleSuspend(): Promise<void> {
    const isSuspended = await submitHandler(customerId, reason);
    if (isSuspended) {
      toggleIsModalOpen();
    }
  }

  return (
    <>
      <Button onClick={toggleIsModalOpen} variant="text" size="large" color="error">
        Suspend Account
      </Button>

      <AppModal open={isModalOpen} onClose={toggleIsModalOpen} title="Please Fill Suspension Reason" color="error">
        <Box>
          <Typography variant="h6" gutterBottom>
            You are about to resume customer account. All cancelled orders will remain cancelled. You will need to
            unlock individual orders to make any account changes. If you resume the account to perform any finance or
            account maintenance action, please remember to suspend the account again upon completion.
          </Typography>
          <AppDropdownWithComment onChange={setReason} reasonOptions={SuspensionReasonsOptions} label="Reason" />
          <AppLoadingButton
            isLoading={isLoading}
            onClick={handleSuspend}
            disabled={isButtonDisabled}
            variant="contained"
            color="error"
            fullWidth
            sx={{ mt: 2 }}
          >
            Confirm Suspension
          </AppLoadingButton>
        </Box>
      </AppModal>
    </>
  );
};
