import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSaveAssessorFreeSlotsMutation } from '@api/api-slice';
import { isErrorResponse } from '@api/error-response.interface';
import { RouteParamsType } from '@routes/route-params.type';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { IWeekTimeSlots } from '@models/week-time-slots.interface';
import { enqueueSnackbar } from 'notistack';
import { useAppSelector } from '@store/use-app-selector';
import { UserRoles } from '@features/user/user-role.type';

type HookResult = {
  submitHandler: (slots: IWeekTimeSlots) => Promise<IWeekTimeSlots | void>;
} & RequestStatusFlags;

export function useSaveAssessorFreeSlots(): HookResult {
  const { assessorIdString } = useParams<RouteParamsType>();
  const [mutation, flags] = useSaveAssessorFreeSlotsMutation();
  const { user } = useAppSelector((state) => state.user);

  const isUserAssessor = user?.role === UserRoles.assessor && 'assessorId' in user;
  const assessorId =
    isUserAssessor && user.assessorId !== null ? user.assessorId : parseInt(assessorIdString as string, 10);

  const submitHandler = useCallback(
    async (slots: IWeekTimeSlots): Promise<IWeekTimeSlots | void> => {
      const result = await mutation({ assessorId, payload: slots }).unwrap();

      if (isErrorResponse(result)) {
        return;
      }
      enqueueSnackbar('Slots saved successfully', { variant: 'success' });

      return result;
    },
    [assessorId, mutation],
  );

  return {
    submitHandler,
    ...flags,
  };
}
