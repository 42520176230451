import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import moment, { Moment } from 'moment-timezone';
import { Box, Typography } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { useTechOrdersByDate } from './use-tech-orders';
import { SelectedOrders } from './SelectedOrders';
import { useAppTitle } from '@hooks/use-app-title';
import { toISODay } from '@utils/dates/to-iso-day';
import { AppDatePicker } from '@components/calendar/AppDatePicker';
import { StyledBadge } from '@components/calendar/StyledBadge';
import { CALENDAR_WIDTH } from '@components/calendar/calendar-width';
import { AppPaper } from '@components/AppPaper';

const DayOrders = (pickersDayProps: PickersDayProps<Moment>, count: number): JSX.Element => (
  <StyledBadge badgeContent={count} color="primary">
    <PickersDay {...pickersDayProps} />
  </StyledBadge>
);

export const DeliveryCalendarPage: FunctionComponent = () => {
  const [initialMoment, startMonthMoment] = useMemo(() => [moment(), moment().startOf('month')], []);
  const [currentDate, setCurrentDate] = useState(initialMoment);
  const [startOfMonthMoment, setStartOfMonthMoment] = useState(startMonthMoment);
  const [currentISODate, startISODate, endISODate] = useMemo(
    () => [toISODay(currentDate), toISODay(startOfMonthMoment), toISODay(moment(startOfMonthMoment).endOf('month'))],
    [currentDate, startOfMonthMoment],
  );
  const { calendar, isLoading } = useTechOrdersByDate(startISODate, endISODate);

  useAppTitle('Delivery Calendar');
  const selectedDateOrders = useMemo(
    () => calendar[currentISODate]?.groupedByOrganisation || [],
    [currentISODate, calendar],
  );

  const getCount = useCallback(
    (pickersDayProps: PickersDayProps<Moment>): number => calendar[toISODay(pickersDayProps.day)]?.count ?? 0,
    [calendar],
  );

  return (
    <AppPaper>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ width: `${CALENDAR_WIDTH}px` }}>
          <Typography variant="h4" component="h1">
            Delivery Calendar
          </Typography>
          <AppDatePicker
            value={currentDate}
            onChange={setCurrentDate}
            onMonthChange={(monthFirstDay): unknown => setStartOfMonthMoment(monthFirstDay)}
            isLoading={isLoading}
            renderDay={(props): JSX.Element => DayOrders(props, getCount(props))}
          />
        </Box>
        <Box sx={{ ml: 4, flex: 1 }}>
          <Typography variant="h4" component="h1">
            {currentDate.format('dddd, MMMM D')}
          </Typography>
          <SelectedOrders groups={selectedDateOrders} />
        </Box>
      </Box>
    </AppPaper>
  );
};
