import { appMutationFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';
import { IOrder } from '@features/order/order.interface';

type Response = { order: IOrder } | IErrorResponse;

export const buildCancelOrder = appMutationFactory<number, IOrder | IErrorResponse>({
  query: (orderId) => ({
    url: EndpointUrlBuilder.deactivateOrder(orderId),
    method: 'POST',
  }),
  transformResponse,
});

export const buildRestoreOrder = appMutationFactory<number, IOrder | IErrorResponse>({
  query: (orderId) => ({
    url: EndpointUrlBuilder.activateOrder(orderId),
    method: 'POST',
  }),
  transformResponse,
});

function transformResponse(response: Response): IOrder | IErrorResponse {
  return isErrorResponse(response) ? response : response.order;
}
