import { useGetQuoteQuery } from '@api/api-slice';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { RouteParamsType } from '@routes/route-params.type';
import { useParams } from 'react-router-dom';
import { IQuoteDetail } from './quote.interface';
import { useEffect, useState } from 'react';

type HookResult = RequestStatusFlags & {
  quote: IQuoteDetail | null;
  setQuote: React.Dispatch<React.SetStateAction<IQuoteDetail | null>>;
};

export function useQuote(id?: string | null): HookResult {
  const { quoteIdString } = useParams<RouteParamsType>();
  const quoteId = id ? id : quoteIdString;
  const [quote, setQuote] = useState<IQuoteDetail | null>(null);
  const [shouldSkip, setShouldSkip] = useState(!quoteId);
  const { data, ...flags } = useGetQuoteQuery(quoteId as string, { skip: shouldSkip });

  useEffect(() => {
    if (data) {
      setQuote(data);
      setShouldSkip(true);
    }
  }, [data]);

  // avoid fetching data, that we already got from create/save quote
  useEffect(() => {
    if (quote !== null) {
      setShouldSkip(true);
    }
  }, [quote]);

  return {
    quote,
    setQuote,
    ...flags,
  };
}
