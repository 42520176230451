import React from 'react';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { AppDataGrid } from '@components/AppDataGrid';
import { Button, ButtonGroup, Link, Typography } from '@mui/material';
import { ISalesOrderLine } from '@features/order/shared/order-lines/sales-order-line.interface';
import { createColumnFactory } from '@utils/create-column.factory';
import { EmptyCell } from '@utils/empty-cell';
import { BooleanChip } from '@utils/render-boolean';
import { StyledTable } from '@components/StyledTable';
import { UpdateSalesOrderLines } from './update-sales-order-lines/UpdateSalesOrderLines';
import { AppDrawer } from '@components/AppDrawer';
import { useToggle } from '@hooks/use-toggle';

type Props = {
  lines: ISalesOrderLine[];
  orderId: number;
};

const defaultSortModel: GridSortModel = [
  {
    field: 'createdAt',
    sort: 'desc',
  },
];

const createLineColumn = createColumnFactory<ISalesOrderLine>({});

const columns: GridColDef[] = [
  createLineColumn('id', {
    headerName: 'Id',
    width: 60,
  }),
  createLineColumn('code', {
    headerName: 'Code',
    width: 90,
  }),
  createLineColumn('quantity', {
    headerName: 'Quantity',
    headerAlign: 'right',
    align: 'right',
    width: 90,
  }),
  createLineColumn('description', {
    headerName: 'Description',
    flex: 1,
  }),

  createLineColumn('serialNumber', {
    headerName: 'Serial Number',
    width: 120,
  }),
  createLineColumn('downloadLink', {
    headerName: 'Download Link',
    width: 200,
    renderCell: ({ row }) =>
      row.downloadLink ? (
        <Link href={row.downloadLink} target="_blank">
          External Link
        </Link>
      ) : (
        EmptyCell
      ),
  }),
  createLineColumn('isPresentInStock', {
    headerName: 'In Stock',
    width: 90,
    renderCell: ({ row }) => <BooleanChip value={row.isPresentInStock} />,
  }),
  createLineColumn('wasShipped', {
    headerName: 'Shipped',
    width: 90,
    renderCell: ({ row }) => <BooleanChip value={row.wasShipped} />,
  }),
  createLineColumn('notes', {
    headerName: 'Notes',
    flex: 1,
  }),
  createLineColumn('rate', {
    headerName: 'Rate',
    flex: 1,
  }),
];

const allowedColumns = ['id', 'code', 'quantity', 'description', 'serialNumber', 'notes', 'rate'];

export const SalesOrderLines: React.FC<Props> = ({ lines, orderId }) => {
  const [isDrawerOpen, toggleDrawer] = useToggle();

  const isDisabled = lines.length > 0;

  const filteredColumns = columns.filter(({ field }) => allowedColumns.includes(field));
  const filteredLines = lines.map(({ id, code, description, quantity, serialNumber, notes, rate }) => ({
    id,
    code,
    quantity,
    description,
    serialNumber,
    notes,
    rate,
  }));

  return (
    <>
      <AppDataGrid
        sx={{ height: '320px' }}
        rows={lines}
        columns={columns}
        sortModel={defaultSortModel}
        hideFooter={true}
      />
      <ButtonGroup variant="outlined" fullWidth sx={{ mt: 1 }}>
        <UpdateSalesOrderLines orderId={orderId} />
        <Button onClick={toggleDrawer} fullWidth disabled={!isDisabled}>
          Print Sales Order
        </Button>
      </ButtonGroup>
      <AppDrawer open={isDrawerOpen} onClose={toggleDrawer}>
        <Typography variant="h4" sx={{ mb: 1 }}>
          Sales Order
        </Typography>
        <StyledTable columns={filteredColumns} rows={filteredLines} />
      </AppDrawer>
    </>
  );
};
