import { useEffect } from 'react';
import { useGetZohoLinkQuery, useRequestZohoLogoutMutation } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useAppSelector } from '@store/use-app-selector';
import { IZohoAuthorizationState, ZohoActions, selectZohoState } from './zoho.slice';

type HookResult = {
  handleZohoLogout: () => void;
} & RequestStatusFlags &
  IZohoAuthorizationState;

export function useZohoAuthorization(): HookResult {
  const user = useAppSelector((state) => state.user.user);
  const { authorizationUri, zohoUserEmail, isLoggedIn } = useAppSelector((state) => selectZohoState(state));
  const shouldSkip = authorizationUri !== null || isLoggedIn || user === null;
  const userId = user?.id || 0;
  const { data, ...flags } = useGetZohoLinkQuery(userId.toString(), { skip: shouldSkip });
  const [requestZohoLogoutMutation] = useRequestZohoLogoutMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) {
      dispatch(ZohoActions.setAuthData(data));
    }
  }, [data, dispatch]);

  const handleZohoLogout = async (): Promise<void> => {
    try {
      await requestZohoLogoutMutation(userId.toString()).unwrap();
      dispatch(ZohoActions.logOut());
    } catch (error) {
      console.error('Failed to log out from Zoho', error);
    }
  };

  return { authorizationUri, zohoUserEmail, isLoggedIn, handleZohoLogout, ...flags };
}
