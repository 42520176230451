import { useSaveOrderMutation } from '@api/api-slice';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { OrdersActions } from '@features/order/orders.slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { isErrorResponse } from '@api/error-response.interface';

type HookResult = {
  saveOrderElectronicInvoice: (value: string) => Promise<void>;
} & RequestStatusFlags;

export function useSaveOrderElectronicInvoice(orderId: number): HookResult {
  const [saveMutation, flags] = useSaveOrderMutation();
  const dispatch = useAppDispatch();

  const saveOrderElectronicInvoice = async (electronicInvoice: string): Promise<void> => {
    const payload = {
      id: orderId,
      payload: {
        electronicInvoice,
        id: orderId,
      },
    };

    const result = await saveMutation(payload).unwrap();
    if (isErrorResponse(result)) {
      return;
    }
    dispatch(OrdersActions.upsertOrder(result));
  };

  return {
    saveOrderElectronicInvoice,
    ...flags,
  };
}
