import React from 'react';
import { Typography } from '@mui/material';
import { AppPaper } from '@components/AppPaper';
import { useAppTitle } from '@hooks/use-app-title';
import { KPI } from './KPI';

export const KPIPage: React.FC = () => {
  useAppTitle('KPIs');
  return (
    <AppPaper>
      <Typography variant="h4" component="h1">
        KPIs
      </Typography>
      <KPI
        url="/kpi?kpi_type=na_first_contact"
        number={1}
        title="Needs Assessment — First Contact"
        label="Initial contact must be made within 2 work days."
        hasFundingBody
      />
      <KPI
        url="/kpi?kpi_type=na_offered_and_completed"
        number={2}
        title="Needs Assessment — Offered and Completed"
        label="Needs Assessment must be available within 7 work days of booking."
        hasFundingBody
      />
      <KPI
        url="/kpi?kpi_type=na_returns"
        number={3}
        title="Needs Assessment — Report Returns"
        label="Needs Assessment report must be returned within 5 working days of Assessment, unless Student review is requested."
        hasFundingBody
      />
      <KPI
        url="/kpi?kpi_type=equipment_status_assigned"
        number={6}
        title="Equipment Delivery — First Contact"
        label="Minimum of 95% of customers will be contacted within 2 working days of receiving SLC approval to arrange delivery of equipment appointment."
        hasFundingBody
        hasTechOrderType
      />
      <KPI
        url="/kpi?kpi_type=equipment_order_confirmed"
        number={7}
        title="Equipment Delivery — Delivery to Customer"
        label="Minimum of 95% of customers will receive equipment package within 5 working days of successful contact, or 5 working days from the date the £200 contribution is received if relevant (excluding customers who request an appointment after the 5 days)"
        hasFundingBody
        hasTechOrderType
      />
      <KPI
        url="/kpi?kpi_type=nmh_equipment_delivery"
        number={9}
        title="AT Training — First Contact"
        label="TBC"
        hasFundingBody
      />
      <KPI
        url="/kpi?kpi_type=nmh_sessions_attendance"
        number={10}
        title="AT Training — Appointment Attendance"
        label="TBC"
        hasFundingBody
        hasEquipmentDeliveryDate
      />
    </AppPaper>
  );
};
