import { useConfirmPasswordMutation } from '@api/api-slice';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useState } from 'react';
import { AxiosResponse } from 'axios';
import { IConfirmRequest, IResetResponseError } from './api';

type HookResult = {
  handleConfirmPassword: (account: IConfirmRequest) => Promise<void>;
  errorMessage: string;
} & RequestStatusFlags;

export function useConfirmPassword(): HookResult {
  const [confirmPassword, flags] = useConfirmPasswordMutation();
  const [errorMessage, setErrorMessage] = useState('');
  const handleConfirmPassword = async (account: IConfirmRequest): Promise<void> => {
    try {
      await confirmPassword(account).unwrap();
    } catch (error) {
      setErrorMessage((error as AxiosResponse<IResetResponseError>).data.error);
    }
  };

  return { handleConfirmPassword, errorMessage, ...flags };
}
