import React from 'react';
import { Control, Controller, FieldValues, Path, UseControllerProps } from 'react-hook-form';
import { ToggleButton, ToggleButtonProps } from '@mui/material';

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  rules?: UseControllerProps<T>['rules'];
  getLabel: (value: boolean) => string;
} & Omit<ToggleButtonProps, 'value'>;

export const ControlledToggleButton: <T extends FieldValues>(props: Props<T>) => JSX.Element = ({
  control,
  name,
  rules,
  getLabel,
  ...fieldProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }): JSX.Element => (
        <ToggleButton
          {...fieldProps}
          value={value}
          sx={{ color: `${value && 'var(--color-blue)'}` }}
          onChange={(): void => onChange(!value)}
        >
          {getLabel(value)}
        </ToggleButton>
      )}
    />
  );
};
