import { FormEvent, FormEventHandler } from 'react';

/**
 * Creates a form event handler that prevents event bubbling and internally passes original event to passed handler.
 *
 * This utility function is useful when handling form events in a React application
 * where a form might be conceptually nested inside another form in terms of React's event system,
 * even if not nested in the actual DOM structure. By preventing event bubbling,
 * the inner form's events can be handled independently without accidentally triggering
 * event handlers of the outer form.
 *
 * @param {FormEventHandler} handler - The form event handler to be wrapped. This handler will
 * be called after stopping the event from bubbling up.
 * @returns {FormEventHandler} A new form event handler that first stops event propagation
 * before invoking the provided handler.
 */
export function createPreventBubblingFormHandler(handler: FormEventHandler): FormEventHandler {
  return (event: FormEvent): void => {
    event.stopPropagation();
    handler(event);
  };
}
