import { IWeekTimeSlots } from '@models/week-time-slots.interface';

export const InitialSlotsState: IWeekTimeSlots = {
  mondaySlots: [],
  tuesdaySlots: [],
  wednesdaySlots: [],
  thursdaySlots: [],
  fridaySlots: [],
  saturdaySlots: [],
  sundaySlots: [],
};
