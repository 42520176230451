import { UserActions } from '@features/user/user.slice';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

export interface IZohoAuthorizationState {
  isLoggedIn: boolean;
  authorizationUri: string | null;
  zohoUserEmail: string | null;
}

const initialState: IZohoAuthorizationState = {
  isLoggedIn: false,
  authorizationUri: null,
  zohoUserEmail: null,
};

const zohoSlice = createSlice({
  name: 'Zoho',
  initialState,
  reducers: {
    setAuthData: (state, { payload }: PayloadAction<IZohoAuthorizationState>) => payload,
    logIn: (state) => ({ ...state, isLoggedIn: true }),
    logOut: (state) => ({ ...state, isLoggedIn: false }),
  },
  extraReducers: (builder) => {
    builder.addCase(UserActions.logOut, () => initialState);
  },
});

export const ZohoActions = zohoSlice.actions;
export const zohoReducer = zohoSlice.reducer;
export type ZohoActionsType = typeof ZohoActions;

export const selectZohoState = (state: RootState): IZohoAuthorizationState => state.zoho;
