import { Describe, assign, boolean, nullable, number, object, string } from 'superstruct';
import { ISalesOrderLine } from '../sales-order-line.interface';
import { EquipmentQuoteLineStruct } from './equipment-quote-line-struct.spec';

export const SalesOrderLineStruct: Describe<ISalesOrderLine> = assign(
  EquipmentQuoteLineStruct,
  object({
    serialNumber: nullable(string()),
    downloadLink: nullable(string()),
    isPresentInStock: boolean(),
    wasShipped: boolean(),
    notes: nullable(string()),
    rate: nullable(number()),
  }),
);
