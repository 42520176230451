import { ICustomer } from '@features/customer/customer.interface';
import { IOrderNeedsAssessment } from '@features/order/needs-assessment/order-needs-assessment.interface';
import React, { FC } from 'react';
import { AppDetails, DetailsConfig } from '@components/AppDetails';

type Props = {
  order: IOrderNeedsAssessment;
  customer: ICustomer | null;
};

type Info = {
  email?: string | null;
  isReviewRequested?: boolean | null;
};

export const StudentInfo: FC<Props> = ({ order, customer }) => {
  const studentInfo: Info = {};

  if (order) {
    studentInfo.isReviewRequested = order.isReviewRequested;
  }

  if (customer) {
    studentInfo.email = customer.email;
  }

  const config: DetailsConfig<Info> = {
    email: { label: 'Student Email' },
    isReviewRequested: { label: 'Student Review' },
  };

  return <AppDetails model={studentInfo} config={config} />;
};
