import { ICustomer } from '@features/customer/customer.interface';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';

export type FormModel = Pick<
  ICustomer,
  | 'address'
  | 'altNumber'
  | 'contactNumber'
  | 'crn'
  | 'email'
  | 'firstName'
  | 'fundingBodyId'
  | 'hepId'
  | 'lastName'
  | 'notes'
  | 'postcode'
  | 'prefCommunicationWay'
  | 'extraMile'
  | 'labels'
>;

export type ApiModel = FormModel;

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  address: { type: 'transportable', initial: '' },
  altNumber: { type: 'transportable', initial: '' },
  contactNumber: { type: 'transportable', initial: '' },
  crn: { type: 'transportable', initial: '' },
  email: { type: 'transportable', initial: '' },
  firstName: { type: 'transportable', initial: '' },
  fundingBodyId: { type: 'backend-label', optionKey: 'fundingBodyOptions', initial: null },
  hepId: { type: 'backend-label', optionKey: 'hepOptions', initial: null },
  lastName: { type: 'transportable', initial: '' },
  notes: { type: 'transportable', initial: '' },
  postcode: { type: 'transportable', initial: '' },
  prefCommunicationWay: {
    type: 'backend-label',
    optionKey: 'preferredCommunicationWaysOptions',
    multiple: true,
    initial: [],
  },
  extraMile: { type: 'transportable', initial: false },
  labels: { type: 'backend-label', optionKey: 'labelOptions', multiple: true, initial: [] },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  address: Serializers.string,
  altNumber: Serializers.string,
  contactNumber: Serializers.string,
  crn: Serializers.string,
  email: Serializers.string,
  firstName: Serializers.string,
  fundingBodyId: Serializers.backendValue,
  hepId: Serializers.backendValue,
  lastName: Serializers.string,
  notes: Serializers.string,
  postcode: Serializers.string,
  prefCommunicationWay: Serializers.array,
  extraMile: Serializers.boolean,
  labels: Serializers.backendValue,
});
