import React, { PropsWithChildren } from 'react';
import { Button, ButtonProps, Skeleton } from '@mui/material';

type Props = PropsWithChildren<{
  isLoading: boolean;
}> &
  ButtonProps;

/**
 * Renders a button with loading state indicated by a skeleton animation.
 *
 * Accepts boolean `isLoading` and `MuiButtonProps` as props.
 *
 * When `isLoading` or `disabled` is `true`, the button becomes unclickable, but visually is not disabled.
 * Instead waving underlay indicates loading state.
 * Underlay is white for `contained` variant and grey for `outlined`.
 */
export const AppLoadingButton: React.FC<Props> = ({ isLoading, disabled, children, ...props }) => {
  const isDisabled = disabled || isLoading;
  const className = isLoading ? 'Mui-loading' : undefined;
  const variant = props.variant ?? 'contained';
  const classNameSkeleton =
    variant === 'contained' ? 'MuiSkeleton-loading-button MuiSkeleton-contrast' : 'MuiSkeleton-loading-button';
  return (
    <Button variant={variant} disabled={isDisabled} className={className} {...props}>
      {isLoading && <Skeleton className={classNameSkeleton} animation="wave" />}
      {children}
    </Button>
  );
};
