import React from 'react';
import { Paper, PaperProps } from '@mui/material';

/**
 * `AppPaper` is a wrapper component for MUI's Paper component.
 * It comes pre-configured with full width and height, padding, and flexbox settings to make it children can fit full height of the parent.
 *
 */
export const AppPaper: React.FC<PaperProps> = ({ children, sx, ...props }) => {
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        flexGrow: 1,
        p: 3,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Paper>
  );
};
