import React from 'react';
import { Chip } from '@mui/material';
import { OpenFile } from '@components/inputs/OpenFile';

type Props = {
  path: string | null;
};

export const PDFCell: React.FC<Props> = ({ path }) => {
  if (path) {
    return (
      <OpenFile path={path} isPreview>
        <Chip label="PDF" size="small" variant="outlined" color="success" className="MuiChip-status" />
      </OpenFile>
    );
  }
  return <Chip label="No" size="small" variant="outlined" color="error" className="MuiChip-status" />;
};
