import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { IWeekTimeSlots } from '@models/week-time-slots.interface';
import { useGetAssessorFreeSlotsQuery } from '@api/api-slice';
import { RouteParamsType } from '@routes/route-params.type';
import { isErrorResponse } from '@api/error-response.interface';
import { InitialSlotsState } from './initial-slots';
import { useAppSelector } from '@store/use-app-selector';
import { UserRoles } from '@features/user/user-role.type';

type HookResult = RequestStatusFlags & {
  slots: IWeekTimeSlots;
  setSlots: (slots: IWeekTimeSlots) => unknown;
};

export function useAssessorFreeSlots(): HookResult {
  const { assessorIdString } = useParams<RouteParamsType>();
  const { user } = useAppSelector((state) => state.user);

  const isUserAssessor = user?.role === UserRoles.assessor && 'assessorId' in user;
  const assessorId = isUserAssessor ? user.assessorId : parseInt(assessorIdString as string, 10);

  const { data, ...flags } = useGetAssessorFreeSlotsQuery(assessorId as number);
  // storing slots not in redux, but just in use state hook for tracking changes after save
  const [slots, setSlots] = useState<IWeekTimeSlots>(InitialSlotsState);

  useEffect(() => {
    if (data && !isErrorResponse(data)) {
      setSlots(data);
    }
  }, [data]);

  return {
    slots,
    setSlots,
    ...flags,
  };
}
