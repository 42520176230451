import { ISODateString } from '@utils/dates/iso-string.type';
import { ISlot } from './slot.interface';

export type GroupedByDay = Record<ISODateString, ISlot[]>;

export function groupSlotsByDay(slots: ISlot[]): GroupedByDay {
  const grouped: Record<string, ISlot[]> = {};

  for (const slot of slots) {
    const [date] = slot.start.split('T'); // Extract YYYY-MM-DD part

    if (!grouped[date]) {
      grouped[date] = [];
    }

    grouped[date].push(slot);
  }

  return grouped;
}
