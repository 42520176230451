import { createEnumOptions } from '@utils/enum/create-enum.factory';

export const Used = {
  Unused: 'unused',
  Used: 'used',
} as const;

type Keys = keyof typeof Used;

export type UsedType = (typeof Used)[Keys];

export const UsedOptions = createEnumOptions(Used);
