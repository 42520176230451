import { selectIsCustomerSuspended } from '@features/customer/customer.slice';
import { selectIsOrderCancelledById } from '@features/order/orders.slice';
import { useAppSelector } from '@store/use-app-selector';
import { OrderDisabledReasons, OrderDisabledReasonsType } from './order-disabled-reason.type';

type HookResult = {
  isDisabled: boolean;
  reason: OrderDisabledReasonsType | null;
};

export function useIsOrderDisabled(orderId: number): HookResult {
  const isCustomerSuspended = useAppSelector((state) => selectIsCustomerSuspended(state));
  const isOrderDisabled = useAppSelector((state) => selectIsOrderCancelledById(state, orderId));

  let reason = null;
  if (isOrderDisabled) {
    reason = OrderDisabledReasons.OrderIsCancelled;
  }
  if (isCustomerSuspended) {
    reason = OrderDisabledReasons.CustomerIsSuspended;
  }

  return {
    isDisabled: isCustomerSuspended || isOrderDisabled,
    reason,
  };
}
