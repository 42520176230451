import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link, TableCell, TableRow } from '@mui/material';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { Routes } from '@routes/routes';
import { IAssessorAvailability } from './assessor-availability.interface';
import { DaySlots } from './DaySlots';

type Props = {
  assessorAvailability: IAssessorAvailability[];
};

export const AssessorAvailability: React.FC<Props> = ({ assessorAvailability }) => {
  const { crmFreeSlotOptions } = useAppBackendLabels(['crmFreeSlotOptions']);
  return (
    <>
      {assessorAvailability.map((assessor, index) => (
        <TableRow
          key={assessor.assessorId}
          className={index !== assessorAvailability.length - 1 ? 'MuiTableRow-weak' : 'MuiTableRow-strong'}
        >
          <TableCell>
            <Link key={assessor.assessorId} component={NavLink} to={`${Routes.assessors}/${assessor.assessorId}`}>
              <BackendLabel value={assessor.assessorId} optionKey="assessorOptions" isPlainText />
            </Link>
          </TableCell>
          <TableCell>{assessor.employment}</TableCell>
          <TableCell>
            <BackendLabel value={assessor.organisationId} optionKey="organisationOptions" isPlainText />
          </TableCell>
          <DaySlots slots={assessor.mondaySlots} options={crmFreeSlotOptions} />
          <DaySlots slots={assessor.tuesdaySlots} options={crmFreeSlotOptions} />
          <DaySlots slots={assessor.wednesdaySlots} options={crmFreeSlotOptions} />
          <DaySlots slots={assessor.thursdaySlots} options={crmFreeSlotOptions} />
          <DaySlots slots={assessor.fridaySlots} options={crmFreeSlotOptions} />
          <DaySlots slots={assessor.saturdaySlots} options={crmFreeSlotOptions} />
          <DaySlots slots={assessor.sundaySlots} options={crmFreeSlotOptions} />
        </TableRow>
      ))}
    </>
  );
};
