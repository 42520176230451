import React, { ComponentType, useEffect } from 'react';
import { useAppSelector } from '@store/use-app-selector';
import { Routes } from '@routes/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { AllowedRoutesByCrmAccessibleRole, DefaultRoutes, isAccessibleRole } from './default-routes-by-role';
import { enqueueSnackbar } from 'notistack';

export const RequireAuth = <P extends object>(Component: ComponentType<P>): ComponentType<P> => {
  const WrappedComponent: React.FC<P> = (props: P) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { token, user } = useAppSelector((state) => state.user);
    const role = user?.role;
    const isAuthorized = Boolean(token);
    const isAccessible = isAccessibleRole(role);

    useEffect(() => {
      const isAnonymousRoute = [Routes.login, Routes.reset].includes(pathname);
      if (isAuthorized) {
        if (isAccessible) {
          const isAccessibleByRole = AllowedRoutesByCrmAccessibleRole[role].some((route) => pathname.startsWith(route));
          if (!isAccessibleByRole || isAnonymousRoute) {
            navigate(DefaultRoutes[role], { replace: true });
          }
        } else {
          enqueueSnackbar(`You have no access to CRM with role ${role}`, { variant: 'error' });
          navigate(Routes.login, { replace: true });
        }
      } else if (!isAnonymousRoute) {
        navigate(Routes.login, { replace: true });
      }
    }, [pathname, navigate, token, role, isAuthorized, isAccessible]);

    return <Component {...props} />;
  };

  return WrappedComponent;
};
