import { Moment } from 'moment-timezone';
import { LondonTimezone } from './london-timezone';

type WeekendRange = {
  saturdayStart: Moment | null;
  mondayStart: Moment | null;
};

export function findClosestWeekends(startDate: Moment, endDate: Moment): WeekendRange {
  if (startDate.isAfter(endDate)) {
    return {
      saturdayStart: null,
      mondayStart: null,
    };
  }

  let saturdayStart = null;
  let mondayStart = null;

  // Clone startDate to avoid mutating original date
  const dateIterator = startDate.clone().tz(LondonTimezone).startOf('day');

  while (dateIterator.isSameOrBefore(endDate)) {
    if (dateIterator.day() === 6) {
      // it is a Saturday
      const saturday = dateIterator.clone().tz(LondonTimezone).startOf('day');
      if (saturday.isAfter(startDate)) {
        saturdayStart = saturday;

        const monday = saturday.clone().tz(LondonTimezone).add(2, 'day');
        if (monday.isBefore(endDate)) {
          mondayStart = monday;
          break;
        }
      }
    } else if (dateIterator.day() === 1) {
      // it is a Monday
      const monday = dateIterator.clone().tz(LondonTimezone).startOf('day');
      if (monday.isBefore(endDate)) {
        mondayStart = monday;
      }
      break;
    }
    dateIterator.add(1, 'day');
  }

  return {
    saturdayStart,
    mondayStart,
  };
}
