import React from 'react';
import { Button } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { IProductSummary } from '@features/product/product.interface';
import { useAssessorQuoteAction } from './use-assessor-quote-action';
import { IQuoteDetail } from '../quote.interface';

type Props = {
  quote: IQuoteDetail;
  setQuote: React.Dispatch<React.SetStateAction<IQuoteDetail | null>>;
  setProducts: React.Dispatch<React.SetStateAction<IProductSummary[]>>;
};

export const AssessorQuoteActionButton: React.FC<Props> = ({ quote, setQuote, setProducts }) => {
  const { editHandler, isEditVisible, isLoading, isDraft } = useAssessorQuoteAction(quote, setQuote, setProducts);
  const buttonLabel = isDraft ? 'Complete Quote' : 'Edit Quote';
  const Icon = isDraft ? LockIcon : LockOpenIcon;
  if (!isEditVisible) {
    return null;
  }
  return (
    <Button fullWidth disabled={isLoading} sx={{ mt: 2 }} onClick={editHandler} startIcon={<Icon />}>
      {buttonLabel}
    </Button>
  );
};
