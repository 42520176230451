import React from 'react';
import { Box, Chip, ChipProps, IconButton, LinearProgress, Stack } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { useAppDispatch } from '@store/use-app-dispatch';
import { AppBackendLabels, BackendValue, IBackendLabelOption } from '@features/backend-label/backend-label.type';
import { BackendLabelsActions } from '@features/backend-label/backend-labels.slice';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { EmptyCell } from '@utils/empty-cell';

function getLabel(value: BackendValue, options: IBackendLabelOption<BackendValue>[]): string | null {
  if (options.length === 0) {
    return null;
  }
  const option = options.find((option) => option.value === value);
  return option ? option.label : null;
}

type Props = {
  value: BackendValue;
  optionKey: keyof AppBackendLabels;
  isPlainText?: boolean;
  fallbackText?: string;
} & ChipProps;

export const BackendLabel: React.FunctionComponent<Props> = ({
  value,
  optionKey,
  isPlainText = false,
  fallbackText = EmptyCell,
  ...chipProps
}) => {
  const appKey: keyof AppBackendLabels = optionKey === 'operatorOptions' ? 'userOptions' : optionKey;
  const { [optionKey]: options, isLoading } = useAppBackendLabels([appKey]);
  const dispatch = useAppDispatch();
  const label = getLabel(value, options);

  if (isLoading) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  if (value === undefined || value === null) {
    return <span>{fallbackText}</span>;
  }

  if (!label) {
    return (
      <Stack direction="row" alignItems="center">
        {value}
        <IconButton onClick={(): unknown => dispatch(BackendLabelsActions.resetOptionByKey(appKey))} size="small">
          <SyncIcon />
        </IconButton>
      </Stack>
    );
  }

  if (isPlainText) {
    return <span>{label}</span>;
  }

  return <Chip {...chipProps} size="small" label={label} />;
};
