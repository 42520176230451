import { useCallback } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useCreateOrResetAccountMutation } from '@api/api-slice';
import { isErrorResponse } from '@api/error-response.interface';
import { RequestStatusFlags, mergeRequestStatusFlags } from '@utils/merge-request-status-flags';

type HookResult = {
  submitHandler(customerId: number): Promise<void>;
} & RequestStatusFlags;

export function useCreateOrResetAccount(): HookResult {
  const [mutation, flags] = useCreateOrResetAccountMutation();

  const submitHandler = useCallback(
    async (customerId: number): Promise<void> => {
      const result = await mutation(customerId).unwrap();

      if (isErrorResponse(result)) {
        return; // will show snackbar in api layer
      } else if (result.message) {
        enqueueSnackbar(result.message, { variant: 'success' });
      }
    },
    [mutation],
  );

  return {
    ...mergeRequestStatusFlags(flags),
    submitHandler,
  };
}
