import React from 'react';
import { Box, Typography } from '@mui/material';
import { ActionRecords } from '@features/action-records/ActionRecords';
import { RecordModelNames } from '@features/action-records/record-model-name.type';
import { HookResult, useProgressRecords } from '@features/action-records/use-progress-records';
import { UserRoles } from '@features/user/user-role.type';
import { RouteParamsType } from '@routes/route-params.type';
import { useAppSelector } from '@store/use-app-selector';
import { useParams } from 'react-router-dom';

export const AssessorProgressRecords: React.FC = () => {
  const { assessorIdString } = useParams<RouteParamsType>();
  const { user } = useAppSelector((state) => state.user);

  const isUserAssessor = user?.role === UserRoles.assessor && 'assessorId' in user;
  const assessorId = isUserAssessor ? user.assessorId : parseInt(assessorIdString as string, 10);

  const useEntries = (params: URLSearchParams, skip: number, count: number): HookResult =>
    useProgressRecords(RecordModelNames.Assessor, assessorId as number, count, skip);

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" sx={{ flexGrow: 1 }}>
        Assessor Progress Records
      </Typography>
      <ActionRecords useEntries={useEntries} />
    </Box>
  );
};
