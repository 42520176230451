import { Moment } from 'moment-timezone';
import {
  BaseOrderApiModel,
  BaseOrderFormModel,
  baseOrderSanitizeMap,
  baseOrderSerializeMap,
} from '@features/order/base/form-config';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { IOrderNeedsAssessment } from './order-needs-assessment.interface';

export type FormModel = BaseOrderFormModel &
  Pick<
    IOrderNeedsAssessment,
    | 'assessmentType'
    | 'assessmentCentreId'
    | 'isReviewRequested'
    | 'shareNarWithHep'
    | 'quoteId'
    | 'qualityAssuranceUserId'
    | 'organisationId'
  > & {
    nextContactDate: Moment | null;
    reportSentDate: Moment | null;
    submissionDate: Moment | null;
  };

export type ApiModel = BaseOrderApiModel &
  Pick<
    IOrderNeedsAssessment,
    | 'assessmentType'
    | 'assessmentCentreId'
    | 'isReviewRequested'
    | 'shareNarWithHep'
    | 'quoteId'
    | 'nextContactDate'
    | 'reportSentDate'
    | 'qualityAssuranceUserId'
    | 'submissionDate'
    | 'organisationId'
  >;

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  ...baseOrderSanitizeMap,
  assessmentType: { type: 'backend-label', optionKey: 'assessmentTypeOptions', initial: null },
  assessmentCentreId: { type: 'backend-label', optionKey: 'assessmentCentreOptions', initial: null },
  isReviewRequested: { type: 'transportable', initial: false },
  shareNarWithHep: { type: 'transportable', initial: false },
  quoteId: { type: 'transportable', initial: '' },
  nextContactDate: { type: 'date', initial: null },
  reportSentDate: { type: 'date', initial: null },
  qualityAssuranceUserId: { type: 'backend-label', optionKey: 'userOptions', initial: null },
  submissionDate: { type: 'date', initial: null },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  ...baseOrderSerializeMap,
  assessmentType: Serializers.backendValue,
  assessmentCentreId: Serializers.backendValue,
  isReviewRequested: Serializers.boolean,
  shareNarWithHep: Serializers.boolean,
  quoteId: Serializers.string,
  nextContactDate: Serializers.date,
  reportSentDate: Serializers.dateTime,
  qualityAssuranceUserId: Serializers.backendValue,
  submissionDate: Serializers.dateTime,
  organisationId: Serializers.backendValue,
});
