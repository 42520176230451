import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { FormModel, sanitize, serialize } from './form.config';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { stringifyObjectFields } from '@utils/stringify-object-fields';
import { filterEmptyObjectFields } from '@utils/filter-empty-object-fields';
import { Button, Stack } from '@mui/material';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ControlledTextField } from '@components/inputs/ControlledTextField';

export const FilterAssessors: FC = () => {
  const { isLoading, ...backendLabels } = useAppBackendLabels(['organisationOptions', 'disabilityTypeOptions']);
  const [params, setParams] = useSearchParams();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: sanitize(deserializeURLSearchParams(params), backendLabels),
  });

  function onSubmit(data: FormModel): void {
    setParams(new URLSearchParams(stringifyObjectFields(filterEmptyObjectFields(serialize(data)))));
  }

  function onReset(): void {
    reset();
    setParams({});
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" spacing={1}>
        <ControlledTextField control={control} name="name" label="Name" />
        <ControlledAutocomplete
          control={control}
          name="organisationIds"
          label="Organisation"
          options={backendLabels.organisationOptions}
          isLoading={isLoading}
          multiple
        />
        <ControlledAutocomplete
          control={control}
          name="disabilityTypeIds"
          label="Conditions"
          options={backendLabels.disabilityTypeOptions}
          isLoading={isLoading}
          multiple
        />
        <Button type="reset" variant="outlined" onClick={onReset} fullWidth>
          Reset
        </Button>
        <Button type="submit" variant="contained" fullWidth>
          Search
        </Button>
      </Stack>
    </form>
  );
};
