import React, { useEffect, useMemo, useState } from 'react';
import { isEqual, xor } from 'lodash';
import { Alert, Box, CircularProgress, Stack, Typography } from '@mui/material';
import { AppObjectForEach } from '@components/AppObjectForEach';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { useAssessorFreeSlots } from './use-assessor-free-slots';
import { IWeekTimeSlots } from '@models/week-time-slots.interface';
import { Week } from './Week';
import { useSaveAssessorFreeSlots } from './use-save-assessor-free-slots';
import { InitialSlotsState } from './initial-slots';

export const AssessorWeekTimeSlots: React.FC = () => {
  const { slots, setSlots, isLoading } = useAssessorFreeSlots();
  const { submitHandler, isLoading: isSaving } = useSaveAssessorFreeSlots();
  const [slotsForm, setSlotsForm] = useState<IWeekTimeSlots>(InitialSlotsState);
  const { crmFreeSlotOptions } = useAppBackendLabels(['crmFreeSlotOptions']);
  const fullSlots = useMemo(() => crmFreeSlotOptions.map((option) => option.value), [crmFreeSlotOptions]);
  const isSaveDisabled = useMemo(() => isEqual(slots, slotsForm), [slots, slotsForm]);

  useEffect(() => {
    if (slots) {
      setSlotsForm(slots);
    }
  }, [setSlotsForm, slots]);

  if (isLoading) {
    return <CircularProgress sx={{ margin: 'auto' }} />;
  }

  if (!slots) {
    return <Alert severity="warning">Assessor not found.</Alert>;
  }

  // toggle in array using lodash xor,
  // sort to save order for button disability check
  function onSlotChange(day: keyof IWeekTimeSlots, slotId: number): void {
    setSlotsForm({
      ...slotsForm,
      [day]: xor(slotsForm[day], [slotId]).sort(),
    });
  }

  // uncheck all if all selected, check all otherwise
  function onDayChange(day: keyof IWeekTimeSlots): void {
    const isFull = slotsForm[day].length === fullSlots.length;
    setSlotsForm({
      ...slotsForm,
      [day]: isFull ? [] : fullSlots,
    });
  }

  async function handleSave(): Promise<void> {
    const result = await submitHandler(slotsForm);
    if (result) {
      setSlots(result);
    }
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1">
        Week Time Slots
      </Typography>
      <Typography variant="body1" component="p" sx={{ maxWidth: 680 }}>
        Your weekly availability shows assessment slots that are generally available to book. You will need to manage
        ad-hoc appointments via your Zoho Calendar. If a conflicting event is present in your Zoho Calendar, the slot
        will not be booked. Availability changes are effective going forward.
      </Typography>
      <Stack direction="row">
        <AppObjectForEach
          model={slots}
          renderItem={(key): JSX.Element => (
            <Week key={key} day={key} slots={slotsForm} onSlotChange={onSlotChange} onDayChange={onDayChange} />
          )}
        />
      </Stack>
      <AppLoadingButton fullWidth onClick={handleSave} disabled={isSaveDisabled} isLoading={isSaving} sx={{ mt: 1 }}>
        Save Assessor Time Slots
      </AppLoadingButton>
    </Box>
  );
};
