import React, { useState } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { MuiFileInput, MuiFileInputProps } from 'mui-file-input';

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label: string;
  isMultiple?: boolean;
} & MuiFileInputProps;

export const ControlledUploadFileField: <T extends FieldValues>(props: Props<T>) => JSX.Element = ({
  control,
  name,
  label,
  isMultiple = false,
  ...muiFileInputProps
}) => {
  const [isDragging, setIsDragging] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }): JSX.Element => {
        const { ref, ...restField } = field;
        return (
          <MuiFileInput
            {...restField}
            {...muiFileInputProps}
            className="fix-full-with-click"
            inputRef={ref as React.Ref<HTMLInputElement>}
            label={label}
            helperText={fieldState.invalid ? 'File is invalid' : ''}
            error={fieldState.invalid}
            fullWidth
            focused={isDragging}
            onDragEnter={(): void => setIsDragging(true)}
            onDragLeave={(): void => setIsDragging(false)}
            onDrop={(): void => setIsDragging(false)}
            // here ugly assertion due to mui-file-field mistake
            multiple={isMultiple as false}
          />
        );
      }}
    />
  );
};
