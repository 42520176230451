import React from 'react';
import { Button } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { useUpdateSalesOrderLines } from './use-update-sales-order-lines';

type Props = {
  orderId: number;
};

export const UpdateSalesOrderLines: React.FC<Props> = ({ orderId }) => {
  const { updateSalesOrderLines, isLoading } = useUpdateSalesOrderLines();

  async function handleClick(): Promise<void> {
    await updateSalesOrderLines(orderId);
  }

  return (
    <Button startIcon={<SyncIcon />} onClick={handleClick} disabled={isLoading} fullWidth>
      Update Sales Order
    </Button>
  );
};
