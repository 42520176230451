import { useRefreshOrderElectronicInvoiceMutation } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { OrdersActions } from '@features/order/orders.slice';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { OrderElectronicInvoiceKeys } from './api';
import { isErrorResponse } from '@api/error-response.interface';

type HookResult = {
  refreshOrderElectronicInvoice: (id: number) => Promise<void>;
} & RequestStatusFlags;

export function useRefreshOrderElectronicInvoice(orderClass: OrderElectronicInvoiceKeys): HookResult {
  const [refreshMutation, flags] = useRefreshOrderElectronicInvoiceMutation();
  const dispatch = useAppDispatch();

  const refreshOrderElectronicInvoice = async (id: number): Promise<void> => {
    const electronicInvoice = await refreshMutation({ id, orderClass }).unwrap();
    if (!isErrorResponse(electronicInvoice)) {
      dispatch(OrdersActions.updateOrder({ id, changes: { electronicInvoice } }));
    }
  };

  return { refreshOrderElectronicInvoice, ...flags };
}
