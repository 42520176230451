import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { IProductWithCondition } from './product.interface';
import { RootState } from '@store/store';

const productsAdapter = createEntityAdapter<IProductWithCondition>({
  selectId: (entry) => entry.id,
});

const productsSlice = createSlice({
  name: 'Products',
  initialState: productsAdapter.getInitialState(),
  reducers: {
    upsertProduct: productsAdapter.upsertOne,
  },
});

export const ProductsActions = productsSlice.actions;
export const productsReducer = productsSlice.reducer;
export type ProductsActionsType = typeof ProductsActions;

export const { selectById: selectProductById } = productsAdapter.getSelectors((state: RootState) => state.products);
