import { BulkActionRowResult, Result } from './bulk-action-row-result.type';
import { ModelWithId } from './model-with-id.type';

export type RowWithResult<T extends ModelWithId> = T & { result: Result<boolean> | null };

export function createRowWithResult<T extends ModelWithId>(
  rows: T[],
  results: BulkActionRowResult<T>[],
): Array<RowWithResult<T>> {
  return rows.map((row) => ({
    result: results.find(({ id }) => id === row.id)?.result ?? null,
    ...row,
  }));
}
