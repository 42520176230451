import { enqueueSnackbar } from 'notistack';
import { BulkActionRowResult } from './bulk-action-row-result.type';
import { ModelWithId } from './model-with-id.type';

export function showResultSnackbar<T extends ModelWithId>(entries: Array<BulkActionRowResult<T>>): void {
  const hasErrors = entries.some((item) => item.result.isSuccess === false);
  const hasSuccess = entries.some((item) => item.result.isSuccess);
  if (hasErrors) {
    if (hasSuccess) {
      enqueueSnackbar('Some rows rejected', { variant: 'warning' });
    } else {
      enqueueSnackbar('All rows rejected', { variant: 'error' });
    }
  } else {
    enqueueSnackbar('All rows accepted', { variant: 'success' });
  }
}
