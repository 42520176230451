import React from 'react';

type Props<T extends object> = {
  model: T;
  renderItem: (key: keyof T, value: T[keyof T]) => JSX.Element;
};

export const AppObjectForEach: <T extends object>(props: Props<T>) => JSX.Element = ({ model, renderItem }) => {
  return <>{Object.entries(model).map(([key, value]) => renderItem(key as keyof typeof model, value))}</>;
};
