import { Badge, BadgeProps, styled } from '@mui/material';

export const StyledBadge = styled(Badge)<BadgeProps>({
  '& .MuiBadge-badge': {
    right: 24,
    top: 17,
    padding: '0 4px',
    pointerEvents: 'none',
    touchAction: 'auto',
  },
});
