import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { IQuoteDetail } from './quote.interface';
import { Link, Typography } from '@mui/material';
import { formatDate } from '@utils/dates/format-date';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { AppDetails, DetailsConfig } from '@components/AppDetails';
import { getPartialByKeys } from '@utils/get-partial-by-keys';
import { Routes } from '@routes/routes';

type Props = {
  quote: IQuoteDetail;
  isOrderLink?: true;
};

export const QuoteDetails: FC<Props> = ({ quote, isOrderLink }) => {
  const quiteDetails = getPartialByKeys(quote, ['crn', 'assessmentCentreId', 'supportPeriod', 'createdAt']);

  const config: DetailsConfig<typeof quiteDetails> = {
    crn: { label: 'CRN', render: (value) => value },
    assessmentCentreId: {
      label: 'Assessment Centre',
      render: (value) => <BackendLabel value={value} optionKey="assessmentCentreOptions" isPlainText />,
    },
    supportPeriod: {
      label: 'Support Period',
      render: (value) => <BackendLabel value={value} optionKey="quoteSupportPeriodOptions" isPlainText />,
    },
    createdAt: { label: 'Date Generated', render: (value) => formatDate(value) },
  };

  return (
    <>
      <Typography variant="h4" component="h4">
        Quote: {quote.quoteInternalId}
        {isOrderLink && (
          <Link
            sx={{ ml: 2 }}
            component={NavLink}
            to={`${Routes.orderSummaries}/${quote.order.customerId}/${quote.order.orderId}`}
          >
            {quote.order.customerId}/{quote.order.orderId}
          </Link>
        )}
      </Typography>
      <AppDetails model={quiteDetails} config={config} />
    </>
  );
};
