import React from 'react';
import { Chip } from '@mui/material';

type Props = {
  value: boolean;
  isInvertedColor?: true;
  yesLabel?: string;
  noLabel?: string;
};

export const BooleanChip: React.FC<Props> = ({ value, isInvertedColor, yesLabel = 'Yes', noLabel = 'No' }) => {
  const isInvertedColorValue = isInvertedColor ? !value : value;
  const label = value ? yesLabel : noLabel;
  const color = isInvertedColorValue ? 'success' : 'error';
  return <Chip label={label} size="small" variant="outlined" color={color} className="MuiChip-status" />;
};
