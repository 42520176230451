import { appMutationFactory } from '@api/api-slice';
import { OrderClasses } from '@features/order/order-class.type';
import { IElectronicInvoiceLog } from '@features/electronic-invoice/electronic-invoice-log.interface';
import { IErrorResponse } from '@api/error-response.interface';

export const RefreshEndpoints = {
  [OrderClasses.Technical]: 'regenerate_tech_order_electronic_invoice',
  [OrderClasses.RepairAndReplace]: 'regenerate_tech_order_electronic_invoice',
  [OrderClasses.NeedsAssessment]: 'regenerate_needs_assessment_electronic_invoice',
  [OrderClasses.AnnualSupportCharge]: 'regenerate_annual_support_charge_electronic_invoice',
} as const;

export const SendEndpoints = {
  [OrderClasses.Technical]: 'send_tech_order_electronic_invoice',
  [OrderClasses.RepairAndReplace]: 'send_tech_order_electronic_invoice',
  [OrderClasses.NeedsAssessment]: 'send_needs_assessment_electronic_invoice',
  [OrderClasses.AnnualSupportCharge]: 'send_annual_support_charge_electronic_invoice',
} as const;

export type OrderElectronicInvoiceKeys = keyof typeof RefreshEndpoints;
export type SendOrderElectronicInvoiceKeys = keyof typeof SendEndpoints;

type RefreshElectronicInvoiceRequest = {
  id: number;
  orderClass: OrderElectronicInvoiceKeys;
};

export const buildRefreshOrderElectronicInvoice = appMutationFactory<
  RefreshElectronicInvoiceRequest,
  string | IErrorResponse
>({
  query: ({ id, orderClass }) => ({
    url: createRefreshEndpoint(orderClass, id),
    method: 'POST',
  }),
});

type SendElectronicInvoiceRequest = {
  id: number;
  orderClass: SendOrderElectronicInvoiceKeys;
};

type SendElectronicInvoiceResponse = { electronicInvoiceLogs: IElectronicInvoiceLog[] } | IErrorResponse;

export const buildSendOrderElectronicInvoice = appMutationFactory<
  SendElectronicInvoiceRequest,
  SendElectronicInvoiceResponse
>({
  query: ({ id, orderClass }) => ({
    url: createSendEndpoint(orderClass, id),
    method: 'POST',
  }),
});

function createSendEndpoint(orderClass: SendOrderElectronicInvoiceKeys, id: number): string {
  if (orderClass === OrderClasses.AnnualSupportCharge) {
    return `/annual_support_charges/${id}/send_electronic_invoice`;
  }

  return `/orders/${id}/${SendEndpoints[orderClass]}`;
}

function createRefreshEndpoint(orderClass: OrderElectronicInvoiceKeys, id: number): string {
  if (orderClass === OrderClasses.AnnualSupportCharge) {
    return `/annual_support_charges/${id}/regenerate_electronic_invoice`;
  }

  return `/orders/${id}/${RefreshEndpoints[orderClass]}`;
}
