import React, { SyntheticEvent } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { AppModal } from '@components/AppModal';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { useToggle } from '@hooks/use-toggle';
import { useCancelOrder } from './use-cancel-order';

type Props = {
  orderId: number;
};

export const CancelOrder: React.FC<Props> = ({ orderId }) => {
  const [isModalOpen, toggleIsModalOpen] = useToggle();
  const { submitHandler, isLoading } = useCancelOrder();

  async function handleSuspend(): Promise<void> {
    const isSuspended = await submitHandler(orderId);
    if (isSuspended) {
      toggleIsModalOpen();
    }
  }

  const handleCancel = async (event: SyntheticEvent): Promise<void> => {
    event.stopPropagation();
    toggleIsModalOpen();
  };

  return (
    <>
      <Button onClick={toggleIsModalOpen} variant="text" size="large" color="error">
        Cancel Order
      </Button>

      <AppModal open={isModalOpen} onClose={toggleIsModalOpen} title="Please Fill Suspension Reason" color="error">
        <>
          <Typography variant="h6" gutterBottom>
            You are about to cancel the order and make it read-only.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Please confirm to continue.
          </Typography>

          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
            <AppLoadingButton variant="contained" onClick={handleSuspend} color="error" isLoading={isLoading}>
              Cancel Order
            </AppLoadingButton>
            <Button variant="outlined" onClick={handleCancel}>
              Go Back
            </Button>
          </Stack>
        </>
      </AppModal>
    </>
  );
};
