import React from 'react';
import { ISession } from '../session.interface';
import { useSaveSessionElectronicInvoice } from './use-save-session-electronic-invoice';
import { useRefreshSessionElectronicInvoice } from './use-refresh-session-electronic-invoice';
import { useSendSessionElectronicInvoice } from './use-send-session-electronic-invoice';
import { ElectronicInvoice } from '@features/electronic-invoice/ElectronicInvoice';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { useGenerateSessionElectronicInvoice } from './use-generate-session-electronic-invoice';

type Props = {
  session: ISession;
  allocationId: number;
};

export const SessionElectronicInvoice: React.FC<Props> = ({ session, allocationId }) => {
  const { saveSessionElectronicInvoice, isLoading: isLoadingSave } = useSaveSessionElectronicInvoice(
    session,
    allocationId,
  );
  const { refreshSessionElectronicInvoice, isLoading: isLoadingRefresh } = useRefreshSessionElectronicInvoice();
  const { sendSessionElectronicInvoice, isLoading: isLoadingSend } = useSendSessionElectronicInvoice();
  const { generateSessionElectronicInvoice, isLoading: isGenerating } = useGenerateSessionElectronicInvoice();

  async function handleSave(electronicInvoice: string | null): Promise<void> {
    if (electronicInvoice) {
      saveSessionElectronicInvoice(electronicInvoice);
    }
  }

  async function handleRefresh(): Promise<void> {
    refreshSessionElectronicInvoice(session.id);
  }
  async function handleSend(): Promise<void> {
    sendSessionElectronicInvoice(session.id);
  }

  async function handleGenerate(): Promise<void> {
    generateSessionElectronicInvoice(session.id);
  }

  const isLoading = isLoadingSave || isLoadingRefresh || isLoadingSend;

  if (!session.funderInvoice) {
    return (
      <AppLoadingButton isLoading={isGenerating} onClick={handleGenerate} variant="outlined" size="small">
        Generate
      </AppLoadingButton>
    );
  }

  return (
    <ElectronicInvoice
      invoice={session.electronicInvoice}
      invoiceLogs={session.electronicInvoiceLogs}
      isButtonDisabled={false}
      buttonSize="small"
      isLoading={isLoading}
      handleSave={handleSave}
      handleRefresh={handleRefresh}
      handleSend={handleSend}
    />
  );
};
