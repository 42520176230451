import { Describe, StructError, array, assert, number, object, unknown } from 'superstruct';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { IPaginable } from '../paginable.interface';

const PaginationStruct: Describe<IPaginable<unknown>> = object({
  entries: array(unknown()),
  pagination: object({
    totalEntries: number(),
    currentPage: number(),
    totalPages: number(),
    perPage: number(),
  }),
});

export function validatePagination<T>(response: IPaginable<T>, url: string): void {
  try {
    assert(response, PaginationStruct);
  } catch (error) {
    for (const failure of (error as StructError).failures()) {
      handleFailure(failure, url);
    }
  }
}
