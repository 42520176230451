import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import {
  ApiModel as TechnicalApiModel,
  FormModel as TechnicalFormModel,
  technicalOrderSanitizeMap,
  technicalOrderSerializeMap,
} from '../technical/form-config';
import { RepairAndReplaceType, RepairAndReplaceTypeOptions } from './repair-and-replace-type.type';
import { omit } from 'lodash';

export type FormModel = Omit<TechnicalFormModel, 'quotationFile'> & {
  repairAndReplaceType: RepairAndReplaceType | null;
  rnrQuotationFile: File | null;
};

export type ApiModel = Omit<TechnicalApiModel, 'quotationFile'> & {
  repairAndReplaceType: RepairAndReplaceType | null;
  rnrQuotationFile: File | null;
};

const repairAndReplaceSanitizeMap = omit(technicalOrderSanitizeMap, 'quotationFile');
export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  ...repairAndReplaceSanitizeMap,
  rnrQuotationFile: { type: 'transportable', initial: null },
  repairAndReplaceType: { type: 'enum', options: RepairAndReplaceTypeOptions, initial: null },
});

const repairAndReplaceSerializeMap = omit(technicalOrderSerializeMap, 'quotationFile');
export const serialize = serializeFactory<ApiModel, FormModel>({
  ...repairAndReplaceSerializeMap,
  rnrQuotationFile: Serializers.file,
  repairAndReplaceType: Serializers.enum,
});
