import { IQuoteDetail } from '@features/quote/quote.interface';
import { IProductSummary } from '../product.interface';

export const TotalId = -1;
export function createTotalRow(quote: IQuoteDetail): IProductSummary {
  return {
    id: TotalId,
    quantity: null,
    productCode: null,
    productName: 'Total',
    amountExVat: quote?.exVat ?? 0,
    amountInclVat: quote?.inclVat ?? 0,
  } as unknown as IProductSummary;
}
