export interface IPagination {
  totalEntries: number;
  currentPage: number;
  totalPages: number;
  perPage: number;
}

export const PaginationDefaults: IPagination = {
  totalEntries: 15,
  currentPage: 0,
  totalPages: 1,
  perPage: 15,
};

/**
 * Represents an array of data `T`, and pagination info, needed for building pagination links and controls
 * @template T
 */
export interface IPaginable<T> {
  entries: T[];
  pagination: IPagination;
}

export type PaginationArgs = {
  count: number;
  skip: number;
};
