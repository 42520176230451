import { useState } from 'react';
import { IEquipmentQuoteLine } from './equipment-quote-line.interface';
import { INotPostedProduct } from '../generate-sales-order-id/not-posted-product.interface';
import { mergeBy } from '@utils/merge-by';
import { LineComponentId } from './Lines';

export interface IQuoteLineProduct extends IEquipmentQuoteLine {
  message?: string;
}

export const LinesTabs = {
  quote: 'Quote Lines',
  salesOrder: 'Sales Order Lines',
} as const;
type Keys = keyof typeof LinesTabs;
export type LinesTabsType = (typeof LinesTabs)[Keys];

type HookResult = {
  products: IQuoteLineProduct[];
  handleNotPostedProducts: (products: INotPostedProduct[]) => unknown;
  currentTab: LinesTabsType;
  setCurrentTab: (value: LinesTabsType) => unknown;
};

export function useQuoteLineProducts(initialProducts: IEquipmentQuoteLine[]): HookResult {
  const [products, setProducts] = useState<IQuoteLineProduct[]>(initialProducts);
  const [currentTab, setCurrentTab] = useState<LinesTabsType>(LinesTabs.quote);

  function handleNotPostedProducts(notPostedProducts: INotPostedProduct[]): void {
    if (notPostedProducts.length) {
      setProducts(mergeBy(products, notPostedProducts, 'id'));
      setCurrentTab(LinesTabs.quote);
      document.querySelector(`#${LineComponentId}`)?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return { products, handleNotPostedProducts, currentTab, setCurrentTab };
}
