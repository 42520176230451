import { UserRoleType, UserRoles } from '@features/user/user-role.type';
import { RouteType, Routes } from '@routes/routes';
import { protectedMenuLinks } from './protected-menu-links';
import { CrmAccessibleRoleType, CrmAccessibleRoles } from './crm-accessible-roles.type';

export const DefaultRoutes: Record<CrmAccessibleRoleType, RouteType> = {
  [UserRoles.operator]: Routes.dashboard,
  [UserRoles.financeManager]: Routes.dashboard,
  [UserRoles.assessor]: Routes.orderSummaries,
  [UserRoles.qualityAssurance]: Routes.orderSummaries,
};

export const isAccessibleRole = (role?: UserRoleType): role is CrmAccessibleRoleType =>
  role ? CrmAccessibleRoles.includes(role as CrmAccessibleRoleType) : false;

type RoutesByRole = Record<CrmAccessibleRoleType, RouteType[]>;
export const AllowedRoutesByCrmAccessibleRole: RoutesByRole = CrmAccessibleRoles.reduce((result, role) => {
  result[role] = protectedMenuLinks.filter((link) => link.roles.includes(role)).map((link) => link.path);
  return result;
}, {} as RoutesByRole);
