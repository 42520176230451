import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { IQuoteDetail } from './quote.interface';
import { AppDetails, DetailsConfig } from '@components/AppDetails';

type Props = { quote: IQuoteDetail };

export const QuoteAnnualSupportCharge: FC<Props> = ({ quote }) => {
  const config: DetailsConfig<IQuoteDetail['annualSupportCharge']> = {
    exVat: { label: 'Net Cost (excluding VAT)' },
    inclVat: { label: 'Gross Cost (including VAT)' },
  };
  if (!quote.annualSupportCharge) {
    return null;
  }
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" sx={{ flexGrow: 1 }}>
        Annual Support Charge
      </Typography>
      <AppDetails model={quote.annualSupportCharge} config={config} />
    </Box>
  );
};
