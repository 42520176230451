import { createEnumOptions } from '@utils/enum/create-enum.factory';

export const QuoteStatuses = {
  Draft: 0,
  Completed: 1,
  Accepted: 2,
  SO_Generated: 3,
  Billed: 4,
} as const;

type Keys = keyof typeof QuoteStatuses;

export type QuoteStatusType = (typeof QuoteStatuses)[Keys];

export const QuoteStatusOptions = createEnumOptions(QuoteStatuses);
