import React from 'react';
import { IQuoteDetail } from './quote.interface';
import { Box, Stack, Typography } from '@mui/material';
import { IJustificationTextPart } from './justification-text.interface';

type Props = {
  quote: IQuoteDetail;
};

export const QuoteRecommendationTexts: React.FC<Props> = ({ quote }) => {
  if (quote.justificationTexts.length === 0) {
    return null;
  }
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" component="h2">
        Justification Texts
      </Typography>
      {quote.justificationTexts.map(({ productName, texts }, index) => (
        <Stack direction="row" key={`quote-recommendation-text-${index}`} sx={{ mt: 1 }}>
          <Typography variant="body1" sx={{ fontWeight: 'bold', minWidth: '170px' }}>
            {productName}
          </Typography>
          <Typography variant="body1" sx={{ flexGrow: 1, ml: 1, whiteSpace: 'pre-line' }}>
            {printTexts(texts)}
          </Typography>
        </Stack>
      ))}
    </Box>
  );
};

function printTexts(texts: IJustificationTextPart[]): string {
  return texts.map(({ text, title }) => `[${title}] ${text}`).join('\n\n');
}
