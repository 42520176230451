import { useAppDispatch } from '@store/use-app-dispatch';
import { SessionsActions } from '../sessions.slice';
import { useRefreshSessionElectronicInvoiceMutation } from '@api/api-slice';
import { isErrorResponse } from '@api/error-response.interface';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';

type HookResult = {
  refreshSessionElectronicInvoice: (id: number) => Promise<void>;
} & RequestStatusFlags;

export function useRefreshSessionElectronicInvoice(): HookResult {
  const [refreshMutation, flags] = useRefreshSessionElectronicInvoiceMutation();
  const dispatch = useAppDispatch();

  const refreshSessionElectronicInvoice = async (id: number): Promise<void> => {
    const electronicInvoice = await refreshMutation(id).unwrap();
    if (!isErrorResponse(electronicInvoice)) {
      dispatch(SessionsActions.updateSession({ id, changes: { electronicInvoice } }));
    }
  };

  return { refreshSessionElectronicInvoice, ...flags };
}
