import { createEnumOptions } from '@utils/enum/create-enum.factory';

export const Systems = {
  Windows: 1,
  MacOS: 2,
  Other: 3,
} as const;

type Keys = keyof typeof Systems;

export type SystemType = (typeof Systems)[Keys];

export const SystemOptions = createEnumOptions(Systems);
