import React from 'react';
import { Box, Typography } from '@mui/material';
import { ActionRecords } from '@features/action-records/ActionRecords';
import { RecordModelNames } from '@features/action-records/record-model-name.type';
import { HookResult, useProgressRecords } from '@features/action-records/use-progress-records';

type Props = { id: number };

export const ProductProductRecords: React.FC<Props> = ({ id }) => {
  const useEntries = (params: URLSearchParams, skip: number, count: number): HookResult =>
    useProgressRecords(RecordModelNames.Product, id, count, skip);

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" sx={{ flexGrow: 1 }}>
        Product Progress Records
      </Typography>
      <ActionRecords useEntries={useEntries} />
    </Box>
  );
};
