import { Serializers } from '@utils/forms/serializers';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';

export type FormModel = {
  name: string;
  organisationIds: number[];
  disabilityTypeIds: number[];
};

export type ApiModel = {
  name: string | null;
  organisationIds: number[];
  disabilityTypeIds: number[];
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  name: { type: 'transportable', initial: '' },
  organisationIds: { type: 'backend-label', optionKey: 'organisationOptions', multiple: true, initial: [] },
  disabilityTypeIds: { type: 'backend-label', optionKey: 'disabilityTypeOptions', multiple: true, initial: [] },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  name: Serializers.stringNullable,
  organisationIds: Serializers.array,
  disabilityTypeIds: Serializers.array,
});
