import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Chip,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppTitle } from '@hooks/use-app-title';
import { RouteParamsType } from '@routes/route-params.type';
import { useCustomer } from './use-customer';
import { SaveCustomerForm } from './save-customer-form/SaveCustomerForm';
import { CopyCustomerInfo } from './CopyCustomerInfo';
import { CustomerOrders } from './CustomerOrders';
import { CreateOrResetAccount } from './create-or-reset-account/CreateOrResetAccount';
import { SuspendOrResumeAccount } from './suspend-or-resume-account/SuspendOrResumeAccount';

export const CustomerDetails: React.FC = () => {
  const { customer, isLoading: isCustomerLoading } = useCustomer();
  const { orderIdString } = useParams<RouteParamsType>();

  const title = customer ? `${customer.firstName} ${customer.lastName}` : 'Customer';
  useAppTitle(title);

  if (isCustomerLoading) {
    return <CircularProgress sx={{ margin: 'auto' }} />;
  }

  if (!customer) {
    return <Alert severity="warning">Customer not found.</Alert>;
  }

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            height: '80px',
            minHeight: '80px',
            userSelect: 'text',
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
            <Typography variant="h3">
              {customer.id}/{orderIdString} {customer.firstName} {customer.lastName}
            </Typography>
            <Box>
              <Chip
                label={customer.hasAccount ? 'Account' : 'No Account'}
                variant="outlined"
                color={customer.hasAccount ? 'primary' : 'default'}
              />
            </Box>
            {customer.extraMile && (
              <Box>
                <Chip label="Extra Mile" variant="outlined" color="primary" />
              </Box>
            )}
            <CopyCustomerInfo customer={customer} />
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: 'var(--color-gray)' }}>
          {customer.isAccountSuspended && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              The account is suspended
            </Alert>
          )}
          <SaveCustomerForm
            customer={customer}
            ActionSlot={
              <>
                <CreateOrResetAccount customer={customer} />
                <SuspendOrResumeAccount customerId={customer.id} isInitiallySuspended={customer.isAccountSuspended} />
              </>
            }
          />
        </AccordionDetails>
      </Accordion>
      <CustomerOrders ordersTabs={customer.ordersTabs} />
    </>
  );
};
