import { IBackendLabelOption } from '@features/backend-label/backend-label.type';
import { words } from 'lodash';

export function createEnumOptions<T extends string | number = string>(
  value: Record<string, T>,
): IBackendLabelOption<T>[] {
  return Object.entries(value).map(([key, value]) => ({
    value,
    label: words(key).join(' '),
  }));
}
