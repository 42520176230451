import { appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { validatePagination } from '@features/pagination/spec/pagination.spec';
import { TransportableModel } from '@models/transportable.type';
import { IEquipmentInvoiceSummary } from '../equipment-invoice-summary.interface';
import { validateFilterEquipmentInvoiceSummary } from './spec/validate-equipment-invoice-summaries.spec';
import { ApiModel } from './form-config';
import { FinanceParams } from '@features/bulk-invoicing/finance-params.type';

export type QueryParams = TransportableModel<ApiModel & PaginationArgs & FinanceParams>;
export type EquipmentInvoiceSummariesResponse = IPaginable<IEquipmentInvoiceSummary>;
export const buildGetEquipmentInvoiceSummaries = appQueryFactory<QueryParams, EquipmentInvoiceSummariesResponse>({
  query: (params) => ({
    url: EndpointUrlBuilder.getEquipmentInvoiceSummaries(),
    method: 'GET',
    params,
  }),
  transformResponse(response: EquipmentInvoiceSummariesResponse) {
    validateFilterEquipmentInvoiceSummary(response, EndpointUrlBuilder.getEquipmentInvoiceSummaries());
    validatePagination(response, EndpointUrlBuilder.getEquipmentInvoiceSummaries());
    return response;
  },
});
