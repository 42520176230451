import { useUpdateSalesOrderLinesMutation } from '@api/api-slice';
import { isErrorResponse } from '@api/error-response.interface';
import { OrdersActions } from '@features/order/orders.slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';

type HookResult = {
  updateSalesOrderLines: (id: number) => Promise<void>;
} & RequestStatusFlags;

export function useUpdateSalesOrderLines(): HookResult {
  const [mutation, flags] = useUpdateSalesOrderLinesMutation();
  const dispatch = useAppDispatch();

  async function updateSalesOrderLines(id: number): Promise<void> {
    const changes = await mutation(id).unwrap();

    if (!isErrorResponse(changes)) {
      dispatch(OrdersActions.updateOrder({ id, changes }));
    }
  }

  return { updateSalesOrderLines, ...flags };
}
