import React from 'react';
import { AppObjectForEach } from './AppObjectForEach';
import { pick } from 'lodash';

type Column<K = string> = { field: K; headerName?: string };

type Props<T> = {
  columns: Column[];
  rows: T[];
};

export const StyledTable: <T extends object>(props: Props<T>) => JSX.Element = ({ columns, rows }) => {
  const allowedKeys = columns.map(({ field }) => field);
  const data = rows.map((item) => pick(item, allowedKeys));
  return (
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr style={{ backgroundColor: 'rgb(24, 53, 97)', color: 'white' }}>
          {columns.map((col) => (
            <th style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }} key={col.field}>
              {col.headerName}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <AppObjectForEach
              model={item}
              renderItem={(key, value): JSX.Element => (
                <td key={key.toString()} style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                  {value !== null ? String(value) : ''}
                </td>
              )}
            />
          </tr>
        ))}
      </tbody>
    </table>
  );
};
