import { createEnumOptions } from '@utils/enum/create-enum.factory';

export const RecordModelNames = {
  Assessor: 'assessor',
  Order: 'order',
  Product: 'product',
  Quote: 'quote',
  TheftClaimForm: 'repair_and_replace_report',
} as const;

type Keys = keyof typeof RecordModelNames;

export type RecordModelNameType = (typeof RecordModelNames)[Keys];

export const RecordModelNameOptions = createEnumOptions(RecordModelNames);
