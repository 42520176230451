import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import { Moment } from 'moment-timezone';
import { DateTimeFormat } from '@utils/dates/formats';

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label: string;
  required?: boolean;
} & DateTimePickerProps<Moment>;

export const ControlledDateTimePicker: <T extends FieldValues>(props: Props<T>) => JSX.Element = ({
  control,
  name,
  label,
  required = false,
  ...dateTimePickerProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState }): JSX.Element => (
        <DateTimePicker
          label={label}
          value={value}
          format={DateTimeFormat}
          onChange={onChange}
          slotProps={{
            textField: {
              error: Boolean(fieldState.error),
              required,
              fullWidth: true,
              helperText: fieldState.error?.message,
            },
          }}
          {...dateTimePickerProps}
        />
      )}
    />
  );
};
