import { IEquipmentInvoiceSummary } from '@features/bulk-invoicing/equipment/equipment-invoice-summary.interface';
import { createDraftSafeSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

const equipmentInvoiceSummaryAdapter = createEntityAdapter<IEquipmentInvoiceSummary>({
  selectId: (entry) => entry.id,
});

const equipmentInvoiceSummarySlice = createSlice({
  name: 'Equipment Invoice Summary',
  initialState: equipmentInvoiceSummaryAdapter.getInitialState(),
  reducers: {
    setAll: equipmentInvoiceSummaryAdapter.setAll,
    updateOne: equipmentInvoiceSummaryAdapter.updateOne,
    updateMany: equipmentInvoiceSummaryAdapter.updateMany,
  },
});

export const EquipmentInvoiceSummaryActions = equipmentInvoiceSummarySlice.actions;
export const equipmentInvoiceSummaryReducer = equipmentInvoiceSummarySlice.reducer;
export type EquipmentInvoiceSummaryActionsType = typeof EquipmentInvoiceSummaryActions;

const { selectAll } = equipmentInvoiceSummaryAdapter.getSelectors(
  (state: RootState) => state.equipmentInvoiceSummaries,
);

export const selectAllEquipmentSummaries = selectAll;

export const selectEquipmentSummariesByIds = createDraftSafeSelector(
  selectAll,
  (_: RootState, ids: number[]) => ids,
  (all, ids) => all.filter((item) => ids.includes(item.id)),
);
