import React from 'react';
import { startCase } from 'lodash';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { IWeekTimeSlots } from '@models/week-time-slots.interface';

type Props = {
  slots: IWeekTimeSlots;
  day: keyof IWeekTimeSlots;
  onSlotChange: (day: keyof IWeekTimeSlots, slotId: number) => unknown;
  onDayChange: (day: keyof IWeekTimeSlots) => unknown;
};

export const Week: React.FC<Props> = ({ slots, day, onSlotChange, onDayChange }) => {
  const { crmFreeSlotOptions, isLoading } = useAppBackendLabels(['crmFreeSlotOptions']);

  const isFull = slots[day].length === crmFreeSlotOptions.length;
  const isEmpty = slots[day].length === 0;
  const isIndeterminate = !isEmpty && !isFull;

  return (
    <Box>
      <FormControlLabel
        label={startCase(day)}
        control={
          <Checkbox checked={isFull} indeterminate={isIndeterminate} onChange={(): unknown => onDayChange(day)} />
        }
      />
      {!isLoading && (
        <Box>
          {crmFreeSlotOptions.map((option) => (
            <FormControlLabel
              key={option.value?.toString()}
              sx={{ mt: -0.5, mb: -0.5, whiteSpace: 'nowrap' }}
              label={option.label}
              control={
                <Checkbox
                  size="small"
                  checked={slots[day].includes(option.value)}
                  onChange={(): unknown => onSlotChange(day, option.value)}
                />
              }
            />
          ))}
        </Box>
      )}
    </Box>
  );
};
