import { Moment } from 'moment-timezone';
import {
  BaseOrderApiModel,
  BaseOrderFormModel,
  baseOrderSanitizeMap,
  baseOrderSerializeMap,
} from '@features/order/base/form-config';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { IOrderNmh } from './order-nmh.interface';

export type FormModel = BaseOrderFormModel &
  Pick<IOrderNmh, 'levelOfStudy' | 'intensity' | 'organisationId'> & {
    courseEndDate: Moment | null;
    equipmentDeliveryDate: Moment | null;
  };

export type ApiModel = BaseOrderApiModel &
  Pick<IOrderNmh, 'levelOfStudy' | 'intensity' | 'courseEndDate' | 'organisationId' | 'equipmentDeliveryDate'>;

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  ...baseOrderSanitizeMap,
  levelOfStudy: { type: 'backend-label', optionKey: 'levelOfStudyOptions', initial: null },
  intensity: { type: 'backend-label', optionKey: 'intensityOptions', initial: null },
  courseEndDate: { type: 'date', initial: null },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', initial: null },
  equipmentDeliveryDate: { type: 'date', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  ...baseOrderSerializeMap,
  levelOfStudy: Serializers.backendValue,
  intensity: Serializers.backendValue,
  courseEndDate: Serializers.date,
  organisationId: Serializers.backendValue,
  equipmentDeliveryDate: Serializers.date,
});
