import React from 'react';
import { useProduct } from './use-product';
import { Alert, CircularProgress } from '@mui/material';
import { AppPaper } from '@components/AppPaper';
import { useAppTitle } from '@hooks/use-app-title';
import { ProductDetails } from './product-details/ProductDetails';
import { Justification } from './justification/Justification';
import { ProductProductRecords } from './ProductProductRecords';

export const ProductPage: React.FC = () => {
  const { product, isLoading } = useProduct();

  const title = product ? product.productName : 'Product';
  useAppTitle(title);

  if (isLoading) {
    return <CircularProgress sx={{ margin: 'auto' }} />;
  }

  if (!product) {
    return <Alert severity="warning">Product not found.</Alert>;
  }

  return (
    <AppPaper>
      <ProductDetails product={product} />
      <Justification product={product} />
      <ProductProductRecords id={product.id} />
    </AppPaper>
  );
};
