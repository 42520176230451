import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Alert, Stack, TextField, Typography } from '@mui/material';
import { useAppTitle } from '@hooks/use-app-title';
import { useVerifyEmail } from './use-verify-email';
import { AuthState } from './Reset';
import { AppLoadingButton } from '@components/AppLoadingButton';

type Props = {
  handleNext: (step: number) => void;
};

export const VerifyEmail: React.FC<Props> = ({ handleNext }) => {
  const { handleVerifyEmail, isLoading, errorMessage, isSuccess } = useVerifyEmail();
  const [email, setEmail] = useState('');
  useAppTitle('Verify Email');

  useEffect(() => {
    if (isSuccess) {
      handleNext(AuthState.ConfirmPassword);
    }
  }, [isSuccess, handleNext]);

  const onSubmit = async (event: SyntheticEvent): Promise<void> => {
    event.preventDefault();
    await handleVerifyEmail({ account: { email } });
  };

  const isButtonDisabled = email.trim() === '';

  return (
    <form onSubmit={onSubmit}>
      <Stack direction="column" spacing={2} sx={{ marginTop: 3 }}>
        <Typography variant="subtitle2" color="textSecondary">
          We will send a confirmation code to your email to reset your current password
        </Typography>
        <TextField
          label="Email"
          variant="outlined"
          name="email"
          type="email"
          value={email}
          onChange={(event): void => setEmail(event.target.value)}
          required
        />
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <AppLoadingButton
          variant="contained"
          size="large"
          type="submit"
          disabled={isButtonDisabled}
          isLoading={isLoading}
          fullWidth
        >
          Submit
        </AppLoadingButton>
      </Stack>
    </form>
  );
};
