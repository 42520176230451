import React, { FunctionComponent } from 'react';
import { Grid, Link, Typography } from '@mui/material';
import { Routes } from '@routes/routes';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { IOrderGroupByOrganisation } from './api';

type Props = {
  groups: IOrderGroupByOrganisation[];
};

export const SelectedOrders: FunctionComponent<Props> = ({ groups }) => {
  return (
    <Grid container spacing={2}>
      {groups.map((organisationGroup) => {
        return (
          <Grid key={organisationGroup.organisationId} item xs={12} md={6} lg={4}>
            <Typography variant="h6" component="div">
              <BackendLabel
                value={organisationGroup.organisationId}
                optionKey="organisationOptions"
                fallbackText="No organisation"
                isPlainText
              />
            </Typography>

            {organisationGroup.groupedByType.map((typeGroup) => (
              <div key={typeGroup.orderTypeId}>
                <Typography component="div">
                  <BackendLabel value={typeGroup.orderTypeId} optionKey="orderTypeNameOptions" /> (
                  {typeGroup.orders.length})
                </Typography>

                {typeGroup.orders.map((order) => (
                  <Link
                    href={`${Routes.orderSummaries}/${order.customerId}/${order.id}`}
                    key={order.id}
                    sx={{ display: 'block', mt: 1 }}
                  >
                    {order.id} — {order.customerFullName}
                  </Link>
                ))}
              </div>
            ))}
          </Grid>
        );
      })}
    </Grid>
  );
};
