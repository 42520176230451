import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { IHep } from './hep.interface';
import { useParams } from 'react-router-dom';
import { RouteParamsType } from '@routes/route-params.type';
import { useGetHepQuery } from '@api/api-slice';

type HookResult = RequestStatusFlags & {
  hep: IHep | null;
};

export function useHep(): HookResult {
  const { hepIdString } = useParams<RouteParamsType>();
  const hepId = parseInt(hepIdString as string, 10);
  const { data, ...flags } = useGetHepQuery(hepId);

  return {
    hep: data?.hep ?? null,
    ...flags,
  };
}
