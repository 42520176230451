import { RequestStatusFlags as BaseRequestStatusFlags } from '@reduxjs/toolkit/dist/query/core/apiState';

export type RequestStatusFlags = Pick<
  BaseRequestStatusFlags,
  'isUninitialized' | 'isLoading' | 'isSuccess' | 'isError'
> & {
  // TODO_REF Временное решение, мыф всегда полагались на флаг isLoading, но он показывает загрузку только первого фетча
  isFetching?: boolean;
};

export function mergeRequestStatusFlags(...flags: RequestStatusFlags[]): RequestStatusFlags {
  return {
    isUninitialized: flags.every((flag) => flag.isUninitialized),
    isLoading: flags.some((flag) => flag.isLoading || flag.isFetching),
    isFetching: flags.some((flag) => flag.isLoading || flag.isFetching),
    isSuccess: flags.every((flag) => flag.isSuccess) && !flags.some((flag) => flag.isLoading || flag.isUninitialized),
    isError: flags.some((flag) => flag.isError) && !flags.some((flag) => flag.isLoading || flag.isUninitialized),
  };
}
