import React from 'react';
import { Box, Button } from '@mui/material';
import { useToggle } from '@hooks/use-toggle';

type Props = {
  AutomaticComponent: JSX.Element;
  ManualComponent: JSX.Element;
  isInitiallyManual?: boolean;
  isLocked?: boolean;
};

/**
 * A component that allows users to toggle between automatic and manual booking components.
 *
 * `isInitiallyManual = true` controls which component renders by default.
 *
 * If `isLocked = false`, switch button is hidden.
 */
export const SwitchBooking: React.FC<Props> = ({
  AutomaticComponent,
  ManualComponent,
  isInitiallyManual = true,
  isLocked = false,
}) => {
  const [isManual, toggleIsManual] = useToggle(isInitiallyManual);
  const buttonLabel = `Switch to ${isManual ? 'Automatic' : 'Manual'} Booking`;
  return (
    <Box>
      {isManual ? ManualComponent : AutomaticComponent}
      {!isLocked && (
        <Button onClick={toggleIsManual} fullWidth sx={{ mt: 1 }}>
          {buttonLabel}
        </Button>
      )}
    </Box>
  );
};
