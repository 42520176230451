import React, { FC } from 'react';
import { IOrderNeedsAssessment } from '@features/order/needs-assessment/order-needs-assessment.interface';
import { Box, Stack, Typography } from '@mui/material';
import { useSendReport } from '../use-send-report';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { ICustomer } from '@features/customer/customer.interface';
import { StudentInfo } from './StudentInfo';
import { ReportRecipient } from '../report-recipient';

type Props = {
  order: IOrderNeedsAssessment;
  customer: ICustomer | null;
};

export const SendToStudentReport: FC<Props> = ({ order, customer }) => {
  const { sendReport, isLoading } = useSendReport();

  const handleSubmit = (): void => {
    sendReport(ReportRecipient.student);
  };

  return (
    <Box>
      <Typography variant="h4">Send Draft Needs Assessment Report to Student</Typography>
      <Stack direction="column" spacing={2} sx={{ mt: 1 }}>
        <StudentInfo order={order} customer={customer} />
        <AppLoadingButton
          fullWidth
          onClick={(): void => handleSubmit()}
          disabled={!order.isReviewRequested}
          isLoading={isLoading}
        >
          Send Draft Report to Student
        </AppLoadingButton>
      </Stack>
    </Box>
  );
};
