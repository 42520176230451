import { StructError, assert } from 'superstruct';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { OrderStruct } from './order-struct.spec';
import { IOrder } from '../order.interface';

export function validateOrder(order: IOrder, url: string): void {
  try {
    assert(order, OrderStruct);
  } catch (error) {
    for (const failure of (error as StructError).failures()) {
      handleFailure(failure, url);
    }
  }
}
