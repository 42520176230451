import React, { useMemo } from 'react';
import { Control, Controller, FieldValues, Path, UseControllerProps } from 'react-hook-form';
import { Autocomplete, ListItem, TextField } from '@mui/material';
import { IBackendLabelOption } from '@features/backend-label/backend-label.type';
import { useOrderStatusOptions } from './use-order-status-options';
import { NullOption } from '@utils/null.option';

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label: string;
  options: IBackendLabelOption<string | number | boolean | null>[];
  required?: boolean;
  isLoading?: boolean;
  multiple?: boolean;
  disableClearable?: boolean;
  noOptionsText?: string;
  isStatus?: boolean;
  isNullAvailable?: boolean;
  disabled?: boolean;
  rules?: UseControllerProps<T>['rules'];
};

export const ControlledAutocomplete: <T extends FieldValues>(props: Props<T>) => JSX.Element = ({
  control,
  name,
  label,
  options: ungroupedOptions,
  required,
  rules,
  isLoading = false,
  multiple = false,
  disableClearable = false,
  noOptionsText,
  isStatus = false,
  isNullAvailable = false,
  disabled = false,
}) => {
  const { options: statusOptions, groupBy } = useOrderStatusOptions(ungroupedOptions, isStatus);
  const options = useMemo(
    () => (isNullAvailable ? [NullOption, ...statusOptions] : statusOptions),
    [isNullAvailable, statusOptions],
  );
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value }, fieldState }): JSX.Element =>
        multiple ? (
          <Autocomplete
            fullWidth
            multiple
            value={options.filter((option) => value.includes(option.value))}
            onChange={(_, newValue): void => onChange(newValue.map((option) => option.value))}
            options={options}
            groupBy={groupBy}
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                required={required}
                label={label}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
              />
            )}
            renderOption={(props, option): JSX.Element => (
              <ListItem {...props} key={option.value?.toString()}>
                {option.label}
              </ListItem>
            )}
            loading={isLoading}
            disableClearable={disableClearable}
            noOptionsText={noOptionsText}
            size="small"
            disabled={disabled}
          />
        ) : (
          <Autocomplete
            fullWidth
            value={options.find((option) => option.value === value) ?? null}
            onChange={(_, newValue): void => onChange(newValue?.value ?? null)}
            options={options}
            groupBy={groupBy}
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                required={required}
                label={label}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
              />
            )}
            renderOption={(props, option): JSX.Element => (
              <ListItem {...props} key={option.value?.toString()}>
                {option.label}
              </ListItem>
            )}
            loading={isLoading}
            disableClearable={disableClearable}
            noOptionsText={noOptionsText}
            size="small"
            disabled={disabled}
          />
        )
      }
    />
  );
};
