import React from 'react';
import { TableCell } from '@mui/material';
import { IBackendLabelOption } from '@features/backend-label/backend-label.type';

type Props = {
  slots: number[];
  options: IBackendLabelOption<number>[];
};

export const DaySlots: React.FC<Props> = ({ slots, options }) => {
  return (
    <>
      {options.map(({ value }, index) => (
        <TableCell
          key={value}
          align="center"
          sx={{ borderLeft: '1px solid' }}
          className={index === 0 ? 'MuiTableCell-compact' : 'MuiTableCell-compact MuiTableCell-weak'}
        >
          {slots.includes(value) ? '+' : null}
        </TableCell>
      ))}
    </>
  );
};
