import { IBackendLabelOption, IStatusLabelOption } from '@features/backend-label/backend-label.type';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';

type StatusOption = IStatusLabelOption<number> & {
  type: string | null;
};

function enhanceWithTypeLabel(
  options: IStatusLabelOption<number>[],
  typeOptions: IBackendLabelOption<number>[],
): StatusOption[] {
  return options
    .map((option) => ({
      ...option,
      // Assuming that there is always
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      type: typeOptions.find(({ value }) => option.orderTypeId === value)!.label,
    }))
    .sort((a, b) => -b.type.localeCompare(a.type));
}

type HookResult = {
  options: IBackendLabelOption<string | number | boolean | null>[];
  groupBy?: (option: IBackendLabelOption<string | number | boolean | null>) => string;
};

export function useOrderStatusOptions(
  options: IBackendLabelOption<string | number | boolean | null>[] | IStatusLabelOption<number>[],
  isStatus: boolean,
): HookResult {
  const { orderTypeNameOptions } = useAppBackendLabels(['orderTypeNameOptions']);

  if (isStatus) {
    return {
      options: enhanceWithTypeLabel(options as IStatusLabelOption<number>[], orderTypeNameOptions),
      groupBy: (option: IBackendLabelOption<string | number | boolean | null>): string =>
        (option as StatusOption).type ?? '',
    };
  }

  return { options };
}
