import { StructError, assert } from 'superstruct';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { SessionStruct } from '../filter-sessions-form/spec/session-struct.spec';
import { ISession } from '../session.interface';

export function validateSessions(sessions: ISession[], url: string): void {
  for (const entry of sessions) {
    try {
      assert(entry, SessionStruct);
    } catch (error) {
      for (const failure of (error as StructError).failures()) {
        handleFailure(failure, url);
      }
    }
  }
}
