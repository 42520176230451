import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Alert, CircularProgress, Link, Stack, Typography } from '@mui/material';
import { AppDetails, DetailsConfig } from '@components/AppDetails';
import { AppPaper } from '@components/AppPaper';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { useAppTitle } from '@hooks/use-app-title';
import { Routes } from '@routes/routes';
import { getPartialByKeys } from '@utils/get-partial-by-keys';
import { useAssessmentCentre } from './use-assessment-centre';

export const AssessmentCentrePage: FC = () => {
  const { assessmentCentre, isLoading } = useAssessmentCentre();

  const title = assessmentCentre?.name ?? 'Assessment Centres';
  useAppTitle(title);

  if (isLoading) {
    return <CircularProgress sx={{ margin: 'auto' }} />;
  }

  if (!assessmentCentre) {
    return <Alert severity="warning">Assessment centre not found.</Alert>;
  }

  const assessmentCentreDetails = getPartialByKeys(assessmentCentre, [
    'name',
    'city',
    'postcode',
    'addressLine1',
    'addressLine2',
    'addressLine3',
    'description',
    'googleMapsLink',
    'assessorIds',
  ]);

  const config: DetailsConfig<typeof assessmentCentreDetails> = {
    name: { label: 'Name' },
    city: { label: 'City' },
    postcode: { label: 'Postcode' },
    addressLine1: { label: 'Address Line 1' },
    addressLine2: { label: 'Address Line 2' },
    addressLine3: { label: 'Address Line 3' },
    googleMapsLink: { label: 'Google Maps Link' },
    description: { label: 'Description' },
    assessorIds: {
      label: 'Assessors',
      render: (value) => {
        return (
          <Stack direction="row" spacing={1}>
            {value.map((item) => (
              <Link key={item} component={NavLink} to={`${Routes.assessors}/${item}`}>
                <BackendLabel value={item} optionKey="assessorOptions" isPlainText />
              </Link>
            ))}
          </Stack>
        );
      },
    },
  };

  return (
    <AppPaper>
      <Typography variant="h4" component="h1">
        Assessment centre Details
      </Typography>
      <AppDetails model={assessmentCentreDetails} config={config} />
    </AppPaper>
  );
};
