import moment from 'moment-timezone';
import { DateTimeFormat, TimeFormat } from './formats';
import { LondonTimezone } from './london-timezone';

/**
 * @param date ISODateTime string
 * @param duration number, hours, can be float
 */
export function formatDateTimeAndDuration(date: string, duration: number): string {
  const startMoment = moment(date).tz(LondonTimezone);
  const endMoment = moment(date).tz(LondonTimezone).add(duration, 'hours');

  return `${startMoment.format(DateTimeFormat)}–${endMoment.format(TimeFormat)}`;
}
