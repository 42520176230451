import React, { FC } from 'react';
import { FeedbackDetails } from '../product.interface';

export const FeedbackDetail: FC<FeedbackDetails> = (feedbackDetails) => {
  return (
    <ul style={{ listStyleType: 'none', padding: 0 }}>
      <li>1 - {feedbackDetails.usersRatedOne} users</li>
      <li>2 - {feedbackDetails.usersRatedTwo} users</li>
      <li>3 - {feedbackDetails.usersRatedThree} users</li>
      <li>4 - {feedbackDetails.usersRatedFour} users</li>
      <li>5 - {feedbackDetails.usersRatedFive} users</li>
    </ul>
  );
};
