import React, { FC } from 'react';
import { IAnnualSupportCharge } from './annual-support-charge.interface';
import { IOrderAnnualSupportCharge } from '../annual-support-charge-order.interface';
import { useSaveOrderElectronicInvoice } from '@features/order/shared/order-electronic-invoice/use-save-order-electronic-invoice';
import { useRefreshOrderElectronicInvoice } from '@features/order/shared/order-electronic-invoice/use-refresh-order-electronic-invoice';
import { useSendOrderElectronicInvoice } from '@features/order/shared/order-electronic-invoice/use-send-order-electronic-invoice';
import { ElectronicInvoice } from '@features/electronic-invoice/ElectronicInvoice';

type Props = {
  order: IOrderAnnualSupportCharge;
  annualSupportCharge: IAnnualSupportCharge;
};

export const AnnualSupportChargeElectronicInvoice: FC<Props> = ({ order, annualSupportCharge }) => {
  const { orderClass } = order;
  const { id } = annualSupportCharge;
  const { saveOrderElectronicInvoice, isLoading: isLoadingSave } = useSaveOrderElectronicInvoice(id);
  const { refreshOrderElectronicInvoice, isLoading: isLoadingRefresh } = useRefreshOrderElectronicInvoice(orderClass);
  const { sendOrderElectronicInvoice, isLoading: isLoadingSend } = useSendOrderElectronicInvoice(orderClass);

  async function handleSave(electronicInvoice: string | null): Promise<void> {
    if (electronicInvoice) {
      saveOrderElectronicInvoice(electronicInvoice);
    }
  }

  async function handleRefresh(): Promise<void> {
    refreshOrderElectronicInvoice(id);
  }

  async function handleSend(): Promise<void> {
    sendOrderElectronicInvoice(id);
  }

  const isLoading = isLoadingSave || isLoadingRefresh || isLoadingSend;

  return (
    <ElectronicInvoice
      invoice={annualSupportCharge.invoice}
      invoiceLogs={annualSupportCharge.electronicInvoiceLogs}
      isLoading={isLoading}
      buttonSize="small"
      handleSave={handleSave}
      handleRefresh={handleRefresh}
      handleSend={handleSend}
    />
  );
};
