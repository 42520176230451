import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { AppDetails, DetailsConfig } from '@components/AppDetails';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { BooleanChip } from '@utils/render-boolean';
import { ColorByTrafficLight } from '../color-by-traffic-light';
import { IProductWithCondition } from '../product.interface';
import { getPartialByKeys } from '@utils/get-partial-by-keys';
import { AppModal } from '@components/AppModal';
import { SaveProductForm } from './SaveProductForm';
import { useToggle } from '@hooks/use-toggle';
import { UserRoles } from '@features/user/user-role.type';
import { RestrictRole } from '@features/auth/RestrictRole';
import { FeedbackDetail } from './FeedbackDetails';

type Props = {
  product: IProductWithCondition;
};

export const ProductDetails: React.FC<Props> = ({ product }) => {
  const [isModalOpen, toggleIsModalOpen] = useToggle();

  const chipColor = product.trafficLight ? ColorByTrafficLight[product.trafficLight] : undefined;

  const productDetails = getPartialByKeys(product, [
    'productName',
    'productCategoryId',
    'productCode',
    'productCodeAs',
    'productCodeSst',
    'isActive',
    'amountExVat',
    'vatTreatment',
    'feedbackAverage',
    'trafficLight',
    'productDescription',
    'feedbackDetails',
  ]);

  const config: DetailsConfig<typeof productDetails> = {
    productName: { label: 'Product Name', render: (value) => value },
    productCategoryId: {
      label: 'Product Category',
      render: (value) => <BackendLabel value={value} optionKey="productCategory" isPlainText />,
    },
    productCode: { label: 'Product Code', render: (value) => value },
    productCodeAs: { label: 'Product Code AS', render: (value) => value },
    productCodeSst: { label: 'Product Code SST', render: (value) => value },
    isActive: { label: 'Active', render: (value) => <BooleanChip value={value} /> },
    amountExVat: { label: 'Net Amount', render: (value) => value.toString() },
    vatTreatment: { label: 'VAT Treatment', render: (value) => value },
    feedbackAverage: { label: 'Feedback Average', render: (value) => value.toString() },
    feedbackDetails: {
      label: 'Feedback Details',
      render: (value) => FeedbackDetail(value),
    },
    trafficLight: {
      label: 'Traffic Light',
      render: (value) => <BackendLabel value={value} optionKey="productTrafficLightOptions" color={chipColor} />,
    },
    productDescription: { label: 'Description', render: (value) => value },
  };

  return (
    <Box>
      <Stack direction="row">
        <Typography variant="h4" component="h1">
          Product Details
        </Typography>
        <RestrictRole roles={[UserRoles.assessor, UserRoles.qualityAssurance]}>
          <Button sx={{ ml: 'auto' }} variant="outlined" size="small" onClick={toggleIsModalOpen}>
            Edit
          </Button>
        </RestrictRole>
      </Stack>
      <AppDetails model={productDetails} config={config} />

      <AppModal open={isModalOpen} onClose={toggleIsModalOpen} title="Edit Product">
        <SaveProductForm product={product} onClose={toggleIsModalOpen} />
      </AppModal>
    </Box>
  );
};
