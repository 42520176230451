import React, { SyntheticEvent } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { AppModal } from '@components/AppModal';
import { ICustomer } from '@features/customer/customer.interface';
import { useRole } from '@features/user/use-role';
import { UserRoles } from '@features/user/user-role.type';
import { useToggle } from '@hooks/use-toggle';
import { useCreateOrResetAccount } from './use-create-or-reset-acoount';
import { AppLoadingButton } from '@components/AppLoadingButton';

type Props = {
  customer: ICustomer;
};

export const CreateOrResetAccount: React.FC<Props> = ({ customer }) => {
  const [isModalOpen, toggleIsModalOpen] = useToggle();
  const { submitHandler, isLoading } = useCreateOrResetAccount();
  const role = useRole();

  const isAccessibleRole = role === UserRoles.operator || role === UserRoles.financeManager;

  if (!isAccessibleRole) {
    return null;
  }
  const actionLabel = customer.hasAccount ? 'Reset' : 'Create';
  const buttonLabel = `${actionLabel} Account`;
  const modalLabel = `Account ${actionLabel}`;
  const color = customer.hasAccount ? 'warning' : 'primary';

  const handleClick = (event: SyntheticEvent): void => {
    event.stopPropagation();
    toggleIsModalOpen();
  };

  const handleCancel = async (event: SyntheticEvent): Promise<void> => {
    event.stopPropagation();
    toggleIsModalOpen();
  };

  const handleSubmit = async (event: SyntheticEvent): Promise<void> => {
    event.stopPropagation();
    await submitHandler(customer.id);
    toggleIsModalOpen();
  };

  return (
    <>
      <Button onClick={handleClick} variant="text" color="warning" size="large">
        {buttonLabel}
      </Button>

      <AppModal open={isModalOpen} onClose={toggleIsModalOpen} title={modalLabel} color={color}>
        <>
          <Typography variant="h6" gutterBottom>
            You are about to send the customer an email with a new password and login link to the App.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Please confirm to continue.
          </Typography>

          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
            <AppLoadingButton variant="contained" onClick={handleSubmit} color={color} isLoading={isLoading}>
              {actionLabel}
            </AppLoadingButton>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
          </Stack>
        </>
      </AppModal>
    </>
  );
};
