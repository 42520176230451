import { useRegenerateTimesheetMutation } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { SessionsActions } from '../sessions.slice';
import { ITimesheetInfo } from './timesheet-info.interface';

type HookResult = {
  handleRegenerateTimesheet: (sessionId: number, timesheet: ITimesheetInfo) => Promise<void>;
} & RequestStatusFlags;

export function useRegenerateTimesheet(): HookResult {
  const [mutation, flags] = useRegenerateTimesheetMutation();
  const dispatch = useAppDispatch();

  const handleRegenerateTimesheet = async (sessionId: number, timesheet: ITimesheetInfo): Promise<void> => {
    const downloadPath = await mutation(sessionId).unwrap();

    dispatch(
      SessionsActions.updateSession({
        id: sessionId,
        changes: {
          timesheetInfo: { ...timesheet, downloadPath },
        },
      }),
    );
  };

  return { handleRegenerateTimesheet, ...flags };
}
