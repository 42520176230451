import { Describe, StructError, assert, nullable, number, object } from 'superstruct';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { IOrderTypeSummary } from '../order-type-summary.interface';

const OrderTypeSummaryStruct: Describe<IOrderTypeSummary> = object({
  type: nullable(number()),
  in: number(),
  out: number(),
});

export function validateOrderTypeSummary(response: IOrderTypeSummary[], url: string): void {
  try {
    for (const summary of response) {
      assert(summary, OrderTypeSummaryStruct);
    }
  } catch (error) {
    for (const failure of (error as StructError).failures()) {
      handleFailure(failure, url);
    }
  }
}
