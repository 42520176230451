import React, { useState } from 'react';
import { Tab } from '@mui/material';
import { IReport } from '../report.interface';
import { IOrderNeedsAssessment } from '../../order-needs-assessment.interface';
import { SendFinalReport } from './send-final-report/SendFinalReport';
import { SendToStudentReport } from './send-to-student-report/SendToStudentReport';
import { ReSendReport } from './re-send-report/ReSendReport';
import { useAppSelector } from '@store/use-app-selector';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { selectCustomerState } from '@features/customer/customer.slice';
import { TabContext, TabList, TabPanel } from '@mui/lab';

type Props = {
  report: IReport | void;
  order: IOrderNeedsAssessment;
};

const ReportTabs = {
  draftToStudent: 'Draft To Student',
  fundingBodyAndHEP: 'Send Final',
  reSend: 'Re-Send',
} as const;
type Keys = keyof typeof ReportTabs;
type ReportTabsType = (typeof ReportTabs)[Keys];

export const SendReport: React.FC<Props> = ({ report, order }) => {
  const [currentTab, setCurrentTab] = useState<ReportTabsType>(ReportTabs.draftToStudent);
  const backendLabels = useAppBackendLabels(['hepOptions', 'fundingBodyOptions']);
  const customer = useAppSelector(selectCustomerState);

  const hepOption = backendLabels.hepOptions.find((option) => customer?.hepId === option.value);
  const fundingBodyOption = backendLabels.fundingBodyOptions.find((option) => customer?.fundingBodyId === option.value);

  const handleChange = (event: React.SyntheticEvent, newValue: ReportTabsType): void => {
    setCurrentTab(newValue);
  };

  return (
    <TabContext value={currentTab}>
      <TabList onChange={handleChange} sx={{ mb: 1 }}>
        <Tab value={ReportTabs.draftToStudent} label={ReportTabs.draftToStudent} />
        <Tab value={ReportTabs.fundingBodyAndHEP} label={ReportTabs.fundingBodyAndHEP} />
        <Tab value={ReportTabs.reSend} label={ReportTabs.reSend} />
      </TabList>

      <TabPanel value={ReportTabs.draftToStudent} sx={{ p: 0 }}>
        <SendToStudentReport order={order} customer={customer} />
      </TabPanel>
      <TabPanel value={ReportTabs.fundingBodyAndHEP} sx={{ p: 0 }}>
        <SendFinalReport
          order={order}
          report={report}
          hepOption={hepOption}
          fundingBodyOption={fundingBodyOption}
          customer={customer}
        />
      </TabPanel>
      <TabPanel value={ReportTabs.reSend} sx={{ p: 0 }}>
        <ReSendReport hepOption={hepOption} fundingBodyOption={fundingBodyOption} customer={customer} />,
      </TabPanel>
    </TabContext>
  );
};
