import { mapKeys, snakeCase } from 'lodash';

export type SnakeCase<S extends string> = S extends `${infer P1}${infer P2}${infer P3}`
  ? P2 extends Uppercase<P2>
    ? `${Lowercase<P1>}_${Lowercase<P2>}${SnakeCase<P3>}`
    : `${P1}${SnakeCase<`${P2}${P3}`>}`
  : S;

export type ToSnakeCase<T> = {
  [K in keyof T as SnakeCase<string & K>]: T[K];
};

export function modelToSnakeCase<T extends { [key: string]: unknown }>(model: T): ToSnakeCase<T> {
  return mapKeys(model, (_value, key) => snakeCase(key)) as ToSnakeCase<T>;
}
