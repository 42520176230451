import React from 'react';
import { AppPaper } from '@components/AppPaper';
import { useAppTitle } from '@hooks/use-app-title';
import { TutorDetails } from './TutorDetails';
import { TutorFeedback } from './TutorFeedback';

export const TutorPage: React.FC = () => {
  useAppTitle('Tutor');
  return (
    <AppPaper>
      <TutorDetails />
      <TutorFeedback />
    </AppPaper>
  );
};
