import { createEnumOptions } from '@utils/enum/create-enum.factory';

export const StolenEquipmentTypes = {
  Laptop: 'laptop',
  Desktop: 'desktop',
  Printer: 'printer',
  Tablet: 'tablet',
  Other: 'other',
} as const;

type Keys = keyof typeof StolenEquipmentTypes;

export type StolenEquipmentType = (typeof StolenEquipmentTypes)[Keys];

export const StolenEquipmentTypeOptions = createEnumOptions(StolenEquipmentTypes);
