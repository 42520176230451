import moment from 'moment-timezone';
import { ISlot } from './slot.interface';
import { LondonTimezone } from '@utils/dates/london-timezone';
import { ISODateTimeString } from '@utils/dates/iso-string.type';

export function createSlotByStartAndDuration(start: ISODateTimeString, duration: number): ISlot {
  const startMoment = moment(start).tz(LondonTimezone);
  const endMoment = startMoment.clone().add(duration, 'hours');

  return {
    start: startMoment.toISOString(true),
    end: endMoment.toISOString(true),
  };
}
