import moment from 'moment-timezone';
import { isISODateTimeString } from '@utils/dates/iso-string.type';
import { EmptyCell } from '@utils/empty-cell';
import { DateFormat } from './formats';
import { LondonTimezone } from './london-timezone';

/**
 * Uses moment.js internally to format
 * @param format string, default DD/MM/YYYY
 * */
export function formatDate(date: string | null, format = DateFormat): string {
  if (date === null) {
    return EmptyCell;
  }
  const isWithTime = isISODateTimeString(date);
  const momentDate = moment(date);
  if (isWithTime) {
    momentDate.tz(LondonTimezone);
  }
  return momentDate.format(format);
}
