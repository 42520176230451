import React, { useMemo } from 'react';
import { Button, Stack, Tab, Tabs } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { OrderNew } from '@features/order/new/OrderNew';
import { AppModal } from '@components/AppModal';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { IOrderNew } from '@features/order/new/order-new.interface';
import { UserRoles } from '@features/user/user-role.type';
import { RestrictRole } from '@features/auth/RestrictRole';
import { useToggle } from '@hooks/use-toggle';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from '@routes/routes';
import { RouteParamsType } from '@routes/route-params.type';
import { OrderTab } from './customer.interface';
import { useRole } from '@features/user/use-role';
import { OrderClasses } from '@features/order/order-class.type';

type Props = {
  ordersTabs: OrderTab[];
};

export const CustomerOrders: React.FC<Props> = ({ ordersTabs }) => {
  const { customerIdString, orderIdString } = useParams<RouteParamsType>();
  const [isModalOpen, toggleIsModalOpen] = useToggle();
  const { typeOptions } = useAppBackendLabels(['typeOptions']);
  const navigate = useNavigate();
  const role = useRole();

  const filteredTabs = useMemo(
    () =>
      ordersTabs.filter((tab) =>
        role === UserRoles.assessor ? tab.orderTypeName === OrderClasses.NeedsAssessment : true,
      ),
    [ordersTabs, role],
  );

  function handleSelectOrder(event: React.SyntheticEvent, nextOrderId: string): void {
    navigate(`${Routes.orderSummaries}/${customerIdString}/${nextOrderId}`);
  }

  if (!orderIdString) {
    return null;
  }

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={orderIdString}
          onChange={handleSelectOrder}
          sx={{ flexGrow: 1 }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {filteredTabs.map(({ id, orderTypeId }) => (
            <Tab
              key={id}
              label={`${typeOptions.find(({ value }) => value === orderTypeId)?.label ?? 'NEW'} ${id}`}
              value={id.toString()}
            />
          ))}
        </Tabs>
        <RestrictRole roles={[UserRoles.assessor, UserRoles.qualityAssurance]}>
          <Button
            variant="outlined"
            size="small"
            onClick={toggleIsModalOpen}
            startIcon={<AddIcon />}
            sx={{ whiteSpace: 'nowrap' }}
          >
            Create Order
          </Button>
        </RestrictRole>
      </Stack>
      <AppModal open={isModalOpen} onClose={toggleIsModalOpen} title="New Order">
        <OrderNew order={{} as IOrderNew} onClose={toggleIsModalOpen} />
      </AppModal>
    </>
  );
};
