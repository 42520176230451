import { IAllocation } from '@features/allocations/allocation.interface';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

const allocationsAdapter = createEntityAdapter<IAllocation>({
  selectId: (entry) => entry.id,
});

const allocationsSlice = createSlice({
  name: 'Allocations',
  initialState: allocationsAdapter.getInitialState(),
  reducers: {
    setAllocations: allocationsAdapter.setAll,
    upsertAllocation: allocationsAdapter.upsertOne,
  },
});

export const AllocationsActions = allocationsSlice.actions;
export const allocationsReducer = allocationsSlice.reducer;
export type AllocationsActionsType = typeof AllocationsActions;

const { selectAll } = allocationsAdapter.getSelectors((state: RootState) => state.allocations);

export const selectAllAllocations = selectAll;
