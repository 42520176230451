import { useGetAssessorFeedbackSummariesQuery } from '@api/api-slice';
import { getPaginationResult } from '@features/pagination/get-pagination-result';
import { IPaginable } from '@features/pagination/paginable.interface';
import { RouteParamsType } from '@routes/route-params.type';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useParams } from 'react-router-dom';
import { IAssessorFeedbackSummary } from './assessor-feedback-summary.interface';

type HookResult = RequestStatusFlags & IPaginable<IAssessorFeedbackSummary>;

export function useAssessorFeedbackSummaries(params: URLSearchParams, count: number, skip: number): HookResult {
  const { assessorIdString } = useParams<RouteParamsType>();
  const assessorId = parseInt(assessorIdString as string, 10);
  return getPaginationResult(useGetAssessorFeedbackSummariesQuery({ assessorId, count, skip }));
}
