import { Describe, assign, boolean, literal, nullable, number, object, pattern, string } from 'superstruct';
import { IOrderNeedsAssessment } from '../order-needs-assessment.interface';
import { BaseOrderStruct } from '@features/order/base/spec/base-order-struct.spec';
import { OrderClasses } from '@features/order/order-class.type';
import { ISODateRegexp, ISODateTimeRegexp } from '@utils/dates/iso-string.type';
import { SensitivePersonalInformationStruct } from '../sensitive-personal-information/spec/sensitive-personal-information-struct.spec';

export const NeedsAssessmentOrderStruct: Describe<IOrderNeedsAssessment> = assign(
  BaseOrderStruct,
  SensitivePersonalInformationStruct,
  object({
    orderClass: literal(OrderClasses.NeedsAssessment),
    assessmentType: nullable(string()),
    assessmentCentreId: nullable(number()),
    nextContactDate: pattern(string(), ISODateRegexp),
    organisationId: nullable(number()),
    isReviewRequested: boolean(),
    shareNarWithHep: boolean(),
    reportSentDate: pattern(string(), ISODateTimeRegexp),
    qualityAssuranceUserId: nullable(number()),
    submissionDate: pattern(string(), ISODateTimeRegexp),
    quoteId: nullable(string()),
  }),
);
