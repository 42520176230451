import { useConvertToPdfReportMutation } from '@api/api-slice';
import { isErrorResponse } from '@api/error-response.interface';
import { ReportsActions } from './reports.slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';

type HookResult = {
  convertToPdfHandle: (orderId: number, reportId: number) => Promise<void>;
} & RequestStatusFlags;

export const useConvertToPdf = (): HookResult => {
  const [mutation, flags] = useConvertToPdfReportMutation();
  const dispatch = useAppDispatch();

  const convertToPdfHandle = async (orderId: number, reportId: number): Promise<void> => {
    const result = await mutation({ orderId, reportId }).unwrap();
    if (isErrorResponse(result)) {
      return;
    }
    dispatch(ReportsActions.upsertReports(result.needsAssessmentReports));
  };

  return {
    ...flags,
    convertToPdfHandle,
  };
};
