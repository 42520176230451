import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useGetSensitivePersonalInformationPresenceQuery } from '@api/api-slice';
import { ISensitivePersonalInformationPresence } from './sensitive-personal-information-presence.interface';

const defaultPresence: ISensitivePersonalInformationPresence = {
  hasDsa1: false,
  hasDisabilityTypes: false,
  hasConditions: false,
  hasMedicalEvidence: false,
  hasPreAssessmentForm: false,
  hasDateOfBirth: false,
  hasNarFile: false,
};

type HookResult = RequestStatusFlags & ISensitivePersonalInformationPresence;

export function useSensitivePersonalInformationPresence(orderId: number): HookResult {
  const { data = defaultPresence, ...flags } = useGetSensitivePersonalInformationPresenceQuery(orderId);
  return {
    ...data,
    ...flags,
  };
}
