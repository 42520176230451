import { useCallback } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useRestoreOrderMutation } from '@api/api-slice';
import { isErrorResponse } from '@api/error-response.interface';
import { RequestStatusFlags, mergeRequestStatusFlags } from '@utils/merge-request-status-flags';
import { useAppDispatch } from '@store/use-app-dispatch';
import { OrdersActions } from '@features/order/orders.slice';

type HookResult = {
  submitHandler(orderId: number): Promise<boolean>;
} & RequestStatusFlags;

export function useRestoreOrder(): HookResult {
  const [mutation, flags] = useRestoreOrderMutation();
  const dispatch = useAppDispatch();

  const submitHandler = useCallback(
    async (orderId: number): Promise<boolean> => {
      const result = await mutation(orderId).unwrap();

      if (isErrorResponse(result)) {
        return false; // will show snackbar in api layer
      } else {
        enqueueSnackbar('Order Restored', { variant: 'success' });
        dispatch(OrdersActions.upsertOrder(result));
        return true;
      }
    },
    [dispatch, mutation],
  );

  return {
    ...mergeRequestStatusFlags(flags),
    submitHandler,
  };
}
