import { IAppointmentInvoiceSummary } from '@features/bulk-invoicing/appointment/appointment-invoice-summary.interface';
import { createDraftSafeSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

const appointmentInvoiceSummaryAdapter = createEntityAdapter<IAppointmentInvoiceSummary>({
  selectId: (entry) => entry.id,
});

const appointmentInvoiceSummarySlice = createSlice({
  name: 'Appointment Invoice Summary',
  initialState: appointmentInvoiceSummaryAdapter.getInitialState(),
  reducers: {
    setAppointments: appointmentInvoiceSummaryAdapter.setAll,
    updateAppointment: appointmentInvoiceSummaryAdapter.updateOne,
    updateAppointments: appointmentInvoiceSummaryAdapter.updateMany,
  },
});

export const AppointmentInvoiceSummaryActions = appointmentInvoiceSummarySlice.actions;
export const appointmentInvoiceSummaryReducer = appointmentInvoiceSummarySlice.reducer;
export type AppointmentInvoiceSummaryActionsType = typeof AppointmentInvoiceSummaryActions;

const { selectAll } = appointmentInvoiceSummaryAdapter.getSelectors(
  (state: RootState) => state.appointmentInvoiceSummaries,
);

export const selectAllAppointmentSummaries = selectAll;

export const selectAppointmentSummariesByIds = createDraftSafeSelector(
  selectAll,
  (_: RootState, ids: number[]) => ids,
  (all, ids) => all.filter((item) => ids.includes(item.id)),
);
