import { Describe, StructError, array, assert, nullable, number, object, pattern, record, string } from 'superstruct';
import { IDeliveryCalendar, IOrderGroupByOrganisation, IOrderGroupByType } from '../api';
import { ISODateRegexp } from '@utils/dates/iso-string.type';
import { handleFailure } from '@utils/superstruct/handle-failure';

const OrderGroupByTypeStruct: Describe<IOrderGroupByType> = object({
  orderTypeId: number(),
  orders: array(
    object({
      id: number(),
      customerId: number(),
      orderTypeId: number(),
      pickUp: pattern(string(), ISODateRegexp),
      customerFullName: string(),
      organisationId: nullable(number()),
    }),
  ),
});

const OrderGroupByOrganisation: Describe<IOrderGroupByOrganisation> = object({
  organisationId: nullable(number()),
  groupedByType: array(OrderGroupByTypeStruct),
});

const DeliveryCalendarStruct: Describe<IDeliveryCalendar> = record(
  string(),
  object({
    count: number(),
    groupedByOrganisation: array(OrderGroupByOrganisation),
  }),
);

export function validateDeliveryCalendar(response: IDeliveryCalendar, url: string): void {
  try {
    assert(response, DeliveryCalendarStruct);
  } catch (error) {
    for (const failure of (error as StructError).failures()) {
      handleFailure(failure, url);
    }
  }
}
