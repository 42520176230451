import {
  Describe,
  StructError,
  array,
  assert,
  boolean,
  enums,
  nullable,
  number,
  object,
  pattern,
  string,
} from 'superstruct';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { ISODateRegexp, ISODateTimeRegexp } from '@utils/dates/iso-string.type';
import { IAppointment } from '../appointment.interface';
import { FilterAppointmentsResponse } from '../api';
import { isErrorResponse } from '@api/error-response.interface';
import { AssessmentMethods } from '../../assessment-method.type';
import { ElectronicInvoiceLogStruct } from '@features/electronic-invoice/electronic-invoice-struct';

const AppointmentStruct: Describe<IAppointment> = object({
  id: number(),
  orderId: number(),
  assessmentMethod: nullable(enums(Object.values(AssessmentMethods))),
  assessorId: nullable(number()),
  assessmentCentreId: nullable(number()),
  creationDate: pattern(string(), ISODateRegexp),
  offeredDate: nullable(pattern(string(), ISODateTimeRegexp)),
  appointmentDate: nullable(pattern(string(), ISODateTimeRegexp)),
  isCancelled: boolean(),
  isPaid: boolean(),
  customerId: nullable(number()),
  funderInvoice: nullable(string()),
  supplierInvoice: nullable(string()),
  electronicInvoice: string(),
  electronicInvoiceLogs: array(ElectronicInvoiceLogStruct),
  createdByUserId: nullable(number()),
  cancellationDate: nullable(pattern(string(), ISODateTimeRegexp)),
  cancelledByUserId: nullable(number()),
  cancellationReason: nullable(string()),
});

export function validateAppointments(response: FilterAppointmentsResponse, url: string): void {
  if (!isErrorResponse(response)) {
    for (const entry of response) {
      try {
        assert(entry, AppointmentStruct);
      } catch (error) {
        for (const failure of (error as StructError).failures()) {
          handleFailure(failure, url);
        }
      }
    }
  }
}
