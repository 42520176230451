import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';

export type FormModel = {
  productCategoryId: number[];
  query: string;
};

export type ApiModel = {
  productCategoryId: number[];
  query: string | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  productCategoryId: { type: 'backend-label', optionKey: 'productCategory', multiple: true, initial: [] },
  query: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  productCategoryId: Serializers.backendValue,
  query: Serializers.stringNullable,
});
