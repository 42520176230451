import { appMutationFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { modelToSnakeCase } from '@utils/model-to-snake-case';
import { IEquipmentInvoiceSummary } from './equipment-invoice-summary.interface';
import { BulkUpdateResponse } from '../bulk-update-response.type';
import { BulkUpdateRequest } from '../bulk-update-request.type';

type Response = BulkUpdateResponse<IEquipmentInvoiceSummary> | void;

type SupplierRequest = BulkUpdateRequest & { supplierInvoice: string };

export const buildBulkAddEquipmentSupplierInvoice = appMutationFactory<SupplierRequest, Response>({
  query: (payload) => ({
    url: EndpointUrlBuilder.bulkAddEquipmentSupplierInvoice(),
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
});

export const buildBulkGenerateEquipmentXeroInvoice = appMutationFactory<BulkUpdateRequest, Response>({
  query: (payload) => ({
    url: EndpointUrlBuilder.bulkGenerateEquipmentXeroInvoice(),
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
});

export const buildBulkSendEquipmentInvoice = appMutationFactory<BulkUpdateRequest, Response>({
  query: (payload) => ({
    url: EndpointUrlBuilder.bulkSendEquipmentInvoice(),
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
});
