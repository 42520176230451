import { Moment } from 'moment-timezone';

type MonthRange = {
  from: string;
  to: string;
};

export function getMonthRange(fromMoment: Moment): MonthRange {
  const from = fromMoment.format('YYYY-MM-DD');
  const to = fromMoment.clone().endOf('month').format('YYYY-MM-DD');
  return { from, to };
}
