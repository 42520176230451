import React from 'react';
import { Control, Controller, FieldValues, Path, UseControllerProps } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label: string;
  rules?: UseControllerProps<T>['rules'];
} & TextFieldProps;

export const ControlledTextField: <T extends FieldValues>(props: Props<T>) => JSX.Element = ({
  control,
  name,
  label,
  rules,
  ...textFieldProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }): JSX.Element => (
        <TextField
          fullWidth
          {...field}
          {...textFieldProps}
          error={Boolean(fieldState.error)}
          helperText={fieldState.error?.message}
          label={label}
        />
      )}
    />
  );
};
