export function formatMoney(value: string | number): string {
  const amount = typeof value === 'number' ? value : parseFloat(value);

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: 'GBP',
  }).format(amount);
}
