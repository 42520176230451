import { useGetTutorsQuery } from '@api/api-slice';
import { ITutor } from '@features/tutors/tutor.interface';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';

type HookResult = RequestStatusFlags & {
  tutors: ITutor[];
};

export function useTutors(): HookResult {
  const { data, ...flags } = useGetTutorsQuery();
  return {
    tutors: data?.tutors ?? [],
    ...flags,
  };
}
