import React from 'react';
import { Alert, Box, Button, CircularProgress, Link } from '@mui/material';
import { useZohoAuthorization } from './use-zoho-authorization';

export const ZohoAuthorization: React.FunctionComponent = () => {
  const { isLoggedIn, authorizationUri, zohoUserEmail, handleZohoLogout, isLoading } = useZohoAuthorization();
  if (isLoading) {
    return <CircularProgress sx={{ margin: 'auto' }} />;
  }

  if (!authorizationUri) {
    return <Alert severity="warning">Failed to fetch Zoho Uri</Alert>;
  }

  return (
    <Box sx={{ textAlign: 'center', m: 1 }}>
      {isLoggedIn ? (
        <>
          <Button variant="outlined" size="small" fullWidth onClick={handleZohoLogout}>
            Logout from Zoho
          </Button>
          {zohoUserEmail}
        </>
      ) : (
        <Link href={authorizationUri} sx={{ display: 'block' }}>
          <Button variant="outlined" size="small" fullWidth>
            Login to Zoho
          </Button>
        </Link>
      )}
    </Box>
  );
};
