import { useParams } from 'react-router-dom';
import { RouteParamsType } from '@routes/route-params.type';
import { useGetProductQuery } from '@api/api-slice';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { IProductWithCondition } from './product.interface';
import { useAppDispatch } from '@store/use-app-dispatch';
import { ProductsActions, selectProductById } from './product.slice';
import { useAppSelector } from '@store/use-app-selector';
import { useEffect } from 'react';
import { isErrorResponse } from '@api/error-response.interface';

type HookResult = RequestStatusFlags & {
  product: IProductWithCondition | null;
};

export function useProduct(): HookResult {
  const { productIdString } = useParams<RouteParamsType>();
  const productId = parseInt(productIdString as string, 10);
  const { data, ...flags } = useGetProductQuery(productId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isErrorResponse(data) && data) {
      dispatch(ProductsActions.upsertProduct(data.product));
    }
  }, [data, dispatch]);

  const product = useAppSelector((state) => selectProductById(state, productId));

  return {
    product: product ?? null,
    ...flags,
  };
}
