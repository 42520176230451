import React from 'react';
import { ModalChildProps } from '@components/AppModal';
import { useZohoFreeSlots } from '@features/automatic-booking/use-zoho-free-slots';
import { ISlot } from '@features/automatic-booking/slot.interface';
import { useSubmitAppointmentAutomatedBooking } from '@features/automatic-booking/use-submit-appointment-automated-booking';
import { ChooseTimeSlot } from '@features/automatic-booking/ChooseTimeSlot';
import { AssessmentMethods } from '../../assessment-method.type';

type Props = {
  appointmentId: number | null;
  orderId: number;
} & ModalChildProps;

const AppointmentDuration = 1.5;

export const ChooseAppointmentTimeSlot: React.FC<Props> = ({ appointmentId, orderId, onClose }) => {
  const { handleFormSubmit } = useSubmitAppointmentAutomatedBooking(appointmentId, orderId, onClose);
  const { groupedByDay, offeredSlot, handleMonthChange, isLoading } = useZohoFreeSlots(
    { orderId },
    AppointmentDuration,
    true,
  );

  function onSubmit(slot: ISlot): void {
    const offeredDate = offeredSlot?.start ?? null;
    handleFormSubmit({
      assessmentMethod: AssessmentMethods.RemoteVideo,
      appointmentDate: slot.start,
      offeredDate,
    });
  }

  return (
    <ChooseTimeSlot
      handleFormSubmit={onSubmit}
      groupedByDay={groupedByDay}
      isLoading={isLoading}
      onClose={onClose}
      handleMonthChange={handleMonthChange}
      offeredSlot={offeredSlot}
    />
  );
};
