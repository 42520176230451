import { IOrder } from '@features/order/order.interface';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { DateFilterOptions, DateFilterType } from './date.type';

export type FormModel = {
  orderStatusId: IOrder['orderStatusId'][];
  date: DateFilterType | null;
  customerFirstName: string;
  customerLastName: string;
  customerCrn: string;
};

export type ApiModel = {
  orderStatusId: IOrder['orderStatusId'][];
  date: DateFilterType | null;
  customerFirstName: string | null;
  customerLastName: string | null;
  customerCrn: string | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', multiple: true, initial: [] },
  date: { type: 'enum', options: DateFilterOptions, initial: null },
  customerFirstName: { type: 'transportable', initial: '' },
  customerLastName: { type: 'transportable', initial: '' },
  customerCrn: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  orderStatusId: Serializers.backendValue,
  date: Serializers.enum,
  customerFirstName: Serializers.stringNullable,
  customerLastName: Serializers.stringNullable,
  customerCrn: Serializers.stringNullable,
});
