import { useGenerateSessionElectronicInvoiceMutation } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { SessionsActions } from '../sessions.slice';
import { isErrorResponse } from '@api/error-response.interface';

type HookResult = {
  generateSessionElectronicInvoice: (id: number) => Promise<void>;
} & RequestStatusFlags;

export function useGenerateSessionElectronicInvoice(): HookResult {
  const [mutation, flags] = useGenerateSessionElectronicInvoiceMutation();
  const dispatch = useAppDispatch();

  const generateSessionElectronicInvoice = async (id: number): Promise<void> => {
    const changes = await mutation(id).unwrap();
    if (!isErrorResponse(changes)) {
      dispatch(
        SessionsActions.updateSession({
          id,
          changes,
        }),
      );
    }
  };

  return { generateSessionElectronicInvoice, ...flags };
}
