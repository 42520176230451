import { useEffect, useState } from 'react';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useGetFilteredAssessorsForOrderIdQuery } from '@api/api-slice';
import { IAssessorOption } from '@features/backend-label/user-label.interface';
import { isErrorResponse } from '@api/error-response.interface';

type HookResult = RequestStatusFlags & {
  assessorOptions: IAssessorOption[];
};

/**
 * This method sends order id to the backend
 * where they get disability types for this order.
 * Disability types are sensitive information and should not be used on the client
 * without a reason prompted by operator.
 * The assessor options returned is filtered by disability types on the backend
 */
export function useFilteredAssessors(orderId: number): HookResult {
  const { data, ...flags } = useGetFilteredAssessorsForOrderIdQuery(orderId);
  const [assessorOptions, setAssessorOptions] = useState<IAssessorOption[]>([]);

  useEffect(() => {
    if (data && !isErrorResponse(data)) {
      setAssessorOptions(data);
    }

    return () => {
      setAssessorOptions([]);
    };
  }, [data]);

  return { assessorOptions, ...flags };
}
