import { useLoginMutation } from '@api/api-slice';
import { patchAuthorizationHeader } from '@api/http-query-client';
import { useAppDispatch } from '@store/use-app-dispatch';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { UserActions } from '@features/user/user.slice';
import { IAccount } from './api';
import { isErrorResponse } from '@api/error-response.interface';
import { UseFormReturn, useForm } from 'react-hook-form';
import { errorHandlerFactory } from '@api/error-handler.factory';

type HookResult = {
  submitHandler: React.FormEventHandler;
  isSubmitDisabled: boolean;
} & UseFormReturn<IAccount> &
  RequestStatusFlags;

export function useLogin(): HookResult {
  const [loginMutation, flags] = useLoginMutation();
  const dispatch = useAppDispatch();
  const useFormResult = useForm<IAccount>({
    defaultValues: { email: '', password: '' },
  });
  const { handleSubmit, watch, setError } = useFormResult;

  const email = watch('email');
  const password = watch('password');
  const isSubmitDisabled = email.trim() === '' || password.trim() === '';

  const errorHandler = errorHandlerFactory<IAccount>(setError);

  const handleFormSubmit = async (account: IAccount): Promise<void> => {
    const result = await loginMutation(account).unwrap();

    if (isErrorResponse(result)) {
      errorHandler(result);
      return;
    }

    const { token, user } = result;
    dispatch(UserActions.logIn({ token, user }));
    patchAuthorizationHeader(token);
  };

  const submitHandler = handleSubmit(handleFormSubmit);

  return { ...useFormResult, ...flags, submitHandler, isSubmitDisabled };
}
