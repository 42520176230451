import React from 'react';
import { AppModal } from '@components/AppModal';
import { SwitchBooking } from '@features/automatic-booking/SwitchBooking';
import { ISODateString } from '@utils/dates/iso-string.type';
import { ChooseAppointmentTimeSlot } from './save-appointment-form/ChooseAppointmentTimeSlot';
import { SaveAppointmentForm } from './save-appointment-form/SaveAppointmentForm';
import { IAppointment } from './appointment.interface';

type Props = {
  orderId: number;
  endDate: ISODateString | null;
  appointmentId: number | null;
  activeAppointment: IAppointment | void;
  isOpen: boolean;
  closeModal: () => void;
};

export const OperatorAction: React.FC<Props> = ({
  orderId,
  endDate,
  appointmentId,
  activeAppointment,
  isOpen,
  closeModal,
}) => {
  const modalTitle = appointmentId === null ? 'New Appointment' : 'Edit Appointment';
  return (
    <AppModal open={isOpen} onClose={closeModal} title={modalTitle}>
      <SwitchBooking
        isInitiallyManual={appointmentId !== null}
        isLocked={appointmentId !== null}
        AutomaticComponent={
          <ChooseAppointmentTimeSlot
            appointmentId={activeAppointment?.id ?? null}
            orderId={orderId}
            onClose={closeModal}
          />
        }
        ManualComponent={
          <SaveAppointmentForm
            appointment={activeAppointment}
            orderId={orderId}
            endDate={endDate}
            onClose={closeModal}
          />
        }
      />
    </AppModal>
  );
};
