import { useSaveAppointmentMutation } from '@api/api-slice';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useAppDispatch } from '@store/use-app-dispatch';
import { isErrorResponse } from '@api/error-response.interface';
import { IAppointment } from '../appointment.interface';
import { AppointmentsActions } from '../appointments.slice';

type HookResult = {
  saveAppointmentElectronicInvoice: (value: string) => Promise<void>;
} & RequestStatusFlags;

export function useSaveAppointmentElectronicInvoice(appointment: IAppointment): HookResult {
  const [saveAppointmentMutation, saveFlags] = useSaveAppointmentMutation();
  const dispatch = useAppDispatch();

  const saveAppointmentElectronicInvoice = async (electronicInvoice: string): Promise<void> => {
    const payload = {
      orderId: appointment.orderId,
      appointmentId: appointment.id,
      payload: { electronicInvoice },
    };

    const result = await saveAppointmentMutation(payload).unwrap();
    if (isErrorResponse(result)) {
      return;
    }
    dispatch(AppointmentsActions.upsertAppointment(result));
  };

  return {
    saveAppointmentElectronicInvoice,
    ...saveFlags,
  };
}
