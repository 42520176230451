import { ValidationRule } from 'react-hook-form';

export const MoneyPattern: ValidationRule<RegExp> = {
  value: /^\d+(\.\d{1,2})?$/,
  message: 'Money with up to 2 decimals, for example 20.00 or 20',
};

export const PhonePattern: ValidationRule<RegExp> = {
  value: /^(0|\+44)+\d{10}$/,
  message: 'Starts with 0 or +44 and following 9 digits',
};

export const EmailPattern: ValidationRule<RegExp> = {
  value:
    /^(([^<>()[]\.,;:s@"]+(.[^<>()[]\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/,

  message: 'Valid email',
};

export const NumberPattern: ValidationRule<RegExp> = {
  value: /^\d+$/,
  message: 'Numbers only format. E.g. 1',
};
