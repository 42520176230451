export function concatPath(parts: Array<string | number>): string {
  return parts.reduce((result: string, current, index) => {
    if (typeof current === 'number') {
      // Wrap numbers with brackets
      return `${result}[${current}]`;
    } else {
      // Prefix with a dot if the previous part is a string and not the first element
      const isStringAfterString = index > 0 && typeof parts[index - 1] === 'string';
      return isStringAfterString ? `${result}.${current}` : `${result}${current}`;
    }
  }, '');
}
