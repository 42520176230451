import {
  BaseOrderApiModel,
  BaseOrderFormModel,
  baseOrderSanitizeMap,
  baseOrderSerializeMap,
} from '@features/order/base/form-config';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { IOrderUnsupportedCustomer } from './order-unsupported-customer.interface';

export type FormModel = BaseOrderFormModel & Pick<IOrderUnsupportedCustomer, 'quoteId' | 'funderInvoice'>;

export type ApiModel = BaseOrderApiModel & Pick<IOrderUnsupportedCustomer, 'quoteId' | 'funderInvoice'>;

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  ...baseOrderSanitizeMap,
  quoteId: { type: 'transportable', initial: '' },
  funderInvoice: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  ...baseOrderSerializeMap,
  quoteId: Serializers.string,
  funderInvoice: Serializers.string,
});
