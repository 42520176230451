import { UseFormReturn, useForm } from 'react-hook-form';
import { FormModel } from './form.config';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useSendNeedsAssessmentReportMutation } from '@api/api-slice';
import { useParams } from 'react-router-dom';
import { RouteParamsType } from '@routes/route-params.type';
import { enqueueSnackbar } from 'notistack';
import { isErrorResponse } from '@api/error-response.interface';

type HookResult = {
  submitHandler: React.FormEventHandler;
} & UseFormReturn<FormModel> &
  RequestStatusFlags;

const defaultValues = { finalToStudent: false, toFundingBody: false, toHep: false };

export const useReSendReport = (): HookResult => {
  const [mutation, flags] = useSendNeedsAssessmentReportMutation();

  const { orderIdString } = useParams<RouteParamsType>();
  const orderId = parseInt(orderIdString as string);

  const useFormResult = useForm<FormModel>({ defaultValues });
  const { handleSubmit, reset } = useFormResult;

  const handleFormSubmit = async (data: FormModel): Promise<void> => {
    const payload = {
      orderId,
      payload: data,
    };

    const result = await mutation(payload).unwrap();

    if (isErrorResponse(result)) {
      return;
    }

    enqueueSnackbar('Your report has been sent successfully!', { variant: 'success' });
    reset();
  };

  const submitHandler = handleSubmit(handleFormSubmit);

  return {
    ...useFormResult,
    submitHandler,
    ...flags,
  };
};
