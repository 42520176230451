import { Describe, StructError, assert, boolean, enums, nullable, number, object, pattern, string } from 'superstruct';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { ISODateRegexp } from '@utils/dates/iso-string.type';
import { IReport } from '../report.interface';
import { FilterReportHistoryResponse } from '../api';
import { isErrorResponse } from '@api/error-response.interface';
import { UserRoles } from '@features/user/user-role.type';

const ReportStruct: Describe<IReport> = object({
  id: number(),
  orderId: number(),
  uploadDate: pattern(string(), ISODateRegexp),
  reportReferenceFilePath: string(),
  reportReferenceFileName: string(),
  isCurrent: boolean(),
  role: enums(Object.values(UserRoles)),
  action: string(),
  userId: nullable(number()),
  isFinal: boolean(),
});

export function validateReports(response: FilterReportHistoryResponse, url: string): void {
  if (!isErrorResponse(response)) {
    for (const entry of response) {
      try {
        assert(entry, ReportStruct);
      } catch (error) {
        for (const failure of (error as StructError).failures()) {
          handleFailure(failure, url);
        }
      }
    }
  }
}
