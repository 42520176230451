import { useUploadTimesheetMutation } from '@api/api-slice';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';

export function useUploadTimesheet(): {
  handleUploadTimesheet: (sessionId: number, file: File) => Promise<void>;
} & RequestStatusFlags {
  const [uploadTimesheetMutation, flags] = useUploadTimesheetMutation();

  const handleUploadTimesheet = async (sessionId: number, file: File): Promise<void> => {
    await uploadTimesheetMutation({ sessionId, file }).unwrap();
    // TODO make backend return updated session or timesheet
  };

  return { handleUploadTimesheet, ...flags };
}
