export const QuickFilterTabsTypes = {
  Appointments: 'bulk_appointments_invoicing',
  Sessions: 'bulk_sessions_invoicing',
  Equipment: 'bulk_equipment_invoicing',
  AppointmentSummaries: 'appointments_summaries',
} as const;

type Keys = keyof typeof QuickFilterTabsTypes;

export type QuickFilterTabsType = (typeof QuickFilterTabsTypes)[Keys];
