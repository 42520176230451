import { isEmpty, isNil } from 'lodash';
import { TransportableModel } from '@models/transportable.type';

/**
 * Return new object without null, undefined or empty array fields
 * Works with flat objects
 */
export function filterEmptyObjectFields<T extends Partial<TransportableModel<T>>>(object: T): Partial<T> {
  const result: Partial<T> = {};

  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      const value = object[key];
      if (!isNil(value) && !(Array.isArray(value) && isEmpty(value))) {
        result[key] = value;
      }
    }
  }

  return result;
}
