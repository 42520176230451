import React from 'react';
import { TableCell, Typography } from '@mui/material';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';

export const SlotsHeader: React.FC = () => {
  const { crmFreeSlotOptions } = useAppBackendLabels(['crmFreeSlotOptions']);
  return (
    <>
      {crmFreeSlotOptions.map(({ label, value }, index) => (
        <TableCell
          key={value}
          sx={{
            p: 0,
            borderLeft: '1px solid',
            width: '20px',
            height: '86px',
            position: 'relative',
          }}
          className={index === 0 ? 'MuiTableCell-compact' : 'MuiTableCell-compact MuiTableCell-weak'}
        >
          <Typography
            variant="caption"
            display="block"
            sx={{
              transform: 'rotate(-90deg)',
              transformOrigin: 'left top',
              whiteSpace: 'nowrap',
              position: 'absolute',
              left: '1px',
              top: '100%',
              padding: '0 8px',
            }}
          >
            {label.replace(/\s/g, '').replace('-', '–')}
          </Typography>
        </TableCell>
      ))}
    </>
  );
};
