import { useEffect } from 'react';
import { useGetOrderContactRecordsQuery } from '@api/api-slice';
import { IActionRecord } from '@features/action-records/action-record.interface';
import { RecordModelNames } from '@features/action-records/record-model-name.type';
import { getPaginationResult } from '@features/pagination/get-pagination-result';
import { IPaginable } from '@features/pagination/paginable.interface';
import { useAppDispatch } from '@store/use-app-dispatch';
import { useAppSelector } from '@store/use-app-selector';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { OrderContactRecordsActions, selectAllRecords } from './order-contact-record.slice';

export type HookResult = RequestStatusFlags & IPaginable<IActionRecord>;

export function useOrderContactRecords(id: number, count: number, skip: number): HookResult {
  const queryResult = useGetOrderContactRecordsQuery({ count, skip, modelName: RecordModelNames.Order, id });
  const { entries, ...rest } = getPaginationResult(queryResult);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (rest.isSuccess) {
      dispatch(OrderContactRecordsActions.addRecords(entries));
    }
  }, [entries, dispatch, rest.isSuccess, id]);

  useEffect(() => {
    return () => {
      dispatch(OrderContactRecordsActions.removeRecords());
    };
  }, [dispatch, id]);

  const records = useAppSelector((state) => selectAllRecords(state));

  return { entries: records, ...rest };
}
