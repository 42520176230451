import { createDraftSafeSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { IReport } from './report.interface';

const reportsAdapter = createEntityAdapter<IReport>({
  selectId: (entry) => entry.id,
});

const reportsSlice = createSlice({
  name: 'Reports',
  initialState: reportsAdapter.getInitialState(),
  reducers: {
    upsertReports: reportsAdapter.upsertMany,
    upsertReport: reportsAdapter.upsertOne,
  },
});

export const ReportsActions = reportsSlice.actions;
export const reportsReducer = reportsSlice.reducer;
export type ReportsActionsType = typeof ReportsActions;

const { selectAll } = reportsAdapter.getSelectors((state: RootState) => state.reports);

export const selectReportById = createDraftSafeSelector(
  selectAll,
  (_: RootState, id: number) => id,
  (items, id) => items.find((item) => item.id === id) ?? null,
);

export const selectReportsByOrderId = createDraftSafeSelector(
  selectAll,
  (_: RootState, id: number) => id,
  (items, id) => items.filter((item) => item.orderId === id),
);
