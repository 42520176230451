import { identity } from 'lodash';
import { toISODateTimeFlooredToMinutes } from '@utils/dates/to-iso-date-time-floored-to-minutes';
import { toISODay } from '@utils/dates/to-iso-day';
import { nullToString } from '@utils/forms/null-to-string';
import { emptyStringToNull } from '@utils/forms/empty-string-to-null';
import { toBooleanOrNull } from '@utils/forms/to-boolean-or-null';
import { toBoolean } from '@utils/forms/to-boolean';

/** Contains set of common serializers, turning frontend values to transportable type */
export const Serializers = {
  /** Converts Moment date to ISO String, YYYY-MM-DD format, without time */
  date: toISODay,
  /** Converts Moment date to ISO String, YYYY-MM-DDTHH:mm:00.000Z format, with zero seconds and milliseconds */
  dateTime: toISODateTimeFlooredToMinutes,

  array: nullToString,
  /** Passes as is */
  enum: identity,
  /** Turns empty string to `null` for the field be dropped internally in axios on sending to the backend */
  stringNullable: emptyStringToNull,
  string: identity,
  money: emptyStringToNull,
  integer: parseInt,
  float: parseFloat,
  booleanNullable: toBooleanOrNull,
  boolean: toBoolean,
  backendValue: identity,
  file: identity,
};
