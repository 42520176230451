import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { GridColDef, GridFooter } from '@mui/x-data-grid';
import { AppDataGrid } from '@components/AppDataGrid';
import { Link, Stack, Typography } from '@mui/material';
import { AppPaper } from '@components/AppPaper';
import { useFilteredAppointments } from './filter-appointment-summaries/use-filtered-appointment-summaries';
import { useRole } from '@features/user/use-role';
import { createColumnFactory } from '@utils/create-column.factory';
import { Routes } from '@routes/routes';
import { FilterAppointmentSummariesForm } from './filter-appointment-summaries/FilterAppointmentSummariesForm';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { IAppointmentSummary } from './appointment-summary.interface';
import { formatDateTime } from '@utils/dates/format-date-time';
import { BooleanChip } from '@utils/render-boolean';
import { AssessmentMethodOptions } from '@features/order/needs-assessment/assessment-method.type';
import { renderEnum } from '@utils/enum/render-enum';
import { UserRoles } from '@features/user/user-role.type';
import { CopyTableToClipboard } from '@components/CopyTableToClipboard';
import { useServerPagination } from '@features/pagination/use-server-pagination';
import { PageSizeNames } from '@features/pagination/page-size-names';
import { useAppTitle } from '@hooks/use-app-title';
import { QuickFilterTabsTypes } from '@features/quick-filter-tabs/quick-filter-tabs.type';
import { QuickFilters } from '@features/quick-filter-tabs/QuickFilters';

const createAppointmentSummaryColumn = createColumnFactory<IAppointmentSummary>();

const AllowedAssessorColumnNames: Array<keyof IAppointmentSummary> = [
  'id',
  'customerFullName',
  'customerCrn',
  'appointmentDate',
  'assessorId',
  'assessmentMethod',
  'assessmentCentreId',
  'assessmentType',
  'isCancelled',
];

const columns: GridColDef[] = [
  createAppointmentSummaryColumn('id', {
    width: 100,
    headerName: 'IDs',
    description: 'CustomerId/OrderId',
    renderCell: ({ row }) => (
      <Link key={row.id} component={NavLink} to={`${Routes.orderSummaries}/${row.customerId}/${row.orderId}`}>
        {row.customerId}/{row.orderId}
      </Link>
    ),
  }),
  createAppointmentSummaryColumn('customerFullName', {
    headerName: 'Customer',
    flex: 1,
  }),
  createAppointmentSummaryColumn('customerCrn', {
    headerName: 'CRN',
    width: 120,
  }),
  createAppointmentSummaryColumn('appointmentDate', {
    headerName: 'Appointment Date Time',
    flex: 1,
    renderCell: ({ row }) => formatDateTime(row.appointmentDate),
  }),
  createAppointmentSummaryColumn('assessorId', {
    headerName: 'Assessor',
    flex: 1,
    renderCell: ({ row }) => <BackendLabel value={row.assessorId} optionKey="assessorOptions" />,
    optionKey: 'assessorOptions',
  }),
  createAppointmentSummaryColumn('assessmentMethod', {
    headerName: 'Method',
    flex: 1,
    renderCell: ({ row }) => renderEnum(row.assessmentMethod, AssessmentMethodOptions),
  }),
  createAppointmentSummaryColumn('assessmentType', {
    headerName: 'Assessment Type',
    flex: 1,
    renderCell: ({ row }) => <BackendLabel value={row.assessmentType} optionKey="assessmentTypeOptions" />,
    optionKey: 'assessmentTypeOptions',
  }),
  createAppointmentSummaryColumn('assessmentCentreId', {
    headerName: 'Assessment Centre',
    flex: 1,
    renderCell: ({ row }) => <BackendLabel value={row.assessmentCentreId} optionKey="assessmentCentreOptions" />,
    optionKey: 'assessmentCentreOptions',
  }),
  createAppointmentSummaryColumn('isCancelled', {
    headerName: 'Cancelled',
    width: 80,
    renderCell: ({ row }) => <BooleanChip value={row.isCancelled} isInvertedColor />,
  }),
  createAppointmentSummaryColumn('funderInvoice', { headerName: 'Funder Invoice', width: 120 }),
  createAppointmentSummaryColumn('isPaid', {
    headerName: 'Paid',
    width: 60,
    renderCell: ({ row }) => <BooleanChip value={row.isPaid} />,
  }),
  createAppointmentSummaryColumn('supplierInvoice', { headerName: 'Supplier Invoice', width: 120 }),
  createAppointmentSummaryColumn('organisationId', {
    headerName: 'Organisation',
    flex: 1,
    renderCell: ({ row }) => <BackendLabel value={row.organisationId} optionKey="organisationOptions" />,
    optionKey: 'organisationOptions',
  }),
  createAppointmentSummaryColumn('orderStatusId', {
    headerName: 'Status',
    flex: 1,
    renderCell: ({ row }) => <BackendLabel value={row.orderStatusId} optionKey="statusOptions" />,
    optionKey: 'statusOptions',
  }),
  createAppointmentSummaryColumn('daysInLastStatus', {
    headerName: 'Delay',
    width: 60,
  }),
];

export const AppointmentSummariesPage: React.FC = () => {
  const {
    entries: appointmentSummaries,
    totalEntries,
    paginationModel,
    onPaginationModelChange,
    isLoading,
  } = useServerPagination(useFilteredAppointments, PageSizeNames.AppointmentSummary);

  const role = useRole();
  const isAssessor = role === UserRoles.assessor;

  useAppTitle('Appointments');

  const filteredColumns = useMemo(() => {
    return isAssessor
      ? columns.filter((item) => AllowedAssessorColumnNames.includes(item.field as keyof IAppointmentSummary))
      : columns;
  }, [isAssessor]);

  return (
    <AppPaper>
      <Typography variant="h4" component="h1">
        Appointments
      </Typography>
      <QuickFilters type={QuickFilterTabsTypes.AppointmentSummaries}>
        <FilterAppointmentSummariesForm />
      </QuickFilters>
      <AppDataGrid
        rows={appointmentSummaries}
        columns={filteredColumns}
        loading={isLoading}
        sx={{ mt: 1 }}
        fullHeight
        hideFooter={false}
        slots={{
          footer: () => (
            <Stack direction="row" sx={{ borderTop: '1px solid rgb(224, 224, 224)' }}>
              <CopyTableToClipboard rows={appointmentSummaries} columns={columns} />
              <GridFooter sx={{ flexGrow: 1, border: 'none' }} />
            </Stack>
          ),
        }}
        paginationMode="server"
        rowCount={totalEntries}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
      />
    </AppPaper>
  );
};
