import { useCallback } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useCreateAllocationMutation, useSaveAllocationMutation } from '@api/api-slice';
import { isErrorResponse } from '@api/error-response.interface';
import { errorHandlerFactory } from '@api/error-handler.factory';
import { useAppDispatch } from '@store/use-app-dispatch';
import { IAllocation } from '@features/allocations/allocation.interface';
import { AllocationsActions } from '@features/allocations/allocations.slice';
import { AppBackendLabels } from '@features/backend-label/backend-label.type';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { RequestStatusFlags, mergeRequestStatusFlags } from '@utils/merge-request-status-flags';
import { collectChangedValues } from '@utils/collect-changed-values';
import { FormModel, sanitize, serialize } from './form-config';
import { createPreventBubblingFormHandler } from '@utils/create-prevent-bubbling-form-handler.factory';

type HookResult = {
  submitHandler: React.FormEventHandler;
  isSaveEnabled: boolean;
  isLabelsLoading: boolean;
  buttonText: string;
} & UseFormReturn<FormModel> &
  AppBackendLabels &
  RequestStatusFlags;

export function useSaveAllocationForm(
  allocation: IAllocation | void,
  orderId: number,
  onClose: () => void,
): HookResult {
  const { isLoading: isLabelsLoading, ...backendLabels } = useAppBackendLabels(['supportOptions', 'tutorOptions']);
  const defaultValues = sanitize(allocation, backendLabels);
  const useFormResult = useForm({ defaultValues });
  const { handleSubmit, formState, setError } = useFormResult;
  const [createMutation, createFlags] = useCreateAllocationMutation();
  const [saveMutation, saveFlags] = useSaveAllocationMutation();
  const dispatch = useAppDispatch();

  const hasDirtyFields = Object.keys(formState.dirtyFields).length > 0;
  const id = allocation?.id ?? null;

  const errorHandler = errorHandlerFactory<FormModel>(setError);

  const handleFormSubmit = useCallback(
    async (data: FormModel): Promise<IAllocation | void> => {
      const serializedData = serialize(data);
      const changedData = collectChangedValues(serializedData, defaultValues);

      const mutationHandler = id ? saveMutation : createMutation;
      const payload = {
        ...changedData,
        orderId, // used to determine which order allocation belong
      };
      const result = await mutationHandler({ id, payload }).unwrap();

      if (isErrorResponse(result)) {
        errorHandler(result);
        return;
      }

      dispatch(AllocationsActions.upsertAllocation(result));
      onClose();

      return result;
    },
    [defaultValues, id, createMutation, saveMutation, orderId, dispatch, onClose, errorHandler],
  );

  const submitHandler = createPreventBubblingFormHandler(handleSubmit(handleFormSubmit));

  return {
    ...backendLabels,
    ...useFormResult,
    ...mergeRequestStatusFlags(createFlags, saveFlags),
    submitHandler,
    isSaveEnabled: hasDirtyFields && !formState.isLoading && !formState.isSubmitting && !isLabelsLoading,
    isLabelsLoading,
    buttonText: id ? 'Save Allocation' : 'Create Allocation',
  };
}
