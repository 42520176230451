import { IQuoteDetail } from '@features/quote/quote.interface';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { QuoteStatusOptions } from '../quote-status.type';

export type FormModel = Pick<IQuoteDetail, 'assessmentCentreId' | 'supportPeriod' | 'status'>;

export const sanitize = sanitizeFactory<FormModel, FormModel>({
  assessmentCentreId: { type: 'backend-label', optionKey: 'assessmentCentreOptions', initial: null },
  supportPeriod: { type: 'backend-label', optionKey: 'quoteSupportPeriodOptions', initial: null },
  status: { type: 'enum', options: QuoteStatusOptions, initial: null },
});
