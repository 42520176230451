import { useGetOrderTypeStatusSummariesQuery } from '@api/api-slice';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { IOrderTypeStatusSummary } from './order-type-status-summary.interface';

type HookResult = RequestStatusFlags & {
  summaries: IOrderTypeStatusSummary[];
};

export function useOrderTypeStatusSummaries(): HookResult {
  const { data: summaries, ...flags } = useGetOrderTypeStatusSummariesQuery();
  return {
    summaries: summaries ?? [],
    ...flags,
  };
}
