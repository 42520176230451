import React, { FC, useState } from 'react';
import { IHep } from './hep.interface';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

type Props = {
  hep: IHep;
};

const LinesTabs = {
  hepInfo: 'Hep Information',
  hepAdditionalInfo: 'Additional Hep Information',
} as const;

type Keys = keyof typeof LinesTabs;

type LinesTabsType = (typeof LinesTabs)[Keys];

export const HepInfo: FC<Props> = ({ hep }) => {
  const [currentTab, setCurrentTab] = useState<LinesTabsType>(LinesTabs.hepInfo);

  const handleChange = (event: React.SyntheticEvent, newValue: LinesTabsType): void => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <TabContext value={currentTab}>
        <TabList onChange={handleChange}>
          <Tab value={LinesTabs.hepInfo} label={LinesTabs.hepInfo} />
          <Tab value={LinesTabs.hepAdditionalInfo} label={LinesTabs.hepAdditionalInfo} />
        </TabList>
        <TabPanel value={LinesTabs.hepInfo}>
          <div dangerouslySetInnerHTML={{ __html: hep.hepInformation }}></div>
        </TabPanel>
        <TabPanel value={LinesTabs.hepAdditionalInfo}>
          <div dangerouslySetInnerHTML={{ __html: hep.additionalHepInformation }}></div>
        </TabPanel>
      </TabContext>
    </Box>
  );
};
