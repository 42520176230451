import { appMutationFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { ICustomer } from '@features/customer/customer.interface';
import { ToSnakeCase, modelToSnakeCase } from '@utils/model-to-snake-case';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';
import { ApiModel } from './form-config';

interface ICustomerResponse {
  student: ICustomer;
}

export function normalizeCustomer(customerDTO: ICustomer): ICustomer {
  const customer: ICustomer = {
    ...customerDTO,
    // if null comes make it empty array, it is important for further form handling
    prefCommunicationWay: customerDTO.prefCommunicationWay ?? [],
  };
  return customer;
}

type CustomerPayload = {
  student: ToSnakeCase<Partial<ApiModel>>;
};

function createCustomerPayload(payload: Partial<ApiModel>, id?: number | null): CustomerPayload {
  // нафига нам еще в боди передавать айди если он в урле есть? // кринж
  const data = id ? { id, ...payload } : payload;
  return { student: modelToSnakeCase(data) };
}

type SaveCustomerRequest = {
  id: number | null;
  payload: Partial<ApiModel>;
};

export const buildCreateCustomer = appMutationFactory<SaveCustomerRequest, ICustomer | IErrorResponse>({
  query: ({ payload }) => ({
    url: EndpointUrlBuilder.createCustomer(),
    method: 'POST',
    data: createCustomerPayload(payload),
  }),
  transformResponse,
});

export const buildSaveCustomer = appMutationFactory<SaveCustomerRequest, ICustomer | IErrorResponse>({
  query: ({ id, payload }) => {
    return {
      url: EndpointUrlBuilder.saveCustomer(id),
      method: 'PATCH',
      data: createCustomerPayload(payload, id),
    };
  },
  transformResponse,
});

function transformResponse(response: ICustomerResponse | IErrorResponse): ICustomer | IErrorResponse {
  return isErrorResponse(response) ? response : normalizeCustomer(response.student);
}
