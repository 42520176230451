import { initApp } from './App';
import { initTitle } from '@environment/init-title';
import { initFavicon } from '@environment/init-favicon';
import '@styles/redefine.css';

const appRootNode = document.createElement('div');
appRootNode.id = 'react-app';
appRootNode.classList.add('root');
document.body.appendChild(appRootNode);

initTitle();
initFavicon();
initApp(appRootNode);
