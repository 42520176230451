import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link, ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import { useRole } from '@features/user/use-role';
import { protectedMenuLinks } from '@features/auth/protected-menu-links';
import { CrmAccessibleRoleType } from '@features/auth/crm-accessible-roles.type';

export const Navigation: React.FC = () => {
  const role = useRole();

  return (
    <MenuList sx={{ borderBottom: 1, borderColor: 'divider' }} className="side-menu">
      {protectedMenuLinks
        .filter((item) => item.roles.includes(role as CrmAccessibleRoleType))
        .map((item, index) => (
          <Link component={NavLink} to={item.path} key={index}>
            <MenuItem divider={item.divider}>
              <ListItemIcon>
                <item.icon />
              </ListItemIcon>
              <ListItemText>{item.name}</ListItemText>
            </MenuItem>
          </Link>
        ))}
    </MenuList>
  );
};
