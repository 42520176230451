import { getRandomInt } from './get-random-int';

/**
 * Splits given `number` into array of numbers, max `length`,
 * where each segment from `min` to `max`
 * and array sum is equal given `number`.
 */
export function splitIntoSegments(number: number, maxLength: number, min: number, max: number): number[] {
  const possibleMin = maxLength * min;
  const possibleMax = maxLength * max;

  if (possibleMin > number) {
    throw new Error(`Possible min ${possibleMin} is greater that given number ${number}`);
  }

  if (possibleMax < number) {
    throw new Error(`Possible max ${possibleMax} is not enough to reach given number ${number}`);
  }

  let remaining = number;
  const segments: Array<number> = [];

  while (remaining > 0) {
    const remainingSegments = maxLength - segments.length;
    const currentMin = Math.max(min, remaining - (remainingSegments - 1) * max);
    const currentMax = Math.min(max, remaining);
    const segment = currentMin === currentMax ? getRandomInt(currentMin, currentMax) : currentMin;

    segments.push(segment);
    remaining -= segment;
  }

  return segments;
}
