import React from 'react';
import { Button, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ICustomer } from '@features/customer/customer.interface';
import { PhonePattern } from '@utils/validation-rules';
import { useSaveCustomerForm } from './use-save-customer-form';
import { UserRoles } from '@features/user/user-role.type';
import { ControlledToggleButton } from '@components/inputs/ControlledToggleButton';
import { useDisableRole } from '@features/auth/use-disable-role';

type Props = {
  customer?: ICustomer | void;
  ActionSlot?: React.ReactNode;
};

export const SaveCustomerForm: React.FC<Props> = ({ customer, ActionSlot }) => {
  const {
    submitHandler,
    isSaveEnabled,
    isLabelsLoading,
    buttonText,
    control,
    fundingBodyOptions,
    hepOptions,
    preferredCommunicationWaysOptions,
    labelOptions,
  } = useSaveCustomerForm(customer);

  const isDisabledForAssessorAndQA = useDisableRole([UserRoles.assessor, UserRoles.qualityAssurance]);
  const isDisabledDueAccountSuspension = customer?.isAccountSuspended;
  const isDisabled = isDisabledForAssessorAndQA || isDisabledDueAccountSuspension;
  return (
    <form onSubmit={submitHandler}>
      <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }}>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField control={control} name="crn" label="Customer Reference Number" disabled={isDisabled} />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="firstName"
            label="First Name *"
            rules={{ required: 'First name is required' }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="lastName"
            label="Last Name *"
            rules={{ required: 'Last name is required' }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="email"
            label="Email *"
            rules={{ required: 'Email is required' }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="contactNumber"
            label="Telephone Number"
            rules={{ pattern: PhonePattern }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="altNumber"
            label="Alternative Number"
            rules={{ pattern: PhonePattern }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="address"
            label="Delivery Address"
            multiline
            minRows={2}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField control={control} name="postcode" label="Postcode" disabled={isDisabled} />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledAutocomplete
            control={control}
            name="fundingBodyId"
            label="Funding Body"
            options={fundingBodyOptions}
            isLoading={isLabelsLoading}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledAutocomplete
            control={control}
            name="hepId"
            label="HEP"
            options={hepOptions}
            isLoading={isLabelsLoading}
            disabled={isDisabled}
          />
        </Grid>

        <Grid item xs={4} md={4} lg={4}>
          <ControlledAutocomplete
            control={control}
            name="prefCommunicationWay"
            label="Preferred Communication Method"
            options={preferredCommunicationWaysOptions}
            isLoading={isLabelsLoading}
            multiple
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledToggleButton
            control={control}
            fullWidth
            name="extraMile"
            getLabel={(value: boolean): string => (value ? 'Extra Mile' : 'Not Extra Mile')}
            disabled={isDisabledDueAccountSuspension}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={12}>
          <ControlledAutocomplete
            control={control}
            name="labels"
            label="Customer Labels"
            options={labelOptions}
            isLoading={isLabelsLoading}
            multiple
          />
        </Grid>
        <Grid item xs={4} md={8} lg={12}>
          <ControlledTextField
            control={control}
            name="notes"
            label="Notes"
            multiline
            minRows={2}
            disabled={isDisabledDueAccountSuspension}
          />
        </Grid>
        <Grid item xs={4} md={8} lg={12}>
          <Stack direction="row" spacing={2}>
            {ActionSlot}
            <Button variant="contained" sx={{ flexGrow: 1 }} size="large" type="submit" disabled={!isSaveEnabled}>
              {buttonText}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};
