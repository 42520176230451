import React from 'react';

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  onChange: (fileList: FileList) => void;
  hidden?: boolean;
};

export const FileInput: React.FC<Props> = ({ onChange, hidden = true, ...inputProps }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;
    files && onChange(files);
  };

  return <input type="file" {...inputProps} hidden={hidden} onChange={handleChange} />;
};
