import React, { PropsWithChildren } from 'react';
import { UserRoleType } from '@features/user/user-role.type';
import { useDisableRole } from './use-disable-role';

type Props = PropsWithChildren & {
  roles: UserRoleType[];
  AlternateComponent?: JSX.Element;
};

/**
 * The component restricts render of children for passed roles.
 * Additionally you can pass AlternateComponent to render for restricted roles.
 */
export const RestrictRole: React.FC<Props> = ({ roles, children, AlternateComponent = null }) => {
  const isDisabled = useDisableRole(roles);
  return isDisabled ? AlternateComponent : <>{children}</>;
};
