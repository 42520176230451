import React, { FunctionComponent } from 'react';
import { Link } from '@mui/material';
import { Routes } from '@routes/routes';
import { IOrderStatusSummary } from './order-type-status-summary.interface';

export const ExtraMileLink: FunctionComponent<{ type: number | null; summary: IOrderStatusSummary }> = ({
  type,
  summary,
}) => {
  const count = summary.extraMileCount;
  if (count === 0) {
    return <span>{count}</span>;
  }

  const href = `${Routes.orderSummaries}?orderTypeId=${type}&orderStatusId=${summary.status}&extraMile=true`;
  return (
    <Link href={href} sx={{ display: 'block' }}>
      {count}
    </Link>
  );
};
