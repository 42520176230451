import React from 'react';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { AppDataGrid } from '@components/AppDataGrid';
import { IQuoteLineProduct } from './use-quote-line-products';
import { createColumnFactory } from '@utils/create-column.factory';
import { Alert, Button, Typography } from '@mui/material';
import { StyledTable } from '@components/StyledTable';
import { AppDrawer } from '@components/AppDrawer';
import { useToggle } from '@hooks/use-toggle';

type Props = {
  lines: IQuoteLineProduct[];
};

const defaultSortModel: GridSortModel = [
  {
    field: 'createdAt',
    sort: 'desc',
  },
];

const createLineColumn = createColumnFactory<IQuoteLineProduct>({});

const columns: GridColDef[] = [
  createLineColumn('id', {
    headerName: 'Id',
    width: 60,
  }),
  createLineColumn('code', {
    headerName: 'Code',
    width: 90,
  }),
  createLineColumn('quantity', {
    headerName: 'Quantity',
    headerAlign: 'right',
    align: 'right',
    width: 90,
  }),
  createLineColumn('description', {
    headerName: 'Description',
    flex: 1,
  }),
  createLineColumn('message', {
    headerName: 'Message',
    flex: 1,
    renderCell: ({ row }) =>
      row.message ? (
        <Alert severity="error" sx={{ width: '100%' }}>
          {row.message}
        </Alert>
      ) : null,
  }),
];

const allowedColumns = ['id', 'code', 'quantity', 'description'];

export const QuoteLines: React.FC<Props> = ({ lines }) => {
  const [isDrawerOpen, toggleDrawer] = useToggle();

  const isDisabled = lines.length > 0;

  const filteredForCopyColumns = columns.filter(({ field }) => allowedColumns.includes(field));
  const filteredForCopyLines = lines.map(({ id, code, quantity, description }) => ({
    id,
    code,
    quantity,
    description,
  }));

  const isAtLeastOneMessage = lines.some(({ message }) => Boolean(message));
  const mayBeWithMessageColumns = columns.filter(({ field }) => isAtLeastOneMessage || field !== 'message');

  return (
    <>
      <AppDataGrid
        sx={{ height: '320px' }}
        rows={lines}
        columns={mayBeWithMessageColumns}
        sortModel={defaultSortModel}
        hideFooter={true}
      />
      <Button onClick={toggleDrawer} variant="outlined" fullWidth disabled={!isDisabled} sx={{ mt: 1 }}>
        Print Quote
      </Button>
      <AppDrawer open={isDrawerOpen} onClose={toggleDrawer}>
        <Typography variant="h4" sx={{ mb: 1 }}>
          Quote Lines
        </Typography>
        <StyledTable columns={filteredForCopyColumns} rows={filteredForCopyLines} />
      </AppDrawer>
    </>
  );
};
