import { Moment } from 'moment-timezone';
import {
  BaseOrderApiModel,
  BaseOrderFormModel,
  baseOrderSanitizeMap,
  baseOrderSerializeMap,
} from '@features/order/base/form-config';
import { SystemOptions } from '@features/order/shared/system.type';
import { InstallationOptions } from '@features/order/shared/installation.type';
import { SanitizeMap, sanitizeFactory } from '@utils/forms/sanitize.factory';
import { SerializeMap, serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { IOrderTechnical } from './order-technical.interface';

export type FormModel = BaseOrderFormModel &
  Pick<
    IOrderTechnical,
    | 'isContributePayable'
    | 'organisationId'
    | 'warranty'
    | 'quoteId'
    | 'paymentLink'
    | 'salesId'
    | 'invoiceNumber'
    | 'isUpgradedEquipment'
    | 'dsa2ApprovedAmount'
    | 'upgradePrice'
    | 'system'
    | 'installationType'
    | 'immyBotInstallationLink'
  > & {
    pickUp: Moment | null;
    arranged: Moment | null;
    quotationFile: File | null;
  };

export type ApiModel = BaseOrderApiModel &
  Pick<
    IOrderTechnical,
    | 'isContributePayable'
    | 'organisationId'
    | 'warranty'
    | 'quoteId'
    | 'paymentLink'
    | 'salesId'
    | 'pickUp'
    | 'arranged'
    | 'invoiceNumber'
    | 'isUpgradedEquipment'
    | 'dsa2ApprovedAmount'
    | 'upgradePrice'
    | 'system'
    | 'installationType'
    | 'immyBotInstallationLink'
  > & {
    quotationFile: File | null;
  };

export const technicalOrderSanitizeMap: SanitizeMap<FormModel> = {
  ...baseOrderSanitizeMap,
  isContributePayable: { type: 'transportable', initial: false },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', initial: null },
  warranty: { type: 'backend-label', optionKey: 'warrantyOptions', initial: null },
  quoteId: { type: 'transportable', initial: '' },
  paymentLink: { type: 'transportable', initial: '' },
  salesId: { type: 'transportable', initial: '' },
  pickUp: { type: 'date', initial: null },
  arranged: { type: 'date', initial: null },
  invoiceNumber: { type: 'transportable', initial: '' },
  isUpgradedEquipment: { type: 'transportable', initial: false },
  dsa2ApprovedAmount: { type: 'transportable', initial: '' },
  upgradePrice: { type: 'transportable', initial: '' },
  system: { type: 'enum', options: SystemOptions, initial: null },
  installationType: { type: 'enum', options: InstallationOptions, initial: null },
  immyBotInstallationLink: { type: 'transportable', initial: '' },
  quotationFile: { type: 'transportable', initial: null },
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>(technicalOrderSanitizeMap);

export const technicalOrderSerializeMap: SerializeMap<ApiModel> = {
  ...baseOrderSerializeMap,
  isContributePayable: Serializers.boolean,
  organisationId: Serializers.backendValue,
  warranty: Serializers.backendValue,
  quoteId: Serializers.string,
  paymentLink: Serializers.string,
  salesId: Serializers.string,
  pickUp: Serializers.date,
  arranged: Serializers.date,
  invoiceNumber: Serializers.string,
  isUpgradedEquipment: Serializers.boolean,
  dsa2ApprovedAmount: Serializers.money,
  upgradePrice: Serializers.money,
  system: Serializers.enum,
  installationType: Serializers.enum,
  immyBotInstallationLink: Serializers.string,
  quotationFile: Serializers.file,
};

export const serialize = serializeFactory<ApiModel, FormModel>(technicalOrderSerializeMap);
