import { UserRoles } from '@features/user/user-role.type';

export const CrmAccessibleRoles = [
  UserRoles.operator,
  UserRoles.financeManager,
  UserRoles.assessor,
  UserRoles.qualityAssurance,
];

export type CrmAccessibleRoleType = (typeof CrmAccessibleRoles)[number];
