import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { AppModal } from '@components/AppModal';
import { useSubmitNoShow } from './submit-no-show/use-submit-no-show';

type Props = {
  orderId: number;
  appointmentId: number | null;
  isOpen: boolean;
  closeModal: () => void;
};

export const AssessorAction: React.FC<Props> = ({ orderId, appointmentId, isOpen, closeModal }) => {
  const { submitHandler, isLoading } = useSubmitNoShow();

  function handleSubmit(): void {
    submitHandler(appointmentId, orderId, closeModal);
  }

  return (
    <AppModal open={isOpen} onClose={closeModal} title={`No Show on Appointment ${appointmentId}`}>
      <Box>
        <Typography variant="body1" gutterBottom>
          By marking the student as no show, you confirm that the student is at least 15 minutes late to their Needs
          Assessment Appointment. You have tried calling and emailing the student and have not heard back.
        </Typography>
        <Button fullWidth variant="contained" onClick={handleSubmit} disabled={isLoading} sx={{ mt: 2 }}>
          Mark No Show
        </Button>
      </Box>
    </AppModal>
  );
};
