import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useAppSelector } from '@store/use-app-selector';

export const AppLinearProgressBar: React.FC = () => {
  const isSomeQueryPending = useAppSelector((state) =>
    Object.values(state.api.queries).some((query) => query?.status === 'pending'),
  );

  return isSomeQueryPending ? (
    <Box sx={{ width: '100%', position: 'fixed', zIndex: 1300 }}>
      <LinearProgress />
    </Box>
  ) : null;
};
