import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, TextField, Typography } from '@mui/material';
import { AppDrawer } from '@components/AppDrawer';
import { ElectronicInvoiceLog } from '@features/electronic-invoice/ElectronicInvoiceLog';
import { IElectronicInvoiceLog } from './electronic-invoice-log.interface';
import { useToggle } from '@hooks/use-toggle';

type Props = {
  invoice: string | null;
  invoiceLogs: IElectronicInvoiceLog[];
  isButtonDisabled?: boolean;
  buttonSize?: 'small' | 'medium' | 'large';
  isLoading: boolean;
  handleSave: (invoice: string | null) => unknown;
  handleRefresh: () => unknown;
  handleSend: () => unknown;
};

export const ElectronicInvoice: React.FC<Props> = ({
  invoice,
  invoiceLogs,
  isButtonDisabled = false,
  buttonSize = 'medium',
  isLoading,
  handleSave,
  handleRefresh,
  handleSend,
}) => {
  const [isDrawerOpen, toggleIsDrawerOpen] = useToggle();
  const [electronicInvoice, setElectronicInvoice] = useState<string | null>(invoice);

  useEffect(() => {
    setElectronicInvoice(invoice);
  }, [invoice]);

  function onChange(e: ChangeEvent<HTMLTextAreaElement>): void {
    setElectronicInvoice(e.target.value);
  }

  const hasLog = invoiceLogs.length > 0;
  // window height - drawer title, - 2 button rows, divide by textarea row height, floored down
  const rowCount = Math.floor((window.innerHeight - 240 - 24 - 48 * 3) / 24);

  return (
    <>
      <Button
        onClick={toggleIsDrawerOpen}
        variant="outlined"
        size={buttonSize}
        disabled={isButtonDisabled}
        sx={{ flexGrow: 1 }}
      >
        Invoice
      </Button>
      <AppDrawer open={isDrawerOpen} onClose={toggleIsDrawerOpen}>
        <Box>
          <Typography variant="h4" sx={{ mb: 1 }}>
            Electronic invoice
          </Typography>
          <TextField
            name="electronicInvoice"
            label="Electronic Invoice"
            multiline
            fullWidth
            rows={rowCount}
            value={electronicInvoice}
            onChange={onChange}
          />
          {hasLog && <ElectronicInvoiceLog log={invoiceLogs} />}
          <Button variant="outlined" fullWidth sx={{ mt: 1 }} onClick={handleSend} disabled={isLoading}>
            Send
          </Button>
          <ButtonGroup fullWidth sx={{ mt: 1 }}>
            <Button onClick={handleRefresh} variant="outlined" disabled={isLoading}>
              Refresh
            </Button>
            <Button
              onClick={(): unknown => handleSave(electronicInvoice)}
              type="submit"
              variant="contained"
              disabled={isLoading}
            >
              Save
            </Button>
          </ButtonGroup>
        </Box>
      </AppDrawer>
    </>
  );
};
