import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { AppDetails, DetailsConfig } from '@components/AppDetails';
import { getPartialByKeys } from '@utils/get-partial-by-keys';
import { BooleanChip } from '@utils/render-boolean';
import { IHep } from './hep.interface';

type Props = {
  hep: IHep;
};

export const HepDetails: FC<Props> = ({ hep }) => {
  const hepDetails = getPartialByKeys(hep, [
    'name',
    'email',
    'description',
    'contactNumber',
    'contactPerson',
    'receiveCopyNar',
    'address',
  ]);

  const config: DetailsConfig<typeof hepDetails> = {
    name: { label: 'Name', render: (value) => value },
    email: { label: 'Email', render: (value) => value },
    contactNumber: { label: 'Contact number', render: (value) => value },
    contactPerson: { label: 'Contact person', render: (value) => value },
    receiveCopyNar: { label: 'Receive copy nar', render: (value) => <BooleanChip value={value} /> },
    address: { label: 'Address', render: (value) => value },
    description: { label: 'Description', render: (value) => value },
  };

  return (
    <Box>
      <Typography variant="h4" component="h1">
        Hep Details
      </Typography>
      <AppDetails model={hepDetails} config={config} />
    </Box>
  );
};
