import moment from 'moment-timezone';
import { ISlot } from './slot.interface';
import { LondonTimezone } from '@utils/dates/london-timezone';

/**
 *
 * @param {number} duration hours
 */
export function splitSlotsByDuration(slots: ISlot[], duration: number): ISlot[] {
  const splittedSlots: ISlot[] = [];

  for (const { start, end } of slots) {
    let currentStart = moment(start).tz(LondonTimezone);
    const currentEnd = moment(end).tz(LondonTimezone);

    while (currentStart.isBefore(currentEnd)) {
      const currentSlotEnd = currentStart.clone().add(duration, 'hours');

      if (currentSlotEnd.isAfter(currentEnd)) {
        break; // Exclude slots shorter than the duration
      }

      splittedSlots.push({
        start: currentStart.toISOString(true),
        end: currentSlotEnd.toISOString(true),
      });

      currentStart = currentSlotEnd;
    }
  }

  return splittedSlots;
}
