import React from 'react';
import { IElectronicInvoiceLog } from './electronic-invoice-log.interface';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { createColumnFactory } from '@utils/create-column.factory';
import { AppDataGrid } from '@components/AppDataGrid';
import { formatDateTime } from '@utils/dates/format-date-time';

type Props = {
  log: IElectronicInvoiceLog[];
};

const defaultSortModel: GridSortModel = [
  {
    field: 'createdAt',
    sort: 'asc',
  },
];

const createProductColumn = createColumnFactory<IElectronicInvoiceLog>();
const columns: GridColDef[] = [
  createProductColumn('createdAt', {
    headerName: 'Created',
    width: 140,
    renderCell: ({ row }) => formatDateTime(row.createdAt),
  }),
  createProductColumn('fullStatus', {
    headerName: 'Status',
    width: 200,
  }),
  createProductColumn('fullMessage', {
    headerName: 'Message',
    flex: 1,
  }),
];

export const ElectronicInvoiceLog: React.FC<Props> = ({ log }) => {
  return <AppDataGrid rows={log} columns={columns} sortModel={defaultSortModel} sx={{ height: '240px', mt: 1 }} />;
};
