import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

export interface IMicrosoftAuthorizationState {
  isLoggedIn: boolean;
  authorizationUri: string | null;
  organisationName: string | null;
}

const initialState: IMicrosoftAuthorizationState = {
  isLoggedIn: false,
  authorizationUri: null,
  organisationName: null,
};

const microsoftSlice = createSlice({
  name: 'Microsoft',
  initialState,
  reducers: {
    setAuthData: (state, { payload }: PayloadAction<IMicrosoftAuthorizationState>) => payload,
    logIn: (state) => ({ ...state, isLoggedIn: true }),
    logOut: (state) => ({ ...state, isLoggedIn: false }),
  },
});

export const MicrosoftActions = microsoftSlice.actions;
export const microsoftReducer = microsoftSlice.reducer;
export type MicrosoftActionsType = typeof MicrosoftActions;

export const selectMicrosoftState = (state: RootState): IMicrosoftAuthorizationState => state.microsoft;
