import { useSendOrderElectronicInvoiceMutation } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { SendOrderElectronicInvoiceKeys } from './api';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { OrdersActions } from '@features/order/orders.slice';
import { isErrorResponse } from '@api/error-response.interface';

type HookResult = {
  sendOrderElectronicInvoice: (id: number) => Promise<void>;
} & RequestStatusFlags;

export function useSendOrderElectronicInvoice(orderClass: SendOrderElectronicInvoiceKeys): HookResult {
  const [sendMutation, flags] = useSendOrderElectronicInvoiceMutation();
  const dispatch = useAppDispatch();

  const sendOrderElectronicInvoice = async (id: number): Promise<void> => {
    const changes = await sendMutation({ id, orderClass }).unwrap();
    if (!isErrorResponse(changes)) {
      dispatch(
        OrdersActions.updateOrder({
          id,
          changes,
        }),
      );
    }
  };

  return { sendOrderElectronicInvoice, ...flags };
}
