import React from 'react';
import { Alert } from '@mui/material';
import { color, isWarning, title } from './config';

export const EnvironmentWarning: React.FunctionComponent = () => {
  return isWarning ? (
    <Alert severity="warning" variant="filled" square sx={{ justifyContent: 'center', backgroundColor: color }}>
      {`You're now using ${title} version on the app`}
    </Alert>
  ) : null;
};
