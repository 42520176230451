import { Serializers } from '@utils/forms/serializers';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';

export type FormModel = {
  assessorId: number[];
  employmentType: number[];
  organisationId: number[];
};

export type ApiModel = {
  assessorId: number[];
  employmentType: number[];
  organisationId: number[];
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  assessorId: { type: 'backend-label', optionKey: 'assessorOptions', multiple: true, initial: [] },
  employmentType: { type: 'backend-label', optionKey: 'employmentOptions', multiple: true, initial: [] },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', multiple: true, initial: [] },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  assessorId: Serializers.array,
  employmentType: Serializers.array,
  organisationId: Serializers.array,
});
