import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useBulkGenerateSessionXeroInvoiceMutation, useBulkSendSessionInvoiceMutation } from '@api/api-slice';
import { AppDataGrid } from '@components/AppDataGrid';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { useBulkUpdate } from '../use-bulk-update';
import { SessionInvoiceSummaryActions } from './session-invoice-summary.slice';
import { sessionInvoiceColumnsWithResult } from './session-invoice-columns';
import { useSelectedSessionInvoiceSummaries } from './filter-session-invoice-summaries/use-selected-session-invoice-summaries';

type Props = {
  selectedIds: number[];
};

export const GenerateSessionXeroInvoices: React.FC<Props> = ({ selectedIds }) => {
  const rows = useSelectedSessionInvoiceSummaries(selectedIds);
  const {
    handleSubmit: handleSendEInvoiceSubmit,
    rowsWithResult,
    isLoading: isSending,
  } = useBulkUpdate(useBulkSendSessionInvoiceMutation, SessionInvoiceSummaryActions.updateSessions, rows);
  const { handleSubmit: handleGenerateXeroInvoiceSubmit, isLoading: isGenerating } = useBulkUpdate(
    useBulkGenerateSessionXeroInvoiceMutation,
    SessionInvoiceSummaryActions.updateSessions,
    rows,
  );

  const handleXeroClick = (): void => {
    handleGenerateXeroInvoiceSubmit({ ids: selectedIds });
  };

  const handleSendClick = (): void => {
    handleSendEInvoiceSubmit({ ids: selectedIds });
  };

  return (
    <>
      <Typography variant="h4" component="h1">
        Generate Xero Invoices
      </Typography>
      <Stack direction="row" spacing={2}>
        <AppLoadingButton isLoading={isGenerating} onClick={handleXeroClick}>
          Generate Xero Invoices
        </AppLoadingButton>
        <AppLoadingButton isLoading={isSending} onClick={handleSendClick}>
          Send E-Invoices
        </AppLoadingButton>
      </Stack>
      <AppDataGrid
        rows={rowsWithResult}
        columns={sessionInvoiceColumnsWithResult}
        sx={{ mt: 1 }}
        fullHeight
        hideFooter
      />
    </>
  );
};
