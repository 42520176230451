import React from 'react';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { useGenerateInvoiceNumber } from './use-generate-invoice-number';
import { IOrderTechnical } from '@features/order/technical/order-technical.interface';
import { IOrderRepairAndReplace } from '@features/order/repair-and-replace/order-repair-and-replace.interface';

type Props = {
  order: IOrderTechnical | IOrderRepairAndReplace;
};

export const GenerateInvoiceNumber: React.FC<Props> = ({ order }) => {
  const { generateInvoiceNumber, isLoading } = useGenerateInvoiceNumber();

  async function handleClick(): Promise<void> {
    await generateInvoiceNumber(order.id);
  }

  if (order.invoiceNumber !== null) {
    return null;
  }

  return (
    <AppLoadingButton variant="outlined" isLoading={isLoading} onClick={handleClick}>
      Generate
    </AppLoadingButton>
  );
};
