import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';

export type FormModel = {
  name: string;
};

export type ApiModel = {
  name: string | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  name: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  name: Serializers.stringNullable,
});
