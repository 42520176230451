import { serializeFactory } from '@utils/forms/serialize.factory';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { Serializers } from '@utils/forms/serializers';
import { IReport } from '../report.interface';

export type FormModel = Pick<IReport, 'isCurrent' | 'isFinal'> & {
  reportReferenceFile: File | null;
};

export type ApiModel = Pick<IReport, 'isCurrent' | 'isFinal'> & {
  reportReferenceFile: File | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  isCurrent: { type: 'transportable', initial: false },
  reportReferenceFile: { type: 'transportable', initial: null },
  isFinal: { type: 'transportable', initial: false },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  isCurrent: Serializers.boolean,
  reportReferenceFile: Serializers.file,
  isFinal: Serializers.boolean,
});
