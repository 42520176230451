import { isWarning } from '@environment/config';
import { createFailureMessage } from '@utils/superstruct/create-failure-message';
import { Failure } from 'superstruct';

/**
 * Now just logging to console
 * Later can implement printing to the screen
 * @export
 * @param {Failure} failure superstruct failure type
 * @param {string} description page name or endpoint
 */
export function handleFailure(failure: Failure, description: string, key = 'id'): void {
  const message = createFailureMessage(failure, description, key);

  // here we're assuming that its excessive field and it can't break the app
  // however excessive fields could load the backend
  if (failure.type === 'never') {
    // we don't want to show error on production
    if (isWarning) {
      console.warn(message);
    }
  } else {
    console.error(message);
  }
}
