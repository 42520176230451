import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { IBackendLabelOption } from '@features/backend-label/backend-label.type';

type Props = {
  label: string;
  reasonOptions: IBackendLabelOption<string>[];
  onChange: (value: string) => void;
};

const OtherOption: IBackendLabelOption<string> = { label: 'Other', value: 'Other' };

/**
 * This component combines two fields: Autocomplete and TextField.
 * If `otherReason` option is selected, TextField appears and is required.
 * The component controls the `onChange` setter property.
 */
export const AppDropdownWithComment: React.FC<Props> = ({ label, reasonOptions, onChange }) => {
  const [reason, setReason] = useState<string | null>(null);
  const [reasonComment, setReasonComment] = useState('');

  const isReasonCommentVisible = reason === OtherOption.value;

  useEffect(() => {
    const requestReason = isReasonCommentVisible ? reasonComment : reason ?? '';
    onChange(requestReason);
  }, [isReasonCommentVisible, onChange, reason, reasonComment]);

  const optionsWithOther = reasonOptions.concat(OtherOption);

  return (
    <>
      <Autocomplete
        options={optionsWithOther}
        value={optionsWithOther.find((option) => option.value === reason) ?? null}
        onChange={(_, newValue): void => (newValue ? setReason(newValue.value) : setReason(null))}
        renderInput={(params): JSX.Element => <TextField {...params} label={label} />}
      />
      {isReasonCommentVisible && (
        <TextField
          fullWidth
          value={reasonComment}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setReasonComment(event.target.value)}
          label="Reason Details"
          sx={{ mt: 2 }}
        />
      )}
    </>
  );
};
