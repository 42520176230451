import { color } from './config';

export function initFavicon(): void {
  const svg = `<svg width="32" height="32" viewBox="0 0 32 32" fill="${color}" xmlns="http://www.w3.org/2000/svg">
  <path d="M17 26L8.5 0L1 18.5L17 26Z"/>
  <path d="M29.7509 5.58183L19.984 21.86C17.0159 20.2533 15 17.1121 15 13.5C15 8.25329 19.2533 4 24.5 4C26.4414 4 28.2467 4.58232 29.7509 5.58183Z"/>
  <path d="M16.2737 30.4345C17.7729 31.424 19.5692 32 21.5 32C26.7467 32 31 27.7467 31 22.5C31 19.0835 29.1965 16.0881 26.4895 14.4142L16.2737 30.4345Z"/>
</svg>`;

  const link = document.createElement('link');
  link.type = 'image/svg+xml';
  link.rel = 'shortcut icon';
  link.href = `data:image/svg+xml,${encodeURIComponent(svg)}`;

  document.head.appendChild(link);
}
