import { isEqual, pickBy } from 'lodash';
import moment from 'moment-timezone';

/**
 * Collects the properties that have changed values between two objects.
 *
 * @param {T} changes - The object containing the new values.
 * @param {Record<keyof T, unknown>} source - The original object for comparison.
 * @returns {Partial<T>} - An object containing only the properties that have changed.
 * @template T - The type of the objects.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function collectChangedValues<T extends Record<string, unknown>>(changes: T, source: any): Partial<T> {
  return pickBy(changes, (value, key) => {
    const sourceValue = source[key as keyof T];
    if (moment.isMoment(value) && moment.isMoment(sourceValue)) {
      return !moment(value).isSame(sourceValue);
    }
    if (Array.isArray(value) && Array.isArray(sourceValue)) {
      return !isEqual(value, sourceValue);
    }
    if (typeof value === 'object' && typeof sourceValue === 'object') {
      return !isEqual(value, sourceValue);
    }
    return sourceValue !== value;
  }) as Partial<T>;
}
