import React from 'react';
import { ActionRecords } from '@features/action-records/ActionRecords';
import { HookResult } from '@features/action-records/use-progress-records';
import { useOrderContactRecords } from './use-order-contact-records';

type Props = {
  orderId: number;
};

export const OrderContactRecords: React.FC<Props> = ({ orderId }) => {
  const useEntries = (params: URLSearchParams, skip: number, count: number): HookResult =>
    useOrderContactRecords(orderId, count, skip);

  return <ActionRecords useEntries={useEntries} />;
};
