import React, { PropsWithChildren } from 'react';
import { FormControl, InputLabel, OutlinedInput, Stack } from '@mui/material';
import { useGeneratedSkeletons } from './use-generated-skeletons';

type Props = PropsWithChildren<{
  isLocked: boolean;
  hasData: boolean;
  label: string;
}>;

/**
 * A React component for sensitive information field.
 * When locked and having data, it shows skeletons to represent the sensitive data.
 * Otherwise, it displays the provided children, typically used for displaying actual data or input fields.
 */
export const SensitiveInformationField: React.FC<Props> = ({ children, isLocked, hasData, label }) => {
  const skeletons = useGeneratedSkeletons(isLocked, hasData);
  if (skeletons.length > 0) {
    return (
      <FormControl disabled fullWidth size="small">
        <InputLabel shrink>{label}</InputLabel>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            boxSizing: 'border-box',
            pl: 2,
          }}
        >
          {skeletons}
        </Stack>
        <OutlinedInput label={label} notched />
      </FormControl>
    );
  }

  return <>{children}</>;
};
